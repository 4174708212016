import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SimpleReactValidator from 'simple-react-validator'
import {get, put} from "../../helpers/api_helper"
import { EDIT_TARGET_ACHIEVEMENT, GET_BRAND_MASTER, GET_TARGET_ACHIEVEMENT,GET_BUSINESS_CATEGORY,GET_EMPLOYEES_PERMISSION} from "../../helpers/api_url_helper"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";


class assignTargetEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      brand: "",
      business_category: "",
      brand_master_id:"",
      assigned_to: "",
      assigned_to_master: [],
      brand_master: [],
      business_category_master: [],
      status: true,
      handleResponse: null,
      submit: false
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  componentDidMount() {
    this.getBrand();
    this.getBusinessCategory();
    this.loadMaster();
  }

  getBrand = () => {
    get(GET_BRAND_MASTER).then(res => {
      if (res.data) {
        let values = res.data.map((brand)=>{
          return {value:brand.id, label:brand.name}
        })
        this.setState({
          brand_master: values,
        })
      }else {
        toastr.error("Data load issue try again!")
      }
    }).catch(err => {
      toastr.error(err?.response?.data?.message)
      // eslint-disable-next-line react/prop-types
      const { history } = this.props;
      // eslint-disable-next-line react/prop-types
      history.push("/business-category");
    })
  }

  
  getBusinessCategory = () => {
    get(GET_TARGET_ACHIEVEMENT, {params: {id: this.props.match.params.id}}).then(res => {
      if (res.data) {
        this.setState({
          id: res.data.id,
          target: res.data.target,
          year: res.data.year,
          month: res.data.month,
          brand_master_id: res.data.brand_master_id,
        })
        this.setState({ brand: {value:res.data.brand_id, label: res.data.brand } }, this.loadBusinessCategory); 
        this.setState({ assigned_to: { value: res.data.employee_id, label: res.data.employee } });
        this.setState({ business_category: { value: res.data.business_category_id, label: res.data.business_category } });
      }else {
        toastr.error("Data load issue try again!")
      }
    }).catch(err => {
      toastr.error(err?.response?.data?.message)
      // eslint-disable-next-line react/prop-types
      const { history } = this.props;
      // eslint-disable-next-line react/prop-types
      // history.push("/business-category");
    })
  }
  
  loadMaster = () => {    
    get(GET_EMPLOYEES_PERMISSION, { params: { status: 1 } }).then(res => {
        if (res.status) {
          let data = res.data;
          this.setState({ assigned_to_master: data })
        }
      }).catch(err => {
        toastr.error(err)
      })
  }


  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = {
        "brand":this.state.brand.value,
        "business_category":this.state.business_category.value,
        "assigned_to":this.state.assigned_to.value,
        "target" : this.state.target,
        "id":this.props.match.params.id,
        "year" : this.state.year,
        "month" : this.state.month
      }
      this.setState({submit: true})
      put(EDIT_TARGET_ACHIEVEMENT,formData).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('Target update successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          history.push('/assign-target')
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message)
        this.setState({submit: false})
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  handleBrandChange = (selectedBrand) => {
    this.setState({ brand: selectedBrand, business_category: null }, () => {
      // Revalidate the brand field
      this.validator.showMessageFor("brand");
    });
    if (selectedBrand) {
      get(GET_BUSINESS_CATEGORY, { params: { id: selectedBrand.value } })
        .then((response) => {
          console.log(response);
          if (response.status) {
            this.setState({ business_category_master: response.data });
          } else {
            this.setState({ business_category_master: [] });
            toastr.error(response.message || "No business categories found.");
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the business categories!", error);
          toastr.error("Error fetching business categories.");
        });
    } else {
      this.setState({ business_category_master: [] });
    }
  }
  
  handleYearInput = (event) => {
    const year = event.target.value;
    const maxYear = new Date().getFullYear() + 2;
  
    // Ensure year is a four-digit number within range
    if (/^\d{4}$/.test(year) && year >= 1900 && year <= maxYear) {
      this.setState({ year });
    } else {
      this.setState({ year: "" });  // Clear input or show error
    }
  };  
  
  handleMonthInput = (event) => {
    const month = event.target.value;
  
    // Ensure month is a number between 1 and 12
    if (/^(0?[1-9]|1[0-2])$/.test(month)) {
      this.setState({ month });
    } else {
      this.setState({ month: "" });  // Clear input or show error
    }
  };  
  
  loadBusinessCategory = () => {
    const { brand } = this.state; // Destructure brand from state
    const brand_id = brand.value; // Directly assign brand.value to brand_id
    get(GET_BUSINESS_CATEGORY, { params: { id: brand_id } })
    .then((response) => {
      if (response.status) {
        this.setState({ business_category_master: response.data });
      } else {
        this.setState({ business_category_master: [] });
        toastr.error(response.message || "No business categories found.");
      }
    })
    .catch((error) => {
      console.error("There was an error fetching the business categories!", error);
      toastr.error("Error fetching business categories.");
    });
  } 

  render() {
    const { brand,brand_master,business_category,business_category_master,assigned_to,assigned_to_master} = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
            Manage Business Category | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Business Category" path="/business-category" breadcrumbItem="Edit"/>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="brand">Brand <span className="text-danger">*</span></Label>
                          <Select isMulti={false} value={brand} name="brand" options={brand_master} onChange={e => {this.setState({ brand: e }); this.handleBrandChange(e); }} classNamePrefix="select2-selection" />
                          {this.validator.message("brand", this.state.brand, "required")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="brand">Busines Category <span className="text-danger">*</span></Label>
                          <Select 
                              isMulti={false} 
                              value={business_category} 
                              options={business_category_master} 
                              onChange={e => this.setState({ business_category: e })} 
                              classNamePrefix="select2-selection" 
                              name="business_category"
                          />
                          {this.validator.message("business_category", business_category, "required")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="assigned_to">Target Assign To <span className="text-danger">*</span></Label>
                          <Select isMulti={false} value={assigned_to} options={assigned_to_master} name="assigned_to" onChange={e => this.setState({ assigned_to: e })} classNamePrefix="select2-selection" />
                          {this.validator.message("assigned_to", this.state.assigned_to, "required")}
                        </Col>
                        <Col md="2" className="mb-3">
                          <Label htmlFor="year">Year <span className="text-danger">*</span></Label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.handleYearInput}
                            style={{ width: "100%", maxWidth: "250px" }}
                            name="year"
                            defaultValue={this.state.year}
                            placeholder="YYYY"
                            pattern="\d{4}"
                            title="Please enter a valid year (e.g., 2024)"
                          />
                          {this.validator.message(
                            "year",
                            this.state.year,
                            `required|numeric|min:1900,num|max:${new Date().getFullYear() + 2},num`
                          )}
                        </Col>   
                        <Col md="2" className="mb-3">
                          <Label htmlFor="month">Month <span className="text-danger">*</span></Label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.handleMonthInput}
                            style={{ width: "100%", maxWidth: "250px" }}
                            name="month"
                            defaultValue={this.state.month}
                            placeholder="MM"
                            pattern="^(0?[1-9]|1[0-2])$"  // Matches 01 to 12
                            title="Please enter a valid month (01-12)"
                          />
                          {this.validator.message(
                            "month",
                            this.state.month,
                            "required|numeric|min:1,num|max:12,num"
                          )}
                        </Col>                   
                        <Col md="2" className="mb-3">
                          <Label htmlFor="target">Target <span className="text-danger">*</span></Label>
                          <input name="target" placeholder="Type Target" defaultValue={this.state.target} type="number" className={"form-control"} onChange={this.handleInput}/>
                          {this.validator.message('target', this.state.target, 'required')}
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={this.state.submit}>
                          {this.state.submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

assignTargetEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default assignTargetEdit
