import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/pcl_favicon.png"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { forgetPassword } from "store/forgetPassword/forgetPasswordSlice"
import toastr from "toastr"
const ForgetPassword = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const validationSchema = Yup.object().shape({
    empID: Yup.string().required("Employee ID is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  })
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>
                          Forget Password {process.env.REACT_APP_PROJECTNAME}.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div
                        className="avatar-lg mb-4"
                        style={{ marginTop: "-40px" }}
                      >
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="38"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={{ empID: "", email: "" }}
                      validationSchema={validationSchema}
                      onSubmit={async values => {
                        try {
                          const response = await dispatch(
                            forgetPassword(values)
                          )
                          console.log(response, "Verify Response Over here....")
                          if (
                            response?.meta?.requestStatus === "fulfilled" &&
                            response?.payload?.data?.status
                          ) {
                            toastr.success(response.payload.data.msg)
                            history.push("/login")
                          } else if (
                            response?.meta?.requestStatus === "rejected"
                          ) {
                            toastr.error("Invalid detail")
                          } else {
                            toastr.error("Something went wrong.")
                          }
                        } catch (error) {
                          console.log(error, "Verify Error Over Here")
                          toastr.error(error.message || "Something went wrong.")
                        }
                        console.log(values, "Verify Forget Password Values")
                      }}
                    >
                      {({ isSubmitting }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="empID" className="form-label">
                              Employee ID
                            </Label>
                            <Field
                              name="empID"
                              placeholder="Enter Employee ID"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="empID"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              Employee Email
                            </Label>
                            <Field
                              name="email"
                              type="email"
                              placeholder="Enter Employee Email"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Please wait..." : "Reset"}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="fw-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  {process.env.REACT_APP_PROJECTNAME}
                  <i className="mdi mdi-heart text-danger ms-2 me-2" />
                  by Innowrap
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPassword
