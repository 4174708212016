import React from "react"
import { ExpenseRuleView } from "./ExpenseRuleView"
import { Container } from "reactstrap"
import { ExpenseRuleFrom } from "./ExpenseRuleFrom"

export const ExpenseRuleList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <ExpenseRuleFrom/>
        <ExpenseRuleView />
      </Container>
    </div>
  )
}
