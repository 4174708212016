import React from "react";
import { Row, Col, Input, Label, Modal, Button } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { get } from "../../helpers/api_helper";
import { ADD_MEETING_DATA, GET_ALL_MASTERS,GET_USER_NAME, GET_LEADS_PERMISSION, GET_GOOGLE_LOCATION, GET_MEETING_LINK } from "../../helpers/api_url_helper";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr, { error } from "toastr";
import CreatableSelect from 'react-select/creatable';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class AddCommonMeeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      lead_id: "",
      meeting_purpose: "",
      meeting_start_date_time: "",
      meeting_end_date_time: "",
      meeting_with: "",
      meeting_conducted_by: "",
      meeting_link: "",
      meeting_location: "",
      google_location_list: [],
      meeting_type: "",
      meeting_participants: [],
      meeting_agenda: "",
      participant_master: [],
      submit: false,
      isShowing: false,
      handleResponse: null,
      formSubmitSuccess: false,
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
  }

  componentDidMount() {
    this.loadMaster();
  }

  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: "employees_email" } })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          this.setState({ participant_master: data.employees_email});
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
      
    get(GET_USER_NAME, { params: {} })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          this.setState({ meeting_conducted_by: data });
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
      
    get(GET_LEADS_PERMISSION, {  })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          this.setState({ leads: data});
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if(e.target.name==="meeting_location" && e.target.value !== ""){
      this.getGoogleLocationApi(e.target.value);
    }
  };

  pastDateTime = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  
  handleStartTimeChange(event) {
    const meeting_start_date_time = event.target.value;

    // Set the min attribute of the end time input dynamically
    const meeting_end_date_time_input = document.getElementsByName('meeting_end_date_time')[0];
    meeting_end_date_time_input.min = meeting_start_date_time;
  }
  
  handleParticipantsChange = (newValue) => {
    this.setState({ meeting_participants: newValue });
  };  

  handleMeetingTypeChange = (selectedOption) => {
    this.setState({ meeting_type: selectedOption }, () => {
      if (selectedOption.value === 1) {
        this.generateMeetingLink();
      }
    });
  };
  

  
  handleLeadChange = (selectedOption) => {
    this.setState({
      lead_id: selectedOption,
      meeting_with: selectedOption ? selectedOption.label : "",
    });
  };
  selectMeetingLocation = (location) => {
    this.setState({
      meeting_location: location,
      google_location_list: []
    });
  };
  
  clearLocation = () => {
    const { meeting_location, google_location_list } = this.state;
  
    // Check if the current meeting_location exists in the google_location_list
    const locationExistsInList = google_location_list.includes(meeting_location) || true;
  
    // Clear the meeting_location only if it does not exist in the list
    if (!locationExistsInList) {
      this.setState({ meeting_location: '', google_location_list: [] });
    }else{
      this.setState({ google_location_list: [] });
    }
  };
  
  
  handleLocationBlur = (e) => {
    if (!e.relatedTarget || !e.relatedTarget.classList.contains("google_location_option")) {
      this.clearLocation();
    }
  };
  
  getGoogleLocationApi = async (keyword) =>{
    const formData = new FormData();
      formData.append("location", keyword);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${GET_GOOGLE_LOCATION}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then((response) => {
          if (response.status) {
            this.setState({
              google_location_list: response?.data?.data
            });
          }
        })
        .catch((err) => {
          toastr.error(err.message);
          console.error(err.message);
        });
      
  }
  generateMeetingLink = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_APIURL}${GET_MEETING_LINK}`, {}, {
        headers: {
          Authorization: accessToken,
        }
      });
      
      if (response.status) {
        this.setState({ meeting_link: 'response.data.link' });
      } else {
        toastr.error('Failed to generate meeting link');
      }
    } catch (error) {
      toastr.error('Error generating meeting link');
      console.error(error);
    }
  };
  
  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = new FormData();
      formData.append("meeting_purpose", this.state.meeting_purpose);      
      const serializedParticipants = this.state.meeting_participants.map(participant => {
        return { value: participant.value, label: participant.label };
      });
      formData.append("meeting_participants", JSON.stringify(serializedParticipants));
      formData.append("meeting_agenda", this.state.meeting_agenda);
      formData.append("meeting_start_date_time", this.state.meeting_start_date_time);
      formData.append("meeting_end_date_time", this.state.meeting_end_date_time);
      formData.append("meeting_with", this.state.meeting_with);
      formData.append("meeting_conducted_by", this.state.meeting_conducted_by);
      formData.append("meeting_link", this.state.meeting_link);
      formData.append("meeting_location", this.state.meeting_location);
      formData.append(
        "lead_id",
        this.state.lead_id.value !== undefined && this.state.lead_id.value
      );
      formData.append(
        "meeting_type",
        this.state.meeting_type.value !== undefined && this.state.meeting_type.value
      );
      this.setState({ submit: true });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${ADD_MEETING_DATA}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then((response) => {
          if (response.status) {
            this.setState({ submit: false });
            this.setState({
              isShowing: false,
            });
            toastr.success("Meeting added successfully.");
            this.props.hide(false);
            this.setState({
              lead_id: "",
              meeting_purpose: "",
              meeting_participants: [],
              meeting_start_date_time: "",
              meeting_agenda: "",
              meeting_end_date_time: "",
              meeting_with: "",
              meeting_conducted_by: "",
              meeting_link: "",
              meeting_location: "",
              meeting_type: "",
            });
            this.props.reloadBoard(true);
          }
        })
        .catch((err) => {
          toastr.error(err.message);
          this.setState({ submit: false });
          alert(err.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const minDateTime = this.pastDateTime();
    const { leads, formSubmitSuccess, meeting_type, meeting_with, meeting_conducted_by,participant_master } = this.state;
    return (
      <React.Fragment>
        <Modal size="lg" isOpen={this.props.isShowing} toggle={this.props.hide} centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">ADD Meeting</h5>
            <button
              id="close_model"
              type="button"
              onClick={this.props.hide}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {formSubmitSuccess === false && (
              <form onSubmit={this.handleFormSubmit}>
                <Row>
                  <Col md="6" className="mb-3">
                    <label className="control-label">
                      Lead<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={leads}
                      classNamePrefix="select2-selection"
                      onChange={this.handleLeadChange}
                    />
                    {this.validator.message("lead_id", this.state.lead_id, "required")}
                  </Col>
                  <Col md="6" className="mb-3">
                    <label className="control-label">
                      Meeting Type<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={[
                        { label: "Virtual", value: 1 },
                        { label: "In Person", value: 2 },
                      ]}
                      classNamePrefix="select2-selection"
                      onChange={this.handleMeetingTypeChange}
                    />
                    {this.validator.message("meeting_type", this.state.meeting_type, "required")}
                  </Col>
                  {meeting_type && meeting_type.value === 1 && (
                    <Col md="12" className="mb-3">
                      <Label htmlFor="meeting_link">
                        Meeting Link<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="meeting_link"
                        placeholder="Type Meeting Link"
                        type="text"
                        value={this.state.meeting_link}
                        className={"form-control"}
                        onChange={this.handleInput}
                      />
                      {this.validator.message("meeting_link", this.state.meeting_link, "required|string")}
                    </Col>
                  )}
                  {meeting_type && meeting_type.value === 2 && (
                  <Col md="12" className="mb-3 position-relative">
                    <Label htmlFor="meeting_location">
                      Meeting Location<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="meeting_location"
                      placeholder="Type Meeting Location"
                      type="text"
                      value={this.state.meeting_location}
                      className={"form-control"}
                      onChange={this.handleInput}
                      onBlur={this.handleLocationBlur}
                    />
                    {this.state?.google_location_list?.length > 0 && (
                      <div 
                        className="google_location_option_list"
                        style={{
                          border: "1px solid #ced4da", 
                          borderRadius: '4px',
                          position: "absolute",
                          bottom: "-5px",
                          background: "#fff",
                          display: "block",
                          width: "calc(100% - 25px)",
                          zIndex: "1",
                          transform: "translateY(100%)",
                        }}>
                        {this.state.google_location_list.map((location) => (
                          <div 
                            key={location} 
                            className="cursor-pointer p-2 google_location_option" 
                            onClick={() => { this.selectMeetingLocation(location); }}
                            tabIndex="0"
                          >
                            {location}
                          </div>
                        ))}
                      </div>
                    )}
                    {this.validator.message("meeting_location", this.state.meeting_location, "required|string")}
                  </Col>
                )}

                  <Col md="8" className="mb-3">
                    <Label htmlFor="meeting_purpose">
                      Meeting Purpose<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="meeting_purpose"
                      placeholder="Type Meeting Purpose"
                      type="text"
                      className={"form-control"}
                      onChange={this.handleInput}
                    />
                    {this.validator.message("meeting_purpose", this.state.meeting_purpose, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="meeting_with">
                      Meeting with<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="meeting_with"
                      value={meeting_with}
                      type="text"
                      className={"form-control"}
                      readOnly
                    />
                    {this.validator.message("meeting_with", this.state.meeting_with, "required|string")}
                  </Col>
                  <Col md="6" className="mb-3">
                      <Label htmlFor="meeting_start_date_time">
                          Start Date & Time<span className="text-danger">*</span>
                      </Label>
                      <Input
                          name="meeting_start_date_time"
                          type="datetime-local"
                          className="form-control"
                          min={minDateTime} 
                          onChange={e => {this.setState({ meeting_start_date_time: e.target.value }); this.handleStartTimeChange(e); }}
                      />
                      {this.validator.message("meeting_start_date_time", this.state.meeting_start_date_time, "required")}
                  </Col>
                  <Col md="6" className="mb-3">
                      <Label htmlFor="meeting_end_date_time">
                          End  Date & Time<span className="text-danger">*</span>
                      </Label>
                      <Input
                          name="meeting_end_date_time"
                          type="datetime-local"
                          className="form-control"
                          min={this.state.meeting_start_date_time} // Set the min attribute dynamically
                          onChange={e => this.setState({ meeting_end_date_time: e.target.value })}
                      />
                      {this.validator.message("meeting_end_date_time", this.state.meeting_end_date_time, "required")}
                  </Col> 
                  <Col md="4" className="mb-3">
                    <Label htmlFor="meeting_conducted_by">
                    Conducted By<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="meeting_conducted_by"
                      value={meeting_conducted_by}
                      type="text"
                      className={"form-control"}
                      readOnly
                    />
                    {this.validator.message("meeting_conducted_by", this.state.meeting_conducted_by, "required|string")}
                  </Col>
                  <Col md="8" className="mb-3">
                      <label className="control-label">Participants<span className="text-danger">*</span></label>
                      <CreatableSelect
                          isMulti
                          options={participant_master}
                          classNamePrefix="select2-selection"
                          onChange={e => {this.setState({ meeting_participants: e }); this.handleParticipantsChange(e); }}
                          
                      />
                      {this.validator.message("meeting_participants", this.state.meeting_participants, "required")}
                  </Col>
                  <Col md="12" className="mb-3">
                      <Label htmlFor="meeting_agenda">
                      Meeting Agenda<span className="text-danger">*</span>
                      </Label>
                      <CKEditor
                          editor={ClassicEditor}
                          data={this.state.meeting_agenda}
                          onChange={(event, editor) => {
                              const data = editor.getData();
                              this.setState({meeting_agenda: data})
                          }}
                          name="meeting_agenda"
                      />
                      {this.validator.message("meeting_agenda", this.state.meeting_agenda, "required")}
                  </Col>
                </Row>

                <div className="d-flex flex-wrap gap-2 justify-content-end">
                  <Button color="primary" type="submit" disabled={this.state.submit}>
                    {this.state.submit === true ? "Please wait..." : "Submit Data"}
                  </Button>
                </div>
              </form>
            )}
            {formSubmitSuccess === true && (
              <div className="text-center mb-4">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light rounded-circle text-primary h1">
                    <i className="bx bx-user-check"></i>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">Success !</h4>
                    <p className="text-muted font-size-14 mb-4">Meeting add successful.</p>
                    <button
                      type="button"
                      id="button-addon2"
                      onClick={(e) => this.setState({ formSubmitSuccess: false })}
                      className="btn btn-primary"
                    >
                      Add New Meeting
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

AddCommonMeeting.propTypes = {
  attribs: PropTypes.any,
  isShowing: PropTypes.any,
  hide: PropTypes.any,
  reloadBoard: PropTypes.func,
};

export default AddCommonMeeting;
