import React from 'react'
import { useSelector } from 'react-redux'
import OnboardingEmergencyContactForm from '../OnbordingForms/OnboardingEmergencyContactForm'
import OnboardingEmergencyDetail from '../OnboardingDetails/OnboardingEmergencyDetail'

export const OnboardingEmergencyDetailView = () => {
    const {editEmergencyDetail} = useSelector((state) => state.onBoarding)
  return (
    <div>
        {editEmergencyDetail ? (
            <OnboardingEmergencyContactForm />
        ) : (
            <OnboardingEmergencyDetail />
        )}
    </div>
  )
}
