import { getUserId } from "constants/CommonFunction";
import { ApiService } from "./ApiService"

export async function apiGetExpenseDocument( { id,length, start }) {
    const params = {
      length,
      start
    };

    return ApiService.fetchData({
      url: `/employee_expenses/document_list/${id}`,
      method: "get",
      params: params,
    });
  }

export async function apiCreateExpenseDocument(id,data){
return ApiService.fetchData({
  url: `/employee_expenses/add_document/${id}`,
  method: "Post",
  headers: {
    'Content-Type': "multipart/form-data",
  },
  data: data,
});
}


export async function apiDeleteExpenseDocument(id, data) {
    // const urlEncodedPayload = new URLSearchParams(data).toString();

    return ApiService.fetchData({
      url: `/employee_expenses/remove_document/${id}`,
      method: "delete",
      headers: {
        'Content-Type': "multipart/form-data",
      },
      data: data,
    });
  }