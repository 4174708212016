import React from 'react'
import { ExpenseRewardList } from '../ExpenseReward';
import Expenses from '../Expenses';
import { useSelector } from 'react-redux';
export default function Expense() {
    const { expenses } = useSelector(state => state.users.userPermissions);
    const params = new URLSearchParams(location.search);
    let currentTab = params.get("tab") || "logs"; // Default to "logs" if no tab is set
  
    // Validating the current tab based on permissions
    const isValidTab = (tab) => {
      switch (tab) {
        case 'category':
          return expenses?.category?.list;
        case 'logs':
          return true; // "Logs" is always valid
        default:
          return false; // Invalid tab
      }
    };
  
    // If the current tab is not valid, default to "logs"
    if (!isValidTab(currentTab)) {
      currentTab = "logs";
    }
  
    // Function to return the correct component based on the current tab
    const getTabContent = () => {
      switch (currentTab) {
        case 'category':
          return <Expenses />;
        default:
          return <ExpenseRewardList />;
      }
    };
  
    return getTabContent();
  
}
