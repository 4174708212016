// Assuming imports are correctly set up
import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import Pagination from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchWorkDetail,
  setCurrentPage,
  setOpenWorkDetailModal,
  setTableRowData,
} from "../../store/workDetail/workDetailSlice"
import { formatDateFormat, getUserId } from "constants/CommonFunction"
import { setUserId } from "store/onBoarding/onBordingSlice"
import { useLocation } from "react-router-dom"

export const WorkDetails = () => {
  const dispatch = useDispatch()
  const { workDetailData, loading, totalData, currentPage } = useSelector(
    state => state.workDetail
  )
  console.log(workDetailData, "Verify Work Details")
  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "60px" }}
          >
            Sr.No
          </div>
        ),
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            {(currentPage - 1) * 10 + (row.index + 1)}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "160px" }}
          >
            Reporting Manager
          </div>
        ),
        accessor: "EmployeeId",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            {row.original.reporting_manager_employee_first_name &&
            row.original.reporting_manager_employee_last_name
              ? `${row.original.reporting_manager_employee_first_name} ${row.original.reporting_manager_employee_last_name}`
              : "-"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "120px" }}
          >
            Probation Period
          </div>
        ),
        accessor: "probation_period_name",
        Cell: ({ value }) => (
          <div className="d-flex justify-content-center">
            {value ? value : "-"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "120px" }}
          >
            Employee Id
          </div>
        ),
        accessor: "emp_no",
        Cell: ({ value }) => (
          <div className="d-flex justify-content-center">
            {value ? value : "-"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "200px" }}
          >
            Department
          </div>
        ),
        accessor: "department_master_name",
        Cell: ({ row }) => {
          const { department_master_name, department_name } = row.original
          const value = department_master_name || department_name || "-"
          return <div className="d-flex justify-content-center">{value}</div>
        },
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "150px" }}
          >
            Sub Department
          </div>
        ),
        accessor: "sub_department_master_name",
        Cell: ({ row }) => {
          const { sub_department_master_name, sub_department_name } =
            row.original
          const value = sub_department_master_name || sub_department_name || "-"
          return <div className="d-flex justify-content-center">{value}</div>
        },
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "150px" }}
          >
            Designation
          </div>
        ),
        accessor: "designation_master_name",
        Cell: ({ row }) => {
          const { designation_master_name, designation_name } = row.original
          const value = designation_master_name || designation_name || "-"
          return <div className="d-flex justify-content-center">{value}</div>
        },
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "100px" }}
          >
            Created On
          </div>
        ),
        accessor: "created_on",
        Cell: ({ value }) => (
          <div className="d-flex justify-content-center">
            {value ? formatDateFormat(value) : "-"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "80px" }}
          >
            Job Title
          </div>
        ),
        accessor: "job_title",
        Cell: ({ value }) => (
          <div className="d-flex justify-content-center">
            {value ? value : "-"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "110px" }}
          >
            Company Name
          </div>
        ),
        accessor: "company_name",
        Cell: ({ value }) => (
          <div className="d-flex justify-content-center">
            {value ? value : "-"}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            className="d-flex justify-content-center"
            style={{ width: "100px" }}
          >
            Action
          </div>
        ),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center" style={{ gap: "5px" }}>
            <i
              onClick={async () => {
                await dispatch(setTableRowData(row.original))
                await dispatch(setOpenWorkDetailModal(true))
              }}
              className="bx bxs-edit cursor-pointer"
              style={{ fontSize: "17px", color: "#039f03" }}
            />
          </div>
        ),
      },
    ]
  }, [dispatch, currentPage])

  const handleAdd = async () => {
    await dispatch(setTableRowData([]))
    await dispatch(setOpenWorkDetailModal(true))
  }

  const location = useLocation()

  useEffect(() => {
    const profileDetail = async () => {
      const newUserId = location?.state?.row?.id
        ? location?.state?.row?.id
        : getUserId()
      await dispatch(setUserId(newUserId))
      await dispatch(fetchWorkDetail({ id: newUserId }))
    }
    profileDetail()
  }, [dispatch, location, currentPage])

  return (
    <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <div className="d-flex justify-content-between mb-3">
              <CardTitle>Work Detail</CardTitle>
              <Button
                className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                onClick={handleAdd}
              >
                <i
                  className="dripicons-plus mx-2 d-flex"
                  style={{ border: "1px solid white", borderRadius: "50%" }}
                />
                Add
              </Button>
            </div>
            {workDetailData?.map(item => (
              <Card key={item?.id} className="">
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center">
                        <span className="fs-5 ">Work Detail</span>
                        <Col>
                          <div className="d-flex justify-content-end mx-3 gap-3">
                            <i
                              className="bx bxs-edit text-primary fs-5 cursor-pointer"
                              onClick={async () => {
                                await dispatch(setTableRowData(item))
                                await dispatch(setOpenWorkDetailModal(true))
                              }}
                            />
                          </div>
                        </Col>
                      </div>
                      <hr className="underline" />
                    </Col>
                    <Col md={3}>
                      <label>Reporting Manager</label>
                      <div>
                        {item?.reporting_manager_employee_first_name &&
                        item?.reporting_manager_employee_last_name
                          ? `${item.reporting_manager_employee_first_name} ${item.reporting_manager_employee_last_name}`
                          : "-"}
                      </div>
                    </Col>
                    <Col md={3}>
                      <label>Probation Period</label>
                      <div>
                        {item?.probation_period_name
                          ? item?.probation_period_name
                          : "-"}
                      </div>
                    </Col>
                    <Col md={3}>
                      <label>Employee Id</label>
                      <div>{item?.emp_no ? item?.emp_no : "-"}</div>
                    </Col>
                    <Col md={3}>
                      <label>Department</label>
                      <div>
                        {item?.department_master_name
                          ? item?.department_master_name
                          : "-"}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={3}>
                      <label>Sub Department</label>
                      <div>
                        {item?.sub_department_master_name
                          ? item?.sub_department_master_name
                          : "-"}
                      </div>
                    </Col>
                    <Col md={3}>
                      <label>Designation</label>
                      <div>
                        {item?.designation_master_name
                          ? item?.designation_master_name
                          : "-"}
                      </div>
                    </Col>
                    <Col md={3}>
                      <label>Created On</label>
                      <div>
                        {item?.created_on
                          ? formatDateFormat(item?.created_on)
                          : "-"}
                      </div>
                    </Col>
                    <Col md={3}>
                      <label> Job Title</label>
                      <div>{item?.job_title ? item?.job_title : "-"}</div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={3}>
                      <label>Company Name</label>
                      <div>{item?.company_name ? item?.company_name : "-"}</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
            </Col>
      </Row>
    </div>
  )
}
