import { ApiService } from "./ApiService"

export async function apiEmployeeTypeReward() {
  return ApiService.fetchData({
    url: `/users/employee_list?status=1`,
    method: "get",
  })
}

export async function apiGetDeductionReward({
  length,
  start,
  keyword,
  status,
  employee_id,
  from_date,
  to_date,
}) {
  return ApiService.fetchData({
    url: `employee_deduction_rewards`,
    method: "get",
    params: { length, start, keyword, status, employee_id, from_date, to_date },
  })
}

export async function apiEditRewardRule(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_deduction_rewards/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiCreateDeductionReward(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_deduction_rewards`,
    method: "Post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiApproveStatus(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_deduction_rewards/employee_deduction_status_change/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}
