import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import { setEditSalaryDetail } from "store/onBoarding/onBordingSlice"

const OnboardingSalaryDetail = () => {
  const { profileDetails, editProfile } = useSelector(state => state.onBoarding)
  const dispatch = useDispatch()
  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Col md={12}>
                  <div className="d-flex align-items-center">
                    <CardTitle className="header-text">
                      Salary Details
                    </CardTitle>
                    {editProfile && (
                      <Col>
                        <div className="d-flex justify-content-end">
                          <i
                            className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer"
                            onClick={() => {
                              dispatch(setEditSalaryDetail(true))
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <hr className="underline" />
                </Col>
                <Row className="mb-3">
                  <Col md={3}>
                    <Label className="labels">Lock in Period </Label>
                    <div className="values">
                      {profileDetails?.lock_in_period
                        ? profileDetails?.lock_in_period
                        : "-"}
                    </div>
                  </Col>
                  <Col md={3}>
                    <label className="labels">Salary</label>
                    <div className="values">
                      {profileDetails?.salary ? profileDetails?.salary : "-"}
                    </div>
                  </Col>
                  <Col md={3}>
                    <label className="labels">Training Amount</label>
                    <div className="values">
                      {profileDetails?.training_amount
                        ? profileDetails?.training_amount
                        : "-"}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnboardingSalaryDetail
