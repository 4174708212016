import { ApiService } from "./ApiService";


export async function apiGetResignationInfo({ employee_id }) {
  return ApiService.fetchData({
    url: `/users/exit_requests/${employee_id}`,
    method: "get",
    // params: { length, start },
  });
}

export async function apiCreateResignationInfo({employee_id, resignation_date, remark, resignation_reason_master_id}) {
  return ApiService.fetchData({
    url: `/users/exit/${employee_id}`,
    method: "post",
    data: {employee_id, resignation_date, remark, resignation_reason_master_id},
  });
}

export async function apiEditResignationInfo({employee_id, actual_last_day, remark, resignation_reason_master_id}) {
  return ApiService.fetchData({
    url: `/users/exit/${employee_id}`,
    method: "put",
    data: {employee_id, actual_last_day, remark, resignation_reason_master_id},
  });
}

export async function apiUpdateStatus({employeeId, status}) {
  return ApiService.fetchData({
    url: `/users/approve_exit/${employeeId}`,
    method: "post",
    data:{status},
  });
}

export async function apiResignationReasonMaster() {
  return ApiService.fetchData({
    url: `/resignation_reason_masters`,
    method: "get",
  });
}
