import React from "react"
import { DeductionRewardView } from "./DeductionRewardView"
import { Container } from "reactstrap"
import { DeductionRewardForm } from "./DeductionRewardForm"

export const DeductionRewardList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <DeductionRewardForm/>
        <DeductionRewardView />
      </Container>
    </div>
  )
}
