import React from "react"
import { OtherDocument } from "./OtherDocument"
import { OtherDocumentForm } from "./OtherDocumentForm"

export const OtherDocumentList = () => {
  return (
    <div>
      <OtherDocumentForm />
      <OtherDocument />
    </div>
  )
}

