import { ApiService } from "./ApiService"

export async function apiGetEmployeeAccessory({ length, start, keyword}) {
  return ApiService.fetchData({
    url: `employee_assets`,
    method: "get",
    params: { length, start, keyword},
  })
}

export async function apiEditEmployeeAccessory(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_assets/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiCreateEmployeeAccessory(formData) {
  // const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_assets`,
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  })
}
export async function apiGetEmployeeData() {
  return ApiService.fetchData({
    url: "/users/employee_list?status=1",
    method: "get",
  })
}

export async function apiGetOfficeUserLocation() {
  return ApiService.fetchData({
    url: `office_location/get?status=1`,
    method: "get",
  })
}

export async function apiGetAssetType() {
  return ApiService.fetchData({
    url: `asset_type_master?status=1`,
    method: "get",
  })
}