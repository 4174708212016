import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateLeaveLog,
  apiCancelLeaveLog,
  apiGetLeaveLog,
  apiGetLeaveLogTypes,
  apiGetLeaveLogEmployee,
} from "services/LeaveLogService"

export const SLICE_NAME = "leaveLog"

const initialState = {
  loading: false,
  applyLeaveModal: false,
  leaveLogData: [],
  leaveLogCurrentPage: 1,
  leaveLogTotalData: 0,
  leaveLogSearchQuery: "",
  leavesEmployeeData: [],
  leaveTypes: [],
  leaveLogStatus: "",
  leaveLogEmployee: "",
}

export const fetchLeaveLog = createAsyncThunk(
  `${SLICE_NAME}/fetchLeaveLog`,
  async ({ start, length, keyword, status, employee_id }) => {
    try {
      const response = await apiGetLeaveLog({
        start,
        length,
        keyword,
        status,
        employee_id,
      })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchLeaveLogEmployee = createAsyncThunk(
  `${SLICE_NAME}/fetchLeaveLogEmployee`,
  async () => {
    try {
      const response = await apiGetLeaveLogEmployee()
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchLeaveTypes = createAsyncThunk(
  `${SLICE_NAME}/fetchLeaveTypes`,
  async (id) => {
    try {
      const response = await apiGetLeaveLogTypes(id)
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const creteLeaveLog = createAsyncThunk(
  `${SLICE_NAME}/creteLeaveLog`,
  async data => {
    try {
      const response = await apiCreateLeaveLog(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const cancelLeaveLog = createAsyncThunk(
  `${SLICE_NAME}/cancelLeaveLog`,
  async id => {
    try {
      const response = await apiCancelLeaveLog(id)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const leaveLog = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.leaveLog.loading = action.payload
    },
    setApplyLeaveModal: (state, action) => {
      state.applyLeaveModal = action.payload
    },
    setleaveLogCurrentPage: (state, action) => {
      state.leaveLogCurrentPage = action.payload
    },
    setLeaveLogSearchQuery: (state, action) => {
      state.leaveLogSearchQuery = action.payload
    },
    setLeaveLogStatus: (state, action) => {
      state.leaveLogStatus = action.payload
    },
    setLeaveLogEmployee: (state, action) => {
      state.leaveLogEmployee = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLeaveLog.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchLeaveLog.fulfilled, (state, action) => {
      state.loading = false
      state.leaveLogData = action?.payload?.data?.data
      state.leaveLogTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchLeaveLog.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchLeaveTypes.fulfilled, (state, action) => {
      state.leaveTypes = action?.payload?.data?.data.map(item => ({
        value: item.id,
        label: item.name,
      }))
    })
    builder.addCase(fetchLeaveLogEmployee.fulfilled, (state, action) => {
      const defaultEmployee = { value: "", label: "All" }
      const leaveEmployee = action?.payload?.data?.data
        .map(item => ({
          value: item?.employee_id,
          label: `${item?.first_name} ${item?.last_name}`,
        }))
        .reduce((unique, item) => {
          const isDuplicate = unique.some(
            uniqueItem => uniqueItem.label === item.label
          )
          if (!isDuplicate) {
            unique.push(item)
          }
          return unique
        }, [])
      state.leavesEmployeeData = [defaultEmployee, ...leaveEmployee]
    })
  },
})

export const {
  setLoading,
  setApplyLeaveModal,
  setleaveLogCurrentPage,
  setLeaveLogSearchQuery,
  setLeaveLogStatus,
  setLeaveLogEmployee,
} = leaveLog.actions

export default leaveLog.reducer
