import { locations } from "constants/CommonFunction"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row, Label } from "reactstrap"
import {
  approveProfile,
  fetchProfileDetail,
  setEditAddressDetail,
} from "store/onBoarding/onBordingSlice"
import toastr from "toastr"

const OnboardingAddressDetail = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId, editProfile } = useSelector(
    state => state.onBoarding
  )
  const approveKycDocument = async (documentType, statusType) => {
    try {
      const payload = {
        [documentType]: statusType,
      }
      const response = await dispatch(
        approveProfile({ id: userId, data: payload })
      )
      if (response?.payload?.status === "success") {
        const successMessage =
          response.payload.data.data.message || "Data updated successfully"
        toastr.success(successMessage)
      } else if (response?.payload?.status === "failed") {
        const failureMessage = response.payload.message || "Verification failed"
        toastr.error(failureMessage)
      }
    } catch (error) {
      toastr.error("Something Went Wrong")
    }
    await dispatch(fetchProfileDetail(userId))
  }

  return (
    <div className="d-flex justify-content-center">
      <Row className="profile-container">
        <Col xl="12">
          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                  <div className="d-flex">
                    <CardTitle className="header-text d-flex">
                      Addresses{" "}
                      <div className="d-flex align-items-center">
                        {profileDetails?.address?.is_approved == 1 ? (
                          <i
                            className="fas fa-check mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "#5BD94E",
                              padding: "2px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : profileDetails?.address?.is_approved == 2 ? (
                          <i
                            className="fas fa-times mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "red",
                              padding: "4px",
                              borderRadius: "70%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </CardTitle>
                    <Col>
                      <div
                        className="d-flex justify-content-end"
                        style={{ gap: "10px" }}
                      >
                        {profileDetails?.address?.c_address_line_1?.length >
                          0 && (
                          <div
                            className="d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            {profileDetails?.address?.is_approved == 0 &&
                            profileDetails?.is_action ? (
                              <>
                                <button
                                  onClick={() =>
                                    approveKycDocument("approve_address", 1)
                                  }
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    approveKycDocument("approve_address", 2)
                                  }
                                  disabled={
                                    profileDetails?.address?.is_approved == 2
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                >
                                  Reject
                                </button>
                              </>
                            ) : null}
                          </div>
                        )}
                        {editProfile && (
                          <i
                            className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer d-flex align-items-center"
                            onClick={() => {
                              dispatch(setEditAddressDetail(true))
                            }}
                          />
                        )}
                      </div>
                    </Col>
                  </div>
                  <hr className="underline" />
                </Col>
                <Col md={12} className="mt-2">
                  <div className="d-flex align-items-center">
                    <CardTitle className="header-text fs-6">
                      Current Address
                    </CardTitle>
                    {/* <Col>
                      <div className="d-flex justify-content-end">
                        <i
                          className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer"
                          onClick={() => {
                            dispatch(setEditAddressDetail(true))
                          }}
                        />
                      </div>
                    </Col> */}
                  </div>
                  <hr className="underline" />
                </Col>
                <Col md="6" className="mb-3">
                  <Label className="labels">Address line 1</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_address_line_1
                      ? profileDetails?.address?.c_address_line_1
                      : "-"}
                  </p>
                </Col>
                <Col md="6" className="mb-3">
                  <Label className="labels">Address line 2</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_address_line_2
                      ? profileDetails?.address?.c_address_line_2
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">Landmark</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_landmark
                      ? profileDetails?.address?.c_landmark
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">Country</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_location_countries_master_name
                      ? profileDetails?.address
                          ?.c_location_countries_master_name
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">State</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_location_states_master_name
                      ? profileDetails?.address?.c_location_states_master_name
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">City</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_location_master_name
                      ? profileDetails?.address?.c_location_master_name
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">Pincode</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.c_pincode
                      ? profileDetails?.address?.c_pincode
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={12}>
                  <CardTitle className="header-text fs-6">
                    Permanent Address
                  </CardTitle>
                  <hr className="underline" />
                </Col>
                <Col md="6" className="mb-3">
                  <Label className="labels">Address line 1</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_address_line_1
                      ? profileDetails?.address?.p_address_line_1
                      : "-"}
                  </p>
                </Col>
                <Col md="6" className="mb-3">
                  <Label className="labels">Address line 2</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_address_line_2
                      ? profileDetails?.address?.p_address_line_2
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">Landmark</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_landmark
                      ? profileDetails?.address?.p_landmark
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">Country</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_location_countries_master_name
                      ? profileDetails?.address
                          ?.p_location_countries_master_name
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">State</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_location_states_master_name
                      ? profileDetails?.address?.p_location_states_master_name
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">City</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_location_master_name
                      ? profileDetails?.address?.p_location_master_name
                      : "-"}
                  </p>
                </Col>
                <Col md="3" className="mb-3">
                  <Label className="labels">Pincode</Label>
                  <p className="form-control-static values">
                    {profileDetails?.address?.p_pincode
                      ? profileDetails?.address?.p_pincode
                      : "-"}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default OnboardingAddressDetail
