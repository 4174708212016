import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiForgetPassword,
  apiResetPassword,
  apiVerifyToken,
} from "services/ForgetPasswordService"
export const SLICE_NAME = "forgetPassword"
import toastr from "toastr"

const initialState = {
  loading: false,
  status: null,
  message: null,
}

export const verifyToken = createAsyncThunk(
  `${SLICE_NAME}/verifyToken`,
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await apiVerifyToken({ token })
      return {
        status: "success",
        data: response.data,
      }
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const forgetPassword = createAsyncThunk(
  `${SLICE_NAME}/forgetPassword`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiForgetPassword(data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)



export const resetPassword = createAsyncThunk(
  `${SLICE_NAME}/resetPassword`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiResetPassword(data);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message || error.toString();
      toastr.error(errorMessage);
      return rejectWithValue({
        status: "failed",
        message: errorMessage,
      });
    }
  }
);


const forgetPasswordSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(forgetPassword.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(forgetPassword.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.status = "success";
  //       state.message = "Password reset link has been sent to your email.";
  //     })
  //     .addCase(forgetPassword.rejected, (state, action) => {
  //       state.loading = false;
  //       state.status = action.payload.status;
  //       state.message = action.payload.message;
  //     });
  // },
})

export const { setLoading } = forgetPasswordSlice.actions

export default forgetPasswordSlice.reducer
