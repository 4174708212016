import React ,{useEffect} from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,Input
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import Select from "react-select"
import {
  createExpenseReward,
  editExpenseReward,
  fetchExpenseReward,
  setExpenseRewardModal,
  fetchEmployeeFormData,
  fetchExpenseTypeFormData
} from "store/expenseReward/expenseRewardSlice"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for react-datepicker
import moment from "moment"

export const ExpenseRewardForm = () => {
  const dispatch = useDispatch()

  const { openRewardModal, rewardRowData, currentPage,employeeFormData ,expenseTypeFormData,review,EmployeeTypeId,dateRange}
  = useSelector(
    state => state.expenseReward
  )
  const validationSchema = Yup.object({
    employee_id:Yup.string().required("Required"),
    expense_type: Yup.string().required("Required"),
    remark: Yup.string().required("Required"),
    amount: Yup.number()
    .typeError("Must be a number")
    .max(99999, "Must be less than or equal to 99999")
    .required("Required"),
    expense_date: Yup.date().required("Required").typeError("Invalid date format")
    .test('format', 'Invalid date format. Use dd-MM-yyyy', (value) => {
      // Validate date format
      return value ? moment(value, 'DD-MM-YYYY', true).isValid() : false;
    }),
    documents:Yup.mixed().required("Required"),
  })

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchEmployeeFormData())
      await dispatch(fetchExpenseTypeFormData())

    }
    fetchData()
  }, [dispatch])

  const toggle = async () => {
    await dispatch(setExpenseRewardModal(false))
  }

  const handleInvalidDate = (dateString) => {
    if (!dateString) return null; // Handle null/undefined dates
    const date = new Date(dateString + "T00:00:00");
    return !isNaN(date.getTime()) ? date : null;

  };

  return (
    <Modal
    isOpen={openRewardModal}
    style={{ maxWidth: "400px" }}
    scrollable
    toggle={toggle}
    size="lg"
    centered>
      <ModalHeader toggle={toggle}>
        {rewardRowData?.id ? "Edit" : "Add"} Expense
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                employee_id: rewardRowData?.employee_id || "",
                expense_type: rewardRowData?.expense_type || "",
                amount: rewardRowData?.amount || "",
                remark: rewardRowData?.remark || "",
                expense_date: rewardRowData?.expense_date || "", // Default to current date if invalid =new Date()
                documents:[]
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting })=> {
                const formData = new FormData();
                  formData.append('employee_id', values.employee_id);
                  formData.append('expense_type', values.expense_type);
                  formData.append('amount', values.amount);
                  formData.append('remark', values.remark);
                  formData.append('expense_date', values.expense_date );


                    values.documents.forEach((file, index) => {
                      formData.append('documents[]', file);
                    });

                    // const logFormData = (formData) => {
                      // for (let pair of formData.entries()) {
                        // console.log(`${pair[0]}: ${pair[1]}`);
                      // }
                    // };
                    // logFormData(formData);

                const response = rewardRowData?.id
                  ? await dispatch(
                      editExpenseReward({
                        id: rewardRowData?.id,
                        data: formData,
                      })
                    ).unwrap()
                  : await dispatch(createExpenseReward(formData)).unwrap()
                  try {
                  if (response.data?.status) {
                    toastr.success(response?.data?.message || "Operation successful")
                  } else {
                    toastr.error(response?.data?.message || "Something went wrong")
                  }
                } catch (error) {
                  toastr.error("Something Went Wrong Please Try Later")
                } finally {
                  await dispatch(
                    fetchExpenseReward({ length: 10,
                       start: currentPage ,
                       status: review,
                       employee_id: EmployeeTypeId,
                       from_date:dateRange[0]?formatDateFormat(dateRange[0],'YYYY-MM-DD'):"",
                       to_date:dateRange[1]?formatDateFormat(dateRange[1],'YYYY-MM-DD'):""
                      })
                  )
                  await dispatch(setExpenseRewardModal(false))
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (

                <Form>
                  <Row>
                  <Col md="12" className="mb-3">
                    <Label htmlFor="employee">
                      Employee
                      <span className="text-danger">*</span>
                    </Label>
                    <Select
                        name="employee_id"
                        value={employeeFormData.find(employee => employee.value == values.employee_id)}
                        onChange={option => setFieldValue("employee_id", option?.value || "")}
                        options={employeeFormData}
                        classNamePrefix="select2-selection"
                      />
                  <ErrorMessage
                    name="employee_id"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="type">
                        Expense Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="expense_type"
                        value={expenseTypeFormData?.find(
                                                  option => option.value == values.expense_type
                                                )}
                        onChange={option => {
                          setFieldValue(
                          "expense_type",
                          Number(option.value)
                          )
                          }}
                          options={expenseTypeFormData}

                      />
                      <ErrorMessage
                        name="expense_type"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="amount">
                        Amount <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="amount"
                        min="0"
                        max="99999"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (value.length > 5) {
                            e.target.value = value.slice(0, 5);
                          }
                        }}
                        placeholder="Amount"
                        type="number"
                        className={`form-control ${
                          touched.amount && errors.amount ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                        Remark <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="remark"
                        placeholder="Remark"
                        type="text"
                        className={`form-control ${
                          touched.remark && errors.remark ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="remark"
                        component="div"
                        className="text-danger"
                      />
                    </Col>

                    <Col md="12" className="mb-3">
                    <Label htmlFor="dob">Expense Date<span className="text-danger">*</span>
                    </Label>
                   <Field
                      name="expense_date"
                      type="date"
                      className={`form-control ${
                        touched.expense_date && errors.expense_date
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {touched.expense_date && errors.expense_date && (
                      <div className="text-danger">{errors.expense_date}</div>
                    )}
                      </Col>
                      {!rewardRowData?.id &&
                      <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                        Document :
                        </Label>
                        <Input type="file"
                        multiple
                        className={"form-control"}
                        name="documents"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        id="documents"
                        aria-describedby="documents"
                        aria-label="Upload"
                        onChange={(e) => {
                        setFieldValue("documents",Array.from(e.target.files))
                        }}/>
                        <ErrorMessage
                          name="documents"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                       }
                        </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >

                      {rewardRowData?.id ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
