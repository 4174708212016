import React, { useEffect } from "react"
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { selectHalf } from "constants/Constants"
import {
  creteLeaveLog,
  fetchLeaveLog,
  fetchLeaveTypes,
  setApplyLeaveModal,
} from "store/leaveLogs/leaveLogSlice"
import toastr from "toastr"
import { getUserId } from "constants/CommonFunction"
import { useLocation } from "react-router-dom"

export const ApplyLeaveForm = () => {
  const {
    applyLeaveModal,
    leaveTypes,
    leaveLogCurrentPage,
    leaveLogSearchQuery,
  } = useSelector(state => state.leaveLog)
  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    employee_leave_rule_id: Yup.string().required("Required"),
    start_date: Yup.date().required("Required"),
    start_half: Yup.string().required("Required"),
    end_date: Yup.date().required("Required"),
    end_half: Yup.string().required("Required"),
    reason: Yup.string().required("Required"),
  })

  const toggle = () => {
    dispatch(setApplyLeaveModal(false))
  }
  const location = useLocation()

  useEffect(() => {
    const newUserId = location?.state?.row?.id
        ? location?.state?.row?.id
        : getUserId()
        console.log('before',newUserId)
    dispatch(fetchLeaveTypes(newUserId))
  }, [])

  return (
    <Modal
      scrollable
      style={{ maxWidth: "400px" }}
      isOpen={applyLeaveModal}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>Apply Leave</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                employee_leave_rule_id: "",
                start_date: "",
                end_date: "",
                start_half: "",
                end_half: "",
                reason: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                try {
                  const response = await dispatch(
                    creteLeaveLog(values)
                  ).unwrap()
                  if (response?.data?.data?.status) {
                    toastr.success(response?.data?.data.message)
                    await dispatch(setApplyLeaveModal(false))
                  } else {
                    toastr.error(response?.message || "Something went wrong")
                    console.log('1st', response)
                  }
                } catch (error) {
                  toastr.error("Something went wrong")
                  console.log('2nd', error)
                } finally {
                  await dispatch(
                    fetchLeaveLog({
                      start: leaveLogCurrentPage,
                      length: 10,
                      keyword: leaveLogSearchQuery,
                    })
                  )
                  toggle();
                }
              }}
            >
              {({ values, setFieldValue, isSubmitting, errors, touched }) => (
                <Form>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="employee_leave_rule_id">
                        Leave Type<span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="employee_leave_rule_id"
                        value={leaveTypes.find(
                          type => type.value == values.employee_leave_rule_id
                        )}
                        onChange={option =>
                          setFieldValue("employee_leave_rule_id", option.value)
                        }
                        options={leaveTypes}
                        classNamePrefix="select2-selection"
                      />
                      {touched.employee_leave_rule_id &&
                        errors.employee_leave_rule_id && (
                          <div className="text-danger">
                            {errors.employee_leave_rule_id}
                          </div>
                        )}
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="start_date">
                        Start Date<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="start_date"
                        type="date"
                        className={`form-control ${
                          touched.start_date && errors.start_date
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.start_date && errors.start_date && (
                        <div className="text-danger">{errors.start_date}</div>
                      )}
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="start_half">
                        Select Half<span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="start_half"
                        value={selectHalf.find(
                          status => status.value === values.start_half
                        )}
                        onChange={option =>
                          setFieldValue("start_half", option.value)
                        }
                        options={selectHalf}
                        classNamePrefix="select2-selection"
                      />
                      {touched.start_half && errors.start_half && (
                        <div className="text-danger">{errors.start_half}</div>
                      )}
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="end_date">
                        End Date<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="end_date"
                        type="date"
                        className={`form-control ${
                          touched.end_date && errors.end_date
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.end_date && errors.end_date && (
                        <div className="text-danger">{errors.end_date}</div>
                      )}
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="end_half">
                        Select Half<span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="end_half"
                        value={selectHalf.find(
                          status => status.value === values.end_half
                        )}
                        onChange={option =>
                          setFieldValue("end_half", option.value)
                        }
                        options={selectHalf}
                        classNamePrefix="select2-selection"
                      />
                      {touched.end_half && errors.end_half && (
                        <div className="text-danger">{errors.end_half}</div>
                      )}
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="reason">
                        Reason<span className="text-danger">*</span>
                      </Label>
                      <Field
                        as="textarea"
                        name="reason"
                        placeholder="Write your reason"
                        rows="3"
                        className={`form-control ${
                          touched.reason && errors.reason ? "is-invalid" : ""
                        }`}
                      />
                      {touched.reason && errors.reason && (
                        <div className="text-danger">{errors.reason}</div>
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
