import React from 'react'
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"

export  const CorporateOfficeView = () => {
    return (
        <>
            <Row className='d-flex justify-content-center'>
            <Col className="profile-container" xl="12">
                <Card className="mb-lg-75">
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="d-flex align-items-center">
                          <CardTitle className="header-text">
                          Corporate Office
                          </CardTitle>
                        </div>
                        <hr className="underline" />
                      </Col>
                      <Col md="4" className="mb-3">
                        <Label className="labels" htmlFor="company_name">
                        Party Cruisers Limited
                        </Label>
                        <div className="values">
                          301, 3rd Floor, Raheja Point 1, 
                          near Pandit Jawaharlal Nehru Rd, 
                          P and T Colony, Vakola, Santacruz East, 
                          Mumbai, Maharashtra 400055.
                        </div>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </>
      )


}
