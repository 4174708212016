import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiLoginUser, apiUpdatePassword } from "services/LoginService"

export const SLICE_NAME = "login"

const initialState = {
  loading: false,
}

export const updatePassword = createAsyncThunk(
  `${SLICE_NAME}/editEmergencyDetail`,
  async data => {
    try {
      const response = await apiUpdatePassword(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const loginUser = createAsyncThunk(
  `${SLICE_NAME}/loginUser`,
  async data => {
    try {
      const response = await apiLoginUser(data) // Call the API function
      return {
        status: "success", // Return success status and response data
        data: response,
      }
    } catch (error) {
      return {
        status: "failed", // Return failed status and error message
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const login = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: builder => {
    // builder.addCase(fetchLogin.pending, state => {
    //   state.loading = true
    // })
    // builder.addCase(fetchLogin.fulfilled, (state, action) => {
    //   state.loading = false
    //   state.documentData = action?.payload?.data?.data
    //   state.totalData = action?.payload?.data?.total
    // })
    // builder.addCase(fetchLogin.rejected, state => {
    //   state.loading = false
    // })
  },
})

export const { setLoading } = login.actions

export default login.reducer
