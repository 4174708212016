import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Card, CardBody, Input, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import Pagination from "components/Common/Pagination"
import { TableHeader, Search } from "components/Datatable/index"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import useFullPageLoader from "../../components/Common/useFullPageLoader"
import { Link } from "react-router-dom"
import { get, post } from "../../helpers/api_helper"
import { VENDOR_IMPORT, GET_VENDOR_DATA, GET_ALL_MASTERS } from "../../helpers/api_url_helper"
import toastr from "toastr"
import axios from "axios"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import useExportXlsx from "components/Hooks/useExportXlsx"
import DOMPurify from 'dompurify'
import { CSVLink } from "react-csv"
import Select from "react-select"
import { convertHtmlToFormattedText, downloadVendorSampleCsv } from "../../constants/CommonFunction"
import { useSelector } from "react-redux"

const DatatableTables = () => {
  const sanitizeHtml = (content) => {
    return { __html: DOMPurify.sanitize(content) }
  }

  const [tableData, setTableData] = useState([])
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const [FormMsg, setFormMsg] = useState("")
  const [reloadData, setReloadData] = useState(false)
  const [vendorCategoryMaster, setVendorCategoryMaster] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [csv_load, setCsvLoad] = useState(false)
  const [headers, setHeaders] = useState([]);
  const [newData, setNewData] = useState([]);
  const csvInstance = useRef();
  const { manage_vendor } = useSelector(state => state.users.userPermissions);
  const TableColum = [
    { name: "#", field: "id", sortable: false },
    { name: "Category", field: "category_name", sortable: false },
    { name: "Name", field: "name", sortable: false },
    { name: "Company Name", field: "company_name", sortable: false },
    { name: "Mobile", field: "mobile", sortable: false },
    { name: "Email", field: "email", sortable: false },
    { name: "City", field: "city_name", sortable: false },
    { name: "Address", field: "address", sortable: false },
    { name: "Rate/Amount", field: "rate_amount", sortable: false },
    { name: "Work Portfolio", field: "work_portfolio", sortable: false },
    { name: "Remark", field: "remark", sortable: false },
    { name: "Status", field: "status", sortable: false },
    manage_vendor?.edit ? { name: "Action", field: "", sortable: false } : null,
  ].filter(Boolean);

  const PageSize = 15;
  useEffect(() => {
    showLoader();
    let filter = {
      category: selectedCategories,
    };
    const params = { keyword: search, length: PageSize, start: currentPage, filter: filter };
    post(GET_VENDOR_DATA, params)
      .then(res => {
        if (res) {
          setTableData(res.data);
          setTotalItems(res.total);
          hideLoader();
        }
      })
      .catch(err => {
        hideLoader();
        toastr.error(err);
      });
  }, [currentPage, search, selectedCategories, reloadData]); // Add reloadData here
  

  useEffect(() => {
    loadMaster()
  }, [])

  const loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'vendor_category' } })
      .then(res => {
        if (res.status) {
          let data = res.data
          setVendorCategoryMaster(data.vendor_category)
        }
      })
      .catch(err => {
        toastr.error(err)
      })
  }
  
  const downloadRejectCSV = (data) => {
    // Convert the array of objects to CSV format
    const csvRows = [];
    
    // Get headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(',')); // join headers by comma
    
    // Loop over the rows
    for (const row of data) {
      const values = headers.map(header => {
        let cellValue = row[header] === null || row[header] === undefined ? '' : row[header]; // replace null or undefined with an empty string
        const escape = ('' + cellValue).replace(/"/g, '\\"');
        return `"${escape}"`; // wrap in double quotes and escape any inner quotes
      });
      csvRows.push(values.join(',')); // join values by comma
    }
  
    // Create a Blob from the CSV string
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
  
    // Create a link element and trigger the download
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };   

  const ImportCSV = e => {
    e.preventDefault();
    let file = document.getElementById("file").files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("master_name", "vendor");
      formData.append("master_data", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${VENDOR_IMPORT}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then(response => {
          if (response.data.status == true) {
            setReloadData(prevState => !prevState); 
            document.getElementById("file").value = "";
            if (response.data.data.msg) {
              toastr.success(response.data.data.msg);
            }
            if(response.data.data.result){
              downloadRejectCSV(response.data.data.result);
            }
          }else{
            toastr.error(response.data.message);
          }
        })
        .catch(err => {
          toastr.error(err?.response?.data?.message);
        });
    } else {
      setFormMsg("Please select a valid excel sheet");
    }
  };
  
  
  const stripHTMLTags = (str) => {
    if (str === null || str === undefined) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }
  
  const downloadData = () => {
    setCsvLoad(true);
    let filter = {
      category: selectedCategories
    };
    const params = { length: 100000, start: 1, keyword: search, filter: filter };
    
    post(GET_VENDOR_DATA, params).then(response => {
      if (response.data.length > 0) {
        // Remove HTML tags from Work Portfolio field
        console.log(response.data)
        const sanitizedData = response.data.map(item => ({
          ...item,
          work_portfolio: convertHtmlToFormattedText(item.work_portfolio)
        }));
  
        setHeaders([
          { label: "Category", key: "category_name" },
          { label: "Name", key: "name" },
          { label: "Company Name", key: "company_name" },
          { label: "Mobile", key: "mobile" },
          { label: "Email", key: "email" },
          { label: "City", key: "city_name" },
          { label: "Address", key: "address" },
          { label: "Amount", key: "rate_amount" },
          { label: "Work Portfolio", key: "work_portfolio" },
          { label: "Remark", key: "remark" },
          { label: "Status", key: "status" },
        ]);
  
        setNewData(sanitizedData);
  
        if (newData && csvInstance.current && csvInstance.current.link) {
          setTimeout(() => {
            csvInstance.current.link.click();
            setNewData([]);
          });
        }
      } else {
        toastr.error(response.message);
      }
      setCsvLoad(false);
    }).catch(err => {
      setCsvLoad(false);
      toastr.error(err?.response?.data?.message);
    });
  };
  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Manage Vendor | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <h5 className="mb-3">Manage Vendor</h5>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-3 mb-2">
                      <Select options={vendorCategoryMaster} isMulti onChange={e => { setSelectedCategories(e) }} placeholder={'Select Category'} isClearable classNamePrefix="select2-selection" />
                    </div>
                    <form onSubmit={ImportCSV} className="col-md-9 d-flex flex-wrap justify-content-end">
                    {manage_vendor?.import &&<button  title="Export" type="button" onClick={downloadVendorSampleCsv} style={{minWidth: '123px', display: 'flex'}} className="btn btn-primary me-2 mb-2">Sample Import</button>}
                      <div>
                      {manage_vendor?.export &&<button title="Export" type="button" onClick={downloadData} style={{minWidth: '110px', display: 'flex'}} className="btn btn-primary me-2 mb-2"><i className="mdi mdi-file-export"></i> {csv_load === false && <>Export CSV</> }{csv_load === true && <>Please wait...</> }</button>}
                        {headers.length > 0 && <CSVLink data={newData} headers={headers} filename={`vendor.csv`} target="_blank" ref={csvInstance}></CSVLink>}
                      </div>
                      <div className="input-group mb-2" style={{ width: 'fit-content'}}>
                      {manage_vendor?.import &&<div className="d-flex"><Input
                          type="file"
                          className="form-control"
                          name={"file"}
                          accept={".xlsx"}
                          id="file"
                          aria-describedby="file"
                          aria-label="Upload"
                          style={{ height: '36px',maxWidth: '250px' }}
                        />
                        <Button
                          color="primary"
                          type="submit"
                          id="inputGroupFileAddon04"
                          style={{ height: '36px' }}
                           className="ms-2"
                        >
                          Import
                        </Button>
                        </div>}
                        {manage_vendor?.add &&<a
                          href="/manage-vendor/add"
                          className="ms-2 btn btn-primary"
                          style={{ borderRadius: "5px", height: '36px' }}
                        >
                          <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                          Add
                        </a>}
                      </div>
                      {FormMsg !== "" && (
                        <span className="text-danger font-size-12">
                          {FormMsg}
                        </span>
                      )}
                    </form>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <Search
                        onSearch={value => {
                          setSearch(value)
                          setCurrentPage(1)
                        }}
                      />
                    </div>
                  </div>                  
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <TableHeader headers={TableColum} />
                      <tbody className="">
                        {loader && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">Loading...</h4>
                            </th>
                          </tr>
                        )}
                        {!loader && tableData.length === 0 && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">No data found</h4>
                            </th>
                          </tr>
                        )}
                        {!loader &&
                          tableData.map((value, index) => (
                            <tr key={index}>
                              <th scope="row">
                                {PageSize * (currentPage - 1) + (index + 1)}
                              </th>
                              <td>{value.category_name}</td>
                              <td>{value.name}</td>
                              <td style={{minWidth: '135px'}}>{value.company_name}</td>
                              <td>{value.mobile}</td>
                              <td>{value.email}</td>
                              <td>{value.city_name}</td>
                              <td>{value.address}</td>
                              <td>{value.rate_amount}</td>
                              <td style={{minWidth: '300px'}}>
                                <p className="custom-table-cell-ellipsis" dangerouslySetInnerHTML={sanitizeHtml(value.work_portfolio)}></p>
                              </td>
                              <td style={{minWidth: '300px'}} >
                                <p className="custom-table-cell-ellipsis" dangerouslySetInnerHTML={sanitizeHtml(value.remark)}></p>
                              </td>
                              <td>
                                {value.status === "1" ? (
                                  <span className="badge badge-pill bg-success font-size-13">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-pill bg-danger font-size-13">
                                    Inactive
                                  </span>
                                )}
                              </td>
                              {manage_vendor?.edit &&<td>
                                <Link to={`/manage-vendor/edit/${value.id}`}>
                                  <i className="bx bxs-edit-alt"></i>{" "}
                                </Link>
                              </td>}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
