import React from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { setEditEmergencyDetail } from "store/onBoarding/onBordingSlice"

const OnboardingEmergencyDetail = () => {
  const dispatch = useDispatch()
  const { profileDetails, editProfile } = useSelector(state => state.onBoarding)
  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Col md={12}>
                  <div className="d-flex align-items-center">
                    <CardTitle className="header-text">
                      Emergency Contact
                    </CardTitle>
                    {editProfile && (
                      <Col>
                        <div className="d-flex justify-content-end">
                          <i
                            className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer"
                            onClick={() => {
                              dispatch(setEditEmergencyDetail(true))
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <hr className="underline" />
                </Col>
                <Row>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="name">
                      Name
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_name_1
                        ? profileDetails?.emg_name_1
                        : "-"}
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="relationship">
                      Relationship
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_relation_1
                        ? profileDetails?.emg_relation_1
                        : "-"}
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="mobile">
                      Mobile
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_mobile_1
                        ? profileDetails?.emg_mobile_1
                        : "-"}
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="address">
                      Address
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_address_1
                        ? profileDetails?.emg_address_1
                        : "-"}
                    </div>
                  </Col>
                </Row>

                {/* Emergency Contact 2 */}
                <CardTitle className="header-text">
                  Emergency Contact 2
                </CardTitle>
                <hr className="underline" />
                <Row>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="emeregencyName">
                      Name
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_name_2
                        ? profileDetails?.emg_name_2
                        : "-"}
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="emeregencyRelationship">
                      Relationship
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_relation_2
                        ? profileDetails?.emg_relation_2
                        : "-"}
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label className="labels" htmlFor="emeregencyMobile">
                      Mobile
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_mobile_2
                        ? profileDetails?.emg_mobile_2
                        : "-"}
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="emeregencyAddress">
                      Address
                    </Label>
                    <div className="values">
                      {profileDetails?.emg_address_2
                        ? profileDetails?.emg_address_2
                        : "-"}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnboardingEmergencyDetail
