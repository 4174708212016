import { ApiService } from "./ApiService"

export async function apiGetOtherDocument({ id, length, start }) {
  return ApiService.fetchData({
    url: `/employee_documents/documents/${id}`,
    method: "get",
    params: { length, start },
  })
}

export async function apiCreateOtherDocument(data) {
  return ApiService.fetchData({
    url: "/employee_documents/document",
    method: "post",
    data: data,
  })
}

export async function apiEditOtherDocument(id, data) {
  return ApiService.fetchData({
    url: `employee_documents/documents/${id}`,
    method: "post",
    data: data,
  })
}
