import { CorporateOfficeView, OverviewDetailView, RegisteredOfficeView, SocialProfileView } from "pages/Overview"
import CompanyPoliciesView from "pages/Overview/CompanyPoliciesView"
import React from "react"
import { useLocation } from "react-router-dom/cjs/react-router-dom"


const useCompanyTabsComponents = () => {
  const location = useLocation()

  // Extract the current tab from the query parameters
  const params = new URLSearchParams(location.search)
//   const currentTab = params.get("tab") || "logs" // Default to an empty string if no tab is set
  const tabsComponents = [
    {
      id: "1",
      component: (
        <>
          <OverviewDetailView />
          <SocialProfileView/>
        </>
      ),
    },
    {
      id: "2",
      component: (
        <>
          <RegisteredOfficeView/>
          <CorporateOfficeView />
        </>
      ),
    },
    {
      id: "3",
      component: (
        <>
          <CompanyPoliciesView />
        </>
      ),
    },
  ]

  return tabsComponents
}

export default useCompanyTabsComponents
