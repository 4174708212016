import { ApiService } from "./ApiService"

export async function apiGetEmployeeAttendanceLog({
  id,
  length,
  start,
  from_date,
  to_date,
  employee_status,
  keyword,
}) {
  try {
    return await ApiService.fetchData({
      url: `attendance/log_reports/${id}`,
      method: "get",
      params: {
        length,
        start,
        from_date,
        to_date,
        employee_status,
        keyword,
      },
    })
  } catch (error) {
    console.error("API call failed:", error)
    throw error
  }
}

export async function apiGetAttendanceLog({
  start,
  length,
  employee_id,
  date,
  employee_status,
  keyword,
}) {
  return ApiService.fetchData({
    url: `attendance/logs`,
    method: "get",
    params: {
      start,
      length,
      employee_id,
      date,
      employee_status,
      keyword,
    },
  })
}

export async function apiGetAttendanceEmployeeLog() {
  return ApiService.fetchData({
    url: `attendance/logs`,
    method: "get",
  })
}

export async function apiCreateAttendanceLog(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: "attendance_rule",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiCreateAttendanceRequest(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `/attendance/request_for_approval/${id}`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditAttendanceLog(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `attendance_rule/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}
