import React from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import { setEditContactDetail } from "store/onBoarding/onBordingSlice"

const OnboaringContactDetail = () => {
  const { profileDetails, editProfile } = useSelector(state => state.onBoarding)
  const dispatch = useDispatch()
  return (
    <>
      <div className="d-flex justify-content-center">
        <MetaTags>
          <title>
            Manage User-boarding | {process.env.REACT_APP_PROJECTNAME}
          </title>
        </MetaTags>
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center">
                      <CardTitle className="header-text">
                        Contact Info
                      </CardTitle>
                      {editProfile && (
                        <Col>
                          <div className="d-flex justify-content-end">
                            <i
                              className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer"
                              onClick={() => {
                                dispatch(setEditContactDetail(true))
                              }}
                            />
                          </div>
                        </Col>
                      )}
                    </div>
                    <hr className="underline" />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="mobile">
                      Mobile
                    </Label>
                    <div className="values">
                      {profileDetails?.mobile ? profileDetails?.mobile : "-"}
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="alternateMobile">
                      Alternate Mobile
                    </Label>
                    <div className="values">
                      {profileDetails?.mobile_2
                        ? profileDetails?.mobile_2
                        : "-"}
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="personalEmail">
                      Personal Email
                    </Label>
                    <div className="values">
                      {profileDetails?.email ? profileDetails?.email : "-"}
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="workEmail">
                      Work Email
                    </Label>
                    <div className="values">
                      {profileDetails?.work_email
                        ? profileDetails?.work_email
                        : "-"}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnboaringContactDetail
