
import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import {
  attendanceStatus,
  attendeTabledata,
  extractTime,
  sortName,
} from "constants/CommonFunction"
import { Search } from "components/Datatable"
import Pagination from "components/Common/Pagination"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import NavTabs from "components/NavTabs/NavTabs"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  fetchAttendanceEmployeeLog,
  fetchAttendanceLog,
  setAttendanceLogPage,
  setAttendanceLogQuery,
  setLogEmployee,
  setLogStatus,
  setLogsSelectedDate,
} from "store/attendanceLogs/attendanceLogSlice"
import { customStyles, logStatusData } from "constants/Constants"
import useExportXlsx from "components/Hooks/useExportXlsx"

export const AttendanceLogs = () => {
  const {
    loading,
    attendanceLogData,
    attendanceLogSearchQuery,
    logsSelectedDate,
    attendanceLogTotalData,
    attendanceLogCurrentPage,
    employeeLogsData,
    logStatus,
    logEmployee,
  } = useSelector(state => state.attendanceLog.attendanceLog);
  const { attendance } = useSelector(state => state.users.userPermissions);

  const { exportXlsx, xlsxLoader } = useExportXlsx()
  
  const dispatch = useDispatch()
  const location = useLocation()
  const activeTab = location.pathname

  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(attendanceLogCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        accessor: "employee_first_name",
        Header: () => <div style={{ width: "200px" }}>Employee Name</div>,
        Cell: ({ row }) => (
          <div
            className="d-flex align-items-center"
            style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
          >
            {row.original?.employee_profile_photo ? (
              <img
                src={row.original?.employee_profile_photo}
                alt="Employee"
                className="rounded-circle avatar-xs me-2"
              />
            ) : (
              <div className="avatar-xs align-self-center">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                  {sortName(
                    `${row.original?.employee_first_name} ${
                      row.original?.employee_last_name || ""
                    }`
                  )}
                </span>
              </div>
            )}
            <div>
              <p className="mb-0">{`${row.original.employee_first_name} ${
                row.original.employee_last_name
                  ? row.original.employee_last_name
                  : ""
              }`}</p>
            </div>
          </div>
        ),
      },
      {
        Header: () => <div style={{ width: "100px" }}>Employee Id</div>,
        accessor: "employee_emp_id",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Status</div>,
        accessor: "attendance_status",
        Cell: ({ value }) => <div>{value ? attendanceStatus(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>In Time</div>,
        accessor: "log_in_time",
        Cell: ({ value }) => (
          <div>
            {value !== "0000-00-00 00:00:00" ? extractTime(value) : "-"}
          </div>
        ),
      },
      {
        Header: () => <div style={{ width: "80px" }}>Out Time</div>,
        accessor: "log_out_time",
        Cell: ({ value }) => (
          <div>
            {value !== "0000-00-00 00:00:00" ? extractTime(value) : "-"}
          </div>
        ),
      },
      {
        Header: () => <div style={{ width: "120px" }}>Clock In Location</div>,
        accessor: "clock_in_location",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "130px" }}>Clock Out Location</div>,
        accessor: "clock_out_location",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "140px" }}>Total Work Duration</div>,
        accessor: "total_log_time",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => (
          <div style={{ width: "140px" }}>Total Break Duration</div>
        ),
        accessor: "total_break_time",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Breaks</div>,
        accessor: "number_of_breaks",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Action</div>,
        accessor: "actions",
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: `/user-employee-attendance-logs`,
              state: { row: row.original },
            }}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              <i
                onClick={async () => {}}
                className="fas fa-eye cursor-pointer "
                style={{ fontSize: "14px", color: "grey" }}
              />
            </div>
          </Link>
        ),
      },
    ]
  }, [dispatch, attendanceLogCurrentPage])

  useEffect(() => {
    dispatch(
      fetchAttendanceLog({
        start: attendanceLogCurrentPage,
        length: 10,
        employee_id: location?.state?.row?.id || logEmployee,
        date: logsSelectedDate,
        employee_status: logStatus,
        keyword: attendanceLogSearchQuery,
      })
    )
  }, [
    dispatch,
    attendanceLogCurrentPage,
    logEmployee,
    location,
    logsSelectedDate,
    logStatus,
    attendanceLogSearchQuery,
  ])

  useEffect(() => {
    dispatch(fetchAttendanceEmployeeLog())
  }, [])

  return (
    <div className={activeTab.includes("/profile/view") ? "" : "page-content"}>
      <div
        className={activeTab.includes("/profile/view") ? "" : "container-fluid"}
      >
          <NavTabs />
          <h5 className="mt-3">Attendance</h5>

        <Card className="cardBorder">
          <CardBody style={{ paddingBottom: "0px" }}>
            <Row className=" align-items-center">
              <Col md={2} sm={12} style={{ marginTop: "15px" }}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setAttendanceLogQuery(value))
                    dispatch(setAttendanceLogPage(1))
                  }}
                />
              </Col>
              <Col md={2} className="mb-3">
                <span>Status</span>
                <Select
                  id="logStatus"
                  name="logStatus"
                  size="sm"
                  options={logStatusData}
                  value={logStatusData?.find(
                    option => option.value === logStatus
                  )}
                  onChange={async option => {
                    await dispatch(setAttendanceLogPage(1))
                    await dispatch(setLogStatus(option?.value))
                  }}
                  styles={customStyles}
                />
              </Col>
              {!location?.state?.row?.id && <Col md={2} className="mb-3">
                <span>Employee</span>
                <Select
                  id="employee_id"
                  name="employee_id"
                  placeholder="Employee"
                  options={employeeLogsData}
                  value={employeeLogsData?.find(
                    option => option.value == logEmployee
                  )}
                  onChange={async option => {
                    await dispatch(setAttendanceLogPage(1))
                    await dispatch(setLogEmployee(option?.value))
                  }}
                  styles={customStyles}
                />
              </Col>}
              {attendance?.monthly_logs && <Col md={2} className="mb-3">
                <span>Select Date</span>
                <div className="input-group d-flex" data-clear>
                  <Input
                    type="date"
                    value={logsSelectedDate}
                    onChange={e => {
                      dispatch(setLogsSelectedDate(e.target.value))
                    }}
                  />
                </div>
              </Col>}
              {attendance?.export && <Col
                md={attendance?.monthly_logs?4:6}
                sm={12}
                className="mb-2 d-flex justify-content-start flex-wrap"
                style={{ marginTop: "15px" }}
              >
                <button
                  color="primary"
                  type="button"
                  className="ml-4 btn btn-primary"
                  style={{ borderRadius: "5px", height: "38px" }}
                  onClick={() => exportXlsx("attelndance-logs", "Attendance",{
                    employee_id: location?.state?.row?.id || logEmployee,
                    date: logsSelectedDate,
                    employee_status: logStatus,
                    keyword: attendanceLogSearchQuery,
                  })}
                  disabled={xlsxLoader}
                >
                  <i className="bx bx-export font-size-16 me-2" />
                  {xlsxLoader ? "Exporting..." : "Export"}
                </button>
              </Col>}
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={attendanceLogData ? attendanceLogData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={attendanceLogCurrentPage}
                totalCount={attendanceLogTotalData ? attendanceLogTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setAttendanceLogPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
