import React from 'react'
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"

export const OverviewDetailView = () => {
  return (
    <>
        <Row className='d-flex justify-content-center'>
        <Col className='profile-container' xl="12">
            <Card className="mb-lg-75">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center">
                      <CardTitle className="header-text">
                      Overview
                      </CardTitle>
                    </div>
                    <hr className="underline" />
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="company_name">
                    Registered Company Name
                    </Label>
                    <div className="values">
                      Party Cruisers Limited
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="email">
                    Company Official Email
                    </Label>
                    <div className="values">-
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="contact">
                    Company Official Contact
                    </Label>
                    <div className="values">
                      -
                    </div>
                  </Col>
                  <Col md="6" className="mb-3">
                    <Label className="labels" htmlFor="blood_group">
                    Website
                    </Label>
                    <div className="values">
                      https://www.partycruisersindia.com/

                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </>
  )

}