import React, { useState, useRef, useEffect } from "react";
import "./TimePicker.css";
import { Input, Label } from "reactstrap";

const minuteGap = 5; // Set your desired minute gap here

const TimePickerInput = ({ name, label, value, onChange }) => {
  const [time, setTime] = useState(value || "00:00");
  const [isFocused, setIsFocused] = useState(false);
  const [showGridHour, setShowGridHour] = useState(false);
  const [showGridMinute, setShowGridMinute] = useState(false);
  const inputRef = useRef(null);

  const [hour, minute] = time.split(":").map(Number);

  const incrementHour = () => {
    const newHour = (hour + 1) % 24;
    setTime(`${newHour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
  };

  const decrementHour = () => {
    const newHour = (hour - 1 + 24) % 24;
    setTime(`${newHour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
  };

  const incrementMinute = () => {
    const newMinute = (minute + minuteGap) % 60;
    setTime(`${hour.toString().padStart(2, "0")}:${newMinute.toString().padStart(2, "0")}`);
  };

  const decrementMinute = () => {
    const newMinute = (minute - minuteGap + 60) % 60;
    setTime(`${hour.toString().padStart(2, "0")}:${newMinute.toString().padStart(2, "0")}`);
  };

  const handleGridClick = (value, isHour) => {
    if (isHour) {
      setTime(`${value.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
      setShowGridHour(false);
    } else {
      setTime(`${hour.toString().padStart(2, "0")}:${value.toString().padStart(2, "0")}`);
      setShowGridMinute(false);
    }
  };

  const renderGrid = (isHour) => {
    const values = isHour 
      ? Array.from({ length: 24 }, (_, i) => i) 
      : Array.from({ length: 60 / minuteGap }, (_, i) => i * minuteGap);
    return (
      <div className="time-grid">
        {values.map((val) => (
          <div key={val} className="time-grid-item" onClick={() => handleGridClick(val, isHour)}>
            {val.toString().padStart(2, "0")}
          </div>
        ))}
      </div>
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
    setShowGridHour(false);
    setShowGridMinute(false);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocused(false);
      setShowGridHour(false);
      setShowGridMinute(false);
    }
  };

  useEffect(() => {
    if (onChange) onChange(name, time);
  }, [time, name]);

  return (
    <div className="time-picker-container w-100" onBlur={handleBlur} tabIndex="0">
      <Label className="labels" htmlFor={name}>
        {label}<span className="text-danger">*</span>
      </Label>
      <Input
        ref={inputRef}
        type="text"
        name={name}
        value={time}
        readOnly
        onFocus={handleFocus}
        className="time-input"
      />
      {isFocused && (
        <div className="time-picker">
          {!showGridHour && !showGridMinute && (
            <div className="time-picker-controls">
              <div className="time-picker-hour">
                <button type="button" onClick={incrementHour}>&#x25B2;</button>
                <span onClick={() => setShowGridHour(true)}>{hour.toString().padStart(2, "0")}</span>
                <button type="button" onClick={decrementHour}>&#x25BC;</button>
              </div>
              <div className="time-picker-minute">
                <button type="button" onClick={incrementMinute}>&#x25B2;</button>
                <span onClick={() => setShowGridMinute(true)}>{minute.toString().padStart(2, "0")}</span>
                <button type="button" onClick={decrementMinute}>&#x25BC;</button>
              </div>
            </div>
          )}
          {showGridHour && renderGrid(true)}
          {showGridMinute && renderGrid(false)}
        </div>
      )}
    </div>
  );
};

export default TimePickerInput;
