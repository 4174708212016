import { ApiService } from "./ApiService"

export async function apiGetExpenseReward({ length, start, keyword,status,employee_id ,from_date,to_date}) {
  return ApiService.fetchData({
    url: `employee_expenses`,
    method: "get",
    params: { length, start, keyword ,status,employee_id,from_date,to_date},
  })
}

export async function apiEditExpenseRewardRule(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_expenses/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiCreateExpenseReward(formData) {
  // const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_expenses`,
    method: "Post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  })
}

export async function apiGetEmployeeData() {
  return ApiService.fetchData({
    url: "/users/employee_list?status=1",
    method: "get",
  })
}

export async function apiGetExpenseTypeData() {
  return ApiService.fetchData({
    url: "/expenses/get",
    method: "get",
  })
}

export async function apiApproveStatus(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `employee_expenses/employee_expense_status_change/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  });
}