import React from "react"
import { ExpenseRewardView } from "./ExpenseRewardView"
import { Container } from "reactstrap"
import { ExpenseRewardForm } from "./ExpenseRewardForm"

export const ExpenseRewardList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <ExpenseRewardForm/>
        <ExpenseRewardView />
      </Container>
    </div>
  )
}
