import { ApiService } from "./ApiService"

export async function apiGetDeductionRewardMapping({
  length,
  start,
  deduction_reward_rule_master_id,
  keyword
}) {
  return ApiService.fetchData({
    url: `deduction_reward_rule_master_mapping`,
    method: "get",
    params: { length, start, deduction_reward_rule_master_id, keyword },
  })
}

export async function apiGetDeductionRule({
  length,
  start,
  keyword, // Include keyword as a parameter
}) {
  return ApiService.fetchData({
    url: `/deduction_reward_rule_master`,
    method: "get",
    params: { length, start, keyword }, // Pass keyword to the params object
  });
}

export async function apiGetDeductionRuleSearch({ length, start, keyword }) {
  return ApiService.fetchData({
    url: `/deduction_reward_rule_master`,
    method: "get",
    params: { length, start, keyword },
  });
}

export async function apiCreateMappingData(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `/deduction_reward_rule_master_mapping`,
    method: "Post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}

export async function apiCreateRuleData(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `/deduction_reward_rule_master`,
    method: "Post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}


export async function apiEditMappingData(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `/deduction_reward_rule_master_mapping/${id}`,
    method: "put",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}


export async function apiEditRuleData(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `deduction_reward_rule_master/${id}`,
    method: "put",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}

export async function apiGetValueData() {
  return ApiService.fetchData({
    url: "/designations",
    method: "get",
  })
}

export async function apiGetEmployeeData() {
  return ApiService.fetchData({
    url: "/users",
    method: "get",
  })
}

export async function apiEditDeductionReward(id, data) {
  return ApiService.fetchData({
    url: `employee_documents/documents/${id}`,
    method: "post",
    data: data,
  })
}
