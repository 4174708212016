import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import {
  editKycDocument,
  fetchProfileDetail,
  setEditKycDetail,
} from "store/onBoarding/onBordingSlice"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { documentData, documnetFields } from "constants/Constants"

const OnboardingKycForm = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId } = useSelector(state => state.onBoarding)
  const validationSchema = Yup.object({
    address_proof_permanent: Yup.mixed(),
    address_proof_temporary: Yup.mixed(),
    id_proof: Yup.mixed(),
    address_proof_permanent_doc_type: Yup.number().when(
      "address_proof_permanent",
      {
        is: value => value != null,
        then: Yup.number().required("Required"),
      }
    ),
    address_proof_permanent_number: Yup.string().when(
      "address_proof_permanent",
      {
        is: value => value != null,
        then: Yup.string().required("Required"),
      }
    ),
    address_proof_temporary_doc_type: Yup.number().when(
      "address_proof_temporary",
      {
        is: value => value != null,
        then: Yup.number().required("Required"),
      }
    ),
    address_proof_temporary_number: Yup.string().when(
      "address_proof_temporary",
      {
        is: value => value != null,
        then: Yup.string().required("Required"),
      }
    ),
    id_proof_doc_type: Yup.number().when("id_proof", {
      is: value => value != null,
      then: Yup.number().required("Required"),
    }),
    id_proof_number: Yup.string().when("id_proof", {
      is: value => value != null,
      then: Yup.string().required("Required"),
    }),
  })

  return (
    <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
          <Card>
            <CardBody>
              <Formik
                initialValues={{
                  address_proof_permanent: null,
                  address_proof_temporary: null,
                  id_proof: null,
                  address_proof_permanent_doc_type:
                    profileDetails?.address_proof_permanent_doc_type || "",
                  address_proof_permanent_number:
                    profileDetails?.address_proof_permanent_number || "",
                  address_proof_temporary_doc_type:
                    profileDetails?.address_proof_temporary_doc_type || "",
                  address_proof_temporary_number:
                    profileDetails?.address_proof_temporary_number || "",
                  id_proof_doc_type: profileDetails?.id_proof_doc_type || "",
                  id_proof_number: profileDetails?.id_proof_number || "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  const formData = new FormData()
                  documnetFields.forEach(field => {
                    formData.append(field, values[field])
                  })
                  try {
                    const response = await dispatch(
                      editKycDocument({ id: userId, data: formData })
                    )
                    if (response.payload?.status === "success") {
                      toastr.success(response?.payload?.data?.message)
                    } else {
                      toastr.error("Failed to update KYC documents.")
                    }
                  } catch (error) {
                    toastr.error("Something went wrong.")
                  }
                  await dispatch(fetchProfileDetail(userId))
                  await dispatch(setEditKycDetail(false))
                }}
              >
                {({
                  values,
                  setFieldValue,
                  touched,
                  errors,
                  isSubmitting,
                  dirty,
                }) => (
                  <Form>
                    <Row>
                      <Col md={12}>
                        <CardTitle
                          className="fs-5"
                          style={{ color: "#556ee6" }}
                        >
                          KYC Document
                        </CardTitle>
                        <hr className="underline" />
                        <div className="mt-3">
                          <Row>
                            <Col xs={12} md={6}>
                              <CardTitle
                                className="header-text"
                                style={{ fontSize: "14px" }}
                              >
                                Address Proof Permanent
                              </CardTitle>
                            </Col>
                          </Row>
                        </div>
                        <hr className="underline" />
                      </Col>
                      {profileDetails?.address_proof_permanent && (
                        <Col md="12" className="mb-3">
                          <Label className="labels" htmlFor="temporaryFile">
                            Previous File
                          </Label>
                          <div>
                            <a
                              href={profileDetails?.address_proof_permanent}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b className="values">File</b>
                            </a>
                          </div>
                        </Col>
                      )}

                      <Col md="4" className="mb-3">
                        <Label
                          className="labels"
                          htmlFor="address_proof_permanent"
                        >
                          Current Address
                        </Label>
                        <Input
                          name="address_proof_permanent"
                          type="file"
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          onChange={event => {
                            setFieldValue(
                              "address_proof_permanent",
                              event.currentTarget.files[0]
                            )
                          }}
                          className={`form-control ${
                            touched.address_proof_permanent &&
                            errors.address_proof_permanent
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="address_proof_permanent"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="4">
                        <Label
                          className="labels"
                          htmlFor="address_proof_permanent_doc_type"
                        >
                          Document Type
                          {values?.address_proof_permanent && (
                            <span className="text-danger">*</span>
                          )}
                        </Label>
                        <Select
                          name="address_proof_permanent_doc_type"
                          value={documentData?.find(
                            option =>
                              option.value ==
                              values.address_proof_permanent_doc_type
                          )}
                          onChange={option =>
                            setFieldValue(
                              "address_proof_permanent_doc_type",
                              Number(option.value)
                            )
                          }
                          options={documentData}
                          classNamePrefix="select2-selection"
                        />
                        <ErrorMessage
                          name="address_proof_permanent_doc_type"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="4">
                        <Label
                          className="labels"
                          htmlFor="address_proof_permanent_number"
                        >
                          Document ID/Number
                          {values?.address_proof_permanent && (
                            <span className="text-danger">*</span>
                          )}
                        </Label>
                        <Field
                          name="address_proof_permanent_number"
                          placeholder="Document ID"
                          type="text"
                          className={`form-control ${
                            touched.address_proof_permanent_number &&
                            errors.address_proof_permanent_number
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="address_proof_permanent_number"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="mt-2">
                          <Row>
                            <Col xs={12} md={6}>
                              <CardTitle
                                className="header-text"
                                style={{ fontSize: "14px" }}
                              >
                                Address Proof Temporary
                              </CardTitle>
                            </Col>
                          </Row>
                        </div>
                        <hr className="underline" />
                      </Col>
                      {profileDetails?.address_proof_temporary && (
                        <Col md="12" className="mb-3">
                          <Label className="labels" htmlFor="temporaryFile">
                            Previous File
                          </Label>
                          <div>
                            <a
                              href={profileDetails?.address_proof_temporary}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b className="values">File</b>
                            </a>
                          </div>
                        </Col>
                      )}
                      <Col md="4" className="mb-3">
                        <Label
                          className="labels"
                          htmlFor="address_proof_temporary"
                        >
                          Permanent Address
                        </Label>
                        <Input
                          name="address_proof_temporary"
                          type="file"
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          onChange={event => {
                            setFieldValue(
                              "address_proof_temporary",
                              event.currentTarget.files[0]
                            )
                          }}
                          className={`form-control ${
                            touched.address_proof_temporary &&
                            errors.address_proof_temporary
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="address_proof_temporary"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="4">
                        <Label
                          className="labels"
                          htmlFor="address_proof_temporary_doc_type"
                        >
                          Document Type
                          {values?.address_proof_temporary && (
                            <span className="text-danger">*</span>
                          )}
                        </Label>
                        <Select
                          name="address_proof_temporary_doc_type"
                          value={documentData?.find(
                            option =>
                              option.value ==
                              values.address_proof_temporary_doc_type
                          )}
                          onChange={option =>
                            setFieldValue(
                              "address_proof_temporary_doc_type",
                              Number(option.value)
                            )
                          }
                          options={documentData}
                          classNamePrefix="select2-selection"
                        />
                        <ErrorMessage
                          name="address_proof_temporary_doc_type"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="4">
                        <Label
                          className="labels"
                          htmlFor="address_proof_temporary_number"
                        >
                          Document ID/Number
                          {values?.address_proof_temporary && (
                            <span className="text-danger">*</span>
                          )}
                        </Label>
                        <Field
                          name="address_proof_temporary_number"
                          placeholder="Document ID"
                          type="text"
                          className={`form-control ${
                            touched.address_proof_temporary_number &&
                            errors.address_proof_temporary_number
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="address_proof_temporary_number"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    {/* ID Proof */}
                    <Row>
                      <Col md={12}>
                        <div className="mt-2">
                          <Row>
                            <Col xs={12} md={6}>
                              <CardTitle
                                className="header-text"
                                style={{ fontSize: "14px" }}
                              >
                                ID Proof
                              </CardTitle>
                            </Col>
                          </Row>
                        </div>
                        <hr className="underline" />
                      </Col>
                      {profileDetails?.id_proof && (
                        <Col md="12" className="mb-3">
                          <Label className="labels" htmlFor="temporaryFile">
                            Previous File
                          </Label>
                          <div>
                            <a
                              href={profileDetails?.id_proof}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <b className="values">File</b>
                            </a>
                          </div>
                        </Col>
                      )}
                      <Col md="4" className="mb-3">
                        <Label className="labels" htmlFor="id_proof">
                          Id Proof
                        </Label>
                        <Input
                          name="id_proof"
                          type="file"
                          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                          onChange={event => {
                            setFieldValue(
                              "id_proof",
                              event.currentTarget.files[0]
                            )
                          }}
                          className={`form-control ${
                            touched.id_proof && errors.id_proof
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="id_proof"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="4">
                        <Label className="labels" htmlFor="id_proof_doc_type">
                          Document Type
                          {values?.id_proof && (
                            <span className="text-danger">*</span>
                          )}
                        </Label>
                        <Select
                          name="id_proof_doc_type"
                          value={documentData?.find(
                            option => option.value == values.id_proof_doc_type
                          )}
                          onChange={option =>
                            setFieldValue(
                              "id_proof_doc_type",
                              Number(option.value)
                            )
                          }
                          options={documentData}
                          classNamePrefix="select2-selection"
                        />
                        <ErrorMessage
                          name="id_proof_doc_type"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md="4">
                        <Label className="labels" htmlFor="id_proof_number">
                          Document ID/Number
                          {values?.id_proof && (
                            <span className="text-danger">*</span>
                          )}
                        </Label>
                        <Field
                          name="id_proof_number"
                          placeholder="Document ID"
                          type="text"
                          className={`form-control ${
                            touched.id_proof_number && errors.id_proof_number
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="id_proof_number"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                      <button
                        className="d-flex align-items-center btn btn-md btn-outline-secondary"
                        type="reset"
                        onClick={() => {
                          dispatch(setEditKycDetail(false))
                        }}
                      >
                        <i className="dripicons-cross mx-1 d-flex" />
                        Cancel
                      </button>
                      <button
                        className="btn btn-md btn-primary"
                        type="submit"
                        disabled={isSubmitting || !dirty}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default OnboardingKycForm
