import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import Pagination from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import { fetchResignationInfo, setApprovalConfirmation, setEmployeeId, setResignationInfoModal, updateStatus } from "store/resignationInfo/ResignationInfoSlice"
import { formatDateFormat, getUserId } from "constants/CommonFunction"
import { useLocation } from "react-router-dom"
import toastr from "toastr"

export const ResignationInfo = () => {
  const { tableRowData, loading, totalData, employee_id, approvalConfirmation } = useSelector(
    state => state.resignationInfo
  )
  // Set initial form data based on status === 0
  const [resignationData, setResignationData] = useState(
    tableRowData.find((row) => row.status === 0) || null
  )
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch();

  const approveStatus = async (id, type) => {
    if(type=="approve"){
      dispatch(setResignationInfoModal(true));
      dispatch(setApprovalConfirmation(true))
      return
    }
    try {
      const response = await dispatch(
        updateStatus({
          employeeId: id,
          status: type == "approve" ? 1 : 2,
        })
      )
      if (response?.payload?.data?.status) {
        toastr.success(response?.payload?.data?.message)
      } else {
        toastr.error(response?.payload?.message)
      }
      fetchResignList()
    } catch (error) {
      toastr.error("Something Went Wrong")
    }
  }
  const columns = useMemo(() => {
    const baseCoulumn = [
      {
        id: "remark",
        Header: () => <div style={{ width: "200px" }}>Remark</div>,
        accessor: "remark",
        Cell: ({ value }) => (
          <div>
            {value ? (
              <>
                {value.length > 20 ? (
                  <>
                    {value.slice(0, 20)}
                    <i
                      className="bx bx-dots-horizontal-rounded cursor-pointer"
                      title="View more"
                      onClick={() => { showRemark(value); }}
                    />
                  </>
                ) : (value)}
              </>
            ) : ("-")}
          </div>
        ),
      },
      {
        id: "created_on",
        Header: () => <div style={{ width: "80px" }}>Created On</div>,
        accessor: "created_on",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
      {
        id: "resignation_date",
        Header: () => <div style={{ width: "120px" }}>Resignation Date</div>,
        accessor: "resignation_date",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
      {
        id: "expected_last_day",
        Header: () => <div style={{ width: "120px" }}>Expected Last Day</div>,
        accessor: "expected_last_day",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
      {
        id: "actual_last_day",
        Header: () => <div style={{ width: "120px" }}>Actual Last Day</div>,
        accessor: "actual_last_day",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
      {
        id: "notice_period_in_days",
        Header: () => <div style={{ width: "140px" }}>Notice Period (Days)</div>,
        accessor: "notice_period_in_days",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      }
    ];
    if(true){
      baseCoulumn.push(
        {
          Header: "Action",
          accessor: "actions",
          Cell: ({ row }) => {
            const { status, is_action, approvals } = row.original
    
            let buttonClass= "btn btn-sm";
            let buttonText="";
    
            if(status === "0" && is_action === false ){
              buttonClass+=" btn-primary";
              buttonText="Pending"
            }else if(status === "1" && is_action === false){
              buttonClass+=" btn-success";
              buttonText="Approved"
            }else {
              buttonClass+=" btn-danger";
              buttonText="Rejected"
            }
            return (  
              <div style={{ display: "flex", gap: "10px" }}>
                {status == "0" && is_action === true ? (
                  <>
                    <i
                      className="bx bx-check-square cursor-pointer"   style={{ fontSize: "17px", color: "#0f8000" }}
                      onClick={() => approveStatus(row?.original?.id, "approve")}
                    />
                      {/* Approve */}
                    {/* </button> */}
                    <i
                       className="bx bx-window-close cursor-pointer"   style={{ fontSize: "17px", color: "#ed1e07" }}
                      onClick={() => approveStatus(row?.original?.id, "reject")}
                    />
                      {/* Reject */}
                    {/* </button> */}
                  </>
                ) : (
                  <span
                    className="d-inline-block"
                    data-toggle="tooltip"
                    // title={generateTooltip(approvals)}
                  >
                    <button
                      className={buttonClass}
                      type="button"
                      style={{
                        // background: "#aaabb6",
                        border: "none",
                        cursor: "no-drop",
                      }}
                    >
                      {buttonText}
                       {/* {status === "0" && is_action === false
                        ? "Pending"
                        : status === "1" && is_action === false
                        ? "Approved"
                        : "Rejected"}*/}
                    </button>
                  </span>
                )
                }
                {status !== "1" && status !== "2" && (
                  <div className="d-flex align-items-center">
                    <i
                      onClick={async () => {
                        await dispatch(setResignationInfoModal(true))
                      }}
                      className="bx bxs-edit cursor-pointer"
                      style={{ fontSize: "17px", color: "#039F03" }}
                    />
                  </div>
                )}
              </div>
            )
          },
        }
      )
    }
    return baseCoulumn;
  }, [dispatch, currentPage])
  

  const handleAdd = async () => {
    if(resignationData?.id){
      toastr.success("You already have a pending request.");
    }else{
      await dispatch(setResignationInfoModal(true))
    }
  }

  const location = useLocation()
  
  const fetchResignList = async ()=>{
    const newUserId = location?.state?.row?.id
    ? location?.state?.row?.id
    : getUserId()
    console.log(newUserId);
    await dispatch(setEmployeeId(newUserId))
    await dispatch(fetchResignationInfo({
        length: 10,
        start: 1,
        employee_id: newUserId,
      }))
  }
  useEffect(() => {
    fetchResignList()
  },[])
  useEffect(() => {
    setResignationData(tableRowData.find((row) => row.status == 0) || null);
  }, [tableRowData])
  
  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="mb-3">E - Exit</h5>
        <Card>
          <CardBody>
            <Row className=" align-items-center">
              <Col
                md={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
              >
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{ height: "52vh", scrollbarWidth: "thin" }}
              >
                <GlobalTable columns={columns} data={tableRowData? tableRowData:[]} loading={false} />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={1}
                totalCount={0}
                pageSize={10}
                onPageChange={page => setCurrentPage(page)}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
