import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getcurrentMonthDateRange } from "constants/CommonFunction"
import {
  apiCreateAttendanceLog,
  apiCreateAttendanceRequest,
  apiEditAttendanceLog,
  apiGetAttendanceEmployeeLog,
  apiGetAttendanceLog,
  apiGetEmployeeAttendanceLog,
} from "services/AttendanceLogService"

export const SLICE_NAME = "attendanceLog"

const initialState = {
  attendanceLog: {
    loading: false,
    attendanceLogData: [],
    attendanceLogSearchQuery: "",
    attendanceLogTotalData: 0,
    attendanceLogCurrentPage: 1,
    logsSelectedDate: new Date().toISOString().split("T")[0],
    employeeLogsData: [],
    logStatus: "",
    logEmployee: "",
  },
  attendanceEmployeeLog: {
    loading: false,
    employeeLogsData: [],
    employeeLogsDateRange: getcurrentMonthDateRange(),
    employeeLogStatus: "",
    employeeLogsCurrentPage: 1,
    employeeLogTotalData: 0,
    employeeLogSearchQuery: "",
    employeeRowData: [],
    employeeLogsModal: false,
  },
}

export const fetchEmployeeAttendanceLog = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeAttendanceLog`,
  async (
    { id, length, start, from_date, to_date, employee_status, keyword },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiGetEmployeeAttendanceLog({
        id,
        length,
        start,
        from_date,
        to_date,
        employee_status,
        keyword,
      })

      if (!response || !response.data) {
        throw new Error("Invalid response from the server")
      }

      return {
        status: "success",
        data: response.data,
      }
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message:
          error?.response?.data?.message ||
          error.message ||
          "An error occurred",
      })
    }
  }
)

export const fetchAttendanceLog = createAsyncThunk(
  `${SLICE_NAME}/fetchAttendanceLog`,
  async ({ start, length, employee_id, date, employee_status, keyword }) => {
    try {
      const response = await apiGetAttendanceLog({
        start,
        length,
        employee_id,
        date,
        employee_status,
        keyword,
      })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchAttendanceEmployeeLog = createAsyncThunk(
  `${SLICE_NAME}/fetchAttendanceEmployeeLog`,
  async () => {
    try {
      const response = await apiGetAttendanceEmployeeLog()
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const creteAttendanceLog = createAsyncThunk(
  `${SLICE_NAME}/creteAttendanceLog`,
  async data => {
    try {
      const response = await apiCreateAttendanceLog(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const attendanceRequest = createAsyncThunk(
  `${SLICE_NAME}/attendanceRequest`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiCreateAttendanceRequest(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editAttendanceLog = createAsyncThunk(
  `${SLICE_NAME}/editAttendanceLog`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiEditAttendanceLog(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const attendanceLog = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.attendanceLog.loading = action.payload
    },
    setAttendanceLogPage: (state, action) => {
      state.attendanceLog.attendanceLogCurrentPage = action.payload
    },
    setAttendanceLogQuery: (state, action) => {
      state.attendanceLog.attendanceLogSearchQuery = action.payload
    },
    setLogsSelectedDate: (state, action) => {
      state.attendanceLog.logsSelectedDate = action.payload
    },
    setLogStatus: (state, action) => {
      state.attendanceLog.logStatus = action.payload
    },
    setLogEmployee: (state, action) => {
      state.attendanceLog.logEmployee = action.payload
    },
    // Employee
    setEmployeeLogPage: (state, action) => {
      state.attendanceEmployeeLog.employeeLogsCurrentPage = action.payload
    },
    setEmployeeLogStatus: (state, action) => {
      state.attendanceEmployeeLog.employeeLogStatus = action.payload
    },
    setEmployeeLogDate: (state, action) => {
      state.attendanceEmployeeLog.employeeLogsDateRange = action.payload
    },
    setEmployeeLogSearch: (state, action) => {
      state.attendanceEmployeeLog.employeeLogSearchQuery = action.payload
    },
    setEmployeeRowData: (state, action) => {
      state.attendanceEmployeeLog.employeeRowData = action.payload
    },
    setEmployeeLogModal: (state, action) => {
      state.attendanceEmployeeLog.employeeLogsModal = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAttendanceLog.pending, state => {
      state.attendanceLog.loading = true
    })
    builder.addCase(fetchAttendanceLog.fulfilled, (state, action) => {
      state.attendanceLog.loading = false
      state.attendanceLog.attendanceLogData = action?.payload?.data?.data
      state.attendanceLog.attendanceLogTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchAttendanceLog.rejected, state => {
      state.attendanceLog.loading = false
    })

    // Employee Dropdown
    builder.addCase(fetchAttendanceEmployeeLog.fulfilled, (state, action) => {
      const initalOptions = { value: "", label: "All" }
      const fetchOption = action?.payload?.data?.data.map(employee => ({
        value: employee?.employee_id,
        label: `${employee?.employee_first_name} ${employee?.employee_last_name}`,
      }))
      state.attendanceLog.employeeLogsData = [initalOptions, ...fetchOption]
    })

    // Employee Case
    builder.addCase(fetchEmployeeAttendanceLog.pending, state => {
      state.attendanceEmployeeLog.loading = true
    })
    builder.addCase(fetchEmployeeAttendanceLog.fulfilled, (state, action) => {
      const { data } = action.payload
      state.attendanceEmployeeLog.loading = false
      state.attendanceEmployeeLog.employeeLogsData = data?.data
      state.attendanceEmployeeLog.employeeLogTotalData = data?.total
    })
    builder.addCase(fetchEmployeeAttendanceLog.rejected, state => {
      state.attendanceLog.loading = false
    })
  },
})

export const {
  setLoading,
  setLogsSelectedDate,
  setAttendanceLogPage,
  setAttendanceLogQuery,
  setAttendanceActiveTab,
  setLogStatus,
  setLogEmployee,
  setEmployeeLogPage,
  setEmployeeLogStatus,
  setEmployeeLogDate,
  setEmployeeLogSearch,
  setEmployeeRowData,
  setEmployeeLogModal,
} = attendanceLog.actions

export default attendanceLog.reducer
