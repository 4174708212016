import React from "react"
import { useSelector } from "react-redux"
import OnbordingPersonalFrom from "../OnbordingForms/OnbordingPersonalFrom"
import OnboardingPersonalDetail from "../OnboardingDetails/OnboardingPersonalDetail"

export const OnboardingPersonalDetailView = () => {
  const { editPersonalDetail } = useSelector(
    state => state.onBoarding
  )

  return (
    <div>
      {editPersonalDetail ? (
        <OnbordingPersonalFrom />
      ) : (
        <OnboardingPersonalDetail />
      )}
    </div>
  )
}


