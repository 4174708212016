import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SimpleReactValidator from 'simple-react-validator'
import {get, post} from "../../../helpers/api_helper"
import { ADD_ES_ELEMENTS_MASTER,GET_ALL_MASTERS} from "../../../helpers/api_url_helper"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";


class EsElementsAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      element: "",
      status: true,
      handleResponse: null,
      submit: false,
      category_master:[],
      category_master_id:"",
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }
  componentDidMount() {
    this.getCategory();
  }
  getCategory = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'es_category_master' } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ category_master: data.es_category_master })
      }
    }).catch(err => {
      toastr.error(err)
    })
  }
  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = {
        "element":this.state.element,
        "ordering" : this.state.ordering,
        "status" : this.state.status ? 1 : 0,
        "category_master_id" : this.state.category_master_id
      }
      this.setState({submit: true})
      post(ADD_ES_ELEMENTS_MASTER,formData).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('Element added successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          history.push('/element-sheet-master/es-elements')
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message)
        this.setState({submit: false})
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Manage ES Element | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="ES Element" path="/element-sheet-master/es-elements" breadcrumbItem="Add"/>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="category">Category <span className="text-danger">*</span></Label>
                          <Select isMulti={false} name="category" options={this.state.category_master} onChange={e => this.setState({ category_master_id: e.value })} classNamePrefix="select2-selection" />
                          {this.validator.message("category", this.state.category_master_id, "required")}
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="element">Element</Label>
                          <input name="element" placeholder="Type Element" defaultValue={this.state.element} type="text" className={"form-control"} onChange={this.handleInput}/>
                          {this.validator.message('element', this.state.element, 'required')}
                        </Col>
                        <Col md="2" className="mb-3">
                          <Label htmlFor="ordering">Ordering</Label>
                          <input name="ordering" placeholder="Type Ordering" defaultValue={this.state.ordering} type="text" className={"form-control"} onChange={this.handleInput}/>
                          {this.validator.message('ordering', this.state.ordering, 'required')}
                        </Col>
                        <Col md="auto" className="mb-3 align-self-end">
                          <div className="form-check form-switch form-switch-lg">
                            <input type="checkbox" className="form-check-input" id="current_status" checked={this.state.status} onClick={() => {this.setState({ status: !this.state.status, })}} />
                            <label className="form-check-label" htmlFor="current_status">Status</label>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={this.state.submit}>
                          {this.state.submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EsElementsAdd.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default EsElementsAdd
