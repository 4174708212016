import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody , UncontrolledTooltip} from "reactstrap";
import MetaTags from "react-meta-tags";
import Pagination from "components/Common/Pagination";
import useFullPageLoader from "../../components/Common/useFullPageLoader";
import { Link } from "react-router-dom";
import useReminder from "./useReminder";
import { get, post } from "../../helpers/api_helper";
import { GET_LEADS_TODAYS_TO_DO_DATA, GET_REMINDER } from "../../helpers/api_url_helper";
import toastr from "toastr"
import AddReminder from "./add-reminder";
import { formatUpcomingEvent } from "constants/CommonFunction";

const TodaysToDoLeads = () => {
  const [tableData, settableData] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const { isShowingReminder, toggleReminder } = useReminder();
  const [leadId, setLeadId] = useState(null);
  const [BoardReload, setBoardReload] = useState(false)
  const [reminderData, setReminderData] = useState(null);

  let PageSize = 10;

  useEffect(() => {
    showLoader()
    const params = { keyword: search, length: PageSize, start: currentPage };
    post(GET_LEADS_TODAYS_TO_DO_DATA, params).then(res => {
      if (res) {
        settableData(res.data)
        setTotalItems(res.total)
        hideLoader()
      }
    }).catch(err => {
      hideLoader()
      toastr.error(err)
    })
  }, [currentPage, search, BoardReload]);  // pass `value` as a dependency 

  const reloadBoard = () => {
    setBoardReload(!BoardReload)
  }
  
  const modalReminder = (id) => {
    setLeadId(id);
    // Fetch the reminder data for the specific lead
    get(GET_REMINDER, { params: { lead_id: id } })
      .then(res => {
        if (res.status) {
          // Assuming res.data contains the reminder data for the lead
          const reminderData = res.data;
          
          // Toggle the reminder modal and pass the reminder data
          setReminderData(reminderData);  // Assuming `setReminderData` is a state setter for reminders
          toggleReminder(!isShowingReminder);
        }else{
          setReminderData('');
          toggleReminder(!isShowingReminder);
        }
      })
      .catch(err => {
        toastr.error("Failed to fetch reminders", err);
        setReminderData('');
        toggleReminder(!isShowingReminder);
      });
  };  
  
  const stageColors = {
    1: "lightgray",
    2: "#b7b7ff",
    3: "white",
    4: "#ffd382",
    5: "#6cd96c",
    6: "#ff7f7f",
    7: "#ffff5b",
    8: "pink"
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Today`s To Do | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <Card className="sticky-main-header">
            <CardBody>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="mt-2 mb-3">
                  <h4 className="m-0">TODAY`S TO DO</h4>
                </div>
                <div className="search-box me-2" style={{ width:"100%", maxWidth: "40%", minWidth: "300px" }}>
                  <div className="position-relative mb-3">
                    <input
                      type="text" onChange={e => { setSearch(e.target.value) }}
                      className="form-control bg-light border-light rounded"
                      placeholder="Search lead by Name, Email, Mobile"
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>              
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col md={12}>
              <div className="d-flex justify-content-between">
                <h6>{totalItems} Leads</h6>
                {/*<h6>BULK ACTIONS</h6>*/}
              </div>
              {/*<Card>*/}
              {/*  <CardBody>*/}
              <div className="">
                {loader && 
                  <div className="wrapper">
                    <div className="wrapper-cell">
                      <div className="text">
                        <div className="text-line"> </div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                      </div>
                    </div>
                    <div className="wrapper-cell">
                      <div className="text">
                        <div className="text-line"> </div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                        <div className="text-line"></div>
                      </div>
                    </div>
                  </div>}
                  {(!loader && tableData.length === 0) && <h4 className="text-center">No data found</h4>}
                  {!loader && tableData.map((value, index) => (
                     <Card key={index} className="candidate-card">
                     <CardBody>
                       <div className="d-flex">
                         <div className="flex-grow-1 overflow-hidden">
                           <h4 className="text-truncate fw-bold font-size-18">
                             <Link to={`/lead/${value.id}`} className="text-dark">
                                {value.name}
                             </Link>
                           </h4>
                           <div className="candidate-meta">
                             {value.source !== null && <span>Source: <span className="text-dark text-primary">{value.source}</span></span>}
                             {value.assigned_to !== null && <span> | Assigned To: <span className="text-dark text-primary">{value.assigned_to}</span></span>}
                           </div>
                           <div className="d-flex flex-wrap justify-content-between">
                            <div className="candidate-meta">
                              {value.brand !== null && <span>Brand: <span className="text-dark text-primary">{value.brand}</span></span>}
                              {value.business_category !== null && <span> | Business Category: <span className="text-dark text-primary">{value.business_category}</span></span>}
                            </div>
                            <div className="candidate-meta">
                                {value.stage_id !== null && (
                                    <span
                                        className="text-dark text-primary"
                                        style={{
                                            background: stageColors[value.stage_id] || "transparent", // Fallback to transparent if stage_id not found
                                            paddingInline: "5px",
                                            borderRadius: "5px"
                                        }}
                                    >
                                        Stage: {value.stage}
                                    </span>
                                )}
                            </div>
                           </div>
                           <div className="d-flex justify-content-between w-100">
                           <ul className="list-inline mb-0">
                              {value.email !== null && value.email !== '' && 
                                <li className="list-inline-item me-3" id="dueDate">
                                  <i className="bx bx-envelope me-1" /> {value.email}
                                </li>
                              }
                              {value.mobile !== null && value.mobile !== '' && 
                                <li className="list-inline-item me-3" id="comments">
                                  <i className="bx bx-phone me-1" />{" "} {value.country_code}&nbsp;{value.mobile}
                                </li>
                              }
                              {value.client_location !== null && value.client_location !== '' && 
                                <li className="list-inline-item me-3" id="comments">
                                  <i className="bx bx-map me-1" />{" "} {value.client_location}
                                </li>
                              }
                           </ul>
                           </div>
                           <div className="d-flex wrap-flex">
                            { value.upcoming && value.upcoming.map((val, index) => (
                              <> 
                                <div
                                className=""
                                style={{
                                    background: '#003070',
                                    color: '#fff',
                                    borderRadius: '50px',
                                    fontSize:'10px',
                                    textAlign: 'center',
                                    width: 'fit-content',
                                    minWidth: '230px',
                                    padding: '5px 10px',
                                    marginTop: '10px',
                                    marginRight: '10px'
                                }}
                            >
                                {formatUpcomingEvent(val.title, val.value)}
                            </div></>
                            ))}
                            </div>
                         </div>
                         <div className="d-flex flex-column justify-content-between" style={{ alignItems: 'end' }}>
                          <div className="candidate-card-tags" style={{ gridTemplateColumns: 'auto auto auto auto auto' }}>
                            <Link to={`/lead/${value.id}?tabId=3`} className="tags-option" id={`tags-option-1-2`}><i className="bx bx-phone"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-2`}>View Call</UncontrolledTooltip></Link>
                            <Link to={`/lead/${value.id}?tabId=4`} className="tags-option" id={`tags-option-1-5`}><i className="bx bx-calendar"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-5`}>View Meeting</UncontrolledTooltip></Link>
                            <Link to={`/lead/${value.id}?tabId=2`} className="tags-option" id={`tags-option-1-1`}><i className="bx bxs-file"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-1`}>View Notes</UncontrolledTooltip></Link>
                            <Link to={`/lead/${value.id}?tabId=5`} className="tags-option" id={`tags-option-1-3`}><i className="bx bx-paperclip"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-3`}>View Attachments</UncontrolledTooltip></Link>
                            <Link  onClick={() => modalReminder(value.id)}  data-lead-d={`${value.id}`} className="tags-option" id={`tags-option-1-4`}><i className="bx bx-alarm"></i> <UncontrolledTooltip placement="top" target={`tags-option-1-4`}>Add Reminder</UncontrolledTooltip></Link>
                          </div>
                         </div>
                       </div>
                     </CardBody>
                    </Card>
                  ))}
              </div>
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                </Col>
              </Row>
              {/*  </CardBody>*/}
              {/*</Card>*/}
            </Col>
          </Row>
          {isShowingReminder === true &&
            <AddReminder
              isShowing={isShowingReminder}
              reloadBoard={reloadBoard}
              hide={toggleReminder}
              lead_id={leadId}
              reminderData={reminderData}
            />
          }          
        </div>
      </div>
    </React.Fragment>
  );
};

export default TodaysToDoLeads;
