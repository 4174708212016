import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import Pagination from "components/Common/Pagination"
import { useLocation } from "react-router-dom"
import { leaveTabledata } from "constants/Constants"
import LeaveNavTabs from "components/LeaveNavTabs/LeaveNavTabs"
import {
  fetchLeaveApproval,
  setleaveApprovalCurrentPage,
  setLeaveApprovalSearchQuery,
  toggleLeaveRejactionForm,
  updateStatus,
} from "store/leaveApproval/leaveApprovalSlice"
import { useDispatch, useSelector } from "react-redux"
import { Search } from "components/Datatable"
import { formatDateFormat, leaveStatus, sortName } from "constants/CommonFunction"

export const LeaveApproval = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const activeTab = location.pathname
  const {
    leaveApprovalCurrentPage,
    leaveApprovalSearchQuery,
    leaveApprovalData,
    loading,
    leaveApprovalTotalData,
  } = useSelector(state => state.leaveApproval)
  
  const toggle = async (values) => {

    await dispatch(toggleLeaveRejactionForm({toggle:true, data:values}))
  }

  const handleLeaveApprove = (id, status) =>{
    console.log(id)
    dispatch(updateStatus({id:id,status:status}))
    // dispatch(
    //   fetchLeaveApproval({
    //     start: leaveApprovalCurrentPage,
    //     length: 10,
    //     keyword: leaveApprovalSearchQuery,
    //     status: "",
    //   })
    // )
  }
  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(leaveApprovalCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        accessor: "first_name",
        Header: () => <div style={{ width: "200px" }}>Employee Name</div>,
        Cell: ({ row }) => {
          const { first_name, last_name, profile_photo } = row.original

          return (
            <div
              className="d-flex align-items-center"
              style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
            >
              {profile_photo ? (
                <img
                  src={profile_photo}
                  alt="Employee"
                  className="rounded-circle avatar-xs me-2"
                />
              ) : (
                <div className="avatar-xs align-self-center">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                    {sortName(`${first_name} ${last_name || ""}`)}
                  </span>
                </div>
              )}
              <div>
                <p className="mb-0">{`${first_name} ${
                  last_name ? last_name : ""
                }`}</p>
              </div>
            </div>
          )
        },
      },
      {
        Header: () => <div style={{ width: "100px" }}>Employee Id</div>,
        accessor: "emp_id",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Status</div>,
        accessor: "status",
        Cell: ({ value }) => <div>{value ? leaveStatus(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Start Date</div>,
        accessor: "start_date",
        Cell: ({ value }) => <div>{value ? formatDateFormat(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>End Date</div>,
        accessor: "end_date",
        Cell: ({ value }) => <div>{value ? formatDateFormat(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>No. of Days</div>,
        accessor: "total_days",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          const { can_action, status, id  } = row.original

          return (can_action && status==0) ? (
            <div className="d-flex gap-3">
              <div
                className="d-flex justify-content-center"
                style={{ gap: "5px", border: "1px solid #28a745" }}
              >
                <i
                  onClick={()=>{handleLeaveApprove(id, 1)}}
                  className="bx bx-check cursor-pointer"
                  style={{ fontSize: "20px", color: "#28a745" }}
                />
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ gap: "5px", border: "1px solid #dc3545" }}
              >
                <i
                  onClick={()=>{toggle(row.original)}}
                  className="bx bx-x cursor-pointer"
                  style={{ fontSize: "20px", color: "#dc3545" }}
                />
              </div>
            </div>
          ) : 
          status == 1 ? <div className="text-success">Approved</div> : status == 2 ? <div className="text-danger">Rejected</div>: <div className="text-info">Cancelled</div>   

        },
      },
    ]
  }, [dispatch, leaveApprovalCurrentPage])

  useEffect(() => {
    dispatch(
      fetchLeaveApproval({
        start: leaveApprovalCurrentPage,
        length: 10,
        keyword: leaveApprovalSearchQuery,
        status: "",
        ...(location?.state?.row?.id && { employee_id: location.state.row.id }), // Only include employee_id if it's available
      })
    )
  }, [dispatch, leaveApprovalCurrentPage, leaveApprovalSearchQuery, location])

  return (
    <div className={activeTab.includes("/profile/view") ? "" : "page-content"}>
      <div className="container-fluid">
        <LeaveNavTabs />
        <div className="col-md-12">
          <h5 className="font-weight-bold mb-2 mt-3">Leave Approvals</h5>
          <Card className="cardBorder">
            <CardBody style={{ paddingBottom: "0px" }}>
              <Row className=" align-items-center">
                <Col md={2} sm={12} style={{ marginTop: "15px" }}>
                  <Search
                    onSearch={value => {
                      dispatch(setLeaveApprovalSearchQuery(value))
                      dispatch(setleaveApprovalCurrentPage(1))
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  className="overflow-auto"
                  style={{
                    height: "56vh",
                    scrollbarWidth: "thin",
                    padding: "0px",
                  }}
                >
                  <GlobalTable
                    columns={columns}
                    data={leaveApprovalData ? leaveApprovalData : []}
                    loading={loading}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter
              className="d-flex justify-content-end"
              style={{
                background: "#ECECEC",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div
                style={{
                  height: "30px",
                  marginLeft: "0px",
                }}
              >
                <Pagination
                  className="mx-2"
                  currentPage={leaveApprovalCurrentPage}
                  totalCount={leaveApprovalTotalData}
                  pageSize={10}
                  onPageChange={async page => {
                    dispatch(setleaveApprovalCurrentPage(page))
                  }}
                />
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  )
}
