import React, {useState} from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import SimpleReactValidator from 'simple-react-validator'
import {post} from "../../../../helpers/api_helper"
import {ADD_DEPARTMENT} from "../../../../helpers/api_url_helper"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";



const ExpenseRuleMappingAdd = () => {
  const [expenseRulesMap, setExpenseRulesMap] = useState("");
  const [ordering, setOrdering] = useState("");
  const [status, setStatus] = useState(true);
  const [submit, setSubmit] = useState(false);

  const validator = new SimpleReactValidator({ autoForceUpdate: this });

  const handleInput = (e) => {
    const { name, value } = e.target;
    name === "expenseRuleMap" ? setExpenseRulesMap(value) : setOrdering(value);
  };


 const handleFormSubmit = (e) => {
    e.preventDefault()
    if (validator.allValid()) {
      const formData = {
        expenseRuleMap,
         ordering,
        "status" : status ? 1 : 0
      }
      setSubmit( true);
      post(ADD_DEPARTMENT,formData)
      .then(response => {
        if (response.status) {
          setSubmit( false)
          toastr.success('Expense Rules Map added successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          history.push('/Expense-Rule-mapping')
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message)
        setSubmit( false)
      })
    } else {
      validator.showMessages();
      forceUpdate();
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Manage Expense Rules Map | {process.env.REACT_APP_PROJECTNAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Expense Rules Map" path="/Expense-Rule" breadcrumbItem="Add"/>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <form onSubmit={handleFormSubmit}>
                  <Row>
                      <Col lg="3" className="mb-3">
                      <Label htmlFor="type">Type</Label>
                      <Select
                      isMulti={false}
                      // options={this.state.country_master}
                      name="type"
                      classNamePrefix="select2-selection"
                      />
                      </Col>
                      <Col lg="3" className="mb-3">
                      <Label htmlFor="type">Value</Label>
                      <Select
                      isMulti={false}
                      name="value"
                      classNamePrefix="select2-selection"
                      />
                      </Col>
                      <Col md="3" className="mb-3">
                      <Label htmlFor="name">Level</Label>
                      <input
                      name="level"
                      placeholder="Type Level"
                      type="number"
                      className={"form-control"}
                      />
                      </Col>
                      <Col lg="3" className="mb-3">
                      <Label htmlFor="type">Employee</Label>
                      <Select
                      isMulti={false}
                      name="employee"
                      classNamePrefix="select2-selection"
                      />
                      </Col>
                    </Row>
                     <Row>
                      <Col md="3" className="mb-3">
                      <div className="form-check form-switch form-switch-lg">
                      <input
                      type="checkbox"
                      className="form-check-input"
                      id="ultimate_approval"/>
                      <label
                      className="form-check-label"
                      htmlFor="ultimate_approval"
                      >
                      Ultimate Approval
                      </label>
                      </div>
                      </Col>
                      <Col md="auto" className="mb-3 align-self-end">
                      <div className="form-check form-switch form-switch-lg">
                      <input
                      type="checkbox"
                      className="form-check-input"
                      id="current_status"
                      />
                      <label
                      className="form-check-label"
                      htmlFor="current_status"
                      >
                      Status
                      </label>
                      </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                      <Button color="primary" type="submit" >
                        {submit === true ? 'Please wait...' : 'Submit Data'}
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
ExpenseRuleMappingAdd.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default ExpenseRuleMappingAdd
