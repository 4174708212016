import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/pcl_logo.png"
import logoLightSvg from "../../assets/images/pcl_logo.png"
import { withTranslation } from "react-i18next"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import {
  getCurrentDayAndDate,
  getUserFirstName,
  getUserId,
} from "constants/CommonFunction"
import { fetchUsers } from "store/users/usersSlice"
import { clockInOut, getClockTime } from "store/master/masterSlice"
import { ClockTimer } from "components/Common/ClockTimer"
import toastr from "toastr"
import { fetchAuthProfileDetail } from "store/onBoarding/onBordingSlice"

const Header = props => {
  const [searchInput, setSearchInput] = useState("")
  const [showDropdown, setShowDropdown] = useState(false)
  const dispatch = useDispatch()
  const { usersData, currentPage } = useSelector(state => state.users)
  const { clockTimeData } = useSelector(state => state.master)
  const [isHovered, setIsHovered] = useState(false)
  const { authUserProfile  } = useSelector(
    state => state.onBoarding
  )
  const userId = getUserId();
  const history = useHistory();
  const filteredOptions = usersData?.filter(user =>
    `${user?.first_name} ${user?.last_name}`
      ?.toLowerCase()
      ?.includes(searchInput?.toLowerCase())
  )

  useEffect(() => {
    dispatch(
      fetchUsers({
        length: 10,
        start: currentPage,
      })
    )
    if(userId){
      dispatch(fetchAuthProfileDetail(userId))
    }
    dispatch(getClockTime())
  }, [])

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  const handleFilterClick = async option => {
    await setSearchInput(`${option.first_name} ${option.last_name}`)
    await setShowDropdown(false)
  }

  const handleKeyDown = event => {
    if (searchInput?.length <= 0) {
      return
    }
    if (event.key === "Enter") {
      event.preventDefault()
      if (filteredOptions.length > 0) {
        const selectedOption = filteredOptions[0]
        setSearchInput(
          `${selectedOption.first_name} ${selectedOption.last_name}`
        )
        setShowDropdown(false)
        history.push({
          pathname: `/profile/view`,
          state: { row: selectedOption },
        })
      }
    }
  }

  const handleClockInOut = async () => {
    try {
      const response = await dispatch(clockInOut())
      if (response?.payload?.data?.status) {
        toastr.success(response?.payload?.data?.message)
        console.log(response?.payload?.message)
      }else{
        console.error(response?.payload?.message)
        toastr.error(response?.payload?.message)
      }
    } catch (error) {
      console.error(error)
      toastr.error("Something Went Wrong")
    } finally {
      await dispatch(getClockTime())
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" width={40} />
                </span>
              </Link>
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
              style={{ color: "white" }}
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="d-none d-lg-block ms-2 align-content-center">
              <span className="font-size-16">Hi {authUserProfile.first_name} !</span>
            </div>
          </div>
          <div className="d-none d-sm-block">
            <div className="input-group" style={{ position: "relative", flexWrap:'nowrap' }}>
              <div className="form-outline" data-mdb-input-init>
                <input
                  id="search-input"
                  type="text"
                  className="form-control"
                  placeholder="Search Employees"
                  style={{
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    height: "40px",
                    border: "1px solid white",
                    background: "#1f2844",
                    color: "white",
                  }}
                  value={searchInput}
                  onChange={e => {
                    setSearchInput(e.target.value)
                    setShowDropdown(e.target.value.length > 0)
                  }}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <button
                id="search-button"
                type="button"
                style={{ background: "#fff" }}
                className="btn no-hover"
              >
                <i className="fas fa-search no-hover-icon" />
              </button>
              {showDropdown && (
                <div
                  className="dropdown-menu show mt-1"
                  style={{
                    position: "absolute",
                    top: "40px",
                    left: "0",
                    width: "100%",
                    zIndex: 1000,
                    backgroundColor: "white",
                    color: "white",
                  }}
                >
                  {filteredOptions?.map(option => (
                    <Link
                      key={option?.id}
                      to={{
                        pathname: `/profile/view`,
                        state: { row: option },
                      }}
                      onClick={() => handleFilterClick(option)}
                    >
                      <a href="#" className="dropdown-item">
                        {`${option?.first_name} ${option?.last_name}`}
                      </a>
                    </Link>
                  ))}
                  {filteredOptions?.length == 0 && (
                    <div className="dropdown-item">No option</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex">
            <div className="clock-in-container">
              <div
                id="clockInWrapper"
                className="no-gutters clockInUser"
                style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                  textAlign: "center",
                }}
              >
                <div id="clockInDate" className="text-uppercase">
                  {getCurrentDayAndDate()}
                </div>
                <div className="mt-1">
                  <button
                    style={{
                      height: "40px",
                      width: "110px",
                      padding: "3px",
                      backgroundColor:
                        clockTimeData?.clockInStatus == 1
                          ? isHovered
                            ? "orange"
                            : "#0094ff"
                          : "#ff002a",
                      borderRadius: "2px",
                      color: "white",
                      border: "none",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={handleClockInOut}
                  >
                    {clockTimeData?.clockInStatus == 1 ? (
                      <div className="d-flex flex-column justify-content-start">
                        <span
                          style={{
                            textAlign: !isHovered && "left",
                            fontSize: !isHovered ? "10px" : "12px",
                          }}
                        >
                          {isHovered ? "WEB CLOCK-OUT" : "CLOCKED IN"}
                        </span>
                        {!isHovered && (
                          <ClockTimer startTime={clockTimeData?.clockInTime} />
                        )}
                      </div>
                    ) : (
                      <span className="fs-6">WEB CLOCK-IN</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return { ...state.Layout }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
