import React,{useEffect} from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Input
  } from "reactstrap"
  import Select from "react-select"
import { useDispatch,useSelector } from 'react-redux'
import { months,customStyles } from 'constants/Constants'
import {setMonthSync,
        setYearSync,
        setOpenSyncModal,
        fetchSyncEmployeeSalary,
        fetchEmployeeSalary
} from 'store/employeeSalary/employeeSalarySlice'
import * as Yup from "yup"
import toastr from "toastr"

const SalarySync = () => {
    const dispatch = useDispatch()
    const {openSyncModal,
        monthSync,
        yearSync,
        salarySearchQuery,
        currentPage,

    }=useSelector(state=>state.employeeSalary)

    const toggle= async() => {
        await dispatch(setOpenSyncModal(false))
      }
      const validationSchema = Yup.object({
        yearSync: Yup.number()
            .integer('Year must be a number')
            .min(1920, 'Year should be between 1920 and the current year')
            .max(new Date().getFullYear(), 'Year should be between 1920 and the current year'),
            // .required('Year is required'),
        monthSync: Yup.object()
            .shape({
                value: Yup.string().required('Month value is required'),
            })
            .nullable()
            .required('Month is required')
    });


  const handleSync = async() => {
    const yearNumber = Number(yearSync);
    try {
       validationSchema.validateSync({ yearSync: yearNumber, monthSync });
      const response =await  dispatch(fetchSyncEmployeeSalary({ month: monthSync?.value, year: yearNumber })).unwrap();
        try {
          if (response?.status) {
            toastr.success(response?.message || "Operation successful")
          } else {
            toastr.error(response?.message || "Something went wrong")
          }
        } catch (error) {
          toastr.error("Something Went Wrong Please Try Later",error)
        } finally {
          await dispatch(
            fetchEmployeeSalary({
              length:10,
              start:currentPage,
              keyword:salarySearchQuery.length >0 ?salarySearchQuery:undefined,
              month:monthSync?.value,
              year: yearSync,
            })
          )
          await dispatch(setOpenSyncModal(false))
        }
        // dispatch(setCurrentPage(1));
    } catch (error) {
      toastr.error(error.message || "Something went wrong")
    }
};
useEffect(() => {
  if (openSyncModal) {
      // Reset month and year when the modal opens
      dispatch(setMonthSync(null));
      dispatch(setYearSync(''));
  }
}, [openSyncModal, dispatch]);

  return (
    <Modal
        isOpen={openSyncModal}
        style={{ maxWidth: "400px", margin: '0 auto' }}
        scrollable
        toggle={toggle}
        size="lg"
        centered>
    <ModalHeader toggle={toggle}>
        Generate Salary For
    </ModalHeader>
    <ModalBody style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
    <Row>
    <Col xl="12">
    {/* <Form> */}
    <Row>
    <Col md="12" className="mb-3">
    <Label htmlFor="employee">
        Month
    <span className="text-danger">*</span>
    </Label>
    <Select
        className="menu-sm-h mb-3"
        id="month"
        name="month"
        size="sm"
        // menuIsOpen={true}
        options={months()}
        onChange={option => dispatch(setMonthSync(option))}
        styles={customStyles}
        value={monthSync}
      />
    <Label htmlFor="year">
        Year
        <span className="text-danger">*</span>
    </Label>
    <Input
        className="form-control mb-3"
        name="year"
        placeholder="Year"
        type="text"
        value={yearSync}
        onChange={e => dispatch(setYearSync(e.target.value))}
    />
      <div className="d-flex flex-wrap gap-2 justify-content-center ">
    <Button
      color="primary"
      type="submit"
      onClick={handleSync}
    > Generate
    </Button>
    </div>
    </Col>
    </Row>
    {/* </Form> */}
    </Col>
    </Row>
    </ModalBody>
 </Modal>


  )
}

export default SalarySync
