import React, { useEffect } from "react"
import { ProfileTabs } from "./ProfileTabs"
import { useDispatch, useSelector } from "react-redux"
import { fetchProfileDetail, setOnBoardingActiveTabs, setUserId } from "store/onBoarding/onBordingSlice"
import { useLocation } from "react-router-dom"
import { getUserId } from "constants/CommonFunction"
import useTabsComponents from "components/Hooks/useTabsComponents"
import { Spinner } from "reactstrap"

export const ProfileView = () => {
  const { onBoardingActiveTabs, loading } = useSelector(state => state.onBoarding)
  const tabsComponents = useTabsComponents()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const profileDetail = async () => {
      const newUserId = location?.state?.row?.id
        ? location.state.row.id
        : getUserId()
      await dispatch(setUserId(newUserId))
      await dispatch(fetchProfileDetail(newUserId))
    }
    profileDetail()
  }, [dispatch, location])
  


  return (
    <div className="page-content">
      {!loading ?
        <div>
          <ProfileTabs />
          <div className="container-fluid">
            {tabsComponents.map(tab =>
              tab?.id === onBoardingActiveTabs ? (
                <div key={tab?.id}>{tab?.component}</div>
              ) : null
            )}
          </div>
        </div>
        :
        <div style={{ height: "calc(90vh - 174px)", display:'grid' }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "100%",
                }}
              >
                <Spinner color="primary" />
              </div>
            </div>  
      }
    </div>
  )
}
