import React from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { get, post } from "../../../helpers/api_helper";
import { ADD_OFFICE_LOCATION, GET_ALL_MASTERS } from "../../../helpers/api_url_helper";
import toastr from "toastr";
import PropTypes from "prop-types";
import Select from "react-select";

class OfficeLocationAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location_master_id: "",
      name: "",
      ordering: "",
      status: true,
      submit: false,
      location: [],
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentDidMount() {
    this.loadMaster();
  }

  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: "location" } })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          this.setState({ location: data.location });
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ location_master_id: selectedOption.value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = {
        location_master_id: this.state.location_master_id,
        name: this.state.name,
        ordering: this.state.ordering,
        status: this.state.status ? 1 : 0,
      };
      this.setState({ submit: true });
      post(ADD_OFFICE_LOCATION, formData)
        .then((response) => {
          if (response.status) {
            this.setState({ submit: false });
            toastr.success("Office Location added successfully.");
            // eslint-disable-next-line react/prop-types
            const { history } = this.props;
            // eslint-disable-next-line react/prop-types
            history.push("/office-location");
          }
        })
        .catch((err) => {
          toastr.error(err?.response?.data?.message);
          this.setState({ submit: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Manage Office Location | {process.env.REACT_APP_PROJECTNAME}</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Office Location" path="/office-location" breadcrumbItem="Add" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col lg="3" className="mb-3">
                          <Label htmlFor="location_master_id">City</Label>
                          <Select
                            isMulti={false}
                            options={this.state.location}
                            name="location_master_id"
                            classNamePrefix="select2-selection"
                            onChange={this.handleSelectChange}
                          />
                          {this.validator.message("location_master_id", this.state.location_master_id, "required")}
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="name">Office Location</Label>
                          <input
                            name="name"
                            placeholder="Type Office Location"
                            value={this.state.name}
                            type="text"
                            className={"form-control"}
                            onChange={this.handleInput}
                          />
                          {this.validator.message("name", this.state.name, "required")}
                        </Col>
                        <Col md="2" className="mb-3">
                          <Label htmlFor="ordering">Ordering</Label>
                          <input
                            name="ordering"
                            placeholder="Type Ordering"
                            value={this.state.ordering}
                            type="text"
                            className={"form-control"}
                            onChange={this.handleInput}
                          />
                          {this.validator.message("ordering", this.state.ordering, "required")}
                        </Col>
                        <Col md="auto" className="mb-3 align-self-end">
                          <div className="form-check form-switch form-switch-lg">
                            <input type="checkbox" className="form-check-input" id="current_status" checked={this.state.status} onClick={() => {this.setState({ status: !this.state.status, })}} />
                            <label className="form-check-label" htmlFor="current_status">Status</label>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={this.state.submit}>
                          {this.state.submit === true ? "Please wait..." : "Submit Data"}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

OfficeLocationAdd.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
};

export default OfficeLocationAdd;
