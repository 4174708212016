import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import Pagination from "components/Common/Pagination"
import { TableHeader, Search } from "components/Datatable/index"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useFullPageLoader from "../../../components/Common/useFullPageLoader"
import { Link } from "react-router-dom"
import { get } from "../../../helpers/api_helper"
import {
  EXPORT_MASTER,
  GET_CANDIDATE_SOURCE,
} from "../../../helpers/api_url_helper"
import axios from "axios"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import { useDispatch, useSelector } from "react-redux"
import { exportXlsxFile } from "store/master/masterSlice"
import toastr from "toastr"
import useExportXlsx from "components/Hooks/useExportXlsx"
import useImportXlxs from "components/Hooks/useImportXlxs"
const CandidateSource = () => {
  const [tableData, settableData] = useState([])
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const [FormMsg, setFormMsg] = useState("")
  const [reloadData, setreloadData] = useState(false)
  const [file, setFile] = useState(null)
  const { exportXlsx, xlsxLoader } = useExportXlsx();
  const { candidate_source } = useSelector(state => state.users.userPermissions);

  const TableColum = [
    { name: "#", field: "id", sortable: false },
    { name: "Candidate Source", field: "name", sortable: false },
    { name: "Ordering", field: "ordering", sortable: false },
    { name: "Status", field: "status", sortable: false },
    candidate_source?.edit ? { name: "Action", field: "", sortable: false } : null,
  ].filter(Boolean);
  let PageSize = 15
  useEffect(() => {
    fetchCandidateSource();
  }, [currentPage, search, reloadData])

  const fetchCandidateSource=()=>{
    showLoader()
    const params = { keyword: search, length: PageSize, start: currentPage }
    get(GET_CANDIDATE_SOURCE, { params: params })
      .then(res => {
        hideLoader()
        if (res) {
          settableData(res.data)
          setTotalItems(res.total)
        }
      })
      .catch(err => {
        hideLoader()
        toastr.error(err)
      })
  }
  const handleFileChange = e => {
    setFile(e.target.files[0])
  }

  const { importXlxs } = useImportXlxs();
  const ImportCSV = async e => {
    e.preventDefault();
    if (file) {
      await importXlxs("Candidate-Source", file);
      document.getElementById("file").value = "";
      fetchCandidateSource();
      setFile(null); // Ensure the state gets cleared
    } else {
      toastr.error("No file selected"); // Handle case where file is not present
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Candidate Source | {process.env.REACT_APP_PROJECTNAME}</title>
        </MetaTags>
        <div className="container-fluid">
          <h5 className="mb-3">Candidate Source</h5>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <Col md="3" xs="12">
                      <Search
                        onSearch={value => {
                          setSearch(value)
                          setCurrentPage(1)
                        }}
                      />
                    </Col>
                    <Col md="9" xs="12">
                      <div className="d-flex flex-wrap justify-content-end">
                        {candidate_source?.export && <div className="d-flex align-items-center mb-2">
                          <button
                            color="primary"
                            type="button"
                            className="mx-4 btn btn-primary"
                            style={{ borderRadius: "5px", height: "38px" }}
                            onClick={() =>
                              exportXlsx("Candidate-Source", "Candidate Source")
                            }
                            disabled={xlsxLoader}
                          >
                            <i className="bx bx-export font-size-16 me-2" />
                            {xlsxLoader ? "Exporting..." : "Export"}
                          </button>
                        </div>}
                        {candidate_source?.import && <div className="d-flex align-items-center mb-2">
                          <form onSubmit={ImportCSV}>
                            <div className="input-group">
                              <Input
                                type="file"
                                className="form-control"
                                name={"file"}
                                accept={".xlsx"}
                                id="file"
                                aria-describedby="file"
                                aria-label="Upload"
                                onChange={handleFileChange}
                              />
                              <Button
                                color="primary"
                                type="submit"
                                id="inputGroupFileAddon04"
                                disabled={!file}
                              >
                                Import
                              </Button>
                            </div>
                          </form>
                        </div>}
                        {candidate_source?.add && <div className="d-flex align-items-center mb-2">
                          <a
                            href="/candidate-source/add"
                            className="mx-4 btn btn-primary"
                            style={{ borderRadius: "5px" }}
                          >
                            <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                            Add
                          </a>
                        </div>}
                      </div>
                    </Col>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <TableHeader
                        headers={TableColum}
                        // onSorting={(field, order) =>
                        //     setSorting({field, order})
                        // }
                      />
                      <tbody className="">
                        {loader && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">Loading...</h4>
                            </th>
                          </tr>
                        )}
                        {!loader && tableData.length === 0 && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">No data found</h4>
                            </th>
                          </tr>
                        )}
                        {!loader &&
                          tableData.map((value, index) => (
                            <tr key={++index}>
                              <th scope="row">
                                {PageSize * (currentPage - 1) + (index + 1)}
                              </th>
                              <td>{value.name}</td>
                              <td>{value.ordering}</td>
                              <td>
                                {value.status === "1" ? (
                                  <span className="badge badge-pill bg-success font-size-13">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-pill bg-danger font-size-13">
                                    Inactive
                                  </span>
                                )}
                              </td>
                               {candidate_source?.edit &&
                              <td>
                               <Link to={`/candidate-source/edit/${value.id}`}>
                                  <i className="bx bxs-edit-alt"></i>{" "}
                                </Link>
                              </td>
                                }
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CandidateSource
