import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiApproveProfile,
  apiEditAddress,
  apiEditContactDetail,
  apiEditEmergencyContact,
  apiEditKycDocument,
  apiEditSalaryDetail,
  apiGetCity,
  apiGetCountry,
  apiGetState,
  apiProfileDetail,
  apiUploadImage,
  apieditPersonalInfo,
} from "services/OnboardingService"

export const SLICE_NAME = "onBoarding"

export const editPersonalInfo = createAsyncThunk(
  `${SLICE_NAME}/personalDetail`,
  async data => {
    try {
      const response = await apieditPersonalInfo(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editContactDetail = createAsyncThunk(
  `${SLICE_NAME}/editContactDetail`,
  async data => {
    try {
      const response = await apiEditContactDetail(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editEmergencyDetail = createAsyncThunk(
  `${SLICE_NAME}/editEmergencyDetail`,
  async data => {
    try {
      const response = await apiEditEmergencyContact(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editAddressDetail = createAsyncThunk(
  `${SLICE_NAME}/editAddress`,
  async data => {
    try {
      const response = await apiEditAddress(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editSalaryDetail = createAsyncThunk(
  `${SLICE_NAME}/editSalaryDetail`,
  async ({ id, data }) => {
    try {
      const response = await apiEditSalaryDetail(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editKycDocument = createAsyncThunk(
  `${SLICE_NAME}/editKycDocument`,
  async ({ id, data }) => {
    try {
      const response = await apiEditKycDocument(id, data)
      return {
        status: "success",
        data: response.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchCountry = createAsyncThunk(
  `${SLICE_NAME}/fetchCountry`,
  async () => {
    try {
      const response = await apiGetCountry()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchState = createAsyncThunk(
  `${SLICE_NAME}/fetchState`,
  async country_id => {
    try {
      const response = await apiGetState(country_id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchPermanentState = createAsyncThunk(
  `${SLICE_NAME}/permanentState`,
  async country_id => {
    try {
      const response = await apiGetState(country_id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchCity = createAsyncThunk(
  `${SLICE_NAME}/fetchCity`,
  async state_id => {
    try {
      const response = await apiGetCity(state_id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchPermanentCity = createAsyncThunk(
  `${SLICE_NAME}/fetchPermanentCity`,
  async state_id => {
    try {
      const response = await apiGetCity(state_id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchProfileDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchProfileDetail`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiProfileDetail(id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)
export const fetchAuthProfileDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchAuthProfileDetail`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiProfileDetail(id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const uploadProfileImage = createAsyncThunk(
  `${SLICE_NAME}/uploadProfileImage`,
  async ({ id, data }) => {
    try {
      const response = await apiUploadImage(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const approveProfile = createAsyncThunk(
  `${SLICE_NAME}/approveProfile`,
  async ({ id, data }) => {
    try {
      const response = await apiApproveProfile(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const initialState = {
  loading: false,
  editPersonalDetail: false,
  editContactDetail: false,
  editAddressDetail: false,
  editWorkDetail: false,
  editSalaryDetail: false,
  editResignationDetail: false,
  editOtherDocumetDetail: false,
  editEmergencyDetail: false,
  editAdditionalDetail: false,
  editKycDetail: false,
  editOtherDocumentDetail: false,
  onBoardingActiveTabs: "1",
  countryData: [],
  stateData: [],
  cityData: [],
  permanentStateData: [],
  permanentCityData: [],
  profileDetails: [],
  userId: [],
  editProfile: false,
  authUserProfile:[]
}

const onBoardingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setEditPersonalDetail: (state, action) => {
      state.editPersonalDetail = action.payload
    },
    setEditContactDetail: (state, action) => {
      state.editContactDetail = action.payload
    },
    setEditAddressDetail: (state, action) => {
      state.editAddressDetail = action.payload
    },
    setEditEmergencyDetail: (state, action) => {
      state.editEmergencyDetail = action.payload
    },
    setEditResignationDetail: (state, action) => {
      state.editResignationDetail = action.payload
    },
    setEditSalaryDetail: (state, action) => {
      state.editSalaryDetail = action.payload
    },
    setEditAdditionalDetail: (state, action) => {
      state.editAdditionalDetail = action.payload
    },
    setEditKycDetail: (state, action) => {
      state.editKycDetail = action.payload
    },
    setEditOtherDocumentDetail: (state, action) => {
      state.editOtherDocumentDetail = action.payload
    },
    setOnBoardingActiveTabs: (state, action) => {
      state.onBoardingActiveTabs = action.payload
    },
    setUserId: (state, action) => {
      state.userId = action?.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCountry.fulfilled, (state, action) => {
      state.countryData = action.payload.data.map(country => ({
        value: country.id,
        label: country.name,
      }))
    })
    builder.addCase(fetchState.fulfilled, (state, action) => {
      state.stateData = action.payload.data.map(state => ({
        value: state.id,
        label: state.name,
      }))
    })
    builder.addCase(fetchCity.fulfilled, (state, action) => {
      state.cityData = action.payload.data.map(city => ({
        value: city.id,
        label: city.name,
      }))
    })
    builder.addCase(fetchPermanentState.fulfilled, (state, action) => {
      state.permanentStateData = action.payload.data.map(state => ({
        value: state.id,
        label: state.name,
      }))
    })
    builder.addCase(fetchPermanentCity.fulfilled, (state, action) => {
      state.permanentCityData = action.payload.data.map(city => ({
        value: city.id,
        label: city.name,
      }))
    })
    // Handle pending state
    builder.addCase(fetchProfileDetail.pending, (state) => {
      state.loading = true; // Set loading to true when pending
      state.error = null; // Reset any previous errors
    });

    // Handle fulfilled state
    builder.addCase(fetchProfileDetail.fulfilled, (state, action) => {
      state.profileDetails = action.payload.data;
      state.editProfile = action?.payload?.data?.can_edit;
      state.loading = false; // Set loading to false when fulfilled
    });

    // Handle rejected state
    builder.addCase(fetchProfileDetail.rejected, (state, action) => {
      state.loading = false; // Set loading to false when rejected
      state.error = action.error.message; // Capture the error message
    });

    // Handle fulfilled state
    builder.addCase(fetchAuthProfileDetail.fulfilled, (state, action) => {
      state.authUserProfile = action.payload.data;
      state.loading = false; // Set loading to false when fulfilled
    });
  },
})

export const {
  setLoading,
  setEditPersonalDetail,
  setEditContactDetail,
  setEditAddressDetail,
  setEditEmergencyDetail,
  setEditSalaryDetail,
  setEditResignationDetail,
  setEditAdditionalDetail,
  setEditKycDetail,
  setEditOtherDocumentDetail,
  setOnBoardingActiveTabs,
  setUserId,
} = onBoardingSlice.actions

export default onBoardingSlice.reducer
