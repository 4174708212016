import { ApiService } from "./ApiService"


export async function apiGetEmployeeSalary({length, start , keyword ,month,year}){
    return ApiService.fetchData({
            url:"salary_logs/logs",
            method:"get",
            params:{length, start , keyword ,month,year}
        })
}

export async function apiGetSyncEmployeeSalary({month,year}){
        return ApiService.fetchData({
            url:"cron/calculate_salary_logs",
            method:"get",
            params:{month,year}
        })
}
