import React from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import { Formik, Form } from "formik"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import {
  createExpenseDocument,
  fetchExpenseDocument,
  setDocumentModal,
} from "store/expenseReward/expenseDocumentSlice"

export const ExpenseDocumentForm = () => {
  const dispatch = useDispatch()

  const { openDocumentModal, currentPage } = useSelector(
    state => state.expenseDocument
  )

  const toggle = async () => {
    await dispatch(setDocumentModal(false))
  }
  const location = useLocation()
  const id = location.state?.id

  return (
    <Modal isOpen={openDocumentModal} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>Add Expense Document</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                document: "",
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const formData = new FormData()
                formData.append("document", values.document)
                try {
                  const response = await dispatch(
                    createExpenseDocument({ id: id, data: formData })
                  ).unwrap()
                  if (response.data?.status === true) {
                    toastr.success("Document Uploaded Successfully")
                  } else {
                    toastr.error(response?.payload?.message)
                  }
                } catch (error) {
                  console.error("Upload error:", error)
                  toastr.error("Something Went Wrong Please Try Later")
                } finally {
                  setSubmitting(false)
                  resetForm()
                  await dispatch(setDocumentModal(false))
                  await dispatch(
                    fetchExpenseDocument({
                      id: id,
                      length: 10,
                      start: currentPage,
                    })
                  )
                }
              }}
            >
              {({ setFieldValue, isSubmitting, dirty }) => (
                <Form>
                  <Row>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="remark">Document :</Label>
                      <Input
                        type="file"
                        className={"form-control"}
                        name={"file"}
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        id="file"
                        aria-describedby="file"
                        aria-label="Upload"
                        onChange={e => {
                          setFieldValue("document", e.currentTarget.files[0])
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
