import { useState } from 'react';

const useReminder = () => {
  const [isShowingReminder, setIsShowingReminder] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleReminder() {
    setIsShowingReminder(!isShowingReminder);
    removeBodyCss();
  }

  return {
    isShowingReminder,
    toggleReminder,
  }
};

export default useReminder;