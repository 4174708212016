import { ApiService } from "./ApiService"

export async function apiGetPermission() {
  return ApiService.fetchData({
    url: `permission_group_master`,
    method: "get",
  })
}

export async function apiGetPermissionUsers() {
  return ApiService.fetchData({
    url: `users/get?status=1`,
    method: "get",
  })
}

export async function apiCreatePermission(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  try {
    const response = await ApiService.fetchData({
      url: `permission_group_master/${id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: urlEncodedPayload,
    })
    return response
  } catch (error) {
    console.error("Error in API call:", error)
    throw error
  }
}


export async function apiEditPermission(id, data) {
  return ApiService.fetchData({
    url: `employee_documents/documents/${id}`,
    method: "post",
    data: data,
  })
}
