import React, { useEffect, useMemo } from "react"
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import Pagination from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import { Search } from "components/Datatable"
import {
  fetchExpenseRewardMapping,
  setMappingCurrentPage,
  setExpenseMappingModal,
  setMappingTableRowData,
} from "store/expenseRule/expenseRuleSlice"
import { modifyDate } from "constants/CommonFunction"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

export const ExpenseMappingView = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { mappingcurrentPage, rewardMappingData, mappingTotalData } =
    useSelector(state => state.expenseRule)
  const columns = useMemo(() => {
    return [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Expense Type",
        accessor: "type",
        Cell: ({ value }) => <div>{value == 1 ? "Designation Manager": "Reporting Manager"}</div>,
      },
      {
        Header: "Expense Value",
        accessor: "ordering",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Expense Level",
        accessor: "level",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Employee",
        accessor: "employee_id",
        Cell: ({ row }) => {
          const firstName = row.original.employee_first_name
          const lastName = row.original.employee_last_name
          return (
            <div>
              {firstName && lastName ? `${firstName} ${lastName}` : "-"}
            </div>
          )
        },
      },
      {
        Header: "Ultimate Approval",
        accessor: "ultimate_approval",
        Cell: ({ value }) => (
          <div
            style={{ width: "60px" }}
            className={`text-white d-flex justify-content-center text-center rounded ${
              value == 1 ? "bg-success" : "bg-danger"
            }`}
          >
            <span style={{ padding: "2px" }}>
              {value == 1 ? "Active" : "Inactive"}
            </span>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div
            style={{ width: "60px" }}
            className={`text-white d-flex justify-content-center text-center rounded ${
              value == 1 ? "bg-success" : "bg-danger"
            }`}
          >
            <span style={{ padding: "2px" }}>
              {value == 1 ? "Active" : "Inactive"}
            </span>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <i
              onClick={async () => {
                await dispatch(setMappingTableRowData(row?.original))
                await dispatch(setExpenseMappingModal(true))
              }}
              className="bx bxs-edit cursor-pointer "
              style={{ fontSize: "17px", color: "#039f03" }}
            />
          </div>
        ),
      },
    ]
  }, [dispatch])

  const handleAdd = async () => {
    await dispatch(setMappingTableRowData([]))
    await dispatch(setExpenseMappingModal(true))
  }

  useEffect(() => {
    dispatch(
      fetchExpenseRewardMapping({
        length: 10,
        start: mappingcurrentPage,
        deduction_reward_rule_master_id: location?.state?.id,
      })
    )
  }, [mappingcurrentPage, location])

  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="mb-3">EXPENSE RULE MAPPING</h5>
        <Card>
          <CardBody>
            <Row className=" align-items-center">
              <Col md={6} sm={12}>
                {" "}
                <Search
                  onSearch={value => {
                    // setSearch(value)
                    // setMappingCurrentPage(0)
                  }}
                />
              </Col>
              <Col
                md={6}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
              >
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={rewardMappingData ? rewardMappingData : []}
                  loading={false}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={mappingcurrentPage}
                totalCount={mappingTotalData ? mappingTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  await dispatch(setMappingCurrentPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
