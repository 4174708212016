import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { employmentType } from "constants/Constants"
import Select from "react-select"
import AsyncSelect from 'react-select/async';
import toastr from "toastr"
import {
  createUsers,
  fetchDepartmentUsers,
  fetchDesignationUsers,
  fetchEmployeeTypeUsers,
  fetchProbationPeriodUsers,
  fetchReportingManagerUsers,
  fetchSubDepartmentUsers,
  fetchUsersJobTitle,
  fetchUsersNoticePeriod,
  fetchUsersOfficeLocation,
} from "store/users/usersSlice"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { apiGetATSUsers } from "services/UsersService"

export const UsersForm = () => {
  const dispatch = useDispatch()
  const {
    currentPage,
    managerData,
    departmentData,
    subDepartmentData,
    designationData,
    employeeTypeData,
    probationData,
    noticePeriodData,
    officeLocationData,
    jobTitleData,
  } = useSelector(state => state.users)
  const [importFromAts, setImportFromAts] = useState(false);
  const [user, setUser] = useState(null);
  const [atsUsers, setAtsUsers] = useState([]);
  const [subDepartmnetValue, setSubdepartmentValue] = useState("")
  const history = useHistory()
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(25, "25 characters only")
      .required("Required"),
    last_name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(25, "25 characters only")
      .required("Required"),
    emp_id: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    dob: Yup.string(),
    mobile: Yup.string()
      .matches(/^\d+$/, "invalid number")
      .min(10, "Min 10 digit")
      .max(10, "only 10 digit allow")
      .required("Required"),
    email: Yup.string().email("Invalid email format").required("required"),
    reporting_manager_employee_id: Yup.number(),
    department_master_id: Yup.number().required("Required"),
    sub_department_master_id: Yup.number(),
    designation_id: Yup.number().required("Required"),
    job_title: Yup.string(),
    job_role_id: Yup.number(),
    lock_in_period: Yup.number()
      .min(1, "Must be 1 or greater")
      .max(12, "Must be 12 or less"),
    salary: Yup.string().required("Required"),
    employment_type_master_id: Yup.number().required("Required"),
    probition_period_id: Yup.number(),
    notice_period_id: Yup.number(),
    office_location_id: Yup.number().required("Required"),
    doj: Yup.string().required("Required"),
  })

  const toggle = async () => {
    // await dispatch(setDocumentModal(false))
  }

  //   centered

  useEffect(() => {
    dispatch(fetchEmployeeTypeUsers())
    dispatch(fetchDepartmentUsers())
    dispatch(fetchSubDepartmentUsers())
    dispatch(fetchDesignationUsers())
    dispatch(fetchProbationPeriodUsers())
    dispatch(fetchUsersNoticePeriod())
    dispatch(fetchReportingManagerUsers())
    dispatch(fetchUsersOfficeLocation())
    dispatch(fetchUsersJobTitle());
    handleFetchAtsUser();
  }, [])

  const handleSubDepartment = async department_id => {
    // await setLoading(true)
    try {
      await dispatch(fetchSubDepartmentUsers(department_id))
    } catch (error) {
      // await setLoading(false)
    } finally {
      // await setLoading(false)
    }
  }

  const handleFetchAtsUser = async (keyword) => {
    try {
      // Fetch ATS users based on the keyword
      let res = await apiGetATSUsers(keyword);
      let options = res?.data?.data.map(user => ({
        value: user,
        label: user.name,

      }));
  
      // Return the options to populate the select
      return options;
    } catch (error) {
      console.error("Error fetching ATS users:", error);
      return [];
    }
  };
  
  const handleCheckAts = (e) => {
    const checked = e.target.checked;
    setImportFromAts(checked);

    if (!checked) {
      setUser(null); // Reset user data if unchecked
    }
  };
  return (
    <div className="page-content">
      <Container fluid={true}>
        <h5 className="mb-3">Add Users</h5>
        <Row>
          <Col sm={12} md={6} className="my-4">
            <Input
              type="checkbox"
              name="is_current"
              className="form-check-input"
              defaultChecked={importFromAts}
              onChange={handleCheckAts}
            />
            <Label className="mx-2 labels mb-0" htmlFor="is_current">
              Import User from ATS
            </Label>
          </Col>
          {importFromAts && <Col sm={12} md={6} className="mb-3">
            <Label htmlFor="gender">
              Select User<span className="text-danger">*</span>
            </Label>
            <AsyncSelect
              cacheOptions
              loadOptions={handleFetchAtsUser} // Only use loadOptions for async select
              onChange={option => setUser(option.value)} // Handle the selection
              classNamePrefix="select2-selection"
              defaultOptions // Load default options initially (empty search)
            />
          </Col>}
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col xl="12">
                <Formik
                  enableReinitialize
                  initialValues={{
                    first_name: user?.name?.split(" ")[0] || "", // First word as first name
                    last_name: user?.name?.split(" ").slice(1).join(" ") || "", // Remaining words as last name
                    gender: user?.gender || "",
                    mobile: user?.mobile || "",
                    email: user?.email || "",
                    emp_id: user?.id || "",
                    dob: "",
                    salary:"",
                    lock_in_period:"",
                    reporting_manager_employee_id: "",
                    department_master_id: "",
                    employment_type_master_id: "",
                    sub_department_master_id: "",
                    designation_id: "",
                    job_title: "",
                    job_role_id: "",
                    probition_period_id: "",
                    notice_period_id: "",
                    office_location_id: "",
                    doj: "",
                    status: 0,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async values => {
                    try {
                      const response = await dispatch(createUsers(values))
                      console.log("Please Verify Response Of Users")
                      if (response.payload?.data?.data?.status) {
                        toastr.success(response?.payload?.data?.data?.message)
                        history.push("/user-boarding")
                      } else {
                        toastr.error(response?.payload?.message)
                      }
                    } catch (error) {
                      toastr.error("Something went wrong.")
                    }
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    touched,
                    errors,
                    isSubmitting,
                    dirty,
                  }) => (
                    <Form>
                      {console.log(values, "Verify values")}
                      <Row>
                        <Col md="12" className="custom-col mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <CardTitle className="mb-1 header-text">
                              Personal Detail
                            </CardTitle>
                          </div>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "3px" }}
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="first_name">
                            First Name <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="first_name"
                            placeholder="First Name"
                            type="text"
                            className={`form-control ${
                              touched.first_name && errors.first_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="last_name">
                            Last Name <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="last_name"
                            placeholder="Last Name"
                            type="text"
                            className={`form-control ${
                              touched.last_name && errors.last_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="gender">
                            Gender<span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="gender"
                            value={employmentType?.find(
                              type => type.value === values?.gender
                            )}
                            onChange={option =>
                              setFieldValue("gender", option.value)
                            }
                            options={employmentType}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="dob">Date of Birth</Label>
                          <Field
                            name="dob"
                            placeholder="Last Name"
                            type="date"
                            className={`form-control ${
                              touched.dob && errors.dob ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="dob"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="12" className="custom-col mb-3">
                          <CardTitle className="header-text">
                            Work Detail
                          </CardTitle>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "3px" }}
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="emp_id">
                            Employee Id <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="emp_id"
                            placeholder="Employee Id"
                            type="text"
                            className={`form-control ${
                              touched.emp_id && errors.emp_id
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="emp_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="department_master_id">
                            Department
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="department_master_id"
                            value={departmentData?.find(
                              option =>
                                option.value == values?.department_master_id
                            )}
                            onChange={option => {
                              setFieldValue(
                                "department_master_id",
                                Number(option.value)
                              )
                              setSubdepartmentValue("")
                              setFieldValue("sub_department_master_id", "")
                            }}
                            options={departmentData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="department_master_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="sub_department_master_id">
                            Sub Department
                          </Label>
                          <Select
                            isDisabled={!values?.department_master_id}
                            name="sub_department_master_id"
                            value={
                              (subDepartmnetValue?.length > 0 &&
                                subDepartmentData.find(
                                  option => option.value == subDepartmnetValue
                                )) ||
                              subDepartmnetValue
                            }
                            onChange={option => {
                              setFieldValue(
                                "sub_department_master_id",
                                Number(option.value)
                              )
                              setSubdepartmentValue(option?.value)
                            }}
                            onFocus={() => {
                              handleSubDepartment(values.department_master_id)
                            }}
                            onMenuOpen={() => {
                              handleSubDepartment(values.department_master_id)
                            }}
                            options={subDepartmentData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="sub_department_master_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="employment_type_master_id">
                            Employee Type
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="employment_type_master_id"
                            value={employeeTypeData?.find(
                              option =>
                                option.value ==
                                values?.employment_type_master_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "employment_type_master_id",
                                Number(option.value)
                              )
                            }
                            options={employeeTypeData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="employment_type_master_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="notice_period_id">
                            Notice Period
                          </Label>
                          <Select
                            name="notice_period_id"
                            value={noticePeriodData?.find(
                              option => option.value == values?.notice_period_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "notice_period_id",
                                Number(option.value)
                              )
                            }
                            options={noticePeriodData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="notice_period_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="job_role_id">Job Role</Label>
                          <Select
                            name="job_role_id"
                            value={jobTitleData?.find(
                              option => option.value == values?.job_role_id
                            )}
                            onChange={option =>
                              setFieldValue("job_role_id", Number(option.value))
                            }
                            options={jobTitleData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="job_role_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="salary">
                            Salary <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="salary"
                            placeholder="Salary"
                            type="text"
                            className={`form-control ${
                              touched.salary && errors.salary
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="salary"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="lock_in_period">
                            Lock In Period
                          </Label>
                          <Field
                            name="lock_in_period"
                            placeholder="Lock In Period"
                            type="text"
                            className={`form-control`}
                          />
                        </Col>
                        {values?.job_role_id === 0 && (
                          <Col md="3" className="mb-3">
                            <Label htmlFor="job_title">Job Title</Label>
                            <Field
                              name="job_title"
                              placeholder="Job Title"
                              type="text"
                              className={`form-control ${
                                touched.emp_no && errors.emp_no
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="job_title"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        )}
                        <Col md="3" className="mb-3">
                          <Label htmlFor="probition_period_id">
                            Probation Period
                          </Label>
                          <Select
                            name="probition_period_id"
                            value={probationData.find(
                              option =>
                                option.value == values.probition_period_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "probition_period_id",
                                Number(option.value)
                              )
                            }
                            options={probationData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="probition_period_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="reporting_manager_employee_id">
                            Reporting Manager
                          </Label>
                          <Select
                            name="reporting_manager_employee_id"
                            value={managerData?.find(
                              option =>
                                option.value ==
                                values?.reporting_manager_employee_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "reporting_manager_employee_id",
                                Number(option.value)
                              )
                            }
                            options={managerData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="reporting_manager_employee_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="designation_id">
                            Designation
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="designation_id"
                            value={designationData?.find(
                              option => option.value == values?.designation_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "designation_id",
                                Number(option.value)
                              )
                            }
                            options={designationData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="designation_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="12" className="custom-col mb-3">
                          <CardTitle className="header-text">
                            Contact Info
                          </CardTitle>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "3px" }}
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="mobile">
                            Mobile<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="mobile"
                            placeholder="Enter mobile"
                            type="number"
                            className={`form-control ${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="email">
                            Personal Email<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="email"
                            placeholder="Personal Email"
                            type="email"
                            className={`form-control ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="12" className="custom-col mb-3">
                          <CardTitle className="header-text">
                            Other Info
                          </CardTitle>
                          <hr
                            style={{ marginTop: "0px", marginBottom: "3px" }}
                          />
                        </Col>

                        <Col md="3" className="mb-3">
                          <Label htmlFor="office_location_id">
                            Office Location
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="office_location_id"
                            value={officeLocationData?.find(
                              option =>
                                option.value == values?.office_location_id
                            )}
                            onChange={option =>
                              setFieldValue(
                                "office_location_id",
                                Number(option.value)
                              )
                            }
                            options={officeLocationData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="office_location_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="doj">
                            Date of Joining
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="doj"
                            placeholder="Date Of Joining"
                            type="date"
                            className={`form-control ${
                              touched.dob && errors.dob ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="doj"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md={12} className="mb-1">
                          <div className="form-check form-switch form-switch-md mb-2 d-flex ">
                            <Input
                              name="status"
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked={
                                values?.status === 0 ? false : true
                              }
                              onChange={e => {
                                setFieldValue(
                                  "status",
                                  e.target.checked ? 1 : 0
                                )
                              }}
                            />
                            <Label className="mx-2 fs-6 mb-0" htmlFor="status">
                              Status
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={() => {
                            history.push("/user-boarding")
                          }}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <button
                          className="btn btn-md btn-primary"
                          type="submit"
                          disabled={isSubmitting || !dirty}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}
