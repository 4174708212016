import React, { useEffect, useMemo,useState } from "react"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  fetchExpenseReward,
  setCurrentPage,
  setExpenseRewardModal,
  setRewardSearch,
  setRewardTableRowData,
  setDocRowDataID,
  setReview,
  updateStatus,
  setEmployeeTypeId,
  setDateRange,
} from "store/expenseReward/expenseRewardSlice"
import Select from "react-select"
import toastr from "toastr"
import { formatDateFormat, generateTooltip } from "constants/CommonFunction"
import "../../../MultiPageLink.css"
import { reviews, customStyles } from "constants/Constants"
import "flatpickr/dist/themes/material_green.css"
import Flatpickr from "react-flatpickr"
import useExportXlsx from "components/Hooks/useExportXlsx"
import CommonModal from "components/Common/Modal"

export const ExpenseRewardView = () => {
  const {
    currentPage,
    rewardSearchQuery,
    expenseRewardData,
    rewardTotalData,
    review,
    loading,
    employeeViewData,
    EmployeeTypeId,
    dateRange,
  } = useSelector(state => state.expenseReward);
  const { expenses } = useSelector(state => state.users?.userPermissions);

  const dispatch = useDispatch()
  const { exportXlsx, xlsxLoader } = useExportXlsx()
  const [modelRemark,setModelRemark]=useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);

  const approveStatus = async (id, type) => {
    try {
      const response = await dispatch(
        updateStatus({
          id: id,
          status: type == "approve" ? 1 : 2,
        })
      )
      if (response?.payload?.data?.status) {
        toastr.success(response?.payload?.data?.message)
      } else {
        toastr.error(response?.payload?.message)
      }
    } catch (error) {
      toastr.error("Something Went Wrong")
    }
    await dispatch(
      fetchExpenseReward({
        length: 10,
        start: currentPage,
        keyword: rewardSearchQuery.length > 0 ? rewardSearchQuery : undefined,
        status: review,
        employee_id: EmployeeTypeId,
        from_date: dateRange[0]
          ? formatDateFormat(dateRange[0], "YYYY-MM-DD")
          : "",
        to_date: dateRange[1]
          ? formatDateFormat(dateRange[1], "YYYY-MM-DD")
          : "",
      })
    )
  }

  const showRemark=(remark) => {
    setModelRemark(remark)
    setIsModalOpen(true)
}

  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        Cell: ({ row }) => (
          <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: <div style={{width:'100px'}}>Employee</div>,
        accessor: "employee_id",
        Cell: ({ row }) => {
          const firstName = row.original.employee_first_name
          const lastName = row.original.employee_last_name
          return (
            <div style={{width:'100px'}}>
              {firstName && lastName ? `${firstName} ${lastName}` : "-"}
            </div>
          )
        },
      },
      {
        Header: <div style={{width:'100px'}}>Expense Type</div>,
        accessor: "expense_type",
        Cell: ({ row }) => {
          const expenseTypeName = row.original.expense_master_name
          return <div style={{width:'100px'}}>{expenseTypeName ? expenseTypeName : "-"}</div>
        },
      },
      {
        Header: <div style={{width:'100px'}}>Remark</div>,
        accessor: "remark",
        Cell: ({ value }) =>
        <div style={{width:'100px'}}>
        {value ? (
          <>
            {value.length > 20 ? (
              <>
                {value.slice(0, 20)}
                <i
                className="bx bx-dots-horizontal-rounded cursor-pointer"
                title="View more"
                onClick={() => {showRemark(value)}}
                />
              </>
            ) : (
              value
            )}
          </>
        ) : (
          "-"
        )}
      </div>
      },
      {
        Header: <div style={{width:'100px'}}>Amount</div>,
        accessor: "amount",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Expense Date</div>,
        accessor: "expense_date",
        Cell: ({ value }) => <div style={{width:'100px'}}>{formatDateFormat(value)}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Applied Date</div>,
        accessor: "created_on",
        Cell: ({ value }) => <div style={{width:'100px'}}>{formatDateFormat(value)}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Created By</div>,
        accessor: "created_by_first_name",
        Cell: ({ row }) => {
          const {created_by_first_name, created_by_last_name} = row.original
          let created_by_name = `${created_by_first_name} ${created_by_last_name}`;
          return <div style={{width:'100px'}}>{created_by_name}</div>
        }
      },
      {
        Header: <div style={{width:'100px'}}>Action</div>,
        accessor: "actions",
        Cell: ({ row }) => {
          const { is_action, approvals, status } = row.original

          let buttonClass="btn btn-sm"
          let buttonText="";

          if(status == 0 && is_action === false){
            buttonClass+=" btn-primary";
            buttonText="Pending"
          } else if(status === "1" && is_action === false){
            buttonClass+=" btn-success";
            buttonText="Approved"
          } else {
            buttonClass+=" btn-danger";
            buttonText="Rejected"
          }


          return (
            <div style={{ display: "flex", gap: "10px" }}>
              {(status === "0" || ("1" && is_action === true)) && (
                <div>
                  <i
                    onClick={async () => {
                      await dispatch(setRewardTableRowData(row?.original))
                      await dispatch(setExpenseRewardModal(true))
                    }}
                    className="bx bxs-edit cursor-pointer "
                    style={{ fontSize: "17px", color: "#039f03" }}
                  />
                </div>
              )}
              {status === "0" && is_action === true ? (
                <>
                  <i
                    className="bx bx-check-square cursor-pointer"   style={{ fontSize: "17px", color: "#0f8000" }}
                    onClick={() => approveStatus(row?.original?.id, "approve")}
                  />
                    {/* Approve */}
                  {/* </button> */}
                  <i
                   className="bx bx-window-close cursor-pointer"   style={{ fontSize: "17px", color: "#ed1e07" }}
                    onClick={() => approveStatus(row?.original?.id, "reject")}
                  />
                    {/* Reject */}
                  {/* </button> */}
                </>
              ) : (
                <span
                  title={generateTooltip(approvals)}
                  className="d-inline-block"
                  data-toggle="tooltip"
                >
                  <button
                    className={buttonClass}
                    type="button"
                    style={{ cursor: "no-drop" }}
                  >
                    {buttonText}
                    {/* {status == 0 && is_action === false
                       ? "Pending"
                       : status === "1" && is_action === false
                       ? "Approved"
                       : "Rejected"}*/}
                  </button>
                </span>
              )}
            </div>
          )
        },
      },

      {
        Header: "Expense Document",
        accessor: "document",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              <Link
                to={{
                  pathname: `/ExpenseDocument`,
                  state: { id: row?.original.id ,expenseData: row.original},
                }}
              >
                <button type="button" className="btn btn-primary btn-sm mx-2">
                  Document
                </button>
              </Link>
            </div>
          </div>
        ),
      },
    ]
  }, [dispatch])

  // const handleDocumentClick = id => {
    // console.log("handleDocumentClick", id)
    // dispatch(setDocRowDataID(id))
  // }

  useEffect(() => {
    dispatch(
      fetchExpenseReward({
        length: 10,
        start: currentPage,
        keyword: rewardSearchQuery.length > 0 ? rewardSearchQuery : undefined,
        status: review,
        employee_id: EmployeeTypeId,
        from_date: dateRange[0]
          ? formatDateFormat(dateRange[0], "YYYY-MM-DD")
          : "",
        to_date: dateRange[1]
          ? formatDateFormat(dateRange[1], "YYYY-MM-DD")
          : "",
      })
    )
  }, [currentPage, rewardSearchQuery, review, EmployeeTypeId, dateRange])

  const handleAdd = async () => {
    await dispatch(setRewardTableRowData([]))
    await dispatch(setExpenseRewardModal(true))
  }

  const selectedOption = employeeViewData?.find(
    option => option.value === EmployeeTypeId
  )
  // console.log("selectedOption",selectedOption)

  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="mb-3">EXPENSE</h5>

        <div className="d-flex multi-page-link flex-wrap">
          <Link className="active" to="/expense">
            Logs
          </Link>
          {expenses?.category?.list && <Link to="/expense-reward?tab=category">Category</Link>}
        </div>

        <Card className="mt-3">
          <CardBody>
            <Row className=" align-items-center">
              <Col md={2} sm={12} style={{ marginTop: "10px" }}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setRewardSearch(value))
                    dispatch(setCurrentPage(1))
                  }}
                />
              </Col>
              <Col md={2} className="mb-3">
                <span>Status</span>
                <Select
                  id="review"
                  name="review"
                  size="sm"
                  options={reviews}
                  onChange={async option => {
                    await dispatch(setCurrentPage(1))
                    await dispatch(setReview(option?.value))
                  }}
                  value={reviews.find(option => option.value === review)}
                  styles={customStyles}
                />
              </Col>
              <Col md={2} className="mb-3">
                <span>Employee </span>
                <Select
                  id="employee_id"
                  name="employee_id"
                  size="sm"
                  options={employeeViewData}
                  onChange={async option => {
                    await dispatch(setCurrentPage(1))
                    await dispatch(setEmployeeTypeId(option?.value))
                  }}
                  value={selectedOption}
                  styles={customStyles}
                />
              </Col>
              <Col md={3} className="mb-3">
                <span>Select Date</span>
                <div>
                  <Flatpickr
                    className="form-control"
                    placeholder="Expense Start Date to End Date"
                    options={{
                      mode: "range",
                      dateFormat: "d-m-Y",
                    }}
                    styles={{ width: "100px" }}
                    value={dateRange}
                    onChange={date => {
                      dispatch(setDateRange(date))
                    }}
                  />
                </div>
              </Col>
              <Col
                md={3}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
                style={{ marginTop: "10px" }}
              >
                {expenses?.export &&<Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  style={{ borderRadius: "5px", height: "38px" }}
                  onClick={() => exportXlsx("expense-logs", "Expense", {
                    keyword: rewardSearchQuery.length > 0 ? rewardSearchQuery : undefined,
                    status: review,
                    employee_id: EmployeeTypeId,
                    from_date: dateRange[0]
                      ? formatDateFormat(dateRange[0], "YYYY-MM-DD")
                      : "",
                    to_date: dateRange[1]
                      ? formatDateFormat(dateRange[1], "YYYY-MM-DD")
                      : "",
                  })}
                  disabled={xlsxLoader}
                >
                  <i className="bx bx-export font-size-16 me-2" />
                  {xlsxLoader ? "Exporting..." : "Export"}
                </Button>}
                {expenses?.add && <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>}
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={expenseRewardData ? expenseRewardData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={currentPage}
                totalCount={rewardTotalData ? rewardTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setCurrentPage(page))
                }}
              />
            <CommonModal
              isOpen={isModalOpen}
              toggleModal={() => setIsModalOpen(!isModalOpen)}
              modalTitle="Remark"
              modalBody={<div>{modelRemark}</div>}
              showFooter={false}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
