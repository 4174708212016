import { getUserId } from "constants/CommonFunction"
import { ApiService } from "./ApiService"

export async function apiGetUserPermissions() {
  return ApiService.fetchData({
    url: `menu_master/submenu`,
    method: "get",
  })
}
export async function apiGetUsers({ length, start, keyword, status }) {
  return ApiService.fetchData({
    url: `users/get`,
    method: "get",
    params: { length, start, keyword, status },
  })
}
export async function apiGetATSUsers(keyword) {
  return ApiService.fetchData({
    url: `users/ats_users`,
    method: "get",
    params: { keyword, length:20 },
  })
}
export async function updateUserStatus(employee_id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `users/updateStatus/${employee_id}`,
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}

export async function apiCreateUsers(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: "users",
    method: "post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}

export async function apiEmployeeType() {
  return ApiService.fetchData({
    url: `employment_type?status=1`,
    method: "get",
  })
}

export async function apiGetDepartment() {
  return ApiService.fetchData({
    url: `department_master?status=1`,
    method: "get",
  })
}

export async function apiGetDesignation() {
  return ApiService.fetchData({
    url: `designations?status=1`,
    method: "get",
  })
}

export async function apiGetNoticePeriod() {
  return ApiService.fetchData({
    url: `notice_period/get?status=1`,
    method: "get",
  })
}

export async function apiGetProbationPeriod() {
  return ApiService.fetchData({
    url: `probation_period?status=1`,
    method: "get",
  })
}

export async function apiGetReportingManager() {
  return ApiService.fetchData({
    url: `/users/get?status=1`,
    method: "get",
  })
}

export async function apiGetOfficeUserLocation() {
  return ApiService.fetchData({
    url: `office_location/get?status=1`,
    method: "get",
  })
}

export async function apiGetSubDepartment(department_id) {
  const url = department_id
    ? `sub_department_master/?department_master_id=${department_id}`
    : "sub_department_master";
  return ApiService.fetchData({
    url: url,
    method: "get",
  });
}

export async function apiGetJobTitle() {
  return ApiService.fetchData({
    url: `requirement_role_master/get?status=1`,
    method: "get",
  })
}