import React from "react"
import { useSelector } from "react-redux"
import OnboardingKycFrom from "../OnbordingForms/OnboardingKycFrom"
import OnboardingKycDetail from "../OnboardingDetails/OnbordingKycDetail"

export const OnboardingKycDetailView = () => {
  const { editKycDetail } = useSelector(state => state.onBoarding)
  return (
    <div>{editKycDetail ? <OnboardingKycFrom /> : <OnboardingKycDetail />}</div>
  )
}
