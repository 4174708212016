import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateWorkDetail,
  apiEditWorkDetail,
  apiEmployeeType,
  apiGetDepartment,
  apiGetDesignation,
  apiGetJobTitle,
  apiGetProbationPeriod,
  apiGetReportingManager,
  apiGetSubDepartment,
  apiGetWorkDetail,
  apiGetWorkJobTitle,
} from "services/WorkDetailService"

export const SLICE_NAME = "workDetail"

const initialState = {
  loading: false,
  openWorkDetailModal: false,
  tableRowData: [],
  currentPage: 1,
  workDetailData: [],
  totalData: 0,
  employeeTypeData: [],
  departmentData: [],
  subDepartmentData: [],
  designationData: [],
  probationData: [],
  managerData: [],
  workJobTitleData: [],
}

export const fetchWorkDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchWorkDetail`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await apiGetWorkDetail(id)
      return response?.data
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "An error occurred while fetching work details"
      )
    }
  }
)

export const createWorkDetail = createAsyncThunk(
  `${SLICE_NAME}/createWorkDetail`,
  async data => {
    try {
      const response = await apiCreateWorkDetail(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editWorkDetail = createAsyncThunk(
  `${SLICE_NAME}/editWorkDetail`,
  async ({ id, data }, { dispatch }) => {
    try {
      const response = await apiEditWorkDetail(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchEmployeeType = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeType`,
  async () => {
    try {
      const response = await apiEmployeeType()
      return {
        status: "success",
        data: response?.data?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchDepartment = createAsyncThunk(
  `${SLICE_NAME}/fetchDepartment`,
  async () => {
    try {
      const response = await apiGetDepartment()
      return {
        status: "success",
        data: response?.data?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchSubDepartment = createAsyncThunk(
  `${SLICE_NAME}/fetchSubDepartment`,
  async department_id => {
    try {
      const response = await apiGetSubDepartment(department_id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchDesignation = createAsyncThunk(
  `${SLICE_NAME}/fetchDesignation`,
  async () => {
    try {
      const response = await apiGetDesignation()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchProbationPeriod = createAsyncThunk(
  `${SLICE_NAME}/fetchProbationPeriod`,
  async () => {
    try {
      const response = await apiGetProbationPeriod()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchReportingManager = createAsyncThunk(
  `${SLICE_NAME}/fetchReportingManager`,
  async () => {
    try {
      const response = await apiGetReportingManager()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchWorkJobTitle = createAsyncThunk(
  `${SLICE_NAME}/fetchWorkJobTitle`,
  async () => {
    try {
      const response = await apiGetWorkJobTitle()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const workDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTableRowData: (state, action) => {
      state.tableRowData = action.payload
    },
    setOpenWorkDetailModal: (state, action) => {
      state.openWorkDetailModal = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setSubdepartment: (state, action) => {
      state.subDepartmentData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWorkDetail.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchWorkDetail.fulfilled, (state, action) => {
      state.loading = false
      state.workDetailData = action.payload?.data
      state.totalData = action?.payload?.total
    })
    builder.addCase(fetchWorkDetail.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchEmployeeType.fulfilled, (state, action) => {
      state.employeeTypeData = action.payload.data.map(employeeType => ({
        value: employeeType.value,
        label: employeeType.label,
      }))
    })
    builder.addCase(fetchDepartment.fulfilled, (state, action) => {
      state.departmentData = action.payload.data.map(department => ({
        value: department?.id,
        label: department?.name,
      }))
      state.departmentData.push({ value: "0", label: "Other" })
    })
    builder.addCase(fetchSubDepartment.fulfilled, (state, action) => {
      state.subDepartmentData = action.payload.data.map(subDepartment => ({
        value: subDepartment?.id,
        label: subDepartment?.name,
      }))
      state.subDepartmentData.push({ value: "0", label: "Other" })
    })
    builder.addCase(fetchDesignation.fulfilled, (state, action) => {
      state.designationData = action.payload.data.map(designation => ({
        value: designation?.id,
        label: designation?.name,
      }))
      state.designationData.push({ value: "0", label: "Other" })
    })
    builder.addCase(fetchProbationPeriod.fulfilled, (state, action) => {
      state.probationData = action.payload.data.map(probation => ({
        value: probation?.id,
        label: probation?.name,
      }))
    })
    builder.addCase(fetchReportingManager.fulfilled, (state, action) => {
      state.managerData = action.payload.data.map(manager => ({
        value: manager?.id,
        label: `${manager?.first_name} ${manager?.last_name}`,
      }))
    })
    builder.addCase(fetchWorkJobTitle.fulfilled, (state, action) => {
      state.workJobTitleData = action.payload.data.map(job => ({
        value: job?.id,
        label: job?.name,
      }))
      state.workJobTitleData.push({ value: "0", label: "Other" })
    })
  },
})

export const {
  setLoading,
  setTableRowData,
  setOpenWorkDetailModal,
  setCurrentPage,
  setSubdepartment,
} = workDetailSlice.actions

export default workDetailSlice.reducer
