import { ApiService } from "./ApiService"

export async function apiGetattendanceRule({ keyword, length, start, employee_id }) {
  return ApiService.fetchData({
    url: `attendance_rule`,
    method: "get",
    params: { 
      keyword, 
      length, 
      start, 
      ...(employee_id && {employee_id:employee_id}) },
  })
}

export async function apiGetAssignAttendance({ length, start, keyword }) {
  return ApiService.fetchData({
    url: `users/get`,
    method: "get",
    params: { length, start, keyword },
  })
}

export async function apiCreateattendanceRule(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: "attendance_rule",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiAssignAttendance(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `attendance_rule/assign_employee/${id}`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditattendanceRule(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `attendance_rule/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}
