import React from "react"
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap"
import { Formik, Form } from "formik"
import { ModalTitle } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteAssignLeave,
  fetchAssignLeave,
  setOpenAssignDeleteModal,
} from "store/leaveRule/leaveRuleSlice"
import toastr from "toastr"

const AssignLeaveDeleteForm = () => {
  const dispatch = useDispatch()
  const {
    openAssignDeleteModal,
    deleteAssignEmployee,
    leaveAssignCurrentPage,
    leaveAssignSearchQuery,
  } = useSelector(state => state.leaveRule.assignLeave)

  const toggle = () => {
    dispatch(setOpenAssignDeleteModal(false))
  }

  return (
    <Modal isOpen={openAssignDeleteModal} toggle={toggle} centered>
      <Formik
        initialValues={{}}
        onSubmit={async values => {
          try {
            const response = await dispatch(
              deleteAssignLeave(deleteAssignEmployee?.id)
            )
            if (response?.payload?.status == "success") {
              toastr.success(response?.payload?.data?.data?.message)
            } else {
              toastr.error(response?.payload?.message)
            }
          } catch (error) {
            toastr.error("Something Went Wrong")
          } finally {
            dispatch(
              fetchAssignLeave({
                length: 10,
                start: leaveAssignCurrentPage,
                keyword: leaveAssignSearchQuery,
              })
            )
            await dispatch(setOpenAssignDeleteModal(false))
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
            <ModalBody>
              <span>Are you sure you want to delete this rule</span>
              <b className="mx-2">{deleteAssignEmployee?.name} ?</b>
            </ModalBody>
            <ModalFooter className="p-1">
              <Button
                color="secondary"
                onClick={toggle}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" color="danger" disabled={isSubmitting}>
                {isSubmitting ? "Deleting..." : "Delete"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AssignLeaveDeleteForm
