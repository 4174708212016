import React, { useEffect } from "react"
import { useTable } from "react-table"
import { Spinner } from "reactstrap"

const GlobalTable = ({
  key,
  columns,
  data,
  loading,
  onSelectionChange,
  toggleRowSelection,
  selectedRowIds = [],
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedRowIds)
    }
  }, [selectedRowIds, onSelectionChange])

  return (
    <div className="" key={key}>
      <table className="table table-bordered" {...getTableProps()}>
        <thead
          className="position-sticky top-0 text-white"
          style={{ background: "#ECECEC", zIndex: 0, transform:"translate(0, -1px)" }}
        >
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  key={column.id}
                  style={{
                    borderBottom: "solid 3px #f8fafb",
                    color: "black",
                  }}
                  className="font-weight-normal"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? (
            <td colSpan={columns.length} style={{ height: "calc( 56vh - 61px)" }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "100%",
                }}
              >
                <Spinner color="primary" />
              </div>
            </td>
          ) : rows.length === 0 ? (
            <td colSpan={columns.length + 1} style={{ height: "calc( 56vh - 72px)" }}>
              <div
                className="d-flex justify-content-center align-items-center fs-5"
                style={{ height: "100%" }}
              >
                No data found
              </div>
            </td>
          ) : (
            rows.map(row => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id}
                  style={{
                    background: "white",
                  }}
                >
                  {row.cells.map(cell => {
                    return (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.column.id === "checkbox" ? (
                        <input
                          style={{ cursor: "pointer" }}
                          type="checkbox"
                          defaultChecked={
                            Array.isArray(selectedRowIds) &&
                            selectedRowIds.includes(row?.original?.id)
                          }
                          onChange={() => toggleRowSelection(row?.original?.id)}
                        />
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  )})}
                </tr>
              )
            })
          )}
        </tbody>
      </table>
    </div>
  )
}

export default GlobalTable
