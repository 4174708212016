import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreatePermission,
  apiEditPermission,
  apiGetPermission,
  apiGetPermissionUsers,
} from "services/PermissionService"

export const SLICE_NAME = "permission"

const initialState = {
  loading: false,
  permissionData: [],
  permissionUsers: [],
}

export const fetchPermission = createAsyncThunk(
  `${SLICE_NAME}/fetchPermission`,
  async () => {
    try {
      const response = await apiGetPermission()
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchPermissionUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchPermissionUsers`,
  async () => {
    try {
      const response = await apiGetPermissionUsers()
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const createPermission = createAsyncThunk(
  `${SLICE_NAME}/createPermission`,
  async ({ id, data }) => {
    try {
      const response = await apiCreatePermission(id, data)
      return response?.data
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editPermission = createAsyncThunk(
  `${SLICE_NAME}/editPermission`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiEditPermission(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const permission = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPermission.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchPermission.fulfilled, (state, action) => {
      state.loading = false
      state.permissionData = action?.payload?.data?.data
    })
    builder.addCase(fetchPermission.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchPermissionUsers.fulfilled, (state, action) => {
      state.permissionUsers = action.payload.data.map(users => ({
        value: users?.id,
        label: `${users?.first_name} ${users?.last_name} (${users?.emp_id})`,
      }))
    })
  },
})

export const { setLoading } = permission.actions

export default permission.reducer
