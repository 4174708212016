import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Input,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/pcl_favicon.png"
import lightlogo from "../../assets/images/logo-light.svg"
import { loginUser } from "store/actions"
import { getCredentials } from "constants/CommonFunction"

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [passwordShow, setPasswordShow] = useState(false)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | PARTY CRUISERS LTD</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block"> */}
        {/* <Link to="/" className="text-dark"> */}
          {/* <i className="bx bx-home h2" /> */}
        {/* </Link> */}
      {/* </div> */}
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to PARTY CRUISERS LTD</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/">
                      <div
                        className="avatar-lg mb-4"
                        style={{ marginTop: "-40px" }}
                      >
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="38"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        emp_id: getCredentials()?.emp_id || "",
                        password: getCredentials()?.password || "",
                        remember_me: true,
                      }}
                      validationSchema={Yup.object().shape({
                        emp_id: Yup.string().required("Required"),
                        password: Yup.string().required("Required"),
                      })}
                      onSubmit={async values => {
                        try {
                          const response = await dispatch(loginUser(values, history))
                          console.log(response, "Verify Response")
                        } catch (error) {
                          console.error("Error during login:", error)
                        }
                      }}
                    >
                      {({
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        isSubmitting,
                      }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="emp_id" className="form-label">
                              Employee ID
                            </Label>
                            <Field
                              name="emp_id"
                              type="text"
                              className={
                                "form-control" +
                                (errors.emp_id && touched.emp_id
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="emp_id"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="password" className="form-label">
                              Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Field
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <button
                                className="btn btn-light"
                                type="button"
                                id="password-addon"
                                onClick={() => {
                                  setPasswordShow(!passwordShow)
                                }}
                              >
                                <i
                                  className={
                                    passwordShow
                                      ? "mdi mdi-eye-off-outline"
                                      : "mdi mdi-eye-outline"
                                  }
                                ></i>
                              </button>
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="form-check mb-3">
                            <Input
                              name="remember_me"
                              type="checkbox"
                              defaultChecked={values.remember_me}
                              onChange={e => {
                                setFieldValue("remember_me", e.target.checked)
                              }}
                              className="form-check-input cursor-pointer"
                              id="remember_me"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="remember_me"
                            >
                              Remember me
                            </Label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Loging..." : "Log In"}
                            </button>
                          </div>
                          <div className="mt-4 text-center">
                            <Link to="/forgot-password" className="text-muted">
                              <i className="mdi mdi-lock me-1" /> Forgot your
                              password?
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()}{" "}
                  {process.env.REACT_APP_PROJECTNAME}
                  <i className="mdi mdi-heart text-danger ms-2 me-2" />
                  by Innowrap
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
