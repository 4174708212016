import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
export const SLICE_NAME = "companyOverview"

const initialState={
    loading:false,
    CompanyOViewActiveTabs: "1",
}

const CompanyOverViewASlice=createSlice({
    name:SLICE_NAME,
    initialState,
    reducers:{
        setLoading: (state, action) => {
            state.loading = action.payload
         },
         setCompanyOViewActiveTabs: (state, action) => {
            state.CompanyOViewActiveTabs = action.payload
         },
    }
})

export const { setLoading, setCompanyOViewActiveTabs } =  CompanyOverViewASlice.actions

export default CompanyOverViewASlice.reducer;