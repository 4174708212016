import { getTimeDifference } from "constants/CommonFunction"
import React, { useState, useEffect } from "react"

export const ClockTimer = ({ startTime }) => {
  const [timeDifference, setTimeDifference] = useState(
    getTimeDifference(startTime)
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(getTimeDifference(startTime))
    }, 1000)
    return () => clearInterval(interval)
  }, [startTime])
  return <span>{timeDifference}</span>
}
