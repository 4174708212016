import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import { Search } from "components/Datatable"
import Pagination from "components/Common/Pagination"
import { Link, useLocation } from "react-router-dom"
import "../../../MultiPageLink.css"
import Select from "react-select"
import LeaveNavTabs from "components/LeaveNavTabs/LeaveNavTabs"
import { useDispatch, useSelector } from "react-redux"
import {
  cancelLeaveLog,
  fetchLeaveLog,
  fetchLeaveLogEmployee,
  setApplyLeaveModal,
  setleaveLogCurrentPage,
  setLeaveLogEmployee,
  setLeaveLogSearchQuery,
  setLeaveLogStatus,
} from "store/leaveLogs/leaveLogSlice"
import {
  leaveStatus,
  extractTime,
  sortName,
  formatDateFormat,
} from "constants/CommonFunction"
import toastr from "toastr"
import { customStyles, leaveLogsStatus } from "constants/Constants"
import useExportXlsx from "components/Hooks/useExportXlsx"

export const LeaveLog = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { exportXlsx, xlsxLoader } = useExportXlsx()

  const {
    leaveLogCurrentPage,
    leaveLogTotalData,
    leaveLogSearchQuery,
    leavesEmployeeData,
    leaveTypes,
    leaveLogStatus,
    leaveLogEmployee,
    leaveLogData,
    loading,
  } = useSelector(state => state.leaveLog);
  const { leaves } = useSelector(state => state.users.userPermissions);
  
  const handleCancel = async employee_id => {
    try {
      const response = await dispatch(cancelLeaveLog(employee_id)).unwrap()
      console.log(response, "Check Response")
      if (response.status == "success") {
        toastr.success(
          response.data.data.message || "Leave log canceled successfully"
        )
      } else {
        toastr.error(response.message || "Failed to cancel the leave log")
      }
    } catch (error) {
      toastr.error(error.message || "Something Went Wrong")
    } finally {
      dispatch(
        fetchLeaveLog({
          start: leaveLogCurrentPage,
          length: 10,
          keyword: leaveLogSearchQuery,
          status: leaveLogStatus,
          employee_id: location?.state?.row?.id || leaveLogEmployee,
        })
      )
    }
  }

  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(leaveLogCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        accessor: "first_name",
        Header: () => <div style={{ width: "200px" }}>Employee Name</div>,
        Cell: ({ row }) => {
          const { first_name, last_name, profile_photo } = row.original

          return (
            <div
              className="d-flex align-items-center"
              style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
            >
              {profile_photo ? (
                <img
                  src={profile_photo}
                  alt="Employee"
                  className="rounded-circle avatar-xs me-2"
                />
              ) : (
                <div className="avatar-xs align-self-center">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                    {sortName(`${first_name} ${last_name || ""}`)}
                  </span>
                </div>
              )}
              <div>
                <p className="mb-0">{`${first_name} ${
                  last_name ? last_name : ""
                }`}</p>
              </div>
            </div>
          )
        },
      },
      {
        Header: () => <div style={{ width: "100px" }}>Employee Id</div>,
        accessor: "emp_id",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Status</div>,
        accessor: "status",
        Cell: ({ value }) => <div>{value ? leaveStatus(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Start Date</div>,
        accessor: "start_date",
        Cell: ({ value }) => <div>{value ? formatDateFormat(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>End Date</div>,
        accessor: "end_date",
        Cell: ({ value }) => <div>{value ? formatDateFormat(value) : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>No. of Days</div>,
        accessor: "total_days",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "80px" }}>Action</div>,
        accessor: "actions",
        Cell: ({ row }) => {
          const { id, can_cancel, status } = row.original
          return can_cancel ? (
            <div>
              <button
                onClick={() => handleCancel(id)}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
            </div>
          ) : (
            <div
              style={{
                color:
                  status == 0
                    ? "#003170"
                    : status == 1
                    ? "#28a745"
                    : status == 2
                    ? "#dc3545"
                    : "#0095fe",
              }}
            >
              {status == 0
                ? "Pending"
                : status == 1
                ? "Approved"
                : status == 2
                ? "Rejected"
                : "Cancelled"}
            </div>
          )
        },
      },
    ]
  }, [dispatch, leaveLogCurrentPage])

  useEffect(() => {
    dispatch(fetchLeaveLogEmployee())
  }, [])

  useEffect(() => {
    dispatch(
      fetchLeaveLog({
        start: leaveLogCurrentPage,
        length: 10,
        keyword: leaveLogSearchQuery,
        status: leaveLogStatus,
        employee_id: location?.state?.row?.id || leaveLogEmployee,
      })
    )
  }, [
    dispatch,
    location,
    leaveLogCurrentPage,
    leaveLogSearchQuery,
    leaveLogStatus,
    leaveLogEmployee,
  ])

  return (
    <div
      className={
        location.pathname.includes("/profile/view") ? "" : "page-content"
      }
    >
      <div
        className={
          location.pathname.includes("/profile/view")
            ? ""
            : "row container-fluid"
        }
      >
        <div className="col-md-12">
          <LeaveNavTabs />
          <h5 className="font-weight-bold mb-2 mt-3">Leaves</h5>
          <Card className="cardBorder">
            <CardBody style={{ paddingBottom: "0px" }}>
              <Row className=" align-items-center">
                <Col md={2} sm={12} style={{ marginTop: "15px" }}>
                  {" "}
                  <Search
                    onSearch={value => {
                      dispatch(setLeaveLogSearchQuery(value))
                      dispatch(setleaveLogCurrentPage(1))
                    }}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  <span>Status</span>
                  <Select
                    id="logStatus"
                    name="logStatus"
                    size="sm"
                    options={leaveLogsStatus}
                    value={leaveLogsStatus?.find(
                      option => option.value === leaveLogStatus
                    )}
                    onChange={option => {
                      dispatch(setleaveLogCurrentPage(1))
                      dispatch(setLeaveLogStatus(option?.value))
                    }}
                    styles={customStyles}
                  />
                </Col>
                <Col md={2} className="mb-3">
                  {!location?.state?.row?.id &&<>
                  <span>Employee</span>
                  <Select
                    id="employee_id"
                    name="employee_id"
                    placeholder="Employee"
                    options={leavesEmployeeData}
                    value={leavesEmployeeData?.find(
                      option => option.value == leaveLogEmployee
                    )}
                    onChange={async option => {
                      dispatch(setleaveLogCurrentPage(1))
                      await dispatch(setLeaveLogEmployee(option?.value))
                    }}
                    styles={customStyles}
                  />
                  </>}
                </Col>
                <Col
                  md={6}
                  sm={12}
                  className="mb-2 d-flex justify-content-end flex-wrap"
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                    style={{ borderRadius: "5px", height: "38px" }}
                    onClick={() => exportXlsx("leave-logs", "Leavse", {
                      keyword: leaveLogSearchQuery,
                      status: leaveLogStatus,
                      employee_id: location?.state?.row?.id || leaveLogEmployee,
                    })}
                    disabled={xlsxLoader}
                  >
                    <i className="bx bx-export font-size-16 me-2" />
                    {xlsxLoader ? "Exporting..." : "Export"}
                  </Button>
                  {(leaves?.apply_leave && !location?.state?.row) &&<Button
                    className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                    onClick={() => {
                      dispatch(setApplyLeaveModal(true))
                    }}
                  >
                    Apply Leave
                  </Button>}
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  className="overflow-auto"
                  style={{
                    height: "56vh",
                    scrollbarWidth: "thin",
                    padding: "0px",
                  }}
                >
                  <GlobalTable
                    columns={columns}
                    data={leaveLogData ? leaveLogData : []}
                    loading={loading}
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter
              className="d-flex justify-content-end"
              style={{
                background: "#ECECEC",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div
                style={{
                  height: "30px",
                  marginLeft: "0px",
                }}
              >
                <Pagination
                  className="mx-2"
                  currentPage={leaveLogCurrentPage}
                  totalCount={leaveLogTotalData ? leaveLogTotalData : 0}
                  pageSize={10}
                  onPageChange={async page => {
                    dispatch(setleaveLogCurrentPage(page))
                  }}
                />
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  )
}
