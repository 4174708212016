import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const NavTabs = () => {
  const { attendance } = useSelector(state => state.users.userPermissions);
  const location = useLocation();
  const history = useHistory();
  
  // Extract the current tab from the query parameters
  const params = new URLSearchParams(location.search);
  const currentTab = params.get("tab") || "logs"; // Default to an empty string if no tab is set

  // Valid tabs based on leaves permissions
  const validTabs = ["logs"];
  if (attendance?.approvals) validTabs.push("approval");
  if (attendance?.rules?.show_rules) validTabs.push("rule");
  
  // Determine the active tab based on the current query parameter
  const isActive = (tab) => {
    // If the current tab is valid, use it, otherwise default to "logs"
    const activeTab = validTabs.includes(currentTab) ? currentTab : "logs";
    return activeTab === tab;
  };

  // Function to handle tab clicks
  const handleTabClick = (tab) => {
    const currentState = history.location.state;  // Get the current state
    params.set("tab", tab); // Set the tab parameter
    history.replace({ pathname: location.pathname, state: currentState, search: params.toString() });
  };


  return (
    <div className="d-flex justify-content-between flex-wrap mb-2">
      <div className="d-flex multi-page-link flex-wrap">
        <a
          className={isActive("logs") ? "active" : ""}
          onClick={() => handleTabClick("logs")}
        >
          Logs
        </a>
        {attendance?.approvals && <a
          className={isActive("approval") ? "active" : ""}
          onClick={() => handleTabClick("approval")}
        >
          Approval
        </a>}
        {attendance?.rules?.show_rules && <a
          className={isActive("rule") ? "active" : ""}
          onClick={() => handleTabClick("rule")}
        >
          Rules
        </a>}
      </div>
    </div>
  );
};

export default NavTabs;
