import {createAsyncThunk,createSlice} from '@reduxjs/toolkit'
import { apiCreateAssetType, apiEditAssetType, apiGetAssetType ,
} from 'services/AssetTypeService'


export const SLICE_NAME='assetType'

const initialState={
    loading:false,
    assetTypeData:[],
    assetTypeSearchQuery:'',
    currentPage:1,
    assetTypeTotalData:0,
    assetSearchQuery:"",
    assetTypeRowData:[],
    openAssetModal:false,

}

export const fetchAssetType=createAsyncThunk(
    `${SLICE_NAME}/fetchAssetType`,
    async({length, start, keyword,status})=>{
        try{
            const response=await apiGetAssetType({length, start, keyword,status})
            return response?.data
        } catch(error){
            throw error
        }
    }
)


export const editEditAssetType = createAsyncThunk(
    `${SLICE_NAME}/editEditAssetType`,
    async ({ id, data }, { rejectWithValue }) => {

      try {
        console.log({ id, data })
        const response = await apiEditAssetType(id, data);
        return response
      } catch (error) {
        return rejectWithValue({
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        })
      }
    }
  )

  export const createAssetType = createAsyncThunk(
    `${SLICE_NAME}/createAssetType`,
    async (data, { rejectWithValue }) => {
      try {
        const response = await apiCreateAssetType(data)
        return response
      } catch (error) {
        return rejectWithValue({
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        })
      }
    }
  )

  const assetTypeSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
      setLoading: (state, action) => {
        state.loading = action.payload
      },
      setAssetTypeRowData: (state, action) => {
        state.assetTypeRowData = action.payload
      },
      setOpenAssetModal: (state, action) => {
        state.openAssetModal = action.payload
      },
      setCurrentPage: (state, action) => {
        state.currentPage = action.payload
      },
      setAssetSearchQuery: (state, action) => {
        state.assetSearchQuery = action.payload
      },
    },
    extraReducers: builder => {
      builder.addCase(fetchAssetType.pending, state => {
        state.loading = true
      })
      builder.addCase(fetchAssetType.fulfilled, (state, action) => {
        state.loading = false
        state.assetTypeData = action?.payload?.data
        state.assetTypeTotalData = action?.payload?.total
      })
      builder.addCase(fetchAssetType.rejected, state => {
        state.loading = false
      })
    },
  })

export const {setLoading,
    setCurrentPage,
    setAssetTypeRowData,
    setOpenAssetModal,
    setAssetSearchQuery,
}= assetTypeSlice.actions
export default assetTypeSlice.reducer
