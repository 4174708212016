import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiApproveStatus,
  apiCreateDeductionReward,
  apiEditRewardRule,
  apiEmployeeTypeReward,
  apiGetDeductionReward,
} from "services/DeductionRewardService"
export const SLICE_NAME = "deductionReward"

const initialState = {
  loading: false,
  openRewardModal: false,
  rewardRowData: [],
  deductionRewardData: [],
  deductionRewardtotalData: 0,
  rewardTotalData: 0,
  currentPage: 1,
  rewardSearchQuery: "",
  employeeTypeRewardData: [],
  employeeTypeViewData: [],
  review: "",
  employeeId: "",
  dateRange: [null, null],
}

export const fetchEmployeeRewardType = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeRewardType`,
  async () => {
    try {
      const response = await apiEmployeeTypeReward()
      return {
        status: "success",
        data: response?.data?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchDeductionReward = createAsyncThunk(
  `${SLICE_NAME}/fetchDeductionReward`,
  async ({
    length,
    start,
    keyword,
    status,
    employee_id,
    from_date,
    to_date,
  }) => {
    try {
      const response = await apiGetDeductionReward({
        length,
        start,
        keyword,
        status,
        employee_id,
        from_date,
        to_date,
      })
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const editDeductionReward = createAsyncThunk(
  `${SLICE_NAME}/editRuleReward`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiEditRewardRule(id, data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const createDeductionReward = createAsyncThunk(
  `${SLICE_NAME}/createDeductionReward`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiCreateDeductionReward(data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const updateStatus = createAsyncThunk(
  `${SLICE_NAME}/approveStatus`,
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await apiApproveStatus(id, data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

const deductionReward = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setRewardTableRowData: (state, action) => {
      state.rewardRowData = action.payload
    },
    setDeductionRewardModal: (state, action) => {
      state.openRewardModal = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setRewardSearch: (state, action) => {
      state.rewardSearchQuery = action.payload
    },
    setReview: (state, action) => {
      state.review = action.payload
    },
    setEmployeeId: (state, action) => {
      state.employeeId = action?.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDeductionReward.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchDeductionReward.fulfilled, (state, action) => {
      state.loading = false
      state.deductionRewardData = action?.payload?.data
      state.rewardTotalData = action?.payload?.total
    })
    builder.addCase(fetchDeductionReward.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchEmployeeRewardType.fulfilled, (state, action) => {
      const initalOptions = { value: "", label: "All" }
      const fetchOption = action?.payload?.data?.map(employeeType => ({
        value: employeeType?.id,
        label: `${employeeType?.first_name} ${employeeType?.last_name}`,
      }))
      state.employeeTypeRewardData = fetchOption
      state.employeeTypeViewData = [initalOptions, ...fetchOption]
    })
  },
})

export const {
  setLoading,
  setRewardTableRowData,
  setDeductionRewardModal,
  setCurrentPage,
  setRewardSearch,
  setReview,
  setEmployeeId,
  setDateRange,
} = deductionReward.actions

export default deductionReward.reducer
