import React from 'react'
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"

const CompanyPoliciesView = () => {
    return (
        <>
        <Row className='d-flex justify-content-center'>
        <Col className='profile-container' xl="12">
            <Card className="mb-lg-75">
              <CardBody>
                <Row>
                <Col md={12}>
                        <div className="d-flex align-items-center">
                          <CardTitle className="header-text">
                          Company Policies
                          </CardTitle>
                        </div>
                        <hr className="underline" />
                      </Col>
                      <Col md="10" >
                        <Label  htmlFor="company_name">
                        Appendix A 2022
                        </Label>.
                        </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
            
        </>
      )
}

export default CompanyPoliciesView
