import React from "react"
import { useDispatch, useSelector } from "react-redux"
import OnboaringContactDetail from "../OnboardingDetails/OnboaringContactDetail"
import OnbordingContactForm from "../OnbordingForms/OnbordingContactForm"

export const OnboardingContactDetailView = () => {
  const { editContactDetail } = useSelector(state => state.onBoarding)
  return (
    <div>
      {editContactDetail ? (
        <OnbordingContactForm />
      ) : (
        <OnboaringContactDetail />
      )}
    </div>
  )
}
