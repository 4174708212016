import { useState } from 'react';

const useCall = () => {
  const [isShowingCall, setIsShowingCall] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleCall() {
    setIsShowingCall(!isShowingCall);
    removeBodyCss();
  }

  return {
    isShowingCall,
    toggleCall,
  }
};

export default useCall;