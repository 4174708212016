import React, { useEffect } from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import Select from "react-select"
import {
  createDeductionReward,
  editDeductionReward,
  fetchDeductionReward,
  fetchEmployeeRewardType,
  setDeductionRewardModal,
} from "store/deductionReward/deductionRewardSlice"
import { months, rewardType } from "constants/Constants"
import { formatDate } from "constants/CommonFunction"

export const DeductionRewardForm = () => {
  const dispatch = useDispatch()
  const {
    openRewardModal,
    rewardRowData,
    currentPage,
    employeeTypeRewardData,
    employeeId,
    review,
    rewardSearchQuery,
    dateRange,
  } = useSelector(state => state.deductionReward)

  const currentYear = new Date().getFullYear()

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    employee_id: Yup.string().required("Required"),
    remark: Yup.string().required("Required"),
    amount: Yup.number()
    .typeError("Must be a number")
    .max(99999,"Must be less than or equal to 99999")
    .required("Required"),
    month: Yup.string().required("Required"),
    year: Yup.number()
      .required("Required")
      .min(2000, "Year must be 2000 or later")
      .max(currentYear, `Year must be ${currentYear} or earlier`)
      .test(
        "len",
        "Year must be exactly 4 digits",
        val => val && val.toString().length === 4
      ),
  })

  useEffect(() => {
    dispatch(fetchEmployeeRewardType())
  }, [dispatch])

  const toggle = async () => {
    await dispatch(setDeductionRewardModal(false))
  }

  return (
    <Modal
      scrollable
      style={{ maxWidth: "400px", marginInline:'auto', width:'calc( 100vw - 20px)' }}
      isOpen={openRewardModal}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>
        {rewardRowData?.id ? "Edit" : "Add"} Deduction Reward
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                type: rewardRowData?.type ? Number(rewardRowData?.type) : "",
                employee_id: rewardRowData?.employee_id
                  ? rewardRowData?.employee_id
                  : "",
                amount: rewardRowData?.amount || "",
                month: rewardRowData?.month || "",
                year: rewardRowData?.year || "",
                remark: rewardRowData?.remark || "",
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                try {
                  const response = rewardRowData?.id
                    ? await dispatch(
                        editDeductionReward({
                          id: rewardRowData?.id,
                          data: values,
                        })
                      ).unwrap()
                    : await dispatch(createDeductionReward(values)).unwrap()
                  if (response.data?.status) {
                    toastr.success(
                      response?.data?.message || "Operation successful"
                    )
                  } else {
                    toastr.error(
                      response?.payload?.message || "Something went wrong"
                    )
                  }
                } catch (error) {
                  toastr.error(
                    error.message ||
                      "Something went wrong, please try again later"
                  )
                } finally {
                  dispatch(
                    fetchDeductionReward({
                      length: 10,
                      start: currentPage,
                      keyword:
                        rewardSearchQuery.length > 0
                          ? rewardSearchQuery
                          : undefined,
                      status: review,
                      employee_id: employeeId,
                      from_date: dateRange[0] ? formatDate(dateRange[0]) : "",
                      to_date: dateRange[1] ? formatDate(dateRange[1]) : "",
                    })
                  )
                  await dispatch(setDeductionRewardModal(false))
                }
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="employee_id">
                        Employee <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="employee_id"
                        name="employee_id"
                        options={employeeTypeRewardData}
                        value={employeeTypeRewardData.find(
                          option => option.value === values.employee_id
                        )}
                        onChange={option =>
                          setFieldValue("employee_id", option.value)
                        }
                        className={`${
                          touched.employee_id && errors.employee_id
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="type">
                        Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="type"
                        name="type"
                        options={rewardType}
                        value={rewardType.find(
                          option => option.value === values.type
                        )}
                        onChange={option => setFieldValue("type", option.value)}
                        className={`${
                          touched.type && errors.type ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="amount">
                        Amount <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="amount"
                        placeholder="Amount"
                        type="number"
                        min="0"
                        max="99999"
                        onInput={(e)=> {
                          const value=e.target.value
                          if(value.length>5){
                            e.target.value=value.slice(0,5);
                          }
                        }}
                        className={`form-control ${
                          touched.amount && errors.amount ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                        Remark <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="remark"
                        placeholder="Remark"
                        type="text"
                        className={`form-control ${
                          touched.remark && errors.remark ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="remark"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="month">
                        Month <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="month"
                        value={months().find(
                          option => option.value == values.month
                        )}
                        onChange={option => {
                          setFieldValue("month", option?.value)
                        }}
                        options={months()}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="month"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="year">
                        Year <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="year"
                        placeholder="Year"
                        type="text"
                        className={`form-control ${
                          touched.year && errors.year ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="year"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      {rewardRowData?.id ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
