import React from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setOnBoardingActiveTabs } from "store/onBoarding/onBordingSlice";
import { tabItems } from "constants/Constants";
import { useHistory } from "react-router-dom"; // Import useHistory

export const ProfileTabs = () => {
  const { onBoardingActiveTabs } = useSelector(state => state.onBoarding);
  const dispatch = useDispatch();
  const history = useHistory(); // Initialize useHistory

  const handleTabClick = (tabId) => {
    dispatch(setOnBoardingActiveTabs(tabId)); // Dispatch the action to update the active tab

    // Get the current pathname and state from the location object
    const currentPath = window.location.pathname; // Get the current pathname
    const currentState = history.location.state;  // Get the current state

    // Use replace to update the URL without query parameters, but keep the current state
    history.replace({
      pathname: currentPath,
      state: currentState  // Preserve the state
    });
};


const { profileDetails } = useSelector(
  state => state.onBoarding
)


  return (
    <div className="d-flex justify-content-center">
      <Row className="justify-content-center profile-container">
        <Col md="12" className="mb-4 text-center">
          <Nav className="icon-tab nav-justified pb-1 scrollbar-slim">
            {tabItems?.filter((item) => profileDetails?.tabs?.[item.key])
            .map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  style={{
                    cursor: "pointer",
                    height: "55px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={classnames({
                    active: onBoardingActiveTabs === item.id,
                    "nav-link": true,
                  })}
                  onClick={() => handleTabClick(item.id)} // Call handleTabClick on click
                >
                  <span style={{whiteSpace:'nowrap'}}>
                    {/* <i className={item.icon}></i> */}
                    {item.label}
                  </span>
                  {/* <span className="d-block d-lg-none">
                    <i className={item.icon}></i>
                  </span> */}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
    </div>
  );
};
