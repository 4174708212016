import React from "react"
import { Container } from "reactstrap"
import { ExpenseMappingView } from "./ExpenseMappingView"
import { ExpenseMappingForm } from "./ExpenseMappingFrom"

export const ExpenseMappingList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <ExpenseMappingForm />
        <ExpenseMappingView />
      </Container>
    </div>
  )
}
