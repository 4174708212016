import React from "react"
import { Card, CardBody, CardTitle, Col, Row, Label, Input } from "reactstrap"
import {
  approveProfile,
  fetchProfileDetail,
  setEditKycDetail,
} from "store/onBoarding/onBordingSlice"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"

const OnboardingKycDetail = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId, editProfile } = useSelector(
    state => state.onBoarding
  )

  const approveKycDocument = async (documentType, statusType) => {
    try {
      const payload = {
        [documentType]: statusType,
      }
      const response = await dispatch(
        approveProfile({ id: userId, data: payload })
      )
      if (response?.payload?.status === "success") {
        const successMessage =
          response.payload.data.data.message || "Data updated successfully"
        toastr.success(successMessage)
      } else if (response?.payload?.status === "failed") {
        const failureMessage = response.payload.message || "Verification failed"
        toastr.error(failureMessage)
      }
    } catch (error) {
      toastr.error("Something Went Wrong")
    }
    await dispatch(fetchProfileDetail(userId))
  }

  return (
    <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-center">
                    <CardTitle className="fs-5" style={{ color: "#556ee6" }}>
                      KYC Document
                    </CardTitle>
                    {editProfile && (
                      <Col>
                        <div className="d-flex justify-content-end">
                          <i
                            className="bx bxs-edit mx-4 text-primary fs-5 cursor-pointer"
                            onClick={() => {
                              dispatch(setEditKycDetail(true))
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </div>
                  <hr className="underline" />
                </Col>
                <Col md={12} className="mt-2">
                  <div className="d-flex align-items-center">
                    <label
                      className="labels d-flex"
                      style={{ fontSize: "14px" }}
                    >
                      Address Proof Permanent
                      <div className="d-flex align-items-center">
                        {profileDetails?.is_address_proof_permanent_verified ==
                        1 ? (
                          <i
                            className="fas fa-check mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "#5BD94E",
                              padding: "2px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : profileDetails?.is_address_proof_permanent_verified ==
                          2 ? (
                          <i
                            className="fas fa-times mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "red",
                              padding: "4px",
                              borderRadius: "70%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </label>
                    <Col>
                      <div
                        className="d-flex justify-content-end"
                        style={{ gap: "10px" }}
                      >
                        {profileDetails?.address_proof_permanent?.length >
                          0 && (
                          <div
                            className="d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            {profileDetails?.is_address_proof_permanent_verified ==
                              0 && profileDetails?.is_action ? (
                              <>
                                <button
                                  onClick={() =>
                                    approveKycDocument(
                                      "is_address_proof_permanent_verified",
                                      1
                                    )
                                  }
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    approveKycDocument(
                                      "is_address_proof_permanent_verified",
                                      2
                                    )
                                  }
                                  disabled={
                                    profileDetails?.is_address_proof_permanent_verified ==
                                    2
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                >
                                  Reject
                                </button>
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </Col>
                  </div>
                  <div>
                    <a
                      href={profileDetails?.address_proof_permanent}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b className="values">
                        {profileDetails?.address_proof_permanent ? "File" : "-"}
                      </b>
                    </a>
                  </div>
                </Col>
                <Col md="3" className="mt-4">
                  <Label className="labels">Document Type</Label>
                  <div className="values">
                    {" "}
                    {profileDetails?.address_proof_permanent_doc_type_name
                      ? profileDetails?.address_proof_permanent_doc_type_name
                      : "-"}
                  </div>
                </Col>
                <Col md="3" className="mt-4">
                  <Label className="labels">Document Id / Number</Label>
                  <div className="values">
                    {" "}
                    {profileDetails?.address_proof_permanent_number
                      ? profileDetails?.address_proof_permanent_number
                      : "-"}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-center mt-3">
                    <label
                      className="labels d-flex"
                      style={{ fontSize: "14px" }}
                    >
                      Address Proof Temporary
                      <div className="d-flex align-items-center">
                        {profileDetails?.is_address_proof_temporary_verified ==
                        1 ? (
                          <i
                            className="fas fa-check mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "#5BD94E",
                              padding: "2px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : profileDetails?.is_address_proof_temporary_verified ==
                          2 ? (
                          <i
                            className="fas fa-times mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "red",
                              padding: "4px",
                              borderRadius: "70%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </label>
                    <Col>
                      <div
                        className="d-flex justify-content-end"
                        style={{ gap: "10px" }}
                      >
                        {profileDetails?.address_proof_temporary?.length >
                          0 && (
                          <div
                            className="d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            {profileDetails?.is_address_proof_temporary_verified ==
                              0 && profileDetails?.is_action ? (
                              <>
                                <button
                                  onClick={() =>
                                    approveKycDocument(
                                      "is_address_proof_temporary_verified",
                                      1
                                    )
                                  }
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    approveKycDocument(
                                      "is_address_proof_temporary_verified",
                                      2
                                    )
                                  }
                                  disabled={
                                    profileDetails?.is_address_proof_temporary_verified ==
                                    2
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                >
                                  Reject
                                </button>
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </Col>
                  </div>
                  <div>
                    <a
                      href={profileDetails?.address_proof_temporary}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b className="values">
                        {profileDetails?.address_proof_temporary ? "File" : "-"}
                      </b>
                    </a>
                  </div>
                </Col>
                <Col md="3" className="mt-4">
                  <Label className="labels">Document Type</Label>
                  <div className="values">
                    {" "}
                    {profileDetails?.address_proof_temporary_doc_type_name
                      ? profileDetails?.address_proof_temporary_doc_type_name
                      : "-"}
                  </div>
                </Col>
                <Col md="3" className="mt-4">
                  <Label className="labels">Document Id / Number</Label>
                  <div className="values">
                    {" "}
                    {profileDetails?.address_proof_temporary_number
                      ? profileDetails?.address_proof_temporary_number
                      : "-"}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-center mt-2">
                    <label
                      className="labels d-flex"
                      style={{ fontSize: "14px" }}
                    >
                      ID Proof
                      <div className="d-flex align-items-center">
                        {profileDetails?.is_id_proof_verified == 1 ? (
                          <i
                            className="fas fa-check mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "#5BD94E",
                              padding: "2px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : profileDetails?.is_id_proof_verified == 2 ? (
                          <i
                            className="fas fa-times mx-2 fs-6"
                            style={{
                              color: "white",
                              background: "red",
                              padding: "4px",
                              borderRadius: "70%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </label>
                    <Col>
                      <div
                        className="d-flex justify-content-end"
                        style={{ gap: "10px" }}
                      >
                        {profileDetails?.id_proof?.length > 0 && (
                          <div
                            className="d-flex justify-content-end"
                            style={{ gap: "10px" }}
                          >
                            {profileDetails?.is_id_proof_verified == 0 &&
                            profileDetails?.is_action ? (
                              <>
                                <button
                                  onClick={() =>
                                    approveKycDocument(
                                      "is_id_proof_verified",
                                      1
                                    )
                                  }
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    approveKycDocument(
                                      "is_id_proof_verified",
                                      2
                                    )
                                  }
                                  disabled={
                                    profileDetails?.is_id_proof_verified == 2
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                >
                                  Reject
                                </button>
                              </>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </Col>
                  </div>
                  <div className="values">
                    <a
                      href={profileDetails?.id_proof}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b className="values">
                        {profileDetails?.id_proof ? "File" : "-"}
                      </b>
                    </a>
                  </div>
                </Col>
                <Col md="3" className="mt-4">
                  <Label className="labels">Document Type</Label>
                  <div className="values">
                    {" "}
                    {profileDetails?.id_proof_doc_type_name
                      ? profileDetails?.id_proof_doc_type_name
                      : "-"}
                  </div>
                </Col>
                <Col md="3" className="mt-4">
                  <Label className="labels">Document Id / Number</Label>
                  <div className="values">
                    {" "}
                    {profileDetails?.id_proof_number
                      ? profileDetails?.id_proof_number
                      : "-"}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default OnboardingKycDetail
