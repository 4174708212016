import React, { useEffect, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import MetaTags from "react-meta-tags"
import Pagination from "components/Common/Pagination"
import { TableHeader, Search } from "components/Datatable/index"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import CommonModal from "components/Common/Modal"
import { Link } from "react-router-dom"
import "../../../MultiPageLink.css"

import {
  fetchDeductionReward,
  setCurrentPage,
  setDeductionRewardModal,
  setRewardSearch,
  setRewardTableRowData,
  setEmployeeId,
  setReview,
  updateStatus,
  setDateRange,
} from "store/deductionReward/deductionRewardSlice"
import {
  formatDate,
  formatDateFormat,
  generateTooltip,
  getMonthName,
} from "constants/CommonFunction"
import { customStyles, reviews } from "constants/Constants"
import toastr from "toastr"
import Flatpickr from "react-flatpickr"
import useExportXlsx from "components/Hooks/useExportXlsx"

export const DeductionRewardView = () => {
  const {
    currentPage,
    rewardSearchQuery,
    deductionRewardData,
    rewardTotalData,
    employeeTypeViewData,
    loading,
    review,
    employeeId,
    dateRange,
  } = useSelector(state => state.deductionReward)
  const { deductions } = useSelector(state => state.users.userPermissions);
  const dispatch = useDispatch()
  const { exportXlsx, xlsxLoader } = useExportXlsx()
  const [modelRemark,setModelRemark]=useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);

  const approveStatus = async (id, type) => {
    try {
      const response = await dispatch(
        updateStatus({
          id: id,
          status: type == "approve" ? 1 : 2,
        })
      )
      if (response?.payload?.data?.status) {
        toastr.success(response?.payload?.data?.message)
      } else {
        toastr.error(response?.payload?.message)
      }
    } catch (error) {
      toastr.error("Something Went Wrong")
    }
    await dispatch(
      fetchDeductionReward({
        length: 10,
        start: currentPage,
        keyword: rewardSearchQuery.length > 0 ? rewardSearchQuery : undefined,
        status: review,
        employee_id: employeeId,
        from_date: dateRange[0] ? formatDate(dateRange[0]) : "",
        to_date: dateRange[1] ? formatDate(dateRange[1]) : "",
      })
    )
  }

  const showRemark=(remark)=>{
    setModelRemark(remark)
    setIsModalOpen(true)
  }
  const columns = useMemo(() => {
    const baseCoulumn = [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        Cell: ({ row }) => (
          <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        accessor: "employee_id",
        Header: () => <div style={{ width: "120px" }}>Employee Name</div>,
        accessor: "employee_id",
        Cell: ({ row }) => {
          const firstName = row?.original?.employee_first_name
          const lastName = row?.original?.employee_last_name
          return <div>{`${firstName} ${lastName}`}</div>
        },
      },
      {
        id: "type",
        Header: () => <div style={{ width: "80px" }}>Type</div>,
        accessor: "type",
        Cell: ({ value }) => (
          <div>{value == 1 ? "Reward" : "Deduction" || "-"}</div>
        ),
      },
      {
        id: "remark",
        Header: () => <div style={{ width: "80px" }}>Remark</div>,
        accessor: "remark",
        Cell: ({ value }) => <div>
          {value ? (
            <>
            {
            value.length >20?(
              <>
              {value.slice(0,20)}
              <i
              className="bx bx-dots-horizontal-rounded cursor-pointer"
              title="View more"
              onClick={() => {showRemark(value)}}
              />
              </>
            ):(value)}
            </>
          ):("-")}
        </div>
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Month",
        accessor: "month",
        Cell: ({ value }) => <div>{getMonthName(value)}</div>,
      },
      {
        Header: "Year",
        accessor: "year",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Created By</div>,
        accessor: "created_by_first_name",
        Cell: ({ row }) => {
          const {created_by_first_name, created_by_last_name} = row.original
          let created_by_name = `${created_by_first_name} ${created_by_last_name}`;
          return <div>{created_by_name}</div>
        }
      },
      {
        id: "created_on",
        Header: () => <div style={{ width: "80px" }}>Created On</div>,
        accessor: "created_on",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
    ]
    if(deductions?.edit){
      baseCoulumn.push(
        {
          Header: "Action",
          accessor: "actions",
          Cell: ({ row }) => {
            const { status, is_action, approvals } = row.original
    
            let buttonClass= "btn btn-sm";
            let buttonText="";
    
            if(status === "0" && is_action === false ){
              buttonClass+=" btn-primary";
              buttonText="Pending"
            }else if(status === "1" && is_action === false){
              buttonClass+=" btn-success";
              buttonText="Approved"
            }else {
              buttonClass+=" btn-danger";
              buttonText="Rejected"
            }
            return (  
              <div style={{ display: "flex", gap: "10px" }}>
                {status === "0" && is_action === true ? (
                  <>
                    <i
                      className="bx bx-check-square cursor-pointer"   style={{ fontSize: "17px", color: "#0f8000" }}
                      onClick={() => approveStatus(row?.original?.id, "approve")}
                    />
                      {/* Approve */}
                    {/* </button> */}
                    <i
                       className="bx bx-window-close cursor-pointer"   style={{ fontSize: "17px", color: "#ed1e07" }}
                      onClick={() => approveStatus(row?.original?.id, "reject")}
                    />
                      {/* Reject */}
                    {/* </button> */}
                  </>
                ) : (
                  <span
                    className="d-inline-block"
                    data-toggle="tooltip"
                    title={generateTooltip(approvals)}
                  >
                    <button
                      className={buttonClass}
                      type="button"
                      style={{
                        // background: "#aaabb6",
                        border: "none",
                        cursor: "no-drop",
                      }}
                    >
                      {buttonText}
                       {/* {status === "0" && is_action === false
                        ? "Pending"
                        : status === "1" && is_action === false
                        ? "Approved"
                        : "Rejected"}*/}
                    </button>
                  </span>
                )
                }
                {status !== "1" && status !== "2" && (
                  <div className="d-flex align-items-center">
                    <i
                      onClick={async () => {
                        await dispatch(setRewardTableRowData(row.original))
                        await dispatch(setDeductionRewardModal(true))
                      }}
                      className="bx bxs-edit cursor-pointer"
                      style={{ fontSize: "17px", color: "#039F03" }}
                    />
                  </div>
                )}
              </div>
            )
          },
        }
      )
    }
    return baseCoulumn;
  }, [dispatch, currentPage])

  useEffect(() => {
    dispatch(
      fetchDeductionReward({
        length: 10,
        start: currentPage,
        keyword: rewardSearchQuery.length > 0 ? rewardSearchQuery : undefined,
        status: review,
        employee_id: employeeId,
        from_date: dateRange[0] ? formatDate(dateRange[0], true) : "",
        to_date: dateRange[1] ? formatDate(dateRange[1], true) : "",
      })
    )
  }, [currentPage, rewardSearchQuery, review, employeeId, dateRange])

  const handleAdd = async () => {
    await dispatch(setRewardTableRowData([]))
    await dispatch(setDeductionRewardModal(true))
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="mb-2">Deduction / Reward</h5>
        <div className="d-flex multi-page-link flex-wrap mb-2">
          <Link className="active" to="/DeductionRewards">
            Logs
          </Link>
          {/* <Link to="/DeductionRewards">Approval</Link> */}
        </div>

        <Card>
          <CardBody>
            <Row className=" align-items-center">
              <Col md={2} sm={12} style={{ marginTop: "10px" }}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setRewardSearch(value))
                    dispatch(setRuleCurrentPage(1))
                  }}
                />
              </Col>
              <Col md={2} className="mb-3">
                <span>Status</span>
                <Select
                  id="review"
                  name="review"
                  size="sm"
                  options={reviews}
                  value={reviews.find(option => option.value === review)}
                  onChange={async option => {
                    await dispatch(setCurrentPage(1))
                    await dispatch(setReview(option?.value))
                  }}
                  styles={customStyles}
                />
              </Col>
              <Col md={2} className="mb-3">
                <span>Employee</span>
                <Select
                  id="employee_id"
                  name="employee_id"
                  placeholder="Employee"
                  options={employeeTypeViewData}
                  value={employeeTypeViewData?.find(
                    option => option.value === employeeId
                  )}
                  onChange={async option => {
                    await dispatch(setCurrentPage(1))
                    await dispatch(setEmployeeId(option?.value))
                  }}
                  styles={customStyles}
                />
              </Col>
              <Col md={3} className="mb-3">
                <span>Select Date</span>
                <div className="input-group d-flex" data-clear>
                  <Flatpickr
                    className="form-control"
                    placeholder="Start Date to End Date"
                    options={{
                      mode: "range",
                      dateFormat: "d/m/Y",
                    }}
                    value={dateRange}
                    onChange={date => {
                      dispatch(setDateRange(date))
                    }}
                  />
                  <button
                    type="button"
                    className="d-flex align-items-center btn btn-md btn-outline-secondary"
                    data-clear
                    onClick={() => {
                      dispatch(setDateRange([null, null]))
                    }}
                  >
                    <i className="dripicons-cross mx-1 d-flex" />
                  </button>
                </div>
              </Col>
              <Col
                md={3}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
                style={{ marginTop: "10px" }}
              >
                {deductions?.export && <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  style={{ borderRadius: "5px", height: "38px" }}
                  onClick={() =>
                    exportXlsx("deduction-logs", "Deduction Reward", {
                      keyword: rewardSearchQuery.length > 0 ? rewardSearchQuery : undefined,
                      status: review,
                      employee_id: employeeId,
                      from_date: dateRange[0] ? formatDate(dateRange[0]) : "",
                      to_date: dateRange[1] ? formatDate(dateRange[1]) : "",
                    })
                  }
                  disabled={xlsxLoader}
                >
                  <i className="bx bx-export font-size-16 me-2" />
                  {xlsxLoader ? "Exporting..." : "Export"}
                </Button>}
                {deductions?.add && <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>}
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={deductionRewardData ? deductionRewardData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={currentPage}
                totalCount={rewardTotalData ? rewardTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setCurrentPage(page))
                }}
              />
              <CommonModal
              isOpen={isModalOpen}
              toggleModal={() => setIsModalOpen(!isModalOpen)}
              modalTitle="Remark"
              modalBody={<div>{modelRemark}</div>}
              showFooter={false}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

{
  /* <Breadcrumbs title="Deduction & Rewards" path="/deduction-reward-rules" breadcrumbItem="Deduction & Rewards" /> */
}
