import React from "react"
import { Container } from "reactstrap"
import SalaryView from "./SalaryView"
import SalarySync from "./SalarySync"

export const SalaryList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <SalarySync/>
        <SalaryView />
      </Container>
    </div>
  )
}
