import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {
  convertTime,
  getInitialWorkingDays,
  penaltyOptions,
  workingDaysOptions,
} from "constants/Constants"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import {
  createAttendanceRule,
  editAttendanceRule,
} from "store/attendanceRule/attendanceRuleSlice"
import { useHistory } from "react-router-dom"
import { HandleTimeChange } from "components/Common/HandleTimeChange"
import TimePickerInput from "components/Common/TimePicker/TimePicker"

export const AttendanceRuleForm = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const {
    id,
    name,
    desc,
    working_days,
    in_time,
    out_time,
    in_time_grace_period,
    out_time_grace_period,
    full_day_hours,
    half_day_hours,
    break_time,
    in_time_penalty_interval,
    in_time_penalty_value,
    out_time_penalty_interval,
    out_time_penalty_value,
    status,
  } = location?.state?.row ? location?.state.row : ""

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <MetaTags>
            <title>
              Manage User-boarding | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={{
                      name: name ? name : "",
                      desc: desc ? desc : "",
                      working_days: working_days
                        ? getInitialWorkingDays(
                            working_days,
                            workingDaysOptions
                          )
                        : [],
                      in_time: in_time ? convertTime(in_time) : "",
                      out_time: out_time ? convertTime(out_time) : "",
                      in_time_grace_period: in_time_grace_period
                        ? convertTime(in_time_grace_period)
                        : "",
                      out_time_grace_period: out_time_grace_period
                        ? convertTime(out_time_grace_period)
                        : "",
                      full_day_hours: full_day_hours
                        ? convertTime(full_day_hours)
                        : "",
                      half_day_hours: half_day_hours
                        ? convertTime(half_day_hours)
                        : "",
                      break_time: break_time ? convertTime(break_time) : "",
                      in_time_penalty_interval: in_time_penalty_interval
                        ? in_time_penalty_interval
                        : "",
                      in_time_penalty_value: in_time_penalty_value
                        ? in_time_penalty_value
                        : 0,
                      out_time_penalty_interval: out_time_penalty_interval
                        ? out_time_penalty_interval
                        : "",
                      out_time_penalty_value: out_time_penalty_value
                        ? out_time_penalty_value
                        : 0,
                      status: status ? status : 0,
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Required"),
                      desc: Yup.string().required("Required"),
                      working_days: Yup.array().min(1, "Required"),
                      in_time: Yup.string().required("Required"),
                      out_time: Yup.string().required("Required"),
                      in_time_grace_period: Yup.string().required("Required"),
                      out_time_grace_period: Yup.string().required("Required"),
                      full_day_hours: Yup.string().required("Required"),
                      half_day_hours: Yup.string().required("Required"),
                      break_time: Yup.string().required("Required"),
                      in_time_penalty_interval: Yup.number().max(
                        99,
                        "must be 2 digits or less"
                      ),
                      out_time_penalty_interval: Yup.number().max(
                        99,
                        "Must be 2 digits or less"
                      ),
                    })}
                    onSubmit={async values => {
                      values.working_days = values?.working_days
                        .map(item => item.value)
                        .join(",")
                      try {
                        const response = id
                          ? await dispatch(
                              editAttendanceRule({ id: id, data: values })
                            )
                          : await dispatch(createAttendanceRule(values))
                        if (response?.payload?.status == "success") {
                          toastr.success(response?.payload?.data?.data?.message)
                          history.push("/user-attendance-logs?tab=rule")
                        } else {
                          toastr.error(response?.payload?.message)
                        }
                      } catch (error) {
                        toastr.error("Something went wrong.")
                      }
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      isSubmitting,
                      errors,
                      touched,
                      dirty,
                    }) => (
                      <Form>
                        {console.log(values, "Verify Values ...")}
                        <Row>
                          <Col md={12}>
                            <CardTitle className="header-text">
                              Attendance Details
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="name">
                              Name<span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="5" className="mb-3">
                            <Label className="labels" htmlFor="desc">
                              Description<span className="text-danger">*</span>
                            </Label>
                            <Field
                              as="textarea"
                              name="desc"
                              placeholder="Description"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="desc"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={12}>
                            <CardTitle className="header-text">
                              Shift Timings
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label className="labels" htmlFor="working_days">
                              Working Days<span className="text-danger">*</span>
                            </Label>
                            <Select
                              isMulti
                              name="working_days"
                              options={workingDaysOptions}
                              value={values?.working_days}
                              className={`basic-multi-select ${
                                touched.working_days && errors.working_days
                                  ? "is-invalid"
                                  : ""
                              }`}
                              classNamePrefix="select"
                              onChange={selectedOptions =>
                                setFieldValue(
                                  "working_days",
                                  selectedOptions.map(option => option)
                                )
                              }
                            />
                            <ErrorMessage
                              name="working_days"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label className="labels" htmlFor="in_time">
                              In Time<span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="time"
                              className="cursor-pointer"
                              id="in_time"
                              name="in_time"
                              value={values?.in_time}
                              onChange={e =>
                                setFieldValue("in_time", e.target.value)
                              }
                            />
                            <ErrorMessage
                              name="in_time"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label className="labels" htmlFor="out_time">
                              Out Time<span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="time"
                              className="cursor-pointer"
                              id="out_time"
                              name="out_time"
                              value={values?.out_time}
                              onChange={e =>
                                setFieldValue("out_time", e.target.value)
                              }
                            />
                            <ErrorMessage
                              name="out_time"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Anomaly
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="4" className="mb-3">
                            <TimePickerInput
                              name="in_time_grace_period"
                              label="In Time Grace Period"
                              value={values?.in_time_grace_period}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                            />
                          </Col>
                          <Col md="4" className="mb-3">
                            <TimePickerInput
                              name="out_time_grace_period"
                              label="Out Time Grace Period"
                              value={values?.out_time_grace_period}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                            />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Work Hours
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <HandleTimeChange
                              name="full_day_hours"
                              label="Full Day"
                              value={values?.full_day_hours}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <HandleTimeChange
                              name="half_day_hours"
                              label="Half Day"
                              value={values?.half_day_hours}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                            />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">Break</CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <HandleTimeChange
                              name="break_time"
                              label="Break Duration"
                              value={values.break_time}
                              onChange={(name, value) =>
                                setFieldValue(name, value)
                              }
                            />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Penalty (In Time)
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="in_time_penalty_interval"
                            >
                              Penalty Interval
                            </Label>
                            <Field
                              type="number"
                              name="in_time_penalty_interval"
                              placeholder="Penalty Interval"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="in_time_penalty_interval"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="in_time_penalty_value"
                            >
                              Penalty
                            </Label>
                            <Select
                              name="in_time_penalty_value"
                              options={penaltyOptions}
                              value={penaltyOptions?.find(
                                option =>
                                  option.value == values?.in_time_penalty_value
                              )}
                              classNamePrefix="select"
                              onChange={selectedOption =>
                                setFieldValue(
                                  "in_time_penalty_value",
                                  selectedOption.value
                                )
                              }
                            />
                            <ErrorMessage
                              name="in_time_penalty_value"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Penalty (Out Time)
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="out_time_penalty_interval"
                            >
                              Penalty Interval
                            </Label>
                            <Field
                              type="number"
                              name="out_time_penalty_interval"
                              placeholder="Penalty Interval"
                              className={`form-control ${
                                touched.out_time_penalty_interval &&
                                errors.out_time_penalty_interval
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="out_time_penalty_interval"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="out_time_penalty_value"
                            >
                              Penalty
                            </Label>
                            <Select
                              name="out_time_penalty_value"
                              options={penaltyOptions}
                              value={penaltyOptions?.find(
                                option =>
                                  option?.value ==
                                  values?.out_time_penalty_value
                              )}
                              classNamePrefix="select"
                              onChange={selectedOption =>
                                setFieldValue(
                                  "out_time_penalty_value",
                                  Number(selectedOption.value)
                                )
                              }
                            />
                            <ErrorMessage
                              name="out_time_penalty_value"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={12} className="mb-1">
                            <div className="form-check form-switch form-switch-md mb-2 d-flex ">
                              <Input
                                name="status"
                                type="checkbox"
                                className="form-check-input"
                                defaultChecked={
                                  values?.status === 0 ? false : true
                                }
                                onChange={e => {
                                  setFieldValue(
                                    "status",
                                    e.target.checked ? 1 : 0
                                  )
                                }}
                              />
                              <Label
                                className="mx-2 fs-6 mb-0"
                                htmlFor="status"
                              >
                                Status
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                          <Link to="/user-attendance-rule">
                            <button
                              className="d-flex align-items-center btn btn-md btn-outline-secondary"
                              type="button"
                            >
                              <i className="dripicons-cross mx-1 d-flex" />{" "}
                              Cancel
                            </button>
                          </Link>
                          <button
                            className="btn btn-md btn-primary"
                            type="submit"
                            disabled={isSubmitting || !dirty}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
