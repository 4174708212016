import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetLeaveApproval, apiUpdateLeaveStatus } from "services/LeaveApprovalService"

export const SLICE_NAME = "leaveApproval"

const initialState = {
  loading: false,
  leaveApprovalData: [],
  leaveApprovalTotalData: 0,
  leaveApprovalCurrentPage: 1,
  leaveApprovalSearchQuery: "",
  rejectLeaveForm: false,
  leaveToAction: [],
  updateStatusLoading: false, // New state to handle update status loading
}

export const fetchLeaveApproval = createAsyncThunk(
  `${SLICE_NAME}/fetchLeaveApproval`,
  async ({ start, length, keyword, status, employee_id }) => {
    try {
      const response = await apiGetLeaveApproval({
        start,
        length,
        keyword,
        status,
        employee_id
      })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const updateStatus = createAsyncThunk(
  `${SLICE_NAME}/updateStatus`,
  async ({ id, status }) => {
    try {
      const response = await apiUpdateLeaveStatus({ id, status })
      return {
        status: "success",
        data: response?.data,
        id,
        status,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const leaveApproval = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    toggleLeaveRejactionForm: (state, action) => {
      state.rejectLeaveForm = action.payload.toggle,
      state.leaveToAction = action.payload.data
    },
    setleaveApprovalCurrentPage: (state, action) => {
      state.leaveApprovalCurrentPage = action.payload
    },
    setLeaveApprovalSearchQuery: (state, action) => {
      state.leaveApprovalSearchQuery = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchLeaveApproval.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchLeaveApproval.fulfilled, (state, action) => {
      state.loading = false
      state.leaveApprovalData = action?.payload?.data?.data
      state.leaveApprovalTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchLeaveApproval.rejected, state => {
      state.loading = false
    })

    // Handle updateStatus cases
    builder.addCase(updateStatus.pending, state => {
      state.updateStatusLoading = true
    })
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.updateStatusLoading = false

      // Update the status of the leave in the leaveApprovalData array
      const leaveIndex = state.leaveApprovalData.findIndex(
        leave => leave.id === action.payload.id
      )
      if (leaveIndex !== -1) {
        state.leaveApprovalData[leaveIndex].status = action.payload.status
      }
    })
    builder.addCase(updateStatus.rejected, state => {
      state.updateStatusLoading = false
    })
  },
})

export const {
  setLoading,
  toggleLeaveRejactionForm,
  setleaveApprovalCurrentPage,
  setLeaveApprovalSearchQuery,
} = leaveApproval.actions

export default leaveApproval.reducer
