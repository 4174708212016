import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import Pagination from "components/Common/Pagination"
import { TableHeader, Search } from "components/Datatable/index"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useFullPageLoader from "../../../components/Common/useFullPageLoader"
import { Link } from "react-router-dom"
import { get } from "../../../helpers/api_helper"
import {
  EXPORT_MASTER,
  GET_VENDOR_CTEGORY_MASTER,
} from "../../../helpers/api_url_helper"
import toastr from "toastr"
import axios from "axios"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import { downloadVendorCategorySampleCsv } from "../../../constants/CommonFunction"
import useExportXlsx from "components/Hooks/useExportXlsx"
import { CSVLink } from "react-csv"
import { useSelector } from "react-redux"
const DatatableTables = () => {
  const [tableData, settableData] = useState([])
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const [FormMsg, setFormMsg] = useState("")
  const { exportXlsx, xlsxLoader } = useExportXlsx()
  const [reloadData, setreloadData] = useState(false)
  const [csv_load, setCsvLoad] = useState(false)
  const [headers, setHeaders] = useState([])
  const [newData, setNewData] = useState([])
  const csvInstance = useRef()
  const { vendor_category } = useSelector(state => state.users.userPermissions);
  const TableColum = [
    { name: "#", field: "id", sortable: false },
    { name: "Vendor Category", field: "name", sortable: false },
    { name: "Ordering", field: "ordering", sortable: false },
    { name: "Status", field: "status", sortable: false },
    vendor_category?.edit ? { name: "Action", field: "", sortable: false } : null,
  ].filter(Boolean);

  let PageSize = 15
  useEffect(() => {
    showLoader()
    const params = { keyword: search, length: PageSize, start: currentPage }
    get(GET_VENDOR_CTEGORY_MASTER, { params: params })
      .then(res => {
        hideLoader()
        if (res) {
          settableData(res.data)
          setTotalItems(res.total)
        }
      })
      .catch(err => {
        hideLoader()
        toastr.error(err?.response?.data?.message)
      })
  }, [currentPage, search, reloadData]) // pass `value` as a dependency

  const ImportCSV = e => {
    e.preventDefault();
    let file = document.getElementById("file").files[0];
    if (file !== undefined) {
      const formData = new FormData();
      formData.append("master_name", "vendor_category");
      formData.append("master_data", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${EXPORT_MASTER}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then(response => {
          if (response.status) {
            setreloadData(prevState => !prevState); // Ensure toggling
            document.getElementById("file").value = "";
            if (response.data.message && response.data.status == false) {
              toastr.error(response.data.message);
            }
            if (response.data.data.msg && response.data.status == true) {
              toastr.success(response.data.data.msg)
            }
          }
        })
        .catch(err => {
          // toastr.error(err?.response?.data?.message);
        });
    } else {
      setFormMsg("Please select a valid excel sheet");
    }
  };
  const downloadData = () => {
    setCsvLoad(true)
    const params = { keyword: search, length: 100000, start: 1 }
    get(GET_VENDOR_CTEGORY_MASTER, { params: params })
      .then(response => {
        if (response.data.length > 0) {
          setHeaders([
            { label: "Category", key: "name" },
            { label: "Order", key: "ordering" },
            { label: "Status", key: "status" },
          ])
          setNewData(response.data)
          if (newData && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
              csvInstance.current.link.click()
              setNewData([])
            })
          }
        } else {
          toastr.error(response.message)
        }
        setCsvLoad(false)
      })
      .catch(err => {
        setCsvLoad(false)
        toastr.error(err?.response?.data?.message)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Vendor Category | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <h5 className="mb-3">Vendor Category</h5>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-4">
                      <Search
                        onSearch={value => {
                          setSearch(value)
                          setCurrentPage(1)
                        }}
                      />
                    </div>
                    <form
                      onSubmit={ImportCSV}
                      className="col-md-8 d-flex justify-content-end"
                    >
                      {vendor_category?.import &&<button
                        title="Export"
                        type="button"
                        style={{
                          minWidth: "123px",
                          display: "flex",
                          maxHeight: "37px",
                        }}
                        onClick={downloadVendorCategorySampleCsv}
                        className="btn btn-primary me-2 mb-2"
                      >
                        Sample Import
                      </button>}
                      <div className={"me-3"}>
                        <div className="input-group">
                        {vendor_category?.export &&<button
                            title="Export"
                            type="button"
                            onClick={downloadData}
                            style={{ minWidth: "110px", display: "flex" }}
                            className="btn btn-primary me-2 mb-2"
                          >
                            <i className="mdi mdi-file-export"></i>{" "}
                            {csv_load === false && <>Export CSV</>}
                            {csv_load === true && <>Please wait...</>}
                          </button>}
                          {headers.length > 0 && (
                            <CSVLink
                              data={newData}
                              headers={headers}
                              filename={`vendor_category.csv`}
                              target="_blank"
                              ref={csvInstance}
                            ></CSVLink>
                          )}
                          {vendor_category?.import &&<div className="d-flex"> <Input
                            type="file"
                            className="form-control"
                            name={"file"}
                            accept={".xlsx"}
                            id="file"
                            aria-describedby="file"
                            aria-label="Upload"
                            style={{ height: '36px',maxWidth: '250px' }}
                          />
                          <Button
                            color="primary"
                            type="submit"
                            id="inputGroupFileAddon04"
                            style={{ height: '36px' }}
                            className="ms-2"
                          >
                            Import
                          </Button>
                          </div>}
                          {vendor_category?.add &&<a
                            href="/vendor-category/add"
                            className="ms-2 btn btn-primary"
                            style={{ borderRadius: "5px", maxHeight: "37px" }}
                          >
                            <i className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}
                            Add
                          </a>}
                        </div>
                        {FormMsg !== "" && (
                          <span className="text-danger font-size-12">
                            {FormMsg}
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <TableHeader
                        headers={TableColum}
                        // onSorting={(field, order) =>
                        //     setSorting({field, order})
                        // }
                      />
                      <tbody className="">
                        {loader && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">Loading...</h4>
                            </th>
                          </tr>
                        )}
                        {!loader && tableData.length === 0 && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">No data found</h4>
                            </th>
                          </tr>
                        )}
                        {!loader &&
                          tableData.map((value, index) => (
                            <tr key={++index}>
                              <th scope="row">
                                {PageSize * (currentPage - 1) + (index + 1)}
                              </th>
                              <td>{value.name}</td>
                              <td>{value.ordering}</td>
                              <td>
                                {value.status === "1" ? (
                                  <span className="badge badge-pill bg-success font-size-13">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-pill bg-danger font-size-13">
                                    Inactive
                                  </span>
                                )}
                              </td>
                              {vendor_category?.edit &&<td>
                                <Link to={`/vendor-category/edit/${value.id}`}>
                                  <i className="bx bxs-edit-alt"></i>{" "}
                                </Link>
                              </td>}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
