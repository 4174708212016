import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiGetEmployeeSalary, apiGetSyncEmployeeSalary } from "services/EmployeeSalaryService"

export const SLICE_NAME="employeeSalary"

const initialState={
loading:false,
openSyncModal:false,
salaryRowData:[],
currentPage:1,
salarySearchQuery:"",
year:"",
salaryTotalData:0,
selectedMonth:null,
syncData:[],
yearSync:"",
monthSync:""
}

export const fetchEmployeeSalary=createAsyncThunk(
    `${SLICE_NAME}/fetchEmployeeSalary`,
    async({length, start , keyword ,month,year})=>{
        try{
            const response=await apiGetEmployeeSalary({length, start , keyword ,month,year})
            return response?.data
        } catch(error){
            throw error
        }
    }
)

export const fetchSyncEmployeeSalary=createAsyncThunk(
    `${SLICE_NAME}/fetchSyncEmployeeSalary`,
    async({month,year})=>{
        try{
            const response=await apiGetSyncEmployeeSalary({month,year})
            return response?.data
        } catch(error){
            throw error
        }
    }
)
const employeeSalarySlice=createSlice({
    name:SLICE_NAME,
    initialState,
    reducers:{
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setOpenSyncModal: (state, action) => {
            state.openSyncModal = action.payload
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setSalarySearchQuery: (state, action) => {
            state.salarySearchQuery = action.payload
        },
        setYear:(state,action)=>{
            state.year = action.payload
        },
        setSelectedMonth:(state,action)=> {
            state.selectedMonth = action.payload
        },
        setYearSync:(state,action)=>{
            state.yearSync = action.payload
        },
        setMonthSync:(state,action)=> {
            state.monthSync = action.payload
        }
    },
    extraReducers:builder=> {
        builder.addCase(fetchEmployeeSalary.pending,state=>{
            state.loading = true
        })
        builder.addCase(fetchEmployeeSalary.fulfilled, (state, action) => {
            state.loading = false
            state.salaryRowData = action?.payload?.data
            state.salaryTotalData = action?.payload?.total
        })
        builder.addCase(fetchEmployeeSalary.rejected,state=>{
            state.loading = false
        })
        builder.addCase(fetchSyncEmployeeSalary.pending,state=>{
            state.loading = true
        })
        builder.addCase(fetchSyncEmployeeSalary.fulfilled, (state, action) => {
            state.loading = false
            state.syncData = action?.payload?.data
        })
        builder.addCase(fetchSyncEmployeeSalary.rejected,state=>{
            state.loading = false
        })
    }
})

export const {setLoading,
            setOpenSyncModal,
            setCurrentPage,
            setSalarySearchQuery,
            setYear,
            setSelectedMonth,
            setYearSync,
            setMonthSync
        }= employeeSalarySlice.actions

export default employeeSalarySlice.reducer
