import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export const CommonModal = ({ isOpen, toggleModal, modalTitle, showFooter = true,modalBody, onSave}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader toggle={toggleModal}>{modalTitle}</ModalHeader>
      <ModalBody>
        {modalBody}
      </ModalBody>
      {showFooter && (
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>React</Button>
        <Button color="primary" onClick={onSave}>Approve</Button>
      </ModalFooter>
      )}
    </Modal>
  );
};
