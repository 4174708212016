import React, { useState, useEffect } from "react"
import { Button, Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import {
  editContactDetail,
  fetchProfileDetail,
  setEditContactDetail,
} from "store/onBoarding/onBordingSlice"
import { useDispatch, useSelector } from "react-redux"
import { getUserId } from "constants/CommonFunction"

const OnbordingContactForm = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId } = useSelector(state => state.onBoarding)

  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    mobile: profileDetails?.mobile,
                    mobile_2: profileDetails?.mobile_2
                      ? profileDetails?.mobile_2
                      : "",
                    email: profileDetails?.email,
                    work_email: profileDetails?.work_email
                      ? profileDetails?.work_email
                      : "",
                  }}
                  validationSchema={Yup.object({
                    mobile: Yup.string()
                      .min(10, "Min 10 digit")
                      .max(10, "only 10 digit allow")
                      .required("Required"),
                    email: Yup.string()
                      .email("Invalid email format")
                      .required("required"),
                    work_email: Yup.string().email("Invalid email format"),
                    mobile_2: Yup.string()
                      .min(10, "Min 10 digit")
                      .max(10, "only 10 digit allow"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const payload = {
                      ...values,
                      id: userId,
                    }
                    const response = await dispatch(editContactDetail(payload))
                    if (response.payload?.status === "success") {
                      toastr.success(response?.payload?.data?.data?.message)
                    } else {
                      toastr.error("Something went wrong.")
                    }
                    await dispatch(fetchProfileDetail(userId))
                    await dispatch(setEditContactDetail(false))
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    dirty,
                  }) => (
                    <Form>
                      <Row>
                        <Col md={12}>
                          <CardTitle className="header-text">
                            Contact Info
                          </CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="mobile">
                            Mobile<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            className={`form-control ${
                              touched.mobile && errors.mobile
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="mobile_2">
                            Alternate Mobile
                          </Label>
                          <Field
                            name="mobile_2"
                            placeholder="Alternate Mobile"
                            type="number"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="mobile_2"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="email">
                            Personal Email<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="email"
                            placeholder="Personal Email"
                            type="email"
                            className={`form-control ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="work_email">
                            Work Email
                          </Label>
                          <Field
                            name="work_email"
                            placeholder="Work Email"
                            type="email"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="work_email"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={() => {
                            dispatch(setEditContactDetail(false))
                          }}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <button
                          className="btn btn-md btn-primary"
                          type="submit"
                          disabled={isSubmitting || !dirty}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnbordingContactForm
