import { Field, Form, Formik } from "formik"
import React from "react"
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import toastr from "toastr"
import {
  createAttendanceApproval,
  fetchAttendanceApproval,
  toggleAnomalyRejactionForm,
} from "store/attendanceApproval/attendanceApprovalSlice"
import { useDispatch, useSelector } from "react-redux"
import { extractTime, formatDateFormat } from "constants/CommonFunction"

export default function RejectAnomaliesForm() {
  const dispatch = useDispatch()
  const {
    rejectAnomaliesForm,
    attendanceApprovalcurrentPage,
    approvalStatus,
    attendanceApprovalQuery,
    anomalyToAction
  } = useSelector(state => state.attendanceApproval)
  const toggle = () => {
    dispatch(toggleAnomalyRejactionForm({toggle:false, data:[]}))
  }
  const validationSchema = Yup.object().shape({
    originalTimeIn: Yup.string()
      .required('Original Time In is required'),
    originalTimeOut: Yup.string()
      .required('Original Time Out is required'),
    requestedTimeIn: Yup.string()
      .required('Requested Time In is required'),
    requestedTimeOut: Yup.string()
      .required('Requested Time Out is required'),
    reason: Yup.string().required('Reason is required'),
  });
  return (
    <Modal
      scrollable
      style={{ maxWidth: "400px" }}
      isOpen={rejectAnomaliesForm}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>Reject Anomalies</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                employeeName: `${anomalyToAction?.first_name ||""} ${anomalyToAction?.last_name ||""}`,
                employeeId: anomalyToAction?.emp_id,
                department: anomalyToAction?.department_master_name,
                manager: `${anomalyToAction?.reporting_manager_first_name ||""} ${anomalyToAction?.reporting_manager_last_name ||""}`,
                originalTimeIn: extractTime(anomalyToAction?.log_in_time),
                originalTimeOut: extractTime(anomalyToAction?.log_out_time),
                requestedTimeIn: anomalyToAction?.requested_in_time,
                requestedTimeOut: anomalyToAction?.requested_out_time,
                remark: anomalyToAction?.remark,
                reason: "",
                clock_in_location: (anomalyToAction?.clock_in_location ? anomalyToAction?.clock_in_location : "-"),
                clock_out_location:anomalyToAction?.clock_out_location ? anomalyToAction?.clock_out_location : "-",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const payload = {
                    status: 2,
                    reason: values.reason
                  }
                  const response = await dispatch(
                    createAttendanceApproval({ id: anomalyToAction?.id, data: payload })
                  )
                  if (response?.payload?.data?.data?.status) {
                    toastr.success('Anomaly Rejected')
                  } else {
                    toastr.error(response?.payload?.message || "Error!")
                  }
                } catch (error) {
                  toastr.error("Something Went Wrong")
                } finally {
                  dispatch(
                    fetchAttendanceApproval({
                      start: attendanceApprovalcurrentPage,
                      length: 10,
                      status: approvalStatus,
                      keyword: attendanceApprovalQuery,
                    })
                  )
                  dispatch(toggleAnomalyRejactionForm({toggle:false, data:[]}))
                }
                dispatch(toggleAnomalyRejactionForm({toggle:false, data:[]}))
              }}
            >
              {({ values, setFieldValue, isSubmitting, errors, touched }) => (
                <Form>
                  <Row>
                    <Col md="12">
                    <div className="d-flex mb-3 gap-3 justify-content-between">
                      <div className="d-flex mb-3 gap-3">
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                          src="http://innowrap.s3.ap-south-1.amazonaws.com/hrms/users/12/profile_photo/AOmvYWhKuw-1723205389.png"
                          alt="Emplyees Image"
                        />
                        <div className="">
                          <Label>{values.employeeName}</Label>
                          <p className="mb-0">ID: {values.employeeId}</p>
                        </div>
                      </div>
                      <span style={{ fontSize: "10px" }}>
                          {formatDateFormat(values.date)}
                        </span>
                    </div>
                    </Col>
                    <Col md="6">
                      <div className="">
                        <Label>Department</Label>
                        <p>{values.department}</p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="">
                        <Label>Manager</Label>
                        <p>{values.manager}</p>
                      </div>
                    </Col>

                    <Col md="12" className="mb-3">
                      <Label htmlFor="remark">
                        Remark
                      </Label>
                      <div>{anomalyToAction?.remark}</div>
                    </Col>
                    <Col md="6">
                    <div className="">
                      <Label> Clock In Location</Label>
                      <p>{anomalyToAction?.clock_in_location }</p>
                    </div>
                    </Col>
                    <Col md="6">
                    <div className="">
                      <Label>Clock Out Location</Label>
                      <p>{anomalyToAction?.clock_out_location}</p>
                    </div>
                    </Col>
                    <h6>Original Time</h6>
                    <Col md="6" sm="12" className="mb-3">
                      <Label htmlFor="originalTimeIn">
                        Time In<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="originalTimeIn"
                        type="time"
                        className={`form-control ${
                          touched.originalTimeIn && errors.originalTimeIn
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.originalTimeIn && errors.originalTimeIn && (
                        <div className="text-danger">
                          {errors.originalTimeIn}
                        </div>
                      )}
                    </Col>
                    <Col md="6" sm="12" className="mb-3">
                      <Label htmlFor="originalTimeOut">
                        Time Out<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="originalTimeOut"
                        type="time"
                        className={`form-control ${
                          touched.originalTimeOut && errors.originalTimeOut
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.originalTimeOut && errors.originalTimeOut && (
                        <div className="text-danger">
                          {errors.originalTimeOut}
                        </div>
                      )}
                    </Col>
                    <h6>Requested Time</h6>
                    <Col md="6" sm="12" className="mb-3">
                      <Label htmlFor="requestedTimeIn">
                        Time In<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="requestedTimeIn"
                        type="time"
                        className={`form-control ${
                          touched.requestedTimeIn && errors.requestedTimeIn
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.requestedTimeIn && errors.requestedTimeIn && (
                        <div className="text-danger">
                          {errors.requestedTimeIn}
                        </div>
                      )}
                    </Col>
                    <Col md="6" sm="12" className="mb-3">
                      <Label htmlFor="requestedTimeOut">
                        Time Out<span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="requestedTimeOut"
                        type="time"
                        className={`form-control ${
                          touched.requestedTimeOut && errors.requestedTimeOut
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.requestedTimeOut && errors.requestedTimeOut && (
                        <div className="text-danger">
                          {errors.requestedTimeOut}
                        </div>
                      )}
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="reason">
                        Reason To Reject<span className="text-danger">*</span>
                      </Label>
                      <Field
                        as="textarea"
                        name="reason"
                        placeholder="Write your reason"
                        rows="3"
                        className={`form-control ${
                          touched.reason && errors.reason ? "is-invalid" : ""
                        }`}
                      />
                      {touched.reason && errors.reason && (
                        <div className="text-danger">{errors.reason}</div>
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
