import React from "react"
import { AttendanceRule } from "./AttendanceRule"
import { AssignAttendance } from "./AssignAttendance"
import NavTabs from "components/NavTabs/NavTabs"
import { useDispatch, useSelector } from "react-redux"
import { setAttendanceActiveTab } from "store/attendanceRule/attendanceRuleSlice"
import "../../../MultiPageLink.css"
import { useLocation } from "react-router-dom"

export const AttendanceRuleList = () => {
  const dispatch = useDispatch()
  const { attendanceActiveTabs } = useSelector(state => state.attendanceRule)
const { attendance } = useSelector(state => state.users.userPermissions);
  const handleTabClick = tabName => {
    dispatch(setAttendanceActiveTab(tabName))
  }
  
  const location = useLocation()
  const activeTab = location.pathname
  return (
    <div className={activeTab.includes("/profile/view") ? "" : "page-content"}>
      <div
        className={activeTab.includes("/profile/view") ? "" : "container-fluid"}
      >
        <NavTabs />
        <div className="tab-container">
          <h5
            onClick={() => handleTabClick("attendance")}
            className={`tab-item fs-5 ${
              attendanceActiveTabs === "attendance" ? "active-tab" : ""
            }`}
          >
            Attendance
          </h5>
          {(attendance?.rules?.assign_rule && !location?.state?.row?.id) && <h5
            onClick={() => handleTabClick("assignAttendance")}
            className={`tab-item fs-5 ${
              attendanceActiveTabs === "assignAttendance" ? "active-tab" : ""
            }`}
          >
            Assign Attendance
          </h5>}
        </div>
        {attendanceActiveTabs == "attendance" ? (
          <AttendanceRule />
        ) : (
          <AssignAttendance />
        )}
      </div>
    </div>
  )
}
