export const DASHBOARD_REPORT = "/dashboard/get"
export const EXPORT_MASTER = "/Import_data/post"
// skills -----------
export const GET_SKILLS = "/skills/get";
export const ADD_SKILL = "/skills/post";
export const EDIT_SKILL = "/skills/put";
// currency master
export const GET_CURRENCY = "/salary_currency_master/get";
export const ADD_CURRENCY = "/salary_currency_master/post";
export const EDIT_CURRENCY = "/salary_currency_master/put";
// candidate source
export const GET_CANDIDATE_SOURCE = "/candidate_source/get"
export const ADD_CANDIDATE_SOURCE = "/candidate_source/post"
export const EDIT_CANDIDATE_SOURCE = "/candidate_source/put"
// client source
export const GET_CLIENT_SOURCE = "/client_source/get"
export const ADD_CLIENT_SOURCE = "/client_source/post"
export const EDIT_CLIENT_SOURCE = "/client_source/put"
//  qualifications
export const GET_QUALIFICATION = "/qualification/get"
export const ADD_QUALIFICATION = "/qualification/post"
export const EDIT_QUALIFICATION = "/qualification/put"
// client status master
export const GET_CLIENT_STATUS = "/client_status_master/get"
export const ADD_CLIENT_STATUS = "/client_status_master/post"
export const EDIT_CLIENT_STATUS = "/client_status_master/put"
// requirement status master
export const GET_REQUIREMENT_STATUS = "/requirement_status_master/get"
export const ADD_REQUIREMENT_STATUS = "/requirement_status_master/post"
export const EDIT_REQUIREMENT_STATUS = "/requirement_status_master/put"
// candidate status
export const GET_CANDIDATE_STATUS = "/candidate_status_master/get"
export const ADD_CANDIDATE_STATUS = "/candidate_status_master/post"
export const EDIT_CANDIDATE_STATUS = "/candidate_status_master/put"
//Department
export const GET_DEPARTMENT = "/department_master/get"
export const ADD_DEPARTMENT = "/department_master/post"
export const EDIT_DEPARTMENT = "/department_master/put"
//Sub Department
export const GET_SUB_DEPARTMENT = "/sub_department_master/get"
export const ADD_SUB_DEPARTMENT = "/sub_department_master/post"
export const EDIT_SUB_DEPARTMENT = "/sub_department_master/put"
// Employment Type
export const ADD_EMPLOYMENT_TYPE = "/employment_type/post"
export const EDIT_EMPLOYMENT_TYPE = "/employment_type/put"
//Designations
export const GET_DESIGNATIONS = "/designations/get"
export const ADD_DESIGNATIONS = "/designations/post"
export const EDIT_DESIGNATIONS = "/designations/put"
//Probation Period
export const GET_PROBATION_PERIOD = "/Probation_period/get"
export const ADD_PROBATION_PERIOD = "/Probation_period/post"
export const EDIT_PROBATION_PERIOD = "/Probation_period/put"
//Expenses
export const GET_EXPENSES = "/expenses/get"
export const ADD_EXPENSES = "/expenses/post"
export const EDIT_EXPENSES = "/expenses/put"
//Holiday
export const GET_HOLIDAY= "/holidays/get"
export const ADD_HOLIDAY = "/holidays/post"
export const EDIT_HOLIDAY = "/holidays/put"
//Country
export const GET_COUNTRY= "/location_country_master/get"
export const ADD_COUNTRY = "/location_country_master/post"
export const EDIT_COUNTRY = "/location_country_master/put"
//State
export const GET_STATE= "/location_state_master/get"
export const ADD_STATE = "/location_state_master/post"
export const EDIT_STATE = "/location_state_master/put"
//Office Location
export const GET_OFFICE_LOCATION= "/office_location/get"
export const ADD_OFFICE_LOCATION = "/office_location/post"
export const EDIT_OFFICE_LOCATION = "/office_location/put"
//City
export const GET_CITY= "/Location_master/get"
export const ADD_CITY = "/Location_master/post"
export const EDIT_CITY = "/Location_master/put"
// roles
export const GET_REQUIREMENT_ROLES = "/requirement_role_master/get"
export const ADD_REQUIREMENT_ROLES = "/requirement_role_master/post"
export const EDIT_REQUIREMENT_ROLES = "/requirement_role_master/put"
// requirement post
export const GET_REQUIREMENT = "/requirements/get"
export const ADD_REQUIREMENT = "/requirements/post"
export const EDIT_REQUIREMENT = "/requirements/put"
export const REQUIREMENT_DETAIL = "/requirements/job_detail"
export const ASSIGN_EMPLOYEE = "/requirements/assign_employee"
export const GET_OPENING_WITH_STAGES = "/requirements/opening_with_stages"
// manage boarding
export const JOB_BOARDING = "/requirements/job_boarding"
export const CANDIDATE_FAVOURITE = "/requirements/candidate_favourite"
export const JOB_STAGE_UPDATE = "/requirements/screening_levels"
export const JOB_STAGE_ADD = "/requirements/screening_levels_add"
// clients
export const GET_CLIENTS = "/clients/get"
export const ADD_CLIENTS = "/clients/post"
export const EDIT_CLIENTS = "/clients/put"
// Notice period
export const GET_NOTICE_PERIOD = "/notice_period/get"
export const ADD_NOTICE_PERIOD = "/notice_period/post"
export const EDIT_NOTICE_PERIOD = "/notice_period/put"
// get all master data (masters)
export const GET_ROLES = "/roles/get"
export const GET_EMPLOYMENT_TYPE = "/employment_type/get"
export const GET_ALL_MASTERS = "/master_data/get"
export const GET_BUSINESS_CATEGORY = "/master_data/business_category"
export const GET_SERVICES_MASTER = "/master_data/services_master"
export const GET_ES_ELEMENT = "/master_data/es_element"
export const GET_ACTIVE_COMPANY = "/clients/active"
// Mange candidate data
export const GET_CANDIDATE_DATA = "/candidate_data/talent_pool"
export const ADD_CANDIDATE_DATA = "/candidate_data/post"
export const EDIT_CANDIDATE_DATA = "/candidate_data/put"
export const ADD_CANDIDATE_RESUME = "/candidate_data/resume"
export const UPDATE_CANDIDATE_OPENING = "/candidate_data/assign_opening"
export const JOB_STAGE_MOVE = "/candidate_data/job_stage_move"
export const CANDIDATE_STAGE_MOVE = "/candidate_data/candidate_stage_move"
export const CANDIDATE_REJECT = "/candidate_data/candidate_reject"
export const CANDIDATE_HOLD = "/candidate_data/candidate_hold"
// Leads
export const GET_MEETING_LINK = "/leads/generate_meeting_link"
export const GET_GOOGLE_LOCATION = "/leads/google_place_api"
export const GET_EMPLOYEES_PERMISSION = "/leads/employees_permission"
export const GET_LEADS_PERMISSION = "/leads/leads_permission"
export const ADD_LEADS = "/leads/post"
export const GET_LEADS_DATA = "/leads/leads_list"
export const LEADS_DETAIL = "/leads/leads_detail"
export const EDIT_LEAD_DATA = "/leads/lead_edit"
export const ADD_NOTES_DATA = "/leads/notes"
export const ADD_ATTACHMENT_DATA = "/leads/attachment"
export const ADD_MEETING_DATA = "/leads/meeting"
export const ADD_CALL_LOG_DATA = "/leads/call_log"
export const ADD_CALL_SCHEDULE_DATA = "/leads/call_schedule"
export const GET_NOTES_DATA = "/leads/notes_list"
export const GET_MEETING_DATA = "/leads/meeting_list"
export const GET_CALL_LOG_DATA = "/leads/call_log_list"
export const GET_CALL_SCHEDULE_DATA = "/leads/call_schedule_list"
export const GET_ATTACHMENT_DATA = "/leads/attachment_list"
export const DELETE_NOTES_DATA = "/leads/notes_delete"
export const DELETE_MEETING_DATA = "/leads/meeting_delete"
export const DELETE_CALL_LOG_DATA = "/leads/call_log_delete"
export const DELETE_CALL_SCHEDULE_DATA = "/leads/call_schedule_delete"
export const DELETE_ATTACHMENT_DATA = "/leads/attachment_delete"
export const EDIT_NOTES_DATA = "/leads/notes_edit"
export const EDIT_MEETING_DATA = "/leads/meeting_edit"
export const EDIT_CALL_LOG_DATA = "/leads/call_log"
export const EDIT_CALL_SCHEDULE_DATA = "/leads/call_schedule"
export const GET_USER_NAME = "/leads/login_user_name"
export const ADD_REMINDER = "/leads/reminder"
export const GET_LEAD_DATE_VENUE = "/leads/lead_date_venue"
export const GET_REMINDER = "/leads/reminder"
export const LEAD_IMPORT = "/leads/import"
export const GET_LEADS_TODAYS_TO_DO_DATA = "/leads/leads_todays_to_do_list"
//lead source master
export const GET_LEADS_SOURCE_MASTER = "/Lead_source_master/get"
export const ADD_LEADS_SOURCE_MASTER = "/Lead_source_master/post"
export const EDIT_LEADS_SOURCE_MASTER = "/Lead_source_master/put"
//vendor
export const ADD_VENDOR = "/vendor/post"
export const EDIT_VENDOR = "/vendor/put"
export const GET_VENDOR_DATA = "/vendor/vendor_list"
export const VENDOR_IMPORT = "/vendor/import"
// assign target
export const TNA_IMPORT = "/Import_data/post"
//Brand
export const ADD_BRAND_MASTER = "/brand_master/add"
export const EDIT_BRAND_MASTER = "/brand_master/put"
export const GET_BRAND_MASTER = "/brand_master/get"
//Es_category
export const ADD_ES_CATEGORY_MASTER = "/ES_Category_Master/add"
export const EDIT_ES_CATEGORY_MASTER = "/ES_Category_Master/put"
export const GET_ES_CATEGORY_MASTER = "/ES_Category_Master/get"
//Es_elements master
export const ADD_ES_ELEMENTS_MASTER = "/ES_Elements_Master/add"
export const EDIT_ES_ELEMENTS_MASTER = "/ES_Elements_Master/put"
export const GET_ES_ELEMENTS_MASTER = "/ES_Elements_Master/get"
// element sheet
export const GET_ES_DATA = "/Element_sheet/get"
export const ADD_ELEMENT_SHEET = "/Element_sheet/post"
export const EDIT_ELEMENT_SHEET = "/Element_sheet/put"
export const GET_ES_TRANSACTION_DATA = "/ES_Transaction/get"
export const ES_TRANSACTION_IMPORT = "/ES_Transaction/import"
export const ES_TRANSACTION_EXPORT = "/ES_Transaction/export"
export const ADD_ES_TRANSACTION_DATA = "/ES_Transaction/post"
export const EDIT_ES_TRANSACTION_DATA = "/ES_Transaction/put"
//business category
export const ADD_BUSINESS_CATEGORY_MASTER = "/business_category_master/post"
export const EDIT_BUSINESS_CATEGORY_MASTER = "/business_category_master/put"
export const GET_BUSINESS_CATEGORY_MASTER = "/business_category_master/get"
//vendor category master
export const GET_VENDOR_CTEGORY_MASTER = "/Vendor_category_master/get"
export const ADD_VENDOR_CTEGORY_MASTER = "/Vendor_category_master/post"
export const EDIT_VENDOR_CTEGORY_MASTER = "/Vendor_category_master/put"
// Candidate feedback -----
export const GET_CANDIDATE_FEEDBACK = "/candidate_feedback/get"
export const GET_FEEDBACK_BY_ID = "/candidate_feedback/feedback_by_id"
export const MANAGE_CANDIDATE_FEEDBACK = "/candidate_feedback/manage_feedback"
export const DELETE_CANDIDATE_FEEDBACK = "/candidate_feedback/feedback_delete"
// Candidate interview manage
export const CNADIDATE_INTERVIEW_SECHUDLE = "/candidate_interview/post"
export const GET_CANDIDATE_INTERVIEW = "/candidate_interview/get"
// Opening Candidate 
export const CANDIDATE_DETAIL = "/candidate_data/candidate_detail"
export const GET_OPENING_CANDIDATES = "/candidate_data/candidate_assign_openings"
export const UPDATE_CANDIDATE_STAGES = "/candidate_data/stage_move_by_id"
// Opening Candidate notes
export const GET_CANDIDATE_OPENING_NOTE = "/requirement_candidate_notes/get"
export const ADD_CANDIDATE_OPENING_NOTE = "/requirement_candidate_notes/post"
export const EDIT_CANDIDATE_OPENING_NOTE = "/requirement_candidate_notes/put"
export const DELETE_CANDIDATE_OPENING_NOTE = "/requirement_candidate_notes/delete"
// Reports 
export const GET_CV_REPORT = "/reports/cv_report"
export const GET_PLACEMENT_REPORT = "/reports/candidates_placement"
export const GET_CANDIDATE_SOURCE_REPORT = "/reports/candidate_source"
export const GET_CLIENT_CANDIDATES_REPORT = "/reports/clients_candidate_assign"
export const GET_REQUIREMENT_ROLE_REPORT = "/reports/requirement_role"
export const GET_JOB_PREFORM = "/reports/job_performance"
export const GET_JOB_PREFORM_QUICK_VIEW = "/reports/quick_view"
export const GET_CLIENT_TRACKING = "/reports/client_tracking"
export const GET_CLIENT_QUICK_VIEW = "/reports/client_quick_view"
export const GET_TEAM_TRACKER = "/reports/team_tracker"
// Blogs 
export const GET_BLOGS = "/blogs/get"
export const ADD_BLOGS = "/blogs/post"
export const EDIT_BLOGS = "/blogs/edit"
// Manage requirement
export const REQUIREMENT_PUBLISH = "/requirements/publish"
export const REQUIREMENT_UNPUBLISH = "/requirements/unpublish"
// Download csv data 
export const EXPORT_JOB_BOARD = "/requirements/export_job_boarding"
// User forget password
export const FORGET_PASSWORD = "/auth/forget_password"
export const RESET_PASSWORD = "/auth/reset_password"
export const VERIFY_LINK = "/auth/verify_reset_link"
// User profile 
export const CHANGE_PASSWORD = "/users/change_password"
export const UPDATE_PROFILE = "/users/profile_update"
// Sidebar menu master 
export const GET_SIDEBAR_MENU_MASTER = "/menu_master/list"
// target_Achievement
export const GET_TARGET_ACHIEVEMENT = "/Target_Achievement/get"
export const EDIT_TARGET_ACHIEVEMENT = "/Target_Achievement/put"