import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from "./actionTypes"

import toastr from "toastr"
let api_url = process.env.REACT_APP_APIURL

export const loginUser = async (user, history) => {
  const response = await fetch(`${api_url}/auth/index_post`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emp_id: user["emp_id"],
      password: user["password"],
    }),
  })
  if (response) {
    const data2 = await response.json()
    if (response.status >= 400) {
      toastr.error(data2.status)
    }
    if (response.status == 200) {
      if (data2) {
        const { token, user_id } = data2
        const users = {
          token,
          user_id,
        }
        if (data2?.set_new_password == "1") {
          localStorage.setItem("user", JSON.stringify(users))
          location.href = "/reset-password"
        } else {
          toastr.success("Login successful.")
          localStorage.removeItem("user")
          localStorage.setItem("authUser", JSON.stringify(data2))
          if (user.remember_me) {
            console.log("Verify Rembember me option", user.remember_me)
            sessionStorage.setItem("emp_id", user.emp_id)
            sessionStorage.setItem("password", user.password)
          } else {
            localStorage.removeItem("emp_id")
            localStorage.removeItem("password")
          }
          // Determine where to redirect after login
          const queryParams = new URLSearchParams(history.location.search);
          const next = queryParams.get("next");
          const redirectTo = next ? decodeURIComponent(next) : "/profile/view";
          window.location.href = redirectTo;
        }
      } else {
        toastr.error(data2.message)
      }
    }
  } else {
    toastr.error("Something wrong!")
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}
