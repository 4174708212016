import React from 'react'
import { useSelector } from 'react-redux'
import OnboardingAdditionalDetailForm from '../OnbordingForms/OnboardingAdditionalDetailForm'
import OnboardingAdditionalDetail from '../OnboardingDetails/OnboardingAdditionalDetail'

export const OnboardingAdditionalDetailView = () => {
    const {editAdditionalDetail} = useSelector((state) => state.onBoarding)
  return (
    <div>
        {editAdditionalDetail ? (
            <OnboardingAdditionalDetailForm />
        ) : (
            <OnboardingAdditionalDetail/>
        )}
    </div>
  )
}
