import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

const LeaveNavTabs = () => {
  const { leaves } = useSelector(state => state.users.userPermissions);
  const location = useLocation();
  const history = useHistory();

  // Extract the current tab from the query parameters
  const params = new URLSearchParams(location.search);
  const currentTab = params.get("tab");

  // Valid tabs based on leaves permissions
  const validTabs = ["logs"];
  if (leaves?.approvals) validTabs.push("approval");
  if (leaves?.rules?.show_rules) validTabs.push("rules");
  if (leaves?.balance) validTabs.push("balance");

  // Determine the active tab based on the current query parameter
  const isActive = (tab) => {
    // If the current tab is valid, use it, otherwise default to "logs"
    const activeTab = validTabs.includes(currentTab) ? currentTab : "logs";
    return activeTab === tab;
  };

  // Function to handle tab clicks
  const handleTabClick = (tab) => {
    const currentState = history.location.state;  // Get the current state
    params.set("tab", tab); // Set the tab parameter
    history.replace({ pathname: location.pathname, state: currentState, search: params.toString() });
  };

  return (
    <div className="d-flex justify-content-between flex-wrap mb-2">
      <div className="d-flex multi-page-link flex-wrap">
        <a
          className={isActive("logs") ? "active" : ""}
          onClick={() => handleTabClick("logs")}
        >
          Logs
        </a>
        {leaves?.approvals && (
          <a
            className={isActive("approval") ? "active" : ""}
            onClick={() => handleTabClick("approval")}
          >
            Approval
          </a>
        )}
        {leaves?.rules?.show_rules && (
          <a
            className={isActive("rules") ? "active" : ""}
            onClick={() => handleTabClick("rules")}
          >
            Rules
          </a>
        )}
        {leaves?.balance && (
          <a
            className={isActive("balance") ? "active" : ""}
            onClick={() => handleTabClick("balance")}
          >
            Balance
          </a>
        )}
      </div>
    </div>
  );
};

export default LeaveNavTabs;
