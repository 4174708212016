import React ,{useEffect} from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,Input
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import {
  createAssetType,
  editEditAssetType,
  fetchAssetType,
  setOpenAssetModal,
} from "store/assetType/assetTypeSlice"

export const AssetTypeForm = () => {
  const dispatch = useDispatch()

  const { openAssetModal, assetTypeRowData, currentPage,assetSearchQuery}
  = useSelector(
    state => state.assetType
  )
  const validationSchema = Yup.object({
    name:Yup.string().required("Required"),
    ordering: Yup.string().required("Required"),})

  const toggle = async () => {
    await dispatch(setOpenAssetModal(false))
  }

  return (
    <Modal
    isOpen={openAssetModal}
    style={{ maxWidth: "400px" }}
    scrollable
    toggle={toggle}
    size="lg"
    centered>
      <ModalHeader toggle={toggle}>
        {assetTypeRowData?.id ? "Edit" : "Add"} Asset Type
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                name: assetTypeRowData?.name || "",
                ordering: assetTypeRowData?.ordering || "",
                status: assetTypeRowData?.status  ? assetTypeRowData?.status  : 0,
              }}
              validationSchema={validationSchema}
              onSubmit={async values=> {
                  try{
                const response = assetTypeRowData?.id
                  ? await dispatch(
                      editEditAssetType({
                        id: assetTypeRowData?.id,
                        data: values,
                      })
                    ).unwrap()
                  : await dispatch(createAssetType(values)).unwrap()
                  console.log("Response received:", response);
                  if (response.data?.status) {
                    toastr.success(response?.data?.message || "Operation successful")
                  } else {
                    toastr.error(response?.data?.message || "Something went wrong")
                  }
                } catch (error) {
                  console.error("Error during submission:", error); // Log the error for debugging

                  toastr.error(error.message ||"Something Went Wrong Please Try Later")
                } finally {
                  await dispatch(
                    fetchAssetType({ length: 10,
                       start: currentPage ,
                      //  status: 1,
                      keyword:assetSearchQuery?.length>0? assetSearchQuery: undefined,
                      })
                  )
                  await dispatch(setOpenAssetModal(false))
                }
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (

                <Form>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="name">
                        Name <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="name"
                        placeholder="Name"
                        type="text"
                        className={`form-control ${
                        touched.name && errors.name ? "is-invalid" : ""
                        }`}
                        />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="ordering">
                        Ordering <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="ordering"
                        placeholder="Ordering"
                        type="text"
                        className={`form-control ${
                          touched.ordering && errors.ordering ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="ordering"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                    <div className="form-check form-switch form-switch-md mb-2 d-flex ">
                    <Input
                    name="status"
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={
                    values?.status === 0 ? false : true
                    }
                    onChange={e => {
                    setFieldValue(
                    "status",
                    e.target.checked ? 1 : 0
                    )
                    }}
                    />
                    <Label
                       className="mx-2 fs-6 mb-0"
                      htmlFor="status"
                    >
                     Status
                    </Label>
                    </div>
                     </Col>
                   </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >

                      {assetTypeRowData?.id ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
