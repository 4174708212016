import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/pcl_favicon.png"
import { useDispatch } from "react-redux"

import toastr from "toastr"
import {
  resetPassword,
  verifyToken,
} from "store/forgetPassword/forgetPasswordSlice"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const ResetPassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { token } = useParams()
  const [isTokenValid, setIsTokenValid] = useState(null)

  useEffect(() => {
    const tokenVerify = async () => {
      try {
        const response = await dispatch(verifyToken({ token }))
        const { status, message, data } = response.payload
        if (status === "success" && data?.status) {
          toastr.success(
            data?.msg || "Link is valid. You can now reset your password."
          )
          setIsTokenValid(true)
        } else {
          toastr.error("Invalid Link")
          setIsTokenValid(false)
          history.push("/login")
        }
      } catch (error) {
        toastr.error("An error occurred while verifying the token.")
        setIsTokenValid(false)
        history.push("/login")
      }
    }

    tokenVerify()
  }, [token, dispatch, location])

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirm_password: false,
  })

  const togglePasswordVisibility = field => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }))
  }

  const handleSubmit = async values => {
    try {
      const payload = {
        ...values,
        token: token,
      }
      const response = await dispatch(resetPassword(payload)).unwrap()
      if (response.status) {
        toastr.success(response.msg || "Password reset successfully.")
        history.push("/login")
      } else {
        toastr.error("Invalid token")
        history.push("/login")
      }
    } catch (error) {
      toastr.error("Something Went Wrong")
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <i className="bx bx-home h2" />
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Reset password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div
                      className="avatar-lg mb-4"
                      style={{ marginTop: "-40px" }}
                    >
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="38"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    {isTokenValid && (
                      <Formik
                        initialValues={{
                          password: "",
                          confirm_password: "",
                        }}
                        validationSchema={Yup.object({
                          password: Yup.string()
                            .required("Required")
                            .min(8, "Must be at least 8 characters")
                            .matches(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                              "Must include upper, lower, number, and symbol"
                            ),
                          confirm_password: Yup.string()
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            )
                            .required("Required"),
                        })}
                        onSubmit={handleSubmit}
                      >
                        {({ isSubmitting, errors, touched }) => (
                          <Form>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                New Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={
                                    passwordVisibility.password
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="New Password"
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  onClick={() =>
                                    togglePasswordVisibility("password")
                                  }
                                >
                                  <i
                                    className={
                                      passwordVisibility.password
                                        ? "mdi mdi-eye-outline"
                                        : "mdi mdi-eye-off-outline"
                                    }
                                  ></i>
                                </button>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <Label
                                for="confirm_password"
                                className="form-label"
                              >
                                Confirm Password
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="confirm_password"
                                  type={
                                    passwordVisibility.confirm_password
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="Confirm Password"
                                  autoComplete="true"
                                  className={
                                    "form-control" +
                                    (errors.confirm_password &&
                                    touched.confirm_password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  onClick={() =>
                                    togglePasswordVisibility("confirm_password")
                                  }
                                >
                                  <i
                                    className={
                                      passwordVisibility.confirm_password
                                        ? "mdi mdi-eye-outline"
                                        : "mdi mdi-eye-off-outline"
                                    }
                                  ></i>
                                </button>
                                <ErrorMessage
                                  name="confirm_password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary w-md btn-block"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Please wait..." : "Reset"}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  {process.env.REACT_APP_PROJECTNAME}
                  <i className="mdi mdi-heart text-danger ms-2 me-2" /> by
                  Innowrap
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
