import { combineReducers } from "redux"
import layoutReducer from "./layout/reducer"
import LoginSlice from "./login/LoginSlice"
import registerReducer from "./auth/register/reducer"
import forgetPasswordSlice from "./forgetPassword/forgetPasswordSlice"
import profileReducer from "./auth/profile/reducer"
import onBordingReducer from "./onBoarding/onBordingSlice"
import workDetailReducer from "./workDetail/workDetailSlice"
import resignationInfoReducer from "./resignationInfo/ResignationInfoSlice"
import otherDocumentReducer from "./otherDocument/otherDocumentSlice"
import deductionRewarReducer from "./deductionReward/deductionRewardSlice"
import expenseRuleSlice from "./expenseRule/expenseRuleSlice"
import expenseRewardSlice from "./expenseReward/expenseRewardSlice"
import expenseDocumentSlice from "./expenseReward/expenseDocumentSlice"
import deductionRuleReducer from "./deductionRule/deductionRuleSlice"
import usersSlice from "./users/usersSlice"
import masterSlice from "./master/masterSlice"
import permissionSlice from "./permission/permissionSlice"
import attendanceRuleSlice from "./attendanceRule/attendanceRuleSlice"
import attendanceLogSlice from "./attendanceLogs/attendanceLogSlice"
import attendanceApprovalSlice from "./attendanceApproval/attendanceApprovalSlice"
import leaveApprovalSlice from "./leaveApproval/leaveApprovalSlice"
import leaveRuleSlice from "./leaveRule/leaveRuleSlice"
import leaveLogSlice from "./leaveLogs/leaveLogSlice"
import companyOverviewSlice from "./companyOverview/companyOverviewSlice"
import crmSlice from "./crm/crmSlice"
import employeeSalarySlice from "./employeeSalary/employeeSalarySlice"
import employeeAccessorySlice from "./employeeAccessory/employeeAccessorySlice"
import assetTypeSlice from "./assetType/assetTypeSlice"

const rootReducer = combineReducers({
  Layout: layoutReducer,
  Login: LoginSlice,
  Account: registerReducer,
  users: usersSlice,
  crm: crmSlice,
  forgetPassword: forgetPasswordSlice,
  master: masterSlice,
  Profile: profileReducer,
  onBoarding: onBordingReducer,
  workDetail: workDetailReducer,
  resignationInfo: resignationInfoReducer,
  otherDocument: otherDocumentReducer,
  permission: permissionSlice,
  deductionRule: deductionRuleReducer,
  deductionReward: deductionRewarReducer,
  expenseRule: expenseRuleSlice,
  expenseReward: expenseRewardSlice,
  expenseDocument: expenseDocumentSlice,
  attendanceRule: attendanceRuleSlice,
  attendanceLog: attendanceLogSlice,
  attendanceApproval: attendanceApprovalSlice,
  leaveRule: leaveRuleSlice,
  leaveApproval: leaveApprovalSlice,
  leaveLog: leaveLogSlice,
  companyOverview: companyOverviewSlice,
  employeeSalary:employeeSalarySlice,
  employeeAccessory:employeeAccessorySlice,
  assetType:assetTypeSlice,
})

export default rootReducer
