import React from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import {
  convertTime,
  getInitialWorkingDays,
  accrual_freq,
  accrual_credit,
} from "constants/Constants"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { createLeaveRule, editLeaveRule } from "store/leaveRule/leaveRuleSlice"
import { useHistory } from "react-router-dom"

export const LeaveRuleForm = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const {
    id,
    name,
    desc,
    leaves_allowed,
    weekend_between_leave,
    holiday_between_leave,
    accrual_basis,
    accrual_frequency,
    accrual_credit_on,
    allowed_in_probation,
    allowed_in_notice_period,
    is_encashment_allowed,
    encashment_max_num,
    is_carry_forward_allowed,
    carry_forward_max_number,
    status,
  } = location?.state?.row ? location?.state?.row : ""

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <MetaTags>
            <title>
              Manage User-boarding | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={{
                      name: name ? name : "",
                      desc: desc ? desc : "",
                      leaves_allowed: leaves_allowed ? leaves_allowed : "",
                      weekend_between_leave: weekend_between_leave
                        ? weekend_between_leave
                        : 0,
                      holiday_between_leave: holiday_between_leave
                        ? holiday_between_leave
                        : 0,
                      accrual_basis: accrual_basis ? accrual_basis : 0,
                      accrual_frequency: accrual_frequency
                        ? accrual_frequency
                        : "",
                      accrual_credit_on: accrual_credit_on
                        ? accrual_credit_on
                        : "",
                      allowed_in_probation: allowed_in_probation
                        ? allowed_in_probation
                        : 0,
                      allowed_in_notice_period: allowed_in_notice_period
                        ? allowed_in_notice_period
                        : 0,
                      is_encashment_allowed: is_encashment_allowed
                        ? is_encashment_allowed
                        : 0,
                      encashment_max_num: encashment_max_num
                        ? encashment_max_num
                        : "",
                      is_carry_forward_allowed: is_carry_forward_allowed
                        ? is_carry_forward_allowed
                        : 0,
                      carry_forward_max_number: carry_forward_max_number
                        ? carry_forward_max_number
                        : "",
                      status: status ? status : 0,
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required("Name is required"),
                      desc:Yup.string().required("Description is required"),
                      leaves_allowed: Yup.number().max(
                        99,
                        "must be 2 digits or less"
                      ),
                      leaves_allowed: Yup.string().required("Required"),
                      encashment_max_num: Yup.number().when(
                        "is_encashment_allowed",
                        {
                          is: 1, // when is_encashment_allowed is 1
                          then: Yup.number()
                            .required("Required")
                            .max(99, "Must be 2 digits or less"),
                          otherwise: Yup.number().notRequired(),
                        }
                      ),
                      is_carry_forward_allowed: Yup.number().max(
                        99,
                        "must be 2 digits or less"
                      ),
                      carry_forward_max_number: Yup.number().when(
                        "is_carry_forward_allowed",
                        {
                          is: 1, // when is_carry_forward_allowed is 1
                          then: Yup.number()
                            .required("Required")
                            .max(99, "Must be 2 digits or less"),
                          otherwise: Yup.number().notRequired(),
                        }
                      ),
                      accrual_frequency: Yup.string().when("accrual_basis", {
                        is: 1,
                        then: Yup.string().required(
                          "Accrual Frequency is required"
                        ),
                        otherwise: Yup.string().notRequired(),
                      }),
                      accrual_credit_on: Yup.string().when("accrual_basis", {
                        is: 1,
                        then: Yup.string().required(
                          "Accrual Period is required"
                        ),
                        otherwise: Yup.string().notRequired(),
                      }),
                    })}
                    onSubmit={async values => {
                      try {
                        const response = id
                          ? await dispatch(
                              editLeaveRule({ id: id, data: values })
                            )
                          : await dispatch(createLeaveRule(values))
                        if (response?.payload?.status == "success") {
                          toastr.success(response?.payload?.data?.data?.message)
                          history.push("/user-leave-logs?tab=rules")
                        } else {
                          toastr.error(response?.payload?.message)
                        }
                      } catch (error) {
                        toastr.error("Something went wrong.")
                      }
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      isSubmitting,
                      errors,
                      touched,
                      dirty,
                    }) => (
                      <Form>
                        <Row>
                          <Col md={12}>
                            <CardTitle className="header-text">
                              Leave Details
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="4" className="mb-3">
                            <Label className="labels" htmlFor="name">
                              Name<span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="5" className="mb-3">
                            <Label className="labels" htmlFor="desc">
                              Description<span className="text-danger">*</span>
                            </Label>
                            <Field
                              as="textarea"
                              name="desc"
                              placeholder="Description"
                              className="form-control"
                            />
                             <ErrorMessage
                              name="desc"
                              component="div"
                              className="text-danger"
                              />
                          </Col>
                          <Col md={12}>
                            <CardTitle className="header-text">
                              Leaves Count
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Row>
                            <Col md="4" className="mb-3">
                              <div className="form-check">
                                <Input
                                  name="weekend_between_leave"
                                  type="checkbox"
                                  className="form-check-input"
                                  defaultChecked={
                                    values?.weekend_between_leave == 1
                                  }
                                  onChange={e => {
                                    setFieldValue(
                                      "weekend_between_leave",
                                      e.target.checked ? 1 : 0
                                    )
                                  }}
                                />
                                <Label
                                  className="labels ms-2"
                                  htmlFor="weekend_between_leave"
                                >
                                  Weekends between leave - count as leave
                                </Label>
                              </div>
                            </Col>
                            <Col md="4" className="mb-3">
                              <div className="form-check">
                                <Input
                                  name="holiday_between_leave"
                                  type="checkbox"
                                  className="form-check-input"
                                  defaultChecked={
                                    values?.holiday_between_leave == 1
                                  }
                                  onChange={e => {
                                    setFieldValue(
                                      "holiday_between_leave",
                                      e.target.checked ? 1 : 0
                                    )
                                  }}
                                />
                                <Label
                                  className="labels ms-2"
                                  htmlFor="holiday_between_leave"
                                >
                                  Holiday between leave - count as leave
                                </Label>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="mb-3">
                              <Label
                                className="labels ms-2"
                                htmlFor="leaves_allowed"
                              >
                                Leaves Allowed in a Year
                              </Label>
                              <Field
                                type="number"
                                name="leaves_allowed"
                                placeholder="Leaves Allowed in a Year"
                                className={`form-control ${
                                  touched.leaves_allowed &&
                                  errors.leaves_allowed
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                            </Col>
                          </Row>

                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Accrual
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Input
                              name="accrual_basis"
                              type="checkbox"
                              className="form-check-input"
                              // defaultChecked={
                              // values?.accrual_basis === 1 ? true : false
                              // }
                              defaultChecked={values?.accrual_basis == 1}
                              onChange={e => {
                                setFieldValue(
                                  "accrual_basis",
                                  e.target.checked ? 1 : 0
                                )
                                if (!isChecked) {
                                  setFieldValue("accrual_frequency", "")
                                  setFieldValue("accrual_credit_on", "")
                                }
                              }}
                            />
                            <Label
                              className="labels ms-2"
                              htmlFor="in_time_grace_period"
                            >
                              Creditable on Accrual basis
                            </Label>
                          </Col>
                          {values.accrual_basis == 1 && (
                            <>
                              <Col md="3" className="mb-3">
                                <Label
                                  className="labels"
                                  htmlFor="accrual_frequency"
                                >
                                  Accural Frequency{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="accrual_frequency"
                                  options={accrual_freq}
                                  value={accrual_freq?.find(
                                    option =>
                                      option?.value == values?.accrual_frequency
                                  )}
                                  classNamePrefix="select"
                                  onChange={selectedOption =>
                                    setFieldValue(
                                      "accrual_frequency",
                                      Number(selectedOption.value)
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name="accrual_frequency"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                              <Col md="3" className="mb-3">
                                <Label
                                  className="labels"
                                  htmlFor="out_time_penalty_value"
                                >
                                  Accural Period
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="accrual_credit_on"
                                  options={accrual_credit}
                                  value={accrual_credit?.find(
                                    option =>
                                      option?.value == values?.accrual_credit_on
                                  )}
                                  classNamePrefix="select"
                                  onChange={selectedOption =>
                                    setFieldValue(
                                      "accrual_credit_on",
                                      Number(selectedOption.value)
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name="accrual_credit_on"
                                  component="div"
                                  className="text-danger"
                                />
                              </Col>
                            </>
                          )}
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Applicability
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Input
                              name="allowed_in_probation"
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked={values?.allowed_in_probation == 1}
                              onChange={e => {
                                setFieldValue(
                                  "allowed_in_probation",
                                  e.target.checked ? 1 : 0
                                )
                              }}
                            />
                            <Label
                              className="labels ms-2"
                              htmlFor="allowed_in_probation"
                            >
                              Allowed under probation
                            </Label>
                            <ErrorMessage
                              name="allowed_in_probation"
                              component="div"
                              className="text-danger"
                            />
                          </Col>

                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Leave Encash
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Input
                              name="is_encashment_allowed"
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked={
                                values?.is_encashment_allowed == 1
                              }
                              onChange={e => {
                                setFieldValue(
                                  "is_encashment_allowed",
                                  e.target.checked ? 1 : 0
                                )
                              }}
                            />
                            <Label
                              className="labels ms-2"
                              htmlFor="is_encashment_allowed"
                            >
                              Leave Encash Enabled
                            </Label>
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="encashment_max_num"
                            >
                              Max leaves encash allowed
                            </Label>
                            <Field
                              type="number"
                              name="encashment_max_num"
                              placeholder="Max leaves encash allowed"
                              className={`form-control ${
                                touched.encashment_max_num &&
                                errors.encashment_max_num
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="encashment_max_num"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="header-text">
                              Carry Forward
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Input
                              name="is_carry_forward_allowed"
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked={
                                values?.is_carry_forward_allowed == 1
                              }
                              onChange={e => {
                                setFieldValue(
                                  "is_carry_forward_allowed",
                                  e.target.checked ? 1 : 0
                                )
                              }}
                            />
                            <Label
                              className="labels ms-2"
                              htmlFor="is_carry_forward_allowed"
                            >
                              Carry forward enabled
                            </Label>
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="carry_forward_max_number"
                            >
                              Max leaves allowed to carry forward
                            </Label>
                            <Field
                              type="number"
                              name="carry_forward_max_number"
                              placeholder="Max leaves allowed to carry forward"
                              className={`form-control ${
                                touched.carry_forward_max_number &&
                                errors.carry_forward_max_number
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="carry_forward_max_number"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md={12} className="mb-1">
                            <div className="form-check form-switch form-switch-md mb-2 d-flex ">
                              <Input
                                name="status"
                                type="checkbox"
                                className="form-check-input"
                                defaultChecked={
                                  values?.status === 0 ? false : true
                                }
                                onChange={e => {
                                  setFieldValue(
                                    "status",
                                    e.target.checked ? 1 : 0
                                  )
                                }}
                              />
                              <Label
                                className="mx-2 fs-6 mb-0"
                                htmlFor="status"
                              >
                                Status
                              </Label>
                            </div>
                          </Col>
                        </Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                          <Link to="/user-leave-rules">
                            <button
                              className="d-flex align-items-center btn btn-md btn-outline-secondary"
                              type="button"
                            >
                              <i className="dripicons-cross mx-1 d-flex" />{" "}
                              Cancel
                            </button>
                          </Link>
                          <button
                            className="btn btn-md btn-primary"
                            type="submit"
                            disabled={isSubmitting || !dirty}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
