import { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { EXPORT_MASTER } from 'helpers/api_url_helper';
import accessToken from 'helpers/jwt-token-access/accessToken';
import * as XLSX from 'xlsx';

const useImportXlxs = () => {

    const downloadExcel = (data, sheet_name) => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        
        // Convert the array of objects to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
        
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, sheet_name);
        
        // Generate the XLSX file and trigger the download
        XLSX.writeFile(workbook, `${sheet_name}_rejected_data.xlsx`);
    };

    const importXlxs = async (masterName, file) => {

        if (file !== undefined) {
            const formData = new FormData();
            formData.append("master_name", masterName);
            formData.append("master_data", file);

            try {
                const response = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_APIURL}${EXPORT_MASTER}`,
                    data: formData,
                    headers: {
                        Authorization: accessToken,
                    },
                });
                console.log(response)
                if (response.status === 200) {
                        if (response?.data?.data?.msg) {
                        toastr?.success(response?.data?.data?.msg);
                        }else{
                            toastr?.error(response?.data?.message)
                        }
                        if(response?.data?.data?.result?.length>0){
                        downloadExcel(response?.data?.data?.result, response?.data?.data?.sheet_name);
                        }
                    }else{
                        toastr?.error(response?.data?.message);
                    }
            } catch (err) {
                console.error(err);
                toastr.error(err?.response?.data?.message || 'An error occurred during import.');
            }
        } else {
            console.log("Please select a valid Excel sheet");
        }
    };

    return { importXlxs };
};

export default useImportXlxs;