import React from "react"
import { AttendanceEmployeeLogs } from "./AttendanceEmployeeLogs"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import AttendanceEmployeeFrom from "./AttendanceEmployeeFrom"

export const AttendanceEmployeeList = () => {
  return (
    <div className="page-content">
      <Breadcrumbs
        title="Attendance"
        path="/user-attendance-logs"
        breadcrumbItem="Attendance Logs"
      />
      <AttendanceEmployeeFrom />
      <AttendanceEmployeeLogs />
    </div>
  )
}
