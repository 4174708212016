import React from 'react'
import { ResignationInfoFrom } from './ResignationInfoFrom'
import { ResignationInfo } from './ResignationInfo'

export const ResignationInfoList = () => {
  return (
    <div>
        <ResignationInfoFrom/>
        <ResignationInfo/>
    </div>
  )
}

