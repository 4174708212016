import React from "react";
import { Row, Col, Input, Label, Modal, Button } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { get } from "../../helpers/api_helper";
import { ADD_CALL_SCHEDULE_DATA, GET_LEADS_PERMISSION,GET_USER_NAME } from "../../helpers/api_url_helper";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr from "toastr";
import CreatableSelect from 'react-select/creatable';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class AddCommonCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      lead_id: "",
      call_schedule_type: null,
      call_schedule_purpose: '',
      call_schedule_to: '',
      call_schedule_status: 'Scheduled',
      call_schedule_start_date_time: '',
      call_schedule_end_date_time: '',
      call_schedule_conducted_by: '',
      call_schedule_agenda: '',
      submit: false,
      isShowing: false,
      handleResponse: null,
      formSubmitSuccess: false,
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
  }

  componentDidMount() {
    this.loadMaster();
  }

  loadMaster = () => {      
      get(GET_LEADS_PERMISSION, {  })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          this.setState({ leads: data});
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
      
    get(GET_USER_NAME, { params: {} })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          this.setState({ call_schedule_conducted_by: data });
        }
      })
      .catch((err) => {
        toastr.error(err);
      });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  pastDateTime = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  
  handleStartTimeChange(event) {
    const call_schedule_start_date_time = event.target.value;

    // Set the min attribute of the end time input dynamically
    const call_schedule_end_date_time = document.getElementsByName('call_schedule_end_date_time')[0];
    call_schedule_end_date_time.min = call_schedule_start_date_time;
  }

  handleLeadChange = (selectedOption) => {
    this.setState({
      lead_id: selectedOption,
      call_schedule_to: selectedOption ? selectedOption.label : "",
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = new FormData();      
      formData.append("call_schedule_purpose", this.state.call_schedule_purpose);
      formData.append("call_schedule_agenda", this.state.call_schedule_agenda);
      formData.append("call_schedule_start_date_time", this.state.call_schedule_start_date_time);
      formData.append("call_schedule_end_date_time", this.state.call_schedule_end_date_time);
      formData.append("call_schedule_to", this.state.call_schedule_to);
      formData.append("call_schedule_conducted_by", this.state.call_schedule_conducted_by);
      formData.append("call_schedule_status", this.state.call_schedule_status);
      formData.append(
        "lead_id",
        this.state.lead_id.value !== undefined && this.state.lead_id.value
      );
      formData.append(
        "call_schedule_type",
        this.state.call_schedule_type.value !== undefined && this.state.call_schedule_type.value
      );
      this.setState({ submit: true });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_APIURL}${ADD_CALL_SCHEDULE_DATA}`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      })
        .then((response) => {
          if (response.status) {
            this.setState({ submit: false });
            this.setState({
              isShowing: false,
            });
            toastr.success("Call Scheduled successfully.");
            this.props.hide(false);
            this.setState({
              lead_id: "",
              call_schedule_type: null,
              call_schedule_purpose: '',
              call_schedule_to: '',
              call_schedule_status: 'Scheduled',
              call_schedule_start_date_time: '',
              call_schedule_end_date_time: '',
              call_schedule_conducted_by: '',
              call_schedule_agenda: '',
            });
            this.props.reloadBoard(true);
          }
        })
        .catch((err) => {
          toastr.error(err.message);
          this.setState({ submit: false });
          alert(err.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    const minDateTime = this.pastDateTime();
    const { leads, formSubmitSuccess, call_schedule_to, call_schedule_conducted_by } = this.state;
    return (
      <React.Fragment>
        <Modal size="lg" isOpen={this.props.isShowing} toggle={this.props.hide} centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Schedule Call</h5>
            <button
              id="close_model"
              type="button"
              onClick={this.props.hide}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {formSubmitSuccess === false && (
              <form onSubmit={this.handleFormSubmit}>
                 <Row>
                  <Col md="6" className="mb-3">
                    <label className="control-label">
                      Lead<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={leads}
                      classNamePrefix="select2-selection"
                      onChange={this.handleLeadChange}
                    />
                    {this.validator.message("lead_id", this.state.lead_id, "required")}
                  </Col>
                   <Col md="8" className="mb-3">
                       <Label htmlFor="call_schedule_purpose">Call Purpose<span className="text-danger">*</span></Label>
                       <Input name="call_schedule_purpose" onChange={this.handleInput} placeholder="Type Call Purpose" type="text" className={"form-control"}
                       />
                       {this.validator.message("call_schedule_purpose", this.state.call_schedule_purpose, "required")}
                   </Col>
                   <Col md="4" className="mb-3">
                       <Label htmlFor="call_schedule_to">Call To<span className="text-danger">*</span></Label>
                       <Input name="call_schedule_to" onChange={this.handleInput}  value={call_schedule_to} type="text" className={"form-control"}
                       disabled />
                       {this.validator.message("call_schedule_to", this.state.call_schedule_to, "required")}
                   </Col>
                   <Col md="4" className="mb-3">
                       <label className="control-label">Call Type<span className="text-danger">*</span></label>
                       <Select  name="call_schedule_type" isMulti={false} options={[{ label: "Outbound", value: 1 }]} classNamePrefix="select2-selection" onChange={e => this.setState({ call_schedule_type: e })} />
                       {this.validator.message("call_schedule_type", this.state.call_schedule_type, "required")}
                   </Col>
                   <Col md="4" className="mb-3">
                       <Label htmlFor="call_schedule_status">Call Status<span className="text-danger">*</span></Label>
                       <Input name="call_schedule_status" value={this.state.call_schedule_status} type="text" className={"form-control"}
                           disabled />
                       {this.validator.message("call_schedule_status", this.state.call_schedule_status, "required")}
                   </Col>
                   <Col md="4" className="mb-3">
                       <Label htmlFor="call_schedule_conducted_by">Conducted By<span className="text-danger">*</span></Label>
                       <Input name="call_schedule_conducted_by" onChange={this.handleInput}  value={call_schedule_conducted_by}   type="text" className={"form-control"}
                           disabled />
                       {this.validator.message("call_schedule_conducted_by", this.state.call_schedule_conducted_by, "required")}
                   </Col>
                   <Col md="6" className="mb-3">
                       <Label htmlFor="call_schedule_start_date_time">
                           Start Date & Time<span className="text-danger">*</span>
                       </Label>
                       <Input
                           name="call_schedule_start_date_time"
                           type="datetime-local"
                           className="form-control"
                           min={minDateTime} 
                           onChange={e => {this.setState({ call_schedule_start_date_time: e.target.value }); this.handleStartTimeChange4(e); }}
                       />
                       {this.validator.message("call_schedule_start_date_time", this.state.call_schedule_start_date_time, "required")}
                   </Col>
                   <Col md="6" className="mb-3">
                       <Label htmlFor="call_schedule_end_date_time">
                           End  Date & Time<span className="text-danger">*</span>
                       </Label>
                       <Input
                           name="call_schedule_end_date_time"
                           type="datetime-local"
                           className="form-control"
                           min={this.state.call_schedule_start_date_time}
                           onChange={e => this.setState({ call_schedule_end_date_time: e.target.value })}
                       />
                       {this.validator.message("call_schedule_end_date_time", this.state.call_schedule_end_date_time, "required")}
                   </Col> 
                    <Col md="12" className="mb-3">
                       <Label htmlFor="call_schedule_agenda">
                       Call Agenda<span className="text-danger">*</span>
                       </Label>
                       <CKEditor
                           editor={ClassicEditor}
                           data={this.state.call_schedule_agenda}
                           onChange={(event, editor) => {
                               const data = editor.getData();
                               this.setState({call_schedule_agenda: data})
                           }}
                           name="call_schedule_agenda"
                       />
                       {this.validator.message("call_schedule_agenda", this.state.call_schedule_agenda, "required")}
                   </Col>
               </Row>
                <div className="d-flex flex-wrap gap-2 justify-content-end">
                  <Button color="primary" type="submit" disabled={this.state.submit}>
                    {this.state.submit === true ? "Please wait..." : "Submit Data"}
                  </Button>
                </div>
              </form>
            )}
            {formSubmitSuccess === true && (
              <div className="text-center mb-4">
                <div className="avatar-md mx-auto mb-4">
                  <div className="avatar-title bg-light rounded-circle text-primary h1">
                    <i className="bx bx-user-check"></i>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <h4 className="text-primary">Success !</h4>
                    <p className="text-muted font-size-14 mb-4">Call Schedule add successful.</p>
                    <button
                      type="button"
                      id="button-addon2"
                      onClick={(e) => this.setState({ formSubmitSuccess: false })}
                      className="btn btn-primary"
                    >
                      Add New Call
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

AddCommonCall.propTypes = {
  attribs: PropTypes.any,
  isShowing: PropTypes.any,
  hide: PropTypes.any,
  reloadBoard: PropTypes.func,
};

export default AddCommonCall;
