import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Formik, ErrorMessage, Form } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { mappingTypeOptions } from "constants/Constants"
import {
  createExpenseMapping,
  editExpenseMapping,
  fetchDeductionRewardMapping,
  fetchEmployeeFormData,
  fetchValueFormData,
  setDeductionMappingModal,
  setMappingSearch,
} from "store/deductionRule/deductionRuleSlice"
import toastr from "toastr"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

export const DeductionMappingForm = () => {
  const dispatch = useDispatch()
  const {
    openDeductionMappingModal,
    valueFormData,
    employeeFormData,
    mappingcurrentPage,
    deductionmappingRowData,
  } = useSelector(state => state.deductionRule)
  const location = useLocation()
  const [valueData, setValueData] = useState("")
  const [employeeData, setEmployeeData] = useState("")

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    value: Yup.number().required("Required"),
    level: Yup.number()
      .required("Required")
      .typeError("Must be a number")
      .positive("Must be a positive number"),
    employee_id: Yup.string().required("Required"),
  })

  const toggle = async () => {
    await dispatch(setDeductionMappingModal(false))
  }

  useMemo(() => {
    setEmployeeData(deductionmappingRowData?.employee_id)
    setValueData(deductionmappingRowData?.value)
  }, [deductionmappingRowData?.employee_id, openDeductionMappingModal])

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchValueFormData())
      await dispatch(fetchEmployeeFormData())
    }
    fetchData()
  }, [])

  return (
    <Modal
      isOpen={openDeductionMappingModal}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>{`${
        deductionmappingRowData?.id ? "Edit" : "Add"
      } Deduction Rule Mapping`}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                type: deductionmappingRowData?.type
                  ? Number(deductionmappingRowData?.type)
                  : "",
                value: deductionmappingRowData?.value
                  ? Number(deductionmappingRowData?.value)
                  : "",
                level: deductionmappingRowData?.level
                  ? deductionmappingRowData?.level
                  : null,
                employee_id: deductionmappingRowData?.employee_id
                  ? Number(deductionmappingRowData?.employee_id)
                  : "",
                status: deductionmappingRowData?.status
                  ? deductionmappingRowData?.status
                  : 0,
                ultimate_approval: deductionmappingRowData?.ultimate_approval
                  ? deductionmappingRowData?.ultimate_approval
                  : 0,
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                const payload = {
                  ...values,
                  deduction_reward_rule_master_id: location?.state?.id,
                }
                const response =
                  Object.keys(deductionmappingRowData).length > 0
                    ? await dispatch(
                        editExpenseMapping({
                          id: deductionmappingRowData?.id,
                          data: payload,
                        })
                      ).unwrap()
                    : await dispatch(createExpenseMapping(payload)).unwrap()
                try {
                  if (response.data?.status == true) {
                    toastr.success(response?.data?.message)
                  } else {
                    toastr.error(response?.payload?.message)
                  }
                } catch (error) {
                  toastr.error("Something Went Wrong Please Try Latter")
                }
                await dispatch(
                  fetchDeductionRewardMapping({
                    length: 10,
                    start: mappingcurrentPage,
                    deduction_reward_rule_master_id: location?.state?.id,
                  })
                )
                await dispatch(setMappingSearch(""))
                await dispatch(setDeductionMappingModal(false))
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Row>
                    <Col md={3}>
                      <div className="form-check form-switch form-switch-md mb-2">
                        <Input
                          name="status"
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked={values?.status == 0 ? false : true}
                          onChange={e => {
                            setFieldValue("status", e.target.checked ? 1 : 0)
                          }}
                        />
                        <Label className="mx-2 fs-6" htmlFor="status">
                          Status
                        </Label>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="form-check form-switch form-switch-md mb-2">
                        <Input
                          name="ultimate_approval"
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked={
                            values.ultimate_approval == 0 ? false : true
                          }
                          onChange={e => {
                            setFieldValue(
                              "ultimate_approval",
                              e.target.checked ? 1 : 0
                            )
                          }}
                        />
                        <Label
                          className="mx-2 fs-6"
                          htmlFor="ultimate_approval"
                        >
                          Ultimate Approval
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="type">
                        Type
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="type"
                        value={mappingTypeOptions.find(
                          option => option.value === values.type
                        )}
                        onChange={async option => {
                          await setFieldValue("type", option?.value)
                          if (option.value == 0) {
                            await setFieldValue("employee_id", 0)
                            await setFieldValue("value", 0)
                            setValueData("")
                            setEmployeeData("")
                          } else {
                            await setFieldValue("employee_id", "")
                            await setFieldValue("value", "")
                            setValueData("")
                            setEmployeeData("")
                          }
                        }}
                        options={mappingTypeOptions}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="value">
                        Value
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="value"
                        isDisabled={!values?.type}
                        value={
                          (valueData?.length > 0 &&
                            valueFormData.find(
                              option => option.value == valueData
                            )) ||
                          valueData
                        }
                        // value={valueData}
                        onChange={option => {
                          setValueData(
                            valueFormData.find(
                              option => option.value == values.value
                            )
                          )
                          setFieldValue("value", Number(option.value))
                        }}
                        options={valueFormData}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="value"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="level">
                        Level
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="level"
                        type="number"
                        placeholder="Level"
                        value={values.level}
                        onChange={e =>
                          setFieldValue("level", Number(e.target.value))
                        }
                      />
                      <ErrorMessage
                        name="level"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="employee">
                        Employee
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="employee_id"
                        isDisabled={!values?.type}
                        value={
                          (employeeData?.length > 0 &&
                            employeeFormData.find(
                              option => option.value == employeeData
                            )) ||
                          employeeData
                        }
                        onChange={option => {
                          setEmployeeData(
                            employeeFormData.find(
                              option => option.value == values.employee
                            )
                          )
                          setFieldValue("employee_id", Number(option.value))
                        }}
                        options={employeeFormData}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      {Object.keys(deductionmappingRowData).length > 0 ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
