import React from "react";
import { Row, Col, Input, Label, Modal, Button } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { get } from "../../helpers/api_helper";
import { ADD_ES_TRANSACTION_DATA, GET_ALL_MASTERS, GET_ES_ELEMENT } from "../../helpers/api_url_helper";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios"
import toastr from "toastr";
class AddTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      es_category_master: [],
      es_element_master: [],
      employees_master: [],
      es_category_id: "",
      es_element_id: "",
      from_date: "",
      to_date: "",
      no_days: "",
      location: "",
      size: "",
      total_size: "",
      quantity: "",
      internal_rate: "",
      internal_total: "",
      margin_percent: "",
      external_rate: "",
      external_total: "",
      expected_GOP: "",
      actual_GOP: "",
      advance: "",
      balance: "",
      remark: "",
      team_members: [],
      submit: false,
      isShowing: false,
      handleResponse: null,
      formSubmitSuccess: false
    }
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        percentage: {
          message: "The :attribute must be a valid percentage 0r Value (e.g., 20%, 200)",
          rule: (val) => {
            // Validate number with an optional percentage sign
            return /^\d+(\.\d{1,2})?%?$/.test(val);
          },
        },
      },
    });
  }

  componentDidMount() {
    this.loadMaster();
  }

  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'employees,es_category_master' } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ employees_master: data.employees, es_category_master: data.es_category_master })
      }
    }).catch(err => {
      toastr.error(err)
    })
  }  
  
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleResume = () => {
    let resume = document.getElementById('resume').files[0]
    this.setState({ resume: resume});
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = new FormData()
      formData.append("leads_id", this.props.leads_id); 
      formData.append("es_category_id", this.state.selectedEsCategory.value);
      formData.append("es_element_id", this.state.selectedEsElement.value);
      formData.append("from_date", this.state.from_date);
      formData.append("to_date", this.state.to_date);
      formData.append("no_days", this.state.no_days);
      formData.append("location", this.state.location);
      formData.append("size", this.state.size);
      formData.append("total_size", this.state.total_size);
      formData.append("quantity", this.state.quantity);
      formData.append("internal_rate", this.state.internal_rate !== undefined && this.state.internal_rate !== null ? this.state.internal_rate : '');
      formData.append("internal_total", this.state.internal_total !== undefined && this.state.internal_total !== null ? this.state.internal_total : '');
      formData.append("margin_percent", this.state.margin_percent !== undefined && this.state.margin_percent !== null ? this.state.margin_percent : '');
      formData.append("external_rate", this.state.external_rate !== undefined && this.state.external_rate !== null ? this.state.external_rate : '');
      formData.append("external_total", this.state.external_total !== undefined && this.state.external_total !== null ? this.state.external_total : '');
      formData.append("expected_GOP", this.state.expected_GOP !== undefined && this.state.expected_GOP !== null ? this.state.expected_GOP : '');
      formData.append("actual_GOP", this.state.actual_GOP !== undefined && this.state.actual_GOP !== null ? this.state.actual_GOP : '');      
      formData.append("advance", this.state.advance);
      formData.append("balance", this.state.balance);
      formData.append("remark", this.state.remark);
      formData.append("team_members", JSON.stringify(this.state.team_members));

      this.setState({ submit: true });
      axios({
        method: "post", url: `${process.env.REACT_APP_APIURL}${ADD_ES_TRANSACTION_DATA}`, data: formData, headers: {
          'Content-Type': 'application/json',
          "Authorization": accessToken,
        }
      }).then(response => {
        if (response.status) {
          this.setState({ submit: false })
          this.setState({
            isShowing: false
          });
          toastr.success('Es Transaction added successful.')
          // this.setState({ formSubmitSuccess: true })
          this.props.hide(false)
          this.setState({
            es_category_id: "",
            es_element_id: "",
            from_date: "",
            to_date: "",
            no_days: "",
            location: "",
            size: "",
            total_size: "",
            quantity: "",
            internal_rate: "",
            internal_total: "",
            margin_percent: "",
            external_rate: "",
            external_total: "",
            expected_GOP: "",
            actual_GOP: "",
            advance: "",
            balance: "",
            remark: "",
            team_members: [],
          })
          this.props.reloadLead(true)
          this.props.reloadElementSheet(true)
        }
      }).catch(err => {
        // Handle 400 Bad Request errors specifically
        if (err.response && err.response.status === 400) {
          const errorMessage = err.response.data.message || 'An error occurred';
          toastr.error(errorMessage);
        }
        this.setState({ submit: false });
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  
  handleEsCategoryChange = (selectedEsCategory) => {
    this.setState({ selectedEsCategory, selectedEsElement: null }, () => {
      // Revalidate the category field
      this.validator.showMessageFor("category");
    });
  
    if (selectedEsCategory) {
      get(GET_ES_ELEMENT, { params: { id: selectedEsCategory.value } })
        .then((response) => {
          // console.log(response);
          if (response.status) {
            this.setState({ es_element_master: response.data });
          } else {
            this.setState({ es_element_master: [] });
            toastr.error(response.message || "No es element found.");
          }
        })
        .catch((error) => {
          // console.error("There was an error fetching the es element!", error);
          toastr.error("Error fetching es element.");
        });
    } else {
      this.setState({ es_element_master: [] });
    }
  };
  
  handleEsElementChange = (selectedEsElement) => {
    this.setState({ selectedEsElement }, () => {
      // Revalidate the business category field
      this.validator.showMessageFor("element");
    });
  };
  
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "from_date") {
      this.setState({ to_date: "", minDate: value });
    }
  }; 

  render() {
    const {formSubmitSuccess } = this.state
    return (
      <React.Fragment>
        <Modal
          size="lg"
          isOpen={this.props.isShowing}
          toggle={this.props.hide}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">ADD TRANSACTION</h5>
            <button
              id="close_model"
              type="button"
              onClick={this.props.hide}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {formSubmitSuccess === false &&
              <form onSubmit={this.handleFormSubmit}>
                <Row>
                  <Col md="4" className="mb-3">
                    <label className="control-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={this.state.es_category_master}
                      classNamePrefix="select2-selection"
                      onChange={this.handleEsCategoryChange}
                      name="es_category_id"
                    />
                    {this.validator.message("category", this.state.selectedEsCategory, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <label className="control-label">
                      Element<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={this.state.es_element_master}
                      classNamePrefix="select2-selection"
                      onChange={this.handleEsElementChange}
                      name="es_element_id"
                    />
                    {this.validator.message("element", this.state.selectedEsElement, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="from_date">From Date<span className="text-danger">*</span></Label>
                    <Input
                      name="from_date"
                      type="date"
                      className="form-control"
                      onChange={this.handleInputChange}
                    />
                    {this.validator.message("from_date", this.state.from_date, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="to_date">To Date<span className="text-danger">*</span></Label>
                    <Input
                      name="to_date"
                      type="date"
                      className="form-control"
                      min={this.state.from_date}
                      onChange={this.handleInputChange}
                    />
                    {this.validator.message("to_date", this.state.to_date, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="no_days">No.Days<span className="text-danger">*</span></Label>
                    <Input name="no_days" placeholder="Enter No.Days" min="1" max="999" maxLength="3" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("no days", this.state.no_days, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="location">Location<span className="text-danger">*</span></Label>
                    <Input name="location" placeholder="Type Location" type="text" className={"form-control"}
                      onChange={this.handleInput} />
                    {this.validator.message("location", this.state.location, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="size">Size<span className="text-danger">*</span></Label>
                    <Input name="size" placeholder="Enter Size" type="text" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("size", this.state.size, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="total_size">Total Size<span className="text-danger">*</span></Label>
                    <Input name="total_size" placeholder="Enter Total Size" type="text" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("total_size", this.state.total_size, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="quantity">Quantity<span className="text-danger">*</span></Label>
                    <Input name="quantity" placeholder="Enter Quantity" min="1" max="999999" maxLength="6" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("quantity", this.state.quantity, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="internal_rate">Internal Rate</Label>
                    <Input name="internal_rate" placeholder="Enter Internal Rate" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("internal_rate", this.state.internal_rate, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="internal_total">Internal Total</Label>
                    <Input name="internal_total" placeholder="Enter Internal Total" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("internal_total", this.state.internal_total, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="margin_percent">Margin</Label>
                    <Input name="margin_percent" placeholder="Type Margin" type="text" className={"form-control"}
                       onChange={this.handleInput} />
                     {this.validator.message("margin", this.state.margin_percent, "percentage")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="external_rate">External Rate</Label>
                    <Input name="external_rate" placeholder="Enter External Rate" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("external_rate", this.state.external_rate, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="external_total">External Total</Label>
                    <Input name="external_total" placeholder="Enter External Total" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("external_total", this.state.external_total, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="expected_GOP">Expected GOP</Label>
                    <Input name="expected_GOP" placeholder="Enter Expected GOP" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("expected gop", this.state.expected_GOP, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="actual_GOP">Actual GOP</Label>
                    <Input name="actual_GOP" placeholder="Enter Actual GOP" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("actual gop", this.state.actual_GOP, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <label className="control-label">
                      Team Member<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={true}
                      options={this.state.employees_master}
                      classNamePrefix="select2-selection"
                      name="team_members"
                      onChange={e => {this.setState({ team_members: e }); }}
                    />
                    {this.validator.message("team member", this.state.team_members, "required|min:1|max:3")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="advance">Advance 1<span className="text-danger">*</span></Label>
                    <Input name="advance" placeholder="Enter Advance 1" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("advance", this.state.advance, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="balance">Balance<span className="text-danger">*</span></Label>
                    <Input name="balance" placeholder="Enter Balance" type="number" className={"form-control"}
                      onChange={this.handleInput} />
                      {this.validator.message("balance", this.state.balance, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="remark">Remark/Notes</Label>
                    <textarea name="remark" placeholder="Type Remark/Notes" rows={3} className={"form-control"}
                      onChange={this.handleInput} />
                    {this.validator.message("remark", this.state.remark, "required")}
                  </Col>
                </Row>

                <div className="d-flex flex-wrap gap-2 justify-content-end">
                  <Button color="primary" type="submit" disabled={this.state.submit}>
                    {this.state.submit === true ? "Please wait..." : "Submit Data"}
                  </Button>
                </div>
              </form>
            }
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

AddTransaction.propTypes = {
  attribs: PropTypes.any,
  isShowing: PropTypes.any,
  hide: PropTypes.any,
  reloadLead: PropTypes.func,
  reloadElementSheet: PropTypes.func
};

export default AddTransaction