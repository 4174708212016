import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle, Col, Row, Button } from "reactstrap"
import {
  createPermission,
  fetchPermission,
  fetchPermissionUsers,
} from "store/permission/permissionSlice"
import "bootstrap/dist/css/bootstrap.min.css"
import Select from "react-select"
import { sortName } from "constants/CommonFunction"
import { isValidUser, multiSelectStyles } from "constants/Constants"
import toastr from "toastr"
import CardSkeleton from "components/Common/CardSkeleton"

const Permission = () => {
  const { permissionData, permissionUsers, loading } = useSelector(
    state => state.permission
  );
  const { permissions } = useSelector(state => state.users.userPermissions);


  const dispatch = useDispatch()
  const [selectedOptions, setSelectedOptions] = useState({})
  const [activeAdminId, setActiveAdminId] = useState(null)
  const [hoveredUserId, setHoveredUserId] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPermission())
      await dispatch(fetchPermissionUsers())
    }

    fetchData()
  }, [dispatch])

  const handleMultiSelectChange = (selected, adminId) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [adminId]: selected,
    }))
  }

  const handleSaveClick = async (id, type) => {
    const selectedIds = selectedOptions[id]?.map(option => option.value) || []
    const payload = {
      employees: type === "admin" ? selectedIds.join(",") : id,
    }
    try {
      const response = await dispatch(
        createPermission({ id: type === "admin" ? id : 3, data: payload })
      )
      toastr.success(response?.payload?.message)
    } catch (error) {
      toastr.error("Something Went Wrong")
    } finally {
      setSelectedOptions({})
      setActiveAdminId(null)
      dispatch(fetchPermission())
    }
  }

  return (
    <div className="page-content d-flex justify-content-center">
      <div className="container profile-container">
        {loading ? (
          <Row>
            {[...Array(4)].map((_, index) => (
              <Col key={index} md={12}>
                <CardSkeleton />
              </Col>
            ))}
          </Row>
        ) : (
          permissionData?.map(admin => (
            <Card key={admin?.id} style={{ marginBottom: "20px" }}>
              <CardBody>
                <CardTitle>{admin?.name}</CardTitle>
                <hr className="underline" />
                <p>{admin?.desc}</p>
                <ul>
                  <li>View all employee profile information (Non-payroll)</li>
                  <li>
                    View sensitive employee information (such as PAN Card, IDs,
                    DOB etc)
                  </li>
                  <li>Add and edit employee profiles</li>
                  <li>Edit, Upload and Approve Attendance and Leaves</li>
                  <li>
                    This Admin will <b>not</b> have any payroll access.
                  </li>
                </ul>
                <Row className="m-lg-1">
                  {admin?.users?.filter(isValidUser).map(user => (
                    <Col
                      key={user?.id}
                      md={3}
                      className="mb-3 d-flex align-items-center mx-2"
                      style={{
                        border: "1px solid #c2cbdc",
                        padding: "2px",
                        borderRadius: "50px",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => setHoveredUserId(user?.id)}
                      onMouseLeave={() => setHoveredUserId(null)}
                    >
                      {user?.profile_photo ? (
                        <img
                          src={user.profile_photo}
                          alt={user?.employee_first_name}
                          className="rounded-circle me-2"
                          style={{ width: "40px", height: "40px" }}
                        />
                      ) : (
                        <span
                          className="avatar-title rounded-circle text-primary"
                          style={{
                            background: "#c2cbdc",
                            height: "45px",
                            width: "45px",
                          }}
                        >
                          {sortName(
                            `${user?.employee_first_name} ${user?.employee_last_name}`
                          )}
                        </span>
                      )}
                      <div className="d-flex align-items-center mx-1">
                        {user?.employee_first_name} {user?.employee_last_name}
                      </div>
                      {hoveredUserId === user?.id &&
                        admin?.name !== "Employee" && permissions?.add && (
                          <i
                            onClick={() => handleSaveClick(user?.id, "user")}
                            className="dripicons-cross d-flex"
                            style={{
                              position: "absolute",
                              right: 0,
                              top: "10%",
                              transform: "translateY(-50%)",
                              fontSize: "12px",
                              borderRadius: "50%",
                              backgroundColor: "#a1a8b8",
                              color: "white",
                              padding: "2px",
                            }}
                          />
                        )}
                    </Col>
                  ))}
                </Row>
                <hr className="underline" />
                {activeAdminId === admin?.id ? (
                  <Row className="d-flex align-items-center">
                    <Col md={8} style={{ cursor: "pointer" }}>
                      <Select
                        isMulti
                        options={permissionUsers}
                        value={selectedOptions[admin?.id] || []}
                        onChange={selected =>
                          handleMultiSelectChange(selected, admin?.id)
                        }
                        styles={multiSelectStyles}
                      />
                    </Col>
                    <Col md={4} className="d-flex justify-content-end">
                      <button
                        className="d-flex align-items-center btn btn-md mx-2 btn-outline-secondary"
                        onClick={() => {
                          setActiveAdminId(null)
                          setSelectedOptions({})
                        }}
                      >
                        <i className="dripicons-cross d-flex" />
                        Cancel
                      </button>
                      <button
                        className="d-flex align-items-center btn btn-md btn-primary"
                        onClick={() => handleSaveClick(admin?.id, "admin")}
                        disabled={
                          !selectedOptions[admin?.id] ||
                          selectedOptions[admin?.id].length === 0
                        }
                      >
                        <i className="fas fa-check mx-2" />
                        {loading ? "Please wait..." : "Save"}
                      </button>
                    </Col>
                  </Row>
                ) : permissions?.edit && (
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      color: "blue",
                      padding: "0px",
                    }}
                    onClick={() => {
                      setSelectedOptions({})
                      setActiveAdminId(admin?.id)
                    }}
                    className="btn btn-lg d-flex align-items-center mt-2 mb-1"
                  >
                    <i
                      className="dripicons-plus mx-2 d-flex"
                      style={{ border: "1px solid blue", borderRadius: "50%" }}
                    />
                    Add
                  </button>
                )}
              </CardBody>
            </Card>
          ))
        )}
      </div>
    </div>
  )
}

export default Permission
