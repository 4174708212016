import React from 'react'
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import { Link } from "react-router-dom"


export const SocialProfileView = () => {
    return (
        <>
            <Row className='d-flex justify-content-center'>
            <Col className='profile-container' xl="12">
                <Card className="mb-lg-75">
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div className="d-flex align-items-center">
                          <CardTitle className="header-text">
                          Social Profile
                          </CardTitle>
                        </div>
                        <hr className="underline" />
                      </Col>
                      <Col md="6" className="mb-3">
                      <div className="mt-4">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item mt-1"><a className="btn btn-outline-primary btn-hover"
                                href={`https://www.facebook.com`}><i className="uil uil-facebook-f"></i> Facebook</a></li>
                            <li className="list-inline-item mt-1"><a className="btn btn-outline-primary btn-hover"
                                href={`https://twitter.com`}><i className="uil uil-twitter"></i> Twitter</a></li>
                            <li className="list-inline-item mt-1"><a className="btn btn-outline-success btn-hover"
                                href={`https://www.linkedin.com`}><i className="uil uil-linkedin-alt"></i> linkedin</a></li>
                        </ul>
                    </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </>
      )
}
