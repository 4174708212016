import {createAsyncThunk,createSlice} from '@reduxjs/toolkit'
import { apiCreateEmployeeAccessory,
  apiEditEmployeeAccessory,
  apiGetAssetType,
  apiGetEmployeeAccessory,
  apiGetEmployeeData,
  apiGetOfficeUserLocation }
  from 'services/EmployeeAccessoryService'

export const SLICE_NAME='employeeAccessory'

const initialState={
    loading:false,
    employeeAccessoryData:[],
    employeeAccessorySearchQuery:'',
    currentPage:1,
    employeeAccessoryTotalData:0,
    accessorySearchQuery:"",
    employeeAccessoryRowData:[],
    openAccessoryModal:false,
    employeeFormData:[],
    officeLocationData: [],
    assetType:[],

}

export const fetchEmployeeAccessory=createAsyncThunk(
    `${SLICE_NAME}/fetchEmployeeAccessory`,
    async({length, start, keyword})=>{
        try{
            const response=await apiGetEmployeeAccessory({length, start, keyword })
            return response?.data
        } catch(error){
            throw error
        }
    }
)


export const editEmployeeAcccessory = createAsyncThunk(
    `${SLICE_NAME}/editEmployeeAcccessory`,
    async ({ id, data }, { rejectWithValue }) => {
      try {
        const response = await apiEditEmployeeAccessory(id, data)
        return response
      } catch (error) {
        return rejectWithValue({
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        })
      }
    }
  )

  export const createEmployeeAcccessory = createAsyncThunk(
    `${SLICE_NAME}/createEmployeeAcccessory`,
    async (data, { rejectWithValue }) => {
      try {
        const response = await apiCreateEmployeeAccessory(data)
        return response
      } catch (error) {
        return rejectWithValue({
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        })
      }
    }
  )

  export const fetchEmployeeFormData = createAsyncThunk(
    `${SLICE_NAME}/fetchEmployeeFormData`,
    async () => {
      try {
        const response = await apiGetEmployeeData()
        return {
          status: "success",
          data: response,
        }
      } catch (error) {
        return {
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        }
      }
    }
  )

  export const fetchUsersOfficeLocation = createAsyncThunk(
    `${SLICE_NAME}/fetchUsersOfficeLocation`,
    async () => {
      try {
        const response = await apiGetOfficeUserLocation()
        return {
          status: "success",
          data: response.data.data,
        }
      } catch (error) {
        return {
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        }
      }
    }
  )

  export const fetchAssetType = createAsyncThunk(
    `${SLICE_NAME}/fetchAssetType`,
    async () => {
      try {
        const response = await apiGetAssetType()
        return {
          status: "success",
          data: response,
        }
      } catch (error) {
        return {
          status: "failed",
          message: error?.response?.data?.message || error.toString(),
        }
      }
    }
  )

  const employeeAccessorySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
      setLoading: (state, action) => {
        state.loading = action.payload
      },
      setEmployeeAccessoryRowData: (state, action) => {
        state.employeeAccessoryRowData = action.payload
      },
      setOpenAccessoryModal: (state, action) => {
        state.openAccessoryModal = action.payload
      },
      setCurrentPage: (state, action) => {
        state.currentPage = action.payload
      },
      setAccessorySearch: (state, action) => {
        state.accessorySearchQuery = action.payload
      },
    },
    extraReducers: builder => {
      builder.addCase(fetchEmployeeAccessory.pending, state => {
        state.loading = true
      })
      builder.addCase(fetchEmployeeAccessory.fulfilled, (state, action) => {
        state.loading = false
        state.employeeAccessoryData = action?.payload?.data
        state.employeeAccessoryTotalData = action?.payload?.total
      })
      builder.addCase(fetchEmployeeAccessory.rejected, state => {
        state.loading = false
      })
      builder.addCase(fetchEmployeeFormData.fulfilled, (state, action) => {
        const initialOptions={value:"",label:"All"}
        const fetchOptions= action.payload.data?.data?.data?.map(
        employee => ({
          value: employee.id,
          label: `${employee?.first_name} ${employee?.last_name}`,
        }))
        state.employeeFormData =fetchOptions
        state.employeeViewData=[initialOptions,...fetchOptions]
      })
      builder.addCase(fetchUsersOfficeLocation.fulfilled, (state, action) => {
        state.officeLocationData = action.payload.data.map(location => ({
          value: location?.id,
          label: location?.name,
        }))
      })
      builder.addCase(fetchAssetType.fulfilled, (state, action) => {
        console.log("Asset type received:", action.payload);
        state.assetType = action.payload.data?.data?.data?.map(asset => ({
          value: asset?.id,
          label: asset?.name,
        }))
      })
    },
  })

export const {setLoading,
    setCurrentPage,
    setEmployeeAccessoryRowData,
    setOpenAccessoryModal,
    setAccessorySearch,
}= employeeAccessorySlice.actions
export default employeeAccessorySlice.reducer
