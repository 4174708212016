import { ApiService } from "./ApiService"

export async function apiGetAttendanceApproval({
  length,
  start,
  status,
  keyword,
  employee_id
}) {
  try {
    return await ApiService.fetchData({
      url: `attendance/approval_requests`,
      method: "get",
      params: {
        length,
        start,
        status,
        keyword,
        ...(employee_id && {employee_id:employee_id})
      },
    })
  } catch (error) {
    console.error("API call failed:", error)
    throw error
  }
}

export async function apiCreateAttendanceApproval(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `attendance/approve_request/${id}`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiCreateAttendanceBulkApproval(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `attendance/bulk_approve_request`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}
