import React from "react";
import { Row, Col, Input, Card, CardBody, Label, Container, Button } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import axios from "axios"
import MetaTags from "react-meta-tags"
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SimpleReactValidator from "simple-react-validator";
import { get, post } from "../../helpers/api_helper";
import { ADD_LEADS, GET_ALL_MASTERS,GET_EMPLOYEES_PERMISSION, GET_BUSINESS_CATEGORY, GET_SERVICES_MASTER } from "../../helpers/api_url_helper";
import toastr from "toastr";
class AddLead extends React.Component {
  constructor(props) {
    super(props);    
    const getCurrentDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    this.state = {
      location_master: [],
      employees_master: [],
      stage_master: [],
      lead_source_master: [],
      brand_master: [],
      business_category_master: [],
      services_master: [],
      selectedBrand: null,
      selectedBusinessCategory: null,
      lead_name: "",
      company_name: "",
      designation: "",
      type_of_franchise: "",
      mobile: "",
      countryCode: "+91",
      alternatemobile: "",
      alternatecountryCode: "+91",
      email: "",
      enquiry_date: getCurrentDate(),
      date_of_event: "",
      bride_groom_name: "",
      venue_of_event: "",
      no_of_people: "",
      no_of_functions: "",
      profile: "",
      job_business_type: "",
      type_of_artist_required: "",
      community: "",
      what_star_hotel: "",
      no_of_days: "",
      budget: "",
      assigned_to: "",
      client_location: "",
      service_required:"",
      submit: false,
      showDesignation: false,
      showDateOfEvent: false,
      showBrideGroomName: false,
      showVenueOfEvent: false,
      showNoOfPeople: false,
      showNoOfFunctions: false,
      showNoOfDays: false,
      showWhatStarHotel: false,
      showCommunity: false,
      showArtist: false,
      showBudget: false,
      showServices: false,
      showFranchise: false,
      showJobBusinessType: false,
      showProfile: false,
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    
    this.handleInput = this.handleInput.bind(this);
    this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);
    this.handleInput1 = this.handleInput1.bind(this);
    this.handleCountryCodeChange1 = this.handleCountryCodeChange1.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
  }
  
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInput(event) {
    this.setState({ mobile: event.target.value });
  }

  handleCountryCodeChange(event) {
    this.setState({ countryCode: event.target.value });
  }
  
  handleInput1(event) {
    this.setState({ alternatemobile: event.target.value });
  }

  handleCountryCodeChange1(event) {
    this.setState({ alternatecountryCode: event.target.value });
  }
  
  handleProfile = (e) => {
    let profile = e.target.files[0];
    this.setState({ profile: profile });
  } 
  
  componentDidMount() {
    this.loadMaster();
    this.loadeEmployee();
  }

  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'location,notice_period,stage_master,lead_source_master,brand_master' } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ location_master: data.location,stage_master: data.stage_master, lead_source_master: data.lead_source_master,brand_master: data.brand_master })
      }
    }).catch(err => {
      toastr.error(err)
    })
  }
  
  loadeEmployee = () => {
    get(GET_EMPLOYEES_PERMISSION, { params: { status: 1 } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ employees_master: data })
      }
    }).catch(err => {
      toastr.error(err)
    })
  }

  handleBrandChange = (selectedBrand) => {
    this.setState({ selectedBrand, selectedBusinessCategory: null }, () => {
      // Revalidate the brand field
      this.validator.showMessageFor("brand");
    });
  
    if (selectedBrand) {
      get(GET_BUSINESS_CATEGORY, { params: { id: selectedBrand.value } })
        .then((response) => {
          console.log(response);
          if (response.status) {
            this.setState({ business_category_master: response.data });
          } else {
            this.setState({ business_category_master: [] });
            toastr.error(response.message || "No business categories found.");
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the business categories!", error);
          toastr.error("Error fetching business categories.");
        });
    } else {
      this.setState({ business_category_master: [] });
    }
    
    if (selectedBrand) {
      get(GET_SERVICES_MASTER, { params: { id: selectedBrand.value } })
        .then((response) => {
          console.log(response);
          if (response.status) {
            this.setState({ services_master: response.data });
          } else {
            this.setState({ services_master: [] });
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the service!", error);
          toastr.error("Error fetching service.");
        });
    } else {
      this.setState({ services_master: [] });
    }
    
     // Based on the selected brand, control which fields to show
     this.setState({ 
      showDesignation: selectedBrand && selectedBrand.value === '6' || selectedBrand.value === '3',
      showDateOfEvent: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2'|| selectedBrand.value === '3'|| selectedBrand.value === '4'|| selectedBrand.value === '5'|| selectedBrand.value === '7'),
      showBrideGroomName: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '5'),
      showVenueOfEvent: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2'|| selectedBrand.value === '3'|| selectedBrand.value === '4'|| selectedBrand.value === '5'|| selectedBrand.value === '6' || selectedBrand.value === '7'),
      showNoOfPeople: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2'|| selectedBrand.value === '3'|| selectedBrand.value === '4'|| selectedBrand.value === '5'|| selectedBrand.value === '7'),
      showNoOfFunctions: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
      showNoOfDays: selectedBrand && selectedBrand.value === '3',
      showWhatStarHotel: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
      showCommunity: selectedBrand && (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
      showArtist: selectedBrand && selectedBrand.value === '5',
      showBudget: selectedBrand &&  (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '3' || selectedBrand.value === '4'|| selectedBrand.value === '5'),
      showServices: selectedBrand &&  (selectedBrand.value === '1' || selectedBrand.value === '2' || selectedBrand.value === '3' || selectedBrand.value === '4'|| selectedBrand.value === '6' || selectedBrand.value === '7'),
      showFranchise: selectedBrand && (selectedBrand.value === '6' || selectedBrand.value === '7' ||  selectedBrand.value === '8'),
      showJobBusinessType: selectedBrand && selectedBrand.value === '9',
      showProfile: selectedBrand && selectedBrand.value === '9',
    });
  };
  
  handleBusinessCategoryChange = (selectedBusinessCategory) => {
    this.setState({ selectedBusinessCategory }, () => {
      // Revalidate the business category field
      this.validator.showMessageFor("business_category");
    });
  };
  
  handleFormSubmit = (e) => {
    e.preventDefault();
    
    if (this.validator.allValid()) {
      const formData = new FormData(); // Create a FormData object
  
      // Append all other fields to FormData
      formData.append("name", this.state.lead_name);
      formData.append("brand", this.state.selectedBrand.value);
      formData.append("business_category", this.state.selectedBusinessCategory.value);
      formData.append("stage", this.state.stage.value);
      formData.append("source", this.state.source.value);
      formData.append("country_code", this.state.countryCode);
      formData.append("mobile", this.state.mobile);
      formData.append("company_name", this.state.company_name);
      formData.append("designation", this.state.designation);
      formData.append("email", this.state.email);
      formData.append("alternate_country_code", this.state.alternatecountryCode);
      formData.append("alternate_mobile", this.state.alternatemobile);
      formData.append("enquiry_date", this.state.enquiry_date);
      formData.append("date_of_event", this.state.date_of_event);
      formData.append("bride_groom_name", this.state.bride_groom_name);
      formData.append("venue_of_event", this.state.venue_of_event);
      formData.append("client_location", this.state.client_location.value);
      formData.append("no_of_people", this.state.no_of_people);
      formData.append("no_of_functions", this.state.no_of_functions);
      
      // Append the file field to FormData (profile)
      if (this.state.profile) {
        formData.append("profile", this.state.profile); // Append the file
      }
  
      formData.append("job_business_type", this.state.job_business_type);
      formData.append("type_of_artist_required", this.state.type_of_artist_required);
      formData.append("community", this.state.community);
      formData.append("what_star_hotel", this.state.what_star_hotel);
      formData.append("no_of_days", this.state.no_of_days);
      formData.append("budget", this.state.budget);
      formData.append("type_of_franchise", this.state.type_of_franchise.value);
      formData.append("assigned_to", this.state.assigned_to.value);
      formData.append("service_required", this.state.service_required.value);
  
      this.setState({ submit: true });
      
      axios({
        method: "post", url: `${process.env.REACT_APP_APIURL}${ADD_LEADS}`, data: formData, headers: {
          'Content-Type': 'application/json',
          "Authorization": accessToken,
        }
      }).then(response => {
        if (response.status) {
          this.setState({ submit: false });
          toastr.success('Lead added successfully.');
          const { history } = this.props;
          history.push('/leads');
        }else{
          toastr.error(response?.data?.message);
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message);
        this.setState({ submit: false });
      });
  
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  
  
  render() {
    const formatDateToDisplay = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    }  
    
    const pastDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    
    const minDate = pastDate();
    const { location_master, employees_master, stage_master, lead_source_master,mobile, countryCode,alternatemobile, alternatecountryCode } = this.state
    const countryCodes = [
      { value: "91", label: "(+91)        India" },
      { value: "213", label: "(+213)        Algeria" },
      { value: "376", label: "(+376)        Andorra" },
      { value: "244", label: "(+244)        Angola" },
      { value: "1264", label: "(+1264)        Anguilla" },
      { value: "1268", label: "(+1268)        Antigua & Barbuda" },
      { value: "54", label: "(+54)        Argentina" },
      { value: "374", label: "(+374)        Armenia" },
      { value: "297", label: "(+297)        Aruba" },
      { value: "61", label: "(+61)        Australia" },
      { value: "43", label: "(+43)        Austria" },
      { value: "994", label: "(+994)        Azerbaijan" },
      { value: "1242", label: "(+1242)        Bahamas" },
      { value: "973", label: "(+973)        Bahrain" },
      { value: "880", label: "(+880)        Bangladesh" },
      { value: "1246", label: "(+1246)        Barbados" },
      { value: "375", label: "(+375)        Belarus" },
      { value: "32", label: "(+32)        Belgium" },
      { value: "501", label: "(+501)        Belize" },
      { value: "229", label: "(+229)        Benin" },
      { value: "1441", label: "(+1441)        Bermuda" },
      { value: "975", label: "(+975)        Bhutan" },
      { value: "591", label: "(+591)        Bolivia" },
      { value: "387", label: "(+387)        Bosnia Herzegovina" },
      { value: "267", label: "(+267)        Botswana" },
      { value: "55", label: "(+55)        Brazil" },
      { value: "673", label: "(+673)        Brunei" },
      { value: "359", label: "(+359)        Bulgaria" },
      { value: "226", label: "(+226)        Burkina Faso" },
      { value: "257", label: "(+257)        Burundi" },
      { value: "855", label: "(+855)        Cambodia" },
      { value: "237", label: "(+237)        Cameroon" },
      { value: "1", label: "(+1)        Canada" },
      { value: "238", label: "(+238)        Cape Verde Islands" },
      { value: "1345", label: "(+1345)        Cayman Islands" },
      { value: "236", label: "(+236)        Central African Republic" },
      { value: "56", label: "(+56)        Chile" },
      { value: "86", label: "(+86)        China" },
      { value: "57", label: "(+57)        Colombia" },
      { value: "269", label: "(+269)        Comoros" },
      { value: "242", label: "(+242)        Congo" },
      { value: "682", label: "(+682)        Cook Islands" },
      { value: "506", label: "(+506)        Costa Rica" },
      { value: "385", label: "(+385)        Croatia" },
      { value: "53", label: "(+53)        Cuba" },
      { value: "90392", label: "(+90392)        Cyprus North" },
      { value: "357", label: "(+357)        Cyprus South" },
      { value: "42", label: "(+42)        Czech Republic" },
      { value: "45", label: "(+45)        Denmark" },
      { value: "253", label: "(+253)        Djibouti" },
      { value: "1809", label: "(+1809)        Dominica" },
      { value: "1809", label: "(+1809)        Dominican Republic" },
      { value: "593", label: "(+593)        Ecuador" },
      { value: "20", label: "(+20)        Egypt" },
      { value: "503", label: "(+503)        El Salvador" },
      { value: "240", label: "(+240)        Equatorial Guinea" },
      { value: "291", label: "(+291)        Eritrea" },
      { value: "372", label: "(+372)        Estonia" },
      { value: "251", label: "(+251)        Ethiopia" },
      { value: "500", label: "(+500)        Falkland Islands" },
      { value: "298", label: "(+298)        Faroe Islands" },
      { value: "679", label: "(+679)        Fiji" },
      { value: "358", label: "(+358)        Finland" },
      { value: "33", label: "(+33)        France" },
      { value: "594", label: "(+594)        French Guiana" },
      { value: "689", label: "(+689)        French Polynesia" },
      { value: "241", label: "(+241)        Gabon" },
      { value: "220", label: "(+220)        Gambia" },
      { value: "7880", label: "(+7880)        Georgia" },
      { value: "49", label: "(+49)        Germany" },
      { value: "233", label: "(+233)        Ghana" },
      { value: "350", label: "(+350)        Gibraltar" },
      { value: "30", label: "(+30)        Greece" },
      { value: "299", label: "(+299)        Greenland" },
      { value: "1473", label: "(+1473)        Grenada" },
      { value: "590", label: "(+590)        Guadeloupe" },
      { value: "671", label: "(+671)        Guam" },
      { value: "502", label: "(+502)        Guatemala" },
      { value: "224", label: "(+224)        Guinea" },
      { value: "245", label: "(+245)        Guinea - Bissau" },
      { value: "592", label: "(+592)        Guyana" },
      { value: "509", label: "(+509)        Haiti" },
      { value: "504", label: "(+504)        Honduras" },
      { value: "852", label: "(+852)        Hong Kong" },
      { value: "36", label: "(+36)        Hungary" },
      { value: "354", label: "(+354)        Iceland" },
      { value: "62", label: "(+62)        Indonesia" },
      { value: "98", label: "(+98)        Iran" },
      { value: "964", label: "(+964)        Iraq" },
      { value: "353", label: "(+353)        Ireland" },
      { value: "972", label: "(+972)        Israel" },
      { value: "39", label: "(+39)        Italy" },
      { value: "1876", label: "(+1876)        Jamaica" },
      { value: "81", label: "(+81)        Japan" },
      { value: "962", label: "(+962)        Jordan" },
      { value: "7", label: "(+7)        Kazakhstan" },
      { value: "254", label: "(+254)        Kenya" },
      { value: "686", label: "(+686)        Kiribati" },
      { value: "850", label: "(+850)        Korea North" },
      { value: "82", label: "(+82)        Korea South" },
      { value: "965", label: "(+965)        Kuwait" },
      { value: "996", label: "(+996)        Kyrgyzstan" },
      { value: "856", label: "(+856)        Laos" },
      { value: "371", label: "(+371)        Latvia" },
      { value: "961", label: "(+961)        Lebanon" },
      { value: "266", label: "(+266)        Lesotho" },
      { value: "231", label: "(+231)        Liberia" },
      { value: "218", label: "(+218)        Libya" },
      { value: "417", label: "(+417)        Liechtenstein" },
      { value: "370", label: "(+370)        Lithuania" },
      { value: "352", label: "(+352)        Luxembourg" },
      { value: "853", label: "(+853)        Macao" },
      { value: "389", label: "(+389)        Macedonia" },
      { value: "261", label: "(+261)        Madagascar" },
      { value: "265", label: "(+265)        Malawi" },
      { value: "60", label: "(+60)        Malaysia" },
      { value: "960", label: "(+960)        Maldives" },
      { value: "223", label: "(+223)        Mali" },
      { value: "356", label: "(+356)        Malta" },
      { value: "692", label: "(+692)        Marshall Islands" },
      { value: "596", label: "(+596)        Martinique" },
      { value: "222", label: "(+222)        Mauritania" },
      { value: "269", label: "(+269)        Mayotte" },
      { value: "52", label: "(+52)        Mexico" },
      { value: "691", label: "(+691)        Micronesia" },
      { value: "373", label: "(+373)        Moldova" },
      { value: "377", label: "(+377)        Monaco" },
      { value: "976", label: "(+976)        Mongolia" },
      { value: "1664", label: "(+1664)        Montserrat" },
      { value: "212", label: "(+212)        Morocco" },
      { value: "258", label: "(+258)        Mozambique" },
      { value: "95", label: "(+95)        Myanmar" },
      { value: "264", label: "(+264)        Namibia" },
      { value: "674", label: "(+674)        Nauru" },
      { value: "977", label: "(+977)        Nepal" },
      { value: "31", label: "(+31)        Netherlands" },
      { value: "687", label: "(+687)        New Caledonia" },
      { value: "64", label: "(+64)        New Zealand" },
      { value: "505", label: "(+505)        Nicaragua" },
      { value: "227", label: "(+227)        Niger" },
      { value: "234", label: "(+234)        Nigeria" },
      { value: "683", label: "(+683)        Niue" },
      { value: "672", label: "(+672)        Norfolk Islands" },
      { value: "670", label: "(+670)        Northern Marianas" },
      { value: "47", label: "(+47)        Norway" },
      { value: "968", label: "(+968)        Oman" },
      { value: "680", label: "(+680)        Palau" },
      { value: "507", label: "(+507)        Panama" },
      { value: "675", label: "(+675)        Papua New Guinea" },
      { value: "595", label: "(+595)        Paraguay" },
      { value: "51", label: "(+51)        Peru" },
      { value: "63", label: "(+63)        Philippines" },
      { value: "48", label: "(+48)        Poland" },
      { value: "351", label: "(+351)        Portugal" },
      { value: "1787", label: "(+1787)        Puerto Rico" },
      { value: "974", label: "(+974)        Qatar" },
      { value: "262", label: "(+262)        Reunion" },
      { value: "40", label: "(+40)        Romania" },
      { value: "7", label: "(+7)        Russia" },
      { value: "250", label: "(+250)        Rwanda" },
      { value: "378", label: "(+378)        San Marino" },
      { value: "239", label: "(+239)        Sao Tome &amp; Principe " },
      { value: "966", label: "(+966)        Saudi Arabia" },
      { value: "221", label: "(+221)        Senegal " },
      { value: "381", label: "(+381)        Serbia " },
      { value: "248", label: "(+248)        Seychelles" },
      { value: "232", label: "(+232)        Sierra Leone" },
      { value: "65", label: "(+65)        Singapore" },
      { value: "421", label: "(+421)        Slovak Republic" },
      { value: "386", label: "(+386)        Slovenia" },
      { value: "677", label: "(+677)        Solomon Islands" },
      { value: "252", label: "(+252)        Somalia" },
      { value: "27", label: "(+27)        South Africa" },
      { value: "34", label: "(+34)        Spain" },
      { value: "94", label: "(+94)        Sri Lanka " },
      { value: "290", label: "(+290)        St. Helena " },
      { value: "1869", label: "(+1869)        St. Kitts " },
      { value: "1758", label: "(+1758)        St. Lucia " },
      { value: "249", label: "(+249)        Sudan" },
      { value: "597", label: "(+597)        Suriname" },
      { value: "268", label: "(+268)        Swaziland" },
      { value: "46", label: "(+46)        Sweden" },
      { value: "41", label: "(+41)        Switzerland" },
      { value: "963", label: "(+963)        Syria" },
      { value: "886", label: "(+886)        Taiwan" },
      { value: "7", label: "(+7)        Tajikstan" },
      { value: "66", label: "(+66)        Thailand" },
      { value: "228", label: "(+228)        Togo" },
      { value: "676", label: "(+676)        Tonga" },
      { value: "1868", label: "(+1868)        Trinidad &amp; Tobago" },
      { value: "216", label: "(+216)        Tunisia" },
      { value: "90", label: "(+90)        Turkey" },
      { value: "7", label: "(+7)        Turkmenistan" },
      { value: "993", label: "(+993)        Turkmenistan" },
      { value: "1649", label: "(+1649)        Turks &amp; Caicos Islands" },
      { value: "688", label: "(+688)        Tuvalu" },
      { value: "256", label: "(+256)        Uganda" },
      { value: "44", label: "(+44)        UK" },
      { value: "380", label: "(+380)        Ukraine" },
      { value: "971", label: "(+971)        United Arab Emirates" },
      { value: "598", label: "(+598)        Uruguay " },
      { value: "1", label: "(+1)        USA " },
      { value: "7", label: "(+7)        Uzbekistan" },
      { value: "678", label: "(+678)        Vanuatu" },
      { value: "379", label: "(+379)        Vatican City" },
      { value: "58", label: "(+58)        Venezuela" },
      { value: "84", label: "(+84)        Vietnam" },
      { value: "84", label: "(+1284)        Virgin Islands - British" },
      { value: "84", label: "(+1340)        Virgin Islands - US " },
      { value: "681", label: "(+681)        Wallis &amp; Futuna" },
      { value: "969", label: "(+969)        Yemen (North)" },
      { value: "967", label: "(+967)        Yemen (South)" },
      { value: "260", label: "(+260)        Zambia" },
      { value: "263", label: "(+263)        Zimbabwe" }
    ];
    return (
      <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Add Leads | {process.env.REACT_APP_PROJECTNAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Leads" path="/leads" breadcrumbItem="Add Leads"/>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                <form onSubmit={this.handleFormSubmit}>
                  <Row>
                    <Col md="4" className="mb-3">
                      <label className="control-label">
                        Brand<span className="text-danger">*</span>
                      </label>
                      <Select
                        isMulti={false}
                        options={this.state.brand_master}
                        classNamePrefix="select2-selection"
                        onChange={this.handleBrandChange}
                        name="brand"
                      />
                      {this.validator.message("brand", this.state.selectedBrand, "required")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <label className="control-label">
                        Business Category<span className="text-danger">*</span>
                      </label>
                      <Select
                        isMulti={false}
                        options={this.state.business_category_master}
                        classNamePrefix="select2-selection"
                        onChange={this.handleBusinessCategoryChange}
                        name="business_category"
                      />
                      {this.validator.message("business_category", this.state.selectedBusinessCategory, "required")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <label className="control-label">Stage<span className="text-danger">*</span></label>
                      <Select isMulti={false} options={stage_master} name="stage" classNamePrefix="select2-selection" onChange={e => this.setState({ stage: e })} />
                      {this.validator.message("stage", this.state.stage, "required")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="lead_name">Lead Name<span className="text-danger">*</span></Label>
                      <Input name="lead_name" placeholder="Type Lead Name" type="text" className={"form-control"}
                        onChange={this.handleInput} />
                      {this.validator.message("lead_name", this.state.lead_name, "required|string")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="company_name">Company Name</Label>
                      <Input name="company_name" placeholder="Type Company Name" type="text" className={"form-control"}
                        onChange={this.handleInput} />
                      {this.validator.message("company_name", this.state.company_name, "string")}
                    </Col>
                    {this.state.showDesignation && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="designation">Designation</Label>
                        <Input name="designation" placeholder="Type Designation" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("designation", this.state.designation, "string")}
                      </Col>
                    )}
                    <Col md="4" className="mb-3">
                      <label className="control-label">Source<span className="text-danger">*</span></label>
                      <Select isMulti={false} options={lead_source_master} name="source" classNamePrefix="select2-selection" onChange={e => this.setState({ source: e })} />
                      {this.validator.message("source", this.state.source, "required")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="Email">Email</Label>
                      <input name="email" placeholder="Type Email Address" type="email" className={"form-control"}
                        onChange={this.handleInput} />
                      {this.validator.message("email", this.state.email, "email")}
                    </Col>                  
                    <Col md="4" className="mb-3">
                      <Label htmlFor="Mobile">Mobile<span className="text-danger">*</span></Label>
                      <div style={{ "display": "flex" }}>
                        <Input 
                          style={{ "width": "80px", "padding-left": "1px" }}
                          type="select"
                          name="countryCode"
                          value={countryCode}
                          onChange={this.handleCountryCodeChange}
                          className="form-control"
                        >
                          {countryCodes.map(code => (
                            <option key={code.value} value={code.value}>
                              {code.label.replace(/ /g, '\u00A0')}
                            </option>
                          ))}
                        </Input>
                        <Input 
                          name="mobile" 
                          placeholder="Type Mobile" 
                          type="tel" 
                          value={mobile}
                          onChange={this.handleInput}
                          className="form-control" 
                        />
                      </div>
                      {this.validator.message("mobile", mobile, "required")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="alternateMobile">Alternate Mobile</Label>
                      <div style={{ "display": "flex" }}>
                        <Input 
                          style={{ "width": "80px", "padding-left": "1px" }}
                          type="select"
                          name="alternatecountryCode"
                          value={alternatecountryCode}
                          onChange={this.handleCountryCodeChange1}
                          className="form-control"
                        >
                          {countryCodes.map(code => (
                            <option key={code.value} value={code.value}>
                              {code.label.replace(/ /g, '\u00A0')}
                            </option>
                          ))}
                        </Input>
                        <Input 
                          name="alternatemobile" 
                          placeholder="Type Mobile" 
                          type="tel" 
                          value={alternatemobile}
                          onChange={this.handleInput}
                          className="form-control" 
                        />
                      </div>
                      {this.validator.message("alternatemobile", alternatemobile, "")}
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="enquiry_date">Enquiry Date</Label>
                      <Input
                        name="enquiry_date"
                        type="date"
                        className="form-control"
                        value={this.state.enquiry_date}
                        onChange={this.handleInput}
                      />
                      {this.validator.message("enquiry_date", formatDateToDisplay(this.state.enquiry_date), "")}
                    </Col>
                    {this.state.showDateOfEvent && (
                      <Col md="4" className="mb-3">
                        <Label htmlFor="date_of_event">Date of Event</Label>
                        <Input
                          name="date_of_event"
                          type="date"
                          className="form-control"
                          min={minDate}
                          value={this.state.date_of_event}
                          onChange={this.handleInput}
                        />
                        {this.validator.message("date_of_event", this.state.date_of_event, "")}
                      </Col>
                    )}
                    {this.state.showBrideGroomName && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="bride_groom_name">Bride & Groom Name</Label>
                        <Input name="bride_groom_name" placeholder="Type Bride & Groom Name" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("bride_groom_name", this.state.bride_groom_name, "string")}
                      </Col>
                    )}
                    {this.state.showVenueOfEvent && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="venue_of_event">Venue of Event</Label>
                        <Input name="venue_of_event" placeholder="Type Venue of Event" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("venue_of_event", this.state.venue_of_event, "string")}
                      </Col>
                    )}
                    <Col md="4" className="mb-3">
                      <label className="control-label">Client’s City</label>
                      <Select isMulti={false} options={location_master} name="client_location" onChange={e => this.setState({ client_location: e })} classNamePrefix="select2-selection" />
                    </Col>
                    {this.state.showNoOfPeople && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="no_of_people">No of People</Label>
                        <Input name="no_of_people" placeholder="Type No of People" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("no_of_people", this.state.no_of_people, "string")}
                      </Col>
                    )}
                    {this.state.showNoOfFunctions && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="no_of_functions">No of Functions</Label>
                        <Input name="no_of_functions" placeholder="Type No of Functions" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("no_of_functions", this.state.no_of_functions, "string")}
                      </Col>
                    )}
                    {this.state.showNoOfDays && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="no_of_days">No of Days</Label>
                        <Input name="no_of_days" placeholder="Type No of Days" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("no_of_days", this.state.no_of_days, "string")}
                      </Col>
                    )}
                    {this.state.showWhatStarHotel && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="what_star_hotel">What Star Hotel</Label>
                        <Input name="what_star_hotel" placeholder="Type What Star Hotel" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("what_star_hotel", this.state.what_star_hotel, "string")}
                      </Col>
                    )}
                    {this.state.showCommunity && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="community">Community</Label>
                        <Input name="community" placeholder="Type Community" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("community", this.state.community, "string")}
                      </Col>
                    )}
                    {this.state.showArtist && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="type_of_artist_required">Type of Artist required</Label>
                        <Input name="type_of_artist_required" placeholder="Type of Artist required" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("type_of_artist_required", this.state.type_of_artist_required, "string")}
                      </Col>
                    )}
                    {this.state.showBudget && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="budget">Budget</Label>
                        <Input name="budget" placeholder="Type Budget" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("budget", this.state.budget, "string")}
                      </Col>
                    )}
                    {this.state.showServices && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="service_required">What services are required</Label>
                        <Select
                          isMulti={false}
                          options={this.state.services_master}
                          onChange={e => this.setState({ service_required: e })} 
                          classNamePrefix="select2-selection"
                          name="service_required"
                        />
                      </Col>
                    )}
                    {this.state.showFranchise && (  
                      <Col md="4" className="mb-3">
                        <label className="control-label">Type of Franchise</label>
                        <Select isMulti={false} name="type_of_franchise" options={[{ label: "Master", value: 1 }, { label: "Unit", value: 2 }]} classNamePrefix="select2-selection" onChange={e => this.setState({ type_of_franchise: e })} />
                        {this.validator.message("type_of_franchise", this.state.type_of_franchise, "")}
                      </Col>
                    )}
                    {this.state.showJobBusinessType && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="job_business_type">Job/Business Type</Label>
                        <Input name="job_business_type" placeholder="Job/Business Type" type="text" className={"form-control"}
                          onChange={this.handleInput} />
                        {this.validator.message("job_business_type", this.state.job_business_type, "string")}
                      </Col>
                    )}
                    <Col md="4" className="mb-3">
                      <Label htmlFor="assigned_to">Assigned to<span className="text-danger">*</span></Label>
                      <Select isMulti={false} name="assigned_to" options={employees_master} onChange={e => this.setState({ assigned_to: e })} classNamePrefix="select2-selection" />
                      {this.validator.message("assigned_to", this.state.assigned_to, "required")}
                    </Col>
                    {this.state.showProfile && (  
                      <Col md="4" className="mb-3">
                        <Label htmlFor="profile">Upload Profile - (PDF, WORD, PPT)</Label>
                        <Input className={"form-control"} id="profile" name="profile" accept=".pdf,.ppt,.word"  onChange={e => {this.handleProfile(e)}} placeholder="Select Attachment" type="file" />
                      </Col>
                    )}
                  </Row>

                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button color="primary" type="submit" disabled={this.state.submit}>
                      {this.state.submit === true ? "Please wait..." : "Submit"}
                    </Button>
                  </div>
                </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
  }
}

AddLead.propTypes = {
  attribs: PropTypes.any,
  hide: PropTypes.any,
  reloadBoard: PropTypes.func
};

export default AddLead