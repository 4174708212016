import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Input,
  Col,
} from "reactstrap"
import { Formik, Form } from "formik"
import {
  assignAttendance,
  fetchAssignAttendance,
  setSelectedEmployee,
} from "store/attendanceRule/attendanceRuleSlice"
import toastr from "toastr"

const AssignAttendanceForm = () => {
  const [menu, setMenu] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const dispatch = useDispatch()
  const { attendanceRuleData } = useSelector(
    state => state?.attendanceRule?.attendanceRule
  )
  const {
    selectedEmployee,
    attendanceAssignCurrentPage,
    attendanceAssignSearchQuery,
  } = useSelector(state => state.attendanceRule.assignAttendance)

  return (
    <Col md={3} key={selectedId}>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block mx-2"
      >
        <DropdownToggle
          style={{ background: "none", border: "none", fontSize: "14px" }}
        >
          <div>
            <p className="d-flex align-items-center mb-0 text-black">
              <i
                style={{
                  borderRadius: "50%",
                  padding: "3px",
                  color: "white",
                  background: "blue",
                }}
                className="bx bx-check-square mx-1 fs-5"
              />
              Assign Rules
            </p>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="p-2 dropdown-menu-responsive"
          style={{
            maxHeight: "240px",
            overflowY: "auto",
            minWidth: "250px",
            position: "relative",
            scrollbarWidth: "thin",
          }}
        >
          <Formik
            initialValues={{ selectedRuleId: "" }}
            onSubmit={async values => {
              const payload = {
                employees: selectedEmployee,
              }
              try {
                const response = await dispatch(
                  assignAttendance({
                    id: selectedId,
                    data: payload,
                  })
                )

                if (response?.payload.data?.data?.status) {
                  toastr.success(response?.payload.data?.data?.message)
                } else {
                  toastr.error("Something Went Wrong")
                }
              } catch (error) {
                toastr.error("Something Went Wrong")
              } finally {
                dispatch(
                  fetchAssignAttendance({
                    length: 10,
                    start: attendanceAssignCurrentPage,
                    keyword: attendanceAssignSearchQuery,
                  })
                )
                dispatch(setSelectedEmployee([]))
                setSelectedId("")
                setMenu(false)
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <div
                  style={{
                    maxHeight: "180px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {attendanceRuleData?.map(item => (
                    <DropdownItem
                      type="button"
                      className="mt-2"
                      key={item?.id}
                      toggle={false}
                    >
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          name="selectedRuleId"
                          id={`rule-${item.id}`}
                          value={item?.id}
                          className="form-check-input"
                          defaultChecked={selectedId === item?.id}
                          onChange={() => {
                            setSelectedId(item?.id)
                            setFieldValue("selectedRuleId", item?.id)
                          }}
                        />
                        <label
                          className="form-check-label mx-2"
                          htmlFor={`rule-${item.id}`}
                        >
                          {item?.name}
                        </label>
                      </div>
                    </DropdownItem>
                  ))}
                </div>
                <div
                  className="d-flex flex-wrap gap-2 justify-content-end mt-3"
                  style={{
                    position: "sticky",
                    bottom: "0",
                    background: "white",
                    padding: "8px 0",
                  }}
                >
                  <button
                    type="button"
                    onClick={() => setMenu(false)}
                    className="d-flex align-items-center btn btn-sm btn-outline-secondary"
                  >
                    <i className="dripicons-cross mx-1 d-flex" />
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="d-flex align-items-center btn btn-sm btn-primary mx-2"
                    disabled={
                      selectedEmployee?.length > 0 && selectedId ? false : true
                    }
                  >
                    <i className="bx bx-check mx-1 fs-5" />
                    {isSubmitting ? "Please Wait" : "Save"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </DropdownMenu>
      </Dropdown>
    </Col>
  )
}

export default AssignAttendanceForm
