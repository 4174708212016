import React, { useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchleaveRule,
  setLeaveRuleQuery,
  setLeaveRulePage,
} from "store/leaveRule/leaveRuleSlice"

export const LeaveRule = () => {
  const dispatch = useDispatch()

  const {
    leaveRuleSearchQuery,
    leaveRuleCurrentPage,
    leaveRuleData,
    leaveRuleTotalData,
    loading,
  } = useSelector(state => state?.leaveRule?.leaveRule)
  const { leaves } = useSelector(state => state.users.userPermissions);
  const location = useLocation()

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Sr. No.",
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(leaveRuleCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Description",
        accessor: "desc",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Leaves Count",
        accessor: "leaves_allowed",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
    ]
    if(leaves?.rules?.create_rule){
      baseColumns.push({
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => (
            <Link
              to={{
                pathname: `/user-leave-rule/edit`,
                state: { row: row.original },
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <i
                  className="bx bxs-edit cursor-pointer "
                  style={{ fontSize: "17px", color: "#039f03" }}
                />
              </div>
            </Link>
        ),
      })
    }
    return baseColumns
  }, [leaves?.rules?.create_rule, leaveRuleCurrentPage])

  useEffect(() => {
    dispatch(
      fetchleaveRule({
        length: 10,
        start: leaveRuleCurrentPage,
        keyword: leaveRuleSearchQuery,
        ...(location?.state?.row?.id && { employee_id: location.state.row.id }), // Only include employee_id if it's available
      })
    )
  }, [leaveRuleCurrentPage, leaveRuleSearchQuery, dispatch, location])

  return (
    <Card
      style={{
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      }}
    >
      <CardBody style={{ paddingBottom: "0px" }}>
        <Row className=" align-items-center">
          <Col md={6} sm={12}>
            <Search
              onSearch={value => {
                dispatch(setLeaveRuleQuery(value))
                dispatch(setLeaveRulePage(1))
              }}
            />
          </Col>
          
          {leaves?.rules?.create_rule &&<Col
            md={6}
            sm={12}
            className="mb-2 d-flex justify-content-end flex-wrap"
          >
            {!location?.state?.row?.id && <Link to="/user-leave-rule/add">
              <Button className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center">
                <i
                  className="dripicons-plus mx-2 d-flex"
                  style={{
                    border: "1px solid white",
                    borderRadius: "50%",
                  }}
                />
                Add
              </Button>
            </Link>}
          </Col>}
        </Row>
        <Row>
          <Col
            md={12}
            className="overflow-auto"
            style={{
              height: "56vh",
              scrollbarWidth: "thin",
              padding: "0px",
            }}
          >
            <GlobalTable
              columns={columns}
              data={leaveRuleData ? leaveRuleData : []}
              loading={loading}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter
        className="d-flex justify-content-end"
        style={{
          background: "#ECECEC",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <div
          style={{
            height: "30px",
            marginLeft: "0px",
          }}
        >
          <Pagination
            className="mx-2"
            leaveRuleCurrentPage={leaveRuleCurrentPage}
            totalCount={leaveRuleTotalData ? leaveRuleTotalData : 0}
            pageSize={10}
            onPageChange={async page => {
              dispatch(setLeaveRulePage(page))
            }}
          />
        </div>
      </CardFooter>
    </Card>
  )
}
