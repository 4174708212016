import React from 'react';
import { useSelector } from 'react-redux';
import { AttendanceRuleList } from '../AttendanceRule';
import { AttendanceApproval } from '../AttendanceApproval/AttendanceApproval';
import { AttendanceLogs } from '../AttendanceLogs';

export default function Attendences() {
const { leaves } = useSelector(state => state.users.userPermissions);
  const params = new URLSearchParams(location.search);
  let currentTab = params.get("tab") || "logs"; // Default to "logs" if no tab is set

  // Validating the current tab based on permissions
  const isValidTab = (tab) => {
    switch (tab) {
      case 'rule':
        return leaves?.rules?.show_rules;
      case 'approval':
        return leaves?.approvals;
      case 'logs':
        return true; // "Logs" is always valid
      default:
        return false; // Invalid tab
    }
  };

  // If the current tab is not valid, default to "logs"
  if (!isValidTab(currentTab)) {
    currentTab = "logs";
  }

  // Function to return the correct component based on the current tab
  const getTabContent = () => {
    switch (currentTab) {
      case 'rule':
        return <AttendanceRuleList />;
      case 'approval':
        return <AttendanceApproval />;
      default:
        return <AttendanceLogs />;
    }
  };

  return getTabContent();
}
