import React, { useEffect, useState, useRef, useMemo } from "react";
import { Row, Col, Card, CardBody, Label, CardFooter } from "reactstrap";
import MetaTags from 'react-meta-tags';
import Select from "react-select";
import { get } from "helpers/api_helper";
import GlobalTable from "components/Datatable/GlobalTable";
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr'; // Ensure toastr is imported
import { GET_EMPLOYEES_PERMISSION } from "helpers/api_url_helper";
import { CSVLink } from "react-csv";
import { fetchActivities, fetchLeadsAssigned, fetchLeadsClosed } from "store/crm/crmSlice";
import Pagination from "components/Common/Pagination";

const TandA = () => {
  let length = 10;
  const [employeesMaster, setEmployeesMaster] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [loading, setLoading] = useState(false); // Placeholder for loading state
  const [filterDate, setFilterDate] = useState({
    startDate:null,
    endDate:null
  })
  const [filterResourceName, setFilterResourceName] = useState({
    resource_name:null
  })
  const [filterReportingManager, setFilterReportingManager] = useState({
    reporting_manager:null
  })
  const [minEndDate, setMinEndDate] = useState(null);
  const csvInstance = useRef(); // Ref for CSV download
  const [activitiesCurrentPage, setActivitiesCurrentPage] = useState(1); // Default to page 1
  const [assignedLeadsCurrentPage, setAssignedLeadsCurrentPage] = useState(1); // Default to page 1
  const [closedLeadsCurrentPage, setClosedLeadsCurrentPage] = useState(1); 
  const headers = [
    { label: "Business Category", key: "business_category" },
    { label: "Resource Name", key: "resource_name" },
    { label: "Month", key: "month" },
    { label: "Target", key: "target" }
  ];

  const newData = [
    {
      business_category: "VOWS _VACHAN -WEDDINGS -(TURNKEY) -HNI (only Management)",
      resource_name: "Rohit Sharma",
      month: "July 2024",
      target: "4"
    }
  ];

  const { 
    leadsAssignedData, 
    leadsAssignedtotalData,
    activitiesData,
    ActivitiestotalData,
    leadsClosedData,
    leadsClosedtotalData
  } = useSelector(state => state.crm);
  const stageNames = {
    "new": "New",
    "connected": "Connected", 
    "notConnected": "Not Connected",
    "51percent": "51 Percent",
    "75percent": "75 Percent",
    "dead": "Dead",
    "future prospect": "Future Prospect",
    "confirmed": "Confirmed"
};
  const dispatch = useDispatch(); // Use dispatch from Redux

  const ClosedLeadsColumns = useMemo(() => [
    {
      id: "srNo",
      Header: () => <div style={{ width: "40px" }}>Sr. No</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
      ),
    },
    {
      id: "Brand",
      Header: () => <div style={{ width: "130px" }}>Brand</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original?.brand_name}</div>
      ),
    },
    {
      id: "Business Category",
      Header: () => <div style={{ width: "130px" }}>Business Category</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original?.business_category_name}</div>
      ),
    },
    {
      id: "Target",
      Header: () => <div style={{ width: "60px" }}>Target</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original?.target}</div>
      ),
    },
    {
      id: "Achievement",
      Header: () => <div style={{ width: "100px" }}>Achievement</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original?.achievement}</div>
      ),
    },
    // Add other column definitions as needed
  ], [closedLeadsCurrentPage]);

  const ActivitiesColumns = useMemo(() => [
    {
      id: "srNo",
      Header: () => <div style={{ width: "40px" }}>Sr. No</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
      ),
    },
    {
      id: "Title",
      Header: () => <div style={{ width: "130px" }}>Title</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original.title}</div>
      ),
    },
    {
      id: "Value",
      Header: () => <div style={{ width: "60px" }}>Value</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original.value}</div>
      ),
    },
    // Add other column definitions as needed
  ], [currentPage]);

  const AssignedLeadsColumns = useMemo(() => [
    {
      id: "srNo",
      Header: () => <div style={{ width: "40px" }}>Sr. No</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
      ),
    },
    {
      id: "Brand",
      Header: () => <div style={{ width: "130px" }}>Brand</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original.brand_name}</div>
      ),
    },
    {
      id: "Business Category",
      Header: () => <div style={{ width: "130px" }}>Business Category</div>,
      accessor: (_row, index) => index + 1,
      width: 120,
      Cell: ({ row }) => (
        <div>{row.original.business_category_name}</div>
      ),
    },
    ...Object.keys(stageNames).map((key, index) => ({
      id: `Leads Stage ${index + 1}`,
      Header: () => <div style={{ width: "max-content", whiteSpace:"", maxWidth:"120px", minWidth:"100px" }}>{stageNames[key]}</div>, // Use value as header
      accessor: key, // Use key as accessor
      width: 120,
      Cell: ({ row }) => (
          <div>{row.original[key]}</div> // Access the value using the key
      ),
  }))
    // Add other column definitions as needed
  ], [assignedLeadsCurrentPage]);

  const downloadData = () => {
    setCsvLoad(true);
    setCsvData(newData);
    setTimeout(() => {
      csvInstance.current.link.click();
      setCsvLoad(false);
    }, 100); // Delay to ensure CSV data is set before clicking
  };


// Function to fetch assigned leads
const fetchAssignedLeads = (page) => {
  dispatch(
      fetchLeadsAssigned({
          length,
          start: page,
          filter:{
            start_date: filterDate.startDate,
            end_date: filterDate.endDate,
            resource_name:filterResourceName.resource_name,
            reporting_manager:filterReportingManager.reporting_manager
          }
      })
  );
};

const fetchClosedLeads = (page) => {
  dispatch(
      fetchLeadsClosed({
          length,
          start: page,
          filter:{
            start_date: filterDate.startDate,
            end_date: filterDate.endDate,
            resource_name: filterResourceName.resource_name,
            reporting_manager: filterReportingManager.reporting_manager
          }
      })
  );
};

// Function to fetch activities
const fetchActivitiesData = () => {
  dispatch(
      fetchActivities({
        filter:{
          start_date: filterDate.startDate,
          end_date: filterDate.endDate,
          resource_name:filterResourceName.resource_name,
          reporting_manager:filterReportingManager.reporting_manager
        }
      })
  );
};

const  loadeEmployee = () => {
  get(GET_EMPLOYEES_PERMISSION, { params: { status: 1 } }).then(res => {
    if (res.status) {
      let data = res.data;
      setEmployeesMaster(data);
    }
  }).catch(err => {
    toastr.error(err)
  })
}

// Effect to load data on component mount and when filters change
useEffect(() => {
  fetchAssignedLeads(assignedLeadsCurrentPage);
  fetchClosedLeads(closedLeadsCurrentPage);
  fetchActivitiesData();
  loadeEmployee();
}, [assignedLeadsCurrentPage, closedLeadsCurrentPage, filterDate, filterResourceName, filterReportingManager]); // Dependencies include page numbers and filter dates

// Example methods to handle page changes
const handleAssignedLeadsPageChange = (newPage) => {
  setAssignedLeadsCurrentPage(newPage);
};

const handleClosedLeadsPageChange = (newPage) => {
  setClosedLeadsCurrentPage(newPage);
};

  const handleFilterDateChange = (e) => {
    const { name, value } = e.target; // Destructure name and value from e.target
    if(name=='startDate'){
      setMinEndDate(value)
    }
    setFilterDate((prev) => ({
      ...prev, // Spread the previous state
      [name]: value, // Update the specific field based on the name
    }));
    
  };
  
  const handleFilterResourceNameChange = (selectedOption) => {
    setFilterResourceName({
      resource_name: selectedOption ? selectedOption.value : null
    });
  };
  
  const handleFilterReportingManagerChange = (selectedOption) => {
    setFilterReportingManager({
      reporting_manager: selectedOption ? selectedOption.value : null
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Target / Achievement | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <Card className="sticky-main-header">
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <div className="mt-2 mb-3">
                    <h4 className="m-0">Target / Achievement</h4>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="sticky-main-header">
            <CardBody>
              <div className="row">
                <div className="col-sm-4 mt-2">
                  <Label htmlFor="">Resource Name</Label>
                  <Select
                    isMulti={false}
                    options={employeesMaster}
                    onChange={handleFilterResourceNameChange}
                    placeholder={'Select Resource name'}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="col-sm-4 mt-2">
                  <Label htmlFor="">Reporting Manager</Label>
                  <Select
                    isMulti={false}
                    options={employeesMaster}
                    onChange={handleFilterReportingManagerChange}
                    placeholder={'Select Reporting manager name'}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 mt-2">
                  <Label className="mb-1">Start Date</Label>
                  <input
                    type="date"
                    name="startDate"
                    value={filterDate.startDate}
                    onChange={handleFilterDateChange}
                    className="form-control"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4 mt-2">
                  <Label className="mb-1">End Date</Label>
                  <input
                    type="date"
                    name="endDate"
                    min={minEndDate}
                    value={filterDate.endDate}
                    onChange={handleFilterDateChange}
                    className="form-control"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="col-sm-12 mt-3">

                <Row>
                  <Col md="8" className="mt-4">
                    <h5 className="modal-title mb-3">Leads Closed</h5>
                    <div>
                      <Row>
                        <Col
                          md={12}
                          className="overflow-auto"
                          style={{
                            height: "56vh",
                            scrollbarWidth: "thin",
                            padding: "0px",
                          }}
                        >
                          <GlobalTable
                            columns={ClosedLeadsColumns}
                            data={leadsClosedData}
                            loading={loading}
                          />
                        </Col>
                      </Row>
                    </div>
                    <CardFooter
                      className="d-flex justify-content-end"
                      style={{
                        background: "#ECECEC",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          marginLeft: "0px",
                        }}
                      >
                        <Pagination
                          className="mx-2"
                          currentPage={closedLeadsCurrentPage}
                          totalCount={leadsClosedtotalData}
                          pageSize={length}
                          onPageChange={page => {
                            handleClosedLeadsPageChange(page)
                          }}
                        />
                      </div>
                    </CardFooter>
                  </Col>
                  <Col md="4" className="mt-4">
                    <h5 className="modal-title mb-3">Activities</h5>
                    <div>
                      <Row>
                        <Col
                          md={12}
                          className="overflow-auto"
                          style={{
                            height: "56vh",
                            scrollbarWidth: "thin",
                            padding: "0px",
                          }}
                        >
                          <GlobalTable
                            columns={ActivitiesColumns}
                            data={activitiesData}
                            loading={loading}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md="12" className="mt-4">
                    <h5 className="modal-title mb-3">Leads Assigned</h5>
                    <div>
                        <Col
                          md={12}
                          className="overflow-auto"
                          style={{
                            height: "56vh",
                            scrollbarWidth: "thin",
                            padding: "0px",
                          }}
                        >
                          <GlobalTable
                            columns={AssignedLeadsColumns}
                            data={leadsAssignedData}
                            loading={loading}
                          />
                        </Col>
                    </div>
                    <CardFooter
                      className="d-flex justify-content-end"
                      style={{
                        background: "#ECECEC",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          marginLeft: "0px",
                        }}
                      >
                        <Pagination
                          className="mx-2"
                          currentPage={assignedLeadsCurrentPage}
                          totalCount={leadsAssignedtotalData}
                          pageSize={length}
                          onPageChange={page => {
                            handleAssignedLeadsPageChange(page)
                          }}
                        />
                      </div>
                    </CardFooter>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <CSVLink
        data={csvData}
        headers={headers}
        filename={"data.csv"}
        className="hidden"
        ref={csvInstance}
      />
    </React.Fragment>
  );
};

export default TandA;