import React, { useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchattendanceRule,
  setAttendanceRuleQuery,
  setAttendanceRulePage,
} from "store/attendanceRule/attendanceRuleSlice"

export const AttendanceRule = () => {
  const dispatch = useDispatch()
  const { attendance } = useSelector(state => state.users.userPermissions);
  const location = useLocation()

  const {
    attendanceRuleSearchQuery,
    attendanceRuleCurrentPage,
    attendanceRuleData,
    attendanceRuleTotalData,
    loading,
  } = useSelector(state => state?.attendanceRule?.attendanceRule)

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: "Sr. No.",
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(attendanceRuleCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Description",
        accessor: "desc",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Working Days",
        accessor: "working_days",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "In Time",
        accessor: "in_time",
        Cell: ({ value }) => <div>{value !== "00:00:00" ? value : "-"}</div>,
      },
      {
        Header: "Out Time",
        accessor: "out_time",
        Cell: ({ value }) => <div>{value !== "00:00:00" ? value : "-"}</div>,
      },
      // {
        // Header: () => <div style={{ width: "120px" }}>Clock In Location</div>,
        // accessor: "clock_in_location",
        // Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      // },
      // {
        // Header: () => <div style={{ width: "130px" }}>Clock Out Location</div>,
        // accessor: "clock_out_location",
        // Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      // },
    ];

    if (attendance?.rules?.create_rule) {
      baseColumns.push({
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => (
          <Link
            to={{
              pathname: `/user-attendance-rule/edit`,
              state: { row: row.original },
            }}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              <i
                className="bx bxs-edit cursor-pointer "
                style={{ fontSize: "17px", color: "#039f03" }}
              />
            </div>
          </Link>
        ),
      });
    }

    return baseColumns;
  }, [attendance?.rules?.create_rule, attendanceRuleCurrentPage])

  useEffect(() => {
    dispatch(
      fetchattendanceRule({
        length: 10,
        start: attendanceRuleCurrentPage,
        keyword: attendanceRuleSearchQuery,
        ...(location?.state?.row?.id && { employee_id: location.state.row.id }), // Only include employee_id if it's available
      })
    )
  }, [attendanceRuleCurrentPage, attendanceRuleSearchQuery, dispatch, location])

  return (
    <Card
      style={{
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      }}
    >
      <CardBody style={{ paddingBottom: "0px" }}>
        <Row className=" align-items-center">
          <Col md={6} sm={12}>
            <Search
              onSearch={value => {
                dispatch(setAttendanceRuleQuery(value))
                dispatch(setAttendanceRulePage(1))
              }}
            />
          </Col>
          
          {attendance?.rules?.create_rule && <Col
            md={6}
            sm={12}
            className="mb-2 d-flex justify-content-end flex-wrap"
          >
            {!location?.state?.row?.id && <Link to="/user-attendance-rule/add">
              <Button className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center">
                <i
                  className="dripicons-plus mx-2 d-flex"
                  style={{
                    border: "1px solid white",
                    borderRadius: "50%",
                  }}
                />
                Add
              </Button>
            </Link>}
          </Col>}
        </Row>
        <Row>
          <Col
            md={12}
            className="overflow-auto"
            style={{
              height: "56vh",
              scrollbarWidth: "thin",
              padding: "0px",
            }}
          >
            <GlobalTable
              columns={columns}
              data={attendanceRuleData ? attendanceRuleData : []}
              loading={loading}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter
        className="d-flex justify-content-end"
        style={{
          background: "#ECECEC",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <div
          style={{
            height: "30px",
            marginLeft: "0px",
          }}
        >
          <Pagination
            className="mx-2"
            currentPage={attendanceRuleCurrentPage}
            totalCount={attendanceRuleTotalData ? attendanceRuleTotalData : 0}
            pageSize={10}
            onPageChange={async page => {
              dispatch(setAttendanceRulePage(page))
            }}
          />
        </div>
      </CardFooter>
    </Card>
  )
}
