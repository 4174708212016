import React, { useEffect, useMemo } from "react"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchAssetType,
  setCurrentPage,
  setOpenAssetModal,
  setAssetSearchQuery,
  setAssetTypeRowData,
} from "store/assetType/assetTypeSlice"
import "../../../MultiPageLink.css"
import useExportXlsx from "components/Hooks/useExportXlsx"

 export const AssetTypeView = () => {
  const {
    currentPage,
    assetSearchQuery,
    assetTypeData,
    assetTypeTotalData,
    loading,
  } = useSelector(state => state.assetType);

  const dispatch = useDispatch()
  const { exportXlsx, xlsxLoader } = useExportXlsx()


  const columns = useMemo(() => {
    return [
      {
        Header: "Sr. No.",
        accessor: (row, index) => index + 1,
        width: 120,
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Ordering",
        accessor: "ordering",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <div>{value == 1?(
          <span className="badge badge-pill bg-success font-size-13">
          Active
        </span>)
         : (<span className="badge badge-pill bg-danger font-size-13">
         Inactive
       </span>) }</div>,
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => {
          return (
            <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <i
                    onClick={async () => {
                      await dispatch(setAssetTypeRowData(row?.original))
                      await dispatch(setOpenAssetModal(true))
                    }}
                    className="bx bxs-edit cursor-pointer "
                    style={{ fontSize: "17px", color: "#039f03" }}
                  />
                </div>
            </div>
          )
        },
      },
    ]
  }, [dispatch])
  useEffect(() => {
    dispatch(
      fetchAssetType({
        length: 10,
        start: currentPage,
        keyword: assetSearchQuery.length > 0? assetSearchQuery : undefined,
        // status: 1,
      })
    )
  }, [currentPage, assetSearchQuery])

  const handleAdd = async () => {
    await dispatch(setAssetTypeRowData([]))
    await dispatch(setOpenAssetModal(true))
  }


  return (

    <div className="row">
         <div className="col-md-12">
         <h5 className="mb-3">ASSET TYPE</h5>
         <Card className="mt-3">
          <CardBody>
          <Row className=" align-items-center">
             <Col md={2} sm={12} style={{ marginTop: "10px" }}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setAssetSearchQuery(value))
                    dispatch(setCurrentPage(1))
                  }}
                />
              </Col>

              <Col
                md={10}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
                style={{ marginTop: "10px" }}
              >
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  style={{ borderRadius: "5px", height: "38px" }}
                  onClick={() => exportXlsx("asset-types", "Asset Types")}
                  disabled={xlsxLoader}
                >
                  <i className="bx bx-export font-size-16 me-2" />
                  {xlsxLoader ? "Exporting..." : "Export"}
                </Button>
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
                {/* } */}
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                // className="overflow-auto"
                style={{
                  height: "60vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={assetTypeData ? assetTypeData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={currentPage}
                totalCount={assetTypeTotalData ? assetTypeTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setCurrentPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
