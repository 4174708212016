import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateAttendanceApproval,
  apiGetAttendanceApproval,
} from "services/AttendanceApprovalService"

export const SLICE_NAME = "attendanceApproval"

const initialState = {
  loading: false,
  attendanceApprovalData: [],
  totalData: 0,
  rejectAnomaliesForm:false,
  attendanceApprovalcurrentPage: 1,
  attendanceApprovalQuery: "",
  approvalStatus: "",
  anomalyToAction:[]
}

export const fetchAttendanceApproval = createAsyncThunk(
  `${SLICE_NAME}/fetchAttendanceApproval`,
  async ({ length, start, status, keyword, employee_id }) => {
    try {
      const response = await apiGetAttendanceApproval({
        length,
        start,
        status,
        keyword,
        employee_id
      })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const createAttendanceApproval = createAsyncThunk(
  `${SLICE_NAME}/createAttendanceApproval`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiCreateAttendanceApproval(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const attendanceApproval = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setAttendanceApprovalCurrentPage: (state, action) => {
      state.attendanceApprovalcurrentPage = action.payload
    },
    setAttendanceApprovalQuery: (state, action) => {
      state.attendanceApprovalQuery = action.payload
    },
    setApprovalStatus: (state, action) => {
      state.approvalStatus = action.payload
    },
    toggleAnomalyRejactionForm:(state, action) => {
      state.rejectAnomaliesForm = action.payload?.toggle
      state.anomalyToAction = action.payload?.data
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAttendanceApproval.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchAttendanceApproval.fulfilled, (state, action) => {
      state.loading = false
      state.attendanceApprovalData = action?.payload?.data?.data
      state.totalData = action?.payload?.data?.total
    })
    builder.addCase(fetchAttendanceApproval.rejected, state => {
      state.loading = false
    })
  },
})

export const {
  setLoading,
  setAttendanceApprovalCurrentPage,
  setAttendanceApprovalQuery,
  setApprovalStatus,
  toggleAnomalyRejactionForm
} = attendanceApproval.actions

export default attendanceApproval.reducer
