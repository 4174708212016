import React from "react"
import { ExpenseDocumentView } from "./ExpenseDocumentView"
import { Container } from "reactstrap"
import { ExpenseDocumentForm } from "./ExpenseDocumentForm"

export const ExpenseDocumentList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <ExpenseDocumentForm/>
        <ExpenseDocumentView />
      </Container>
    </div>
  )
}
