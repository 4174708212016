import React from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, Input, CardBody, Col, Container, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleReactValidator from 'simple-react-validator';
import { get, post, put } from "../../helpers/api_helper";
import { GET_VENDOR_DATA, EDIT_VENDOR, GET_ALL_MASTERS } from "../../helpers/api_url_helper";
import toastr from "toastr";
import PropTypes from "prop-types";
import Select from "react-select";
import '../../NewsTimeline.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class JobRoleEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      company_name: "",
      mobile: "",
      email: "",
      address: "",
      rate_amount: "",
      work_portfolio: "",
      remark: "",
      category: "",
      city: "",
      status: true,
      handleResponse: null,
      submit: false,
      vendor_category_master: [],
      location_master: [],
    };
    this.validator = new SimpleReactValidator({
      validators: {
        Number: {
          message: 'The :attribute must be a valid number.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[0-9]+$/) && params.indexOf(val) === -1;
          },
          required: false
        }
      }
    });
  }

  componentDidMount() {
    this.loadMaster();
    this.loadVendorDetails();
  }

  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'vendor_category,location' } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ vendor_category_master: data.vendor_category, location_master: data.location });
      }
    }).catch(err => {
      toastr.error(err);
    });
  };

  loadVendorDetails = () => {
    const params = { id: this.props.match.params.id};
    post(GET_VENDOR_DATA, params).then(res => {
      if (res.status) {
        const vendor = res.data;
        this.setState({
          name: vendor.name,
          company_name: vendor.company_name,
          mobile: vendor.mobile,
          email: vendor.email,
          address: vendor.address,
          rate_amount: vendor.rate_amount,
          work_portfolio: vendor.work_portfolio,
          remark: vendor.remark,
          category: vendor.category,
          city: vendor.city,
          status: res.data.status === "1",
          last_updated_by: res.data.last_updated_by,
          updated_at: res.data.updated_at
        });
      }
    }).catch(err => {
      toastr.error(err);
    });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = {
        "id": this.props.match.params.id,
        "name": this.state.name,
        "company_name": this.state.company_name,
        "mobile": this.state.mobile,
        "email": this.state.email,
        "address": this.state.address,
        "rate_amount": this.state.rate_amount,
        "work_portfolio": this.state.work_portfolio,
        "remark": this.state.remark,
        "category": this.state.category,
        "city": this.state.city,
        "status": this.state.status ? 1 : 0
      };
      this.setState({ submit: true });
      put(EDIT_VENDOR, formData)
      .then(response => {
        if (response.status) {
          this.setState({ submit: false });
          toastr.success('Vendor updated successfully.');
          const { history } = this.props;
          history.push('/manage-vendor');
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message);
        this.setState({ submit: false });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  
  handleCategoryChange = selectedOption => {
    this.setState({ category: selectedOption.value });
  };
  
  handleCityChange = selectedOption => {
    this.setState({ city: selectedOption.value });
  };
  
  convertDateTimeFormat = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Get day
    const day = date.getDate().toString().padStart(2, '0');

    // Get month and convert to full month name
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const month = monthNames[date.getMonth()];

    // Get year
    const year = date.getFullYear();

    // Get hours and convert to 12-hour format
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format hours and minutes
    const time = `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;

    return `${day} ${month} ${year} | ${time}`;
  };

  render() {
    const { vendor_category_master, mobile, location_master } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              Manage Vendor | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Manage Vendor" path="/manage-vendor" breadcrumbItem="Edit"/>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <h4 className="mb-3">Basic Details</h4>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="category">Category <span className="text-danger">*</span></Label>
                          <Select
                            isMulti={false}
                            name="category"
                            options={vendor_category_master}
                            value={vendor_category_master.find(cat => cat.value === this.state.category)}
                            onChange={this.handleCategoryChange}
                          />
                          {this.validator.message('category', this.state.category, 'required')}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="name">Name <span className="text-danger">*</span></Label>
                          <Input
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInput}
                          />
                          {this.validator.message('name', this.state.name, 'required')}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="company_name">Company Name</Label>
                          <input name="company_name"  value={this.state.company_name} placeholder="Type Company Name" defaultValue={this.state.company_name} type="text" className={"form-control"} onChange={this.handleInput}/>
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="Mobile">Mobile<span className="text-danger">*</span></Label>
                          <Input 
                              name="mobile" 
                              placeholder="Type Mobile" 
                              type="tel" 
                              value={this.state.mobile}
                              onChange={this.handleInput}
                              className="form-control" 
                          />
                          {this.validator.message("mobile", mobile, "required|Number")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="email">Email</Label>
                          <Input
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInput}
                          />
                          {this.validator.message('email', this.state.email, 'email')}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="city">City <span className="text-danger">*</span></Label>
                          <Select isMulti={false} name="city" options={location_master} onChange={this.handleCityChange} classNamePrefix="select2-selection"
                           value={location_master.find(loc => loc.value === this.state.city)} />
                          {this.validator.message("City", this.state.city, "required")}
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="address">Address</Label>
                          <textarea name="address" defaultValue={this.state.address}  placeholder="Type Address" rows={3} className={"form-control"}
                            onChange={this.handleInput} />
                        </Col>
                      </Row>
                      <Row>
                        <h4 className="mt-4 mb-3">Additional Details</h4>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="rate_amount">Rate/Amount</Label>
                          <Input name="rate_amount" value={this.state.rate_amount} placeholder="Type Rate/Amount" type="text" className={"form-control"}
                            onChange={this.handleInput} />
                          {this.validator.message("Rate/Amount", this.state.rate_amount, "Number")}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mb-3">
                          <Label htmlFor="work_portfolio">
                          Work Portfolio
                          </Label>
                          <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({work_portfolio: data})
                              }}
                              data={this.state.work_portfolio}
                              name="work_portfolio"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label htmlFor="remark">
                          Remark
                          </Label>
                          <CKEditor
                              editor={ClassicEditor}
                              onChange={(event, editor) => {
                                  const data = editor.getData();
                                  this.setState({remark: data})
                              }}
                              data={this.state.remark}
                              name="remark"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="auto" className="mb-3 align-self-end">
                          <div className="form-check form-switch form-switch-lg">
                            <input type="checkbox" className="form-check-input" id="current_status" checked={this.state.status} onClick={() => {this.setState({ status: !this.state.status, })}} />
                            <label className="form-check-label" htmlFor="current_status">Status</label>
                          </div>
                        </Col>
                      </Row>
                      {this.state.last_updated_by !== '' &&                      
                        <Row>
                          <Col md="auto" className="mb-3 align-self-end">
                                Last Updated By: {this.state.last_updated_by}
                          </Col>
                          <Col md="auto" className="mb-3 align-self-end">
                                Last Updated At: {this.convertDateTimeFormat(this.state.updated_at)}
                          </Col>
                        </Row>
                      }
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={this.state.submit}>
                          {this.state.submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

JobRoleEdit.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default JobRoleEdit;
