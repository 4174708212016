
import LeaveNavTabs from "components/LeaveNavTabs/LeaveNavTabs"
import { getUserId } from "constants/CommonFunction"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { apiGetLeaveLogTypes } from "services/LeaveLogService"

const LeaveBalance = () => {

  const location = useLocation()
  const activeTab = location.pathname
  const [leaveData, setLeaveData] = useState()
  useEffect(() => {
    getLeaveData()
  }, [])

  const getLeaveData = async ()=>{
    const newUserId = location?.state?.row?.id
        ? location?.state?.row?.id
        : getUserId()
    const response = await apiGetLeaveLogTypes(newUserId);
    console.log(response.data.data)
    setLeaveData(response.data.data)
  }

  return (
    <div className={activeTab.includes("/profile/view") ? "" : "page-content"}>
      <LeaveNavTabs />
      <h5>Leave</h5>
      <Row>
        <Col md={12}>
          <Row>
            {leaveData?.map((leave)=>{
              return <Col md={6} key={leave.id}>
              <Card className="mb-3">
                  <CardTitle className="font-weight-bold mb-0 p-3" style={{background:'#e0e5f0'}}>
                    {leave.name}
                  </CardTitle>
                <CardBody className="p-0"
                >
                  <Row
                    style={{
                        paddingLeft: 'calc(var(--bs-gutter-x)* 0.5)',
                        paddingRight: 'calc(var(--bs-gutter-x)* 0.5)'
                      }}
                  >
                    <Col sm={8}>
                      <ul className="p-3">
                        <li className="">
                          <div className="d-flex justify-content-between ">
                            Credited Leaves <span>{parseFloat(leave?.total)?.toFixed(2)}</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="d-flex justify-content-between ">
                            Total Leaves <span>{parseFloat(leave?.total)?.toFixed(2)}</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="d-flex justify-content-between ">
                            Applied Leaves <span>{parseFloat(leave?.applied)?.toFixed(2)}</span>
                          </div>
                        </li>
                        <li className="">
                          <div className="d-flex justify-content-between ">
                            Penalty Leaves <span>{0}</span>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col sm={4} style={{background:'#f3f5ff '}}>
                      <div className="balance d-flex justify-content-center flex-column align-item-center h-100">
                        <h1 className="d-block text-center text-info text-">{parseFloat(leave?.available)?.toFixed(2)}</h1>
                        <label className="d-block text-center" htmlFor="">Leave Balance</label>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            })}
            {!leaveData?.length > 0 && 
              <div className="d-flex">
                No Data Available
              </div>
            }
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default LeaveBalance
