import { ApiService } from "./ApiService"

export async function apiUpdatePassword(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `users/change_password`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiLoginUser(data) {
  return ApiService.fetchData({
    url: `auth/index_post`,
    method: "post",
    data: data,
  })
}
