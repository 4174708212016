import React from 'react'
import { useSelector } from 'react-redux'
import OnbordingAddressForm from '../OnbordingForms/OnbordingAddressForm'
import OnboardingAddressDetail from '../OnboardingDetails/OnboardingAddressDetail'

export const OnbordingAddressDetailView = () => {
  const {editAddressDetail} = useSelector((state) => state.onBoarding)
  return (
    <div>
      {editAddressDetail ? (
          <OnbordingAddressForm /> 
      ) : (
          <OnboardingAddressDetail />
      )}
    </div>
  )
}

