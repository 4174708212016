import { ApiService } from "./ApiService"

export async function apiGetleaveRule({ keyword, length, start, employee_id }) {
  return ApiService.fetchData({
    url: `leave_rules/index`,
    method: "get",
    params: { keyword, length, start,
      ...(employee_id && {employee_id:employee_id})
      },
  })
}

export async function apiGetAssignLeave({ length, start, keyword}) {
  return ApiService.fetchData({
    url: `users/get`,
    method: "get",
    params: { length, start, keyword},
  })
}

export async function apiCreateleaveRule(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: "leave_rules",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiAssignLeave(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `leave_rules/assign_employee/${id}`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditleaveRule(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `leave_rules/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiDeleteAssignLeave(id) {
  return ApiService.fetchData({
    url: `leave_rules/remove_assign_leave_rule/${id}`,
    method: "delete",
  })
}
