import React, { useEffect, useMemo, useCallback, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap"
import Pagination from "components/Common/Pagination"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchExpenseDocument,
  setCurrentPage,
  setDocumentModal,
  deleteExpenseDocument,
  setDocumentId,
  setDocumentContent,
  setIsModalViewOpen
} from "store/expenseReward/expenseDocumentSlice"
import { useLocation } from "react-router-dom"
import { formatDateFormat } from "constants/CommonFunction"
import toastr from "toastr"
import CommonModal from "components/Common/Modal"
import {
  setRewardTableRowData,
} from "store/expenseReward/expenseRewardSlice"
import {  Form } from "formik"

export const ExpenseDocumentView = () => {
  const {
    currentPage,
    expenseDocumentData,
    DocumentTotalData,
    documentContent,
    isModalOpen,
    loading,
    isModalViewOpen
  } = useSelector(state => state.expenseDocument)

  const { rewardRowData}= useSelector( state => state.expenseReward )

  const location = useLocation()
  const id = location.state?.id
  const expenseData = location.state?.expenseData;
  const dispatch = useDispatch()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [documentDelete, setDocumentDelete] = useState(null)

  const toggleDeleteModal = () => setConfirmDeleteModal(!confirmDeleteModal)

  const columns = useMemo(() => {
    return [
      {
        Header: "Sr. No.",
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: "Document",
        accessor: "document",
        Cell: ({ row }) => (
          <div>
            <a
              href={row.original.document}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#007bff",
                textDecoration: "underline",
              }}
            >
              {row.original.document ? "File" : "-"}
            </a>
            {/* <Button
              className="btn btn-info btn-sm mx-2"
              onClick={() => handleViewDocument(row.original.document)}
            >
               View
            </Button> */}
          </div>
        ),
      },
      {
        Header: "Created On",
        accessor: "created_on",
        Cell: ({ value }) => <div>{value ? formatDateFormat(value) : "-"}</div>,
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              <i
                onClick={e => handleDelete(row?.original.id, row?.original)}
                className="bx bxs-trash cursor-pointer "
                style={{ fontSize: "17px", color: "#039f03" }}
              />
            </div>
          </div>
        ),
      },
    ]
  }, [dispatch, id, currentPage])

  const handleDeleteConfirmation = async () => {
    if (documentDelete) {
      try {
        await dispatch(
          deleteExpenseDocument({ id: documentDelete.id, data: documentDelete })
        )
        await dispatch(
          fetchExpenseDocument({ id: id, length: 10, start: currentPage })
        )
        toastr.success("Document deleted successfully")
      } catch (error) {
        console.error("Error deleting document:", error)
        toastr.error("Failed to delete document. Please try again.")
      }
      setConfirmDeleteModal(false)
    }
  }

  const handleDelete = (id, data) => {
    setDocumentDelete({ id, data })
    setConfirmDeleteModal(true)
  }

  const handleData=async(id)=> {
    await dispatch(setRewardTableRowData(id))
     dispatch(setIsModalViewOpen(true))
    console.log(rewardRowData)
  }

  const handleViewDocument = useCallback(
    async url => {
      try {
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
        })
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        dispatch(setDocumentContent(objectURL))
        dispatch(setDocumentModal(true))
      } catch (e) {
        console.error("Error fetching document:", e)
      }
    },
    [dispatch]
  )

  useEffect(() => {

    const fetchExpense = async () => {
      // console.log("fetchExpenseDocument", id, currentPage)
      await dispatch(
        fetchExpenseDocument({ id: id, length: 10, start: currentPage })
      )
    }
    fetchExpense()
  }, [currentPage, dispatch, location])

  const handleAdd = async id => {
    dispatch(setDocumentId(id))
    await dispatch(setDocumentModal(true))
  }



 const expenseView=

 <Col xl="12">
   <Row className="expense-view-border">
   <Col md="12" className="expense-view-cell" >
     <Label htmlFor="employee">Employee :&nbsp;</Label>
     <Label htmlFor="employee">{`${expenseData.employee_first_name} ${expenseData.employee_last_name}`}</Label>
   </Col>
   <Col md="12" className="expense-view-cell">
       <Label htmlFor="type">Expense Type :&nbsp;</Label>
       <Label htmlFor="type">{expenseData.expense_master_name}</Label>
     </Col>
    <Col md="12" className="expense-view-cell">
       <Label htmlFor="amount">Amount :&nbsp;</Label>
       <Label htmlFor="amount">{expenseData.amount}</Label>
     </Col>
     <Col md="12" className="expense-view-cell">
       <Label htmlFor="remark">Remark :&nbsp;</Label>
       <Label htmlFor="remark">{expenseData.remark}</Label>
     </Col>

     <Col md="12" className="expense-view-cell">
     <Label htmlFor="dob">Expense Date :&nbsp;</Label>
     <Label htmlFor="dob">{formatDateFormat(expenseData.expense_date)}</Label>
     </Col>
     </Row>
 </Col>


  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="mb-3">EXPENSE DOCUMENT</h5>
        <Card>
          <CardBody>
            <Row className=" align-items-center">
              <Col md={6} sm={12}>
                {" "}
              </Col>
              <Col
                md={6}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
              >
                <div>
              <Button
                className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center">
                <i
                  onClick={()=> handleData(id)}
                  className="bx bx-info-circle cursor-pointer "
                  style={{ fontSize: "25px" }}
                />
                </Button>
                </div>
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={() => handleAdd(id)}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={expenseDocumentData ? expenseDocumentData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={currentPage}
                totalCount={DocumentTotalData ? DocumentTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setCurrentPage(page))
                }}
              />
            <CommonModal
            isOpen={isModalViewOpen}
            toggleModal={() => dispatch(setIsModalViewOpen(!isModalViewOpen))}
            modalTitle="Expense Info"
            modalBody={expenseView}
            // modalBody={<div>{JSON.stringify(expenseData)}</div>}
            showFooter={false}
            />
            </div>
          </CardFooter>
        </Card>
      </div>

      {/* Document preview modal */}
      <Modal
        isOpen={isModalOpen}
        toggle={() => dispatch(setDocumentModal(false))}
        size="lg"
        centered
      >
        <ModalHeader toggle={() => dispatch(setDocumentModal(false))}>
          Document Preview
        </ModalHeader>
        <ModalBody>
          <iframe
            src={documentContent}
            title="Document Preview"
            width="100%"
            height="500px"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => dispatch(setDocumentModal(false))}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>


      {/* Confirmation modal for deleting document */}
      <Modal isOpen={confirmDeleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete this document?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleDeleteConfirmation}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
