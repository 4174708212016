import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateResignationInfo,
  apiDeleteResignationInfo,
  apiEditResignationInfo,
  apiGetResignationInfo,
  apiResignationReasonMaster,
  apiUpdateStatus,
} from "services/ResignatioInfoService"

export const SLICE_NAME = "resignationInfo"

const initialState = {
  loading: false,
  openResignationInfoModal: false,
  tableRowData: [],
  employee_id: "",
  totalData: 0,
  currentPage: 1,
  rewardSearchQuery: "",
  reasonMaster:[],
  approvalConfirmation:false
}

export const fetchResignationInfo = createAsyncThunk(
  `${SLICE_NAME}/fetchResignationInfo`,
  async ({ employee_id }) => {
    try {
      const response = await apiGetResignationInfo({ employee_id })
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const fetchResignationReasonMaster = createAsyncThunk(
  `${SLICE_NAME}/fetchResignationReasonMaster`,
  async () => {
    try {
      const response = await apiResignationReasonMaster()
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const createResignationInfo = createAsyncThunk(
  `${SLICE_NAME}/createResignationInfo`,
  async ({employee_id, resignation_date, remark, resignation_reason_master_id}, { dispatch }) => {
    try {
      const response = await apiCreateResignationInfo({employee_id, resignation_date, remark, resignation_reason_master_id})
      return response?.data
    } catch (error) {
      return error.response.data
    }
  }
)

export const editResignationInfo = createAsyncThunk(
  `${SLICE_NAME}/editResignationInfo`,
  async ({employee_id, actual_last_day, remark, resignation_reason_master_id}) => {
    try {
      const response = await apiEditResignationInfo({employee_id, actual_last_day, remark, resignation_reason_master_id})
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const updateStatus = createAsyncThunk(
  `${SLICE_NAME}/updateStatus`,
  async ({employeeId, status}) => {
    try {
      const response = await apiUpdateStatus({employeeId, status})
      return response
    } catch (error) {
      throw error
    }
  }
)

const resignationInfo = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setApprovalConfirmation: (state, action) => {
      state.approvalConfirmation = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTableRowData: (state, action) => {
      state.tableRowData = action.payload
    },
    setEmployeeId: (state, action) => {
      state.employee_id = action.payload
    },
    setResignationInfoModal: (state, action) => {
      state.openResignationInfoModal = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setRewardSearch: (state, action) => {
      state.rewardSearchQuery = action.payload
    },
  },
  extraReducers: builder => {
    builder
      // Fetch Resignation Info
      .addCase(fetchResignationInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchResignationInfo.fulfilled, (state, action) => {
        state.loading = false
        state.tableRowData = action.payload.data
        state.rewardTotalData = action.payload.total
      })
      .addCase(fetchResignationInfo.rejected, (state) => {
        state.loading = false
      })

      // Create Resignation Info
      .addCase(createResignationInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(createResignationInfo.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(createResignationInfo.rejected, (state) => {
        state.loading = false
      })

      // Edit Resignation Info
      .addCase(editResignationInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(editResignationInfo.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(editResignationInfo.rejected, (state) => {
        state.loading = false
      })

      // Delete Resignation Info
      .addCase(updateStatus.pending, (state) => {
        state.loading = true
      })
      .addCase(updateStatus.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateStatus.rejected, (state) => {
        state.loading = false
      })

      // fetch Resignation Reason Master
      .addCase(fetchResignationReasonMaster.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchResignationReasonMaster.fulfilled, (state, action) => {
        state.loading = false
        state.reasonMaster = action.payload.data
      })
      .addCase(fetchResignationReasonMaster.rejected, (state) => {
        state.loading = false
      })

  },
})

export const { setLoading, setTableRowData, setEmployeeId, setResignationInfoModal, setApprovalConfirmation } =
  resignationInfo.actions

export default resignationInfo.reducer
