import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "helpers/api_helper";
import { apiGetLesadsActivities } from "services/CrmService";

export const SLICE_NAME = "crm";

const initialState = {
  loading: false,
  leadsAssignedData: [],
  leadsAssignedtotalData: 0,
  activitiesData: [],
  ActivitiestotalData: 0,
  leadsClosedData: [],
  leadsClosedtotalData: 0,
  currentPage: 1,
};

export const fetchLeadsAssigned = createAsyncThunk(
  `${SLICE_NAME}/fetchLeadsAssigned`,
  async ({ length, start, filter }) => {
    try {
      const params = {start, length, filter}
      // Await the response from the API call
      const response = await post('target_Achievement/lead_assign', params);
      // Return the response data
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLeadsClosed = createAsyncThunk(
  `${SLICE_NAME}/fetchLeadsClosed`,
  async ({ length, start, filter }) => {
    try {
      const params = {start, length, filter}
      // Await the response from the API call
      const response = await post('target_Achievement/lead_close', params);
      // Return the response data
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchActivities = createAsyncThunk(
  `${SLICE_NAME}/fetchActivities`,
  async ({ filter }) => {
    try {
      const params = {filter}
      // Await the response from the API call
      const response = await post('target_Achievement/activities', params);
      // Return the response data
      return response;
    } catch (error) {
      throw error;
    }
  }
);


const crm = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeadsAssigned.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLeadsAssigned.fulfilled, (state, action) => {
      state.loading = false;
      state.leadsAssignedData = action?.payload?.data;
      state.leadsAssignedtotalData = action?.payload?.total;
    });
    builder.addCase(fetchLeadsAssigned.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.activitiesData = action?.payload?.data;
      state.ActivitiestotalData = action?.payload?.total;
    });
    builder.addCase(fetchActivities.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchLeadsClosed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLeadsClosed.fulfilled, (state, action) => {
      state.loading = false;
      state.leadsClosedData = action?.payload?.data;
      state.leadsClosedtotalData = action?.payload?.total;
    });
    builder.addCase(fetchLeadsClosed.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setLoading, setCurrentPage } = crm.actions;

export default crm.reducer;
