import { ApiService } from "./ApiService"

export async function apiGetAssetType({ length, start, keyword,status}) {
  return ApiService.fetchData({
    url: `asset_type_master`,
    method: "get",
    params: { length, start,keyword,status},
  })
}

export async function apiEditAssetType(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `asset_type_master/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data:urlEncodedPayload,
  })
}

export async function apiCreateAssetType(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `asset_type_master`,
    method: "Post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}
