import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { downloadSampleCsv, getUserId } from "constants/CommonFunction";
import { fetchProfileDetail, setUserId } from "store/onBoarding/onBordingSlice";
import { GET_SIDEBAR_MENU_MASTER } from "helpers/api_url_helper";
import { get } from "helpers/api_helper";

const SidebarContent = (props) => {
  const location = useLocation();
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const dispatch = useDispatch();
  const ref = useRef();

  useEffect(() => {
    getSidebarMenu();
  }, []);

  useEffect(() => {
    if (sidebarMenu.length > 0) {
      initMenu();
    }
  }, [sidebarMenu, location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  const getSidebarMenu = async () => {
    try {
      const res = await get(GET_SIDEBAR_MENU_MASTER);
      if (res.status) {
        setSidebarMenu(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const initMenu = async () => {
    new MetisMenu("#side-menu");

    const pathName = location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

    const newUserId = location?.state?.row?.id ? location?.state?.row?.id : getUserId();
    await dispatch(setUserId(newUserId));
    await dispatch(fetchProfileDetail(newUserId));
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active");
          parent3.childNodes[0].classList.add("mm-active");
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show");
              parent5.childNodes[0].classList.add("mm-active");
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  };

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  };
  const downloadCSV =()=>{
    downloadSampleCsv();
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sidebarMenu.map((menuItem) => (
              <li key={menuItem.id}>
                <Link to={menuItem.url || "/#"} className={menuItem.sub_menu.length > 0 ? "has-arrow" : ""}>
                  <i className={menuItem.class_icon}></i>
                  <span>{props.t(menuItem.name)}</span>
                </Link>
                {menuItem.sub_menu.length > 0 && (
                  <ul className="sub-menu" aria-expanded="false">
                    {menuItem.sub_menu.map((subMenu) => {
                      if (props.t(subMenu.name)=="Sample Csv File") {
                        return <li key={subMenu.id}>
                        <a onClick={downloadCSV}>{props.t(subMenu.name)}</a>
                        </li>
                      } else {
                        return <li key={subMenu.id}>
                        <Link to={subMenu.url || "/#"}>{props.t(subMenu.name)}</Link>
                      </li>
                      }
                    })}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
