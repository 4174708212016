export function getUserDetails() {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const user = JSON.parse(localStorage.getItem("user"))
  return authUser?.token || user?.token || null
}

export function getUserId() {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const user = JSON.parse(localStorage.getItem("user"))
  return authUser?.user_id || user?.user_id || null
}

export const clearUserDetails = () => {
  localStorage.removeItem("userDetails")
  localStorage.removeItem("user")
}

export function getUserFirstName() {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const user = JSON.parse(localStorage.getItem("user"))
  return authUser?.first_name || user?.first_name || ""
}

export const getFullName = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const user = JSON.parse(localStorage.getItem("user"))
  return `${
    authUser?.first_name + " " + authUser?.last_name ||
    user?.first_name + " " + user?.last_name ||
    ""
  }`
}

export const getUserProfile = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const user = JSON.parse(localStorage.getItem("user"))
  return authUser?.profile_photo || user?.profile_photo || ""
}

export const locations = (location, locationId) => {
  return location.find(item => item?.value === locationId)?.label
}

export const modifyDate = dateString => {
  const datePart = dateString.split(" ")[0]
  return datePart
}

export function getCredentials() {
  const empId = sessionStorage.getItem("emp_id")
  const password = sessionStorage.getItem("password")
  return {
    emp_id: empId,
    password: password,
  }
}

export function formatDateFormat(dateString, format = "DD-MM-YYYY") {
  if (!dateString || dateString === "0000-00-00") return "-";

  // Check if the date is already in ISO format (with or without time)
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}(T.*)?$/;

  // Ensure date is in proper ISO format
  if (!isoDateRegex.test(dateString)) {
    dateString = dateString.split(' ').join('T'); // Replace space with 'T' if needed
  }

  // Create a Date object
  const date = new Date(dateString);

  // Handle invalid date issue more gracefully
  if (isNaN(date.getTime())) {
    console.error("Invalid date encountered:", dateString);
    return "-"; 
  }

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  switch (format) {
    case "DD-MM-YYYY":
      return `${day}-${month}-${year}`;
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    default:
      console.error("Unsupported format:", format);
      return "-"; 
  }
}


export const getcurrentMonthDateRange =()=>{
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const currentRange = [firstDayOfMonth, today];
  return currentRange
}

export const formatDate = (date, reverse) => {
  if (!date) return "-";
  const d = new Date(date);
  // Check if the created date is invalid (e.g., Safari issues)
  if (isNaN(d.getTime())) {
    console.error("Invalid date:", date);
    return "-"; // Returning a fallback if date parsing fails
  }
  const year = d.getFullYear()
  const month = String(d.getMonth() + 1).padStart(2, "0")
  const day = String(d.getDate()).padStart(2, "0")
  if(reverse) return `${year}-${month}-${day}`
  return `${day}-${month}-${year}`
}

export function sortName(str) {
  const matches = str.match(/\b(\w)/g)
  return matches.join("")
}

export const sortFirstLetter = str => {
  return str.split("")[0].toUpperCase()
}

export const getMonthName = value => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthIndex = parseInt(value, 10) - 1
  if (monthIndex >= 0 && monthIndex < months.length) {
    return months[monthIndex]
  } else {
    return "-"
  }
}

export const displayValue = value => {
  return value ?? value === "" ? "-" : value
}

export const generateTooltip = approvals => {
  if (!approvals || approvals.length === 0) return "No approvals"
  return Object.values(approvals)
    .map(({ employee_first_name, employee_last_name, status }) => {
      const statusText =
        status == "1" ? "Approved" : status == "2" ? "Rejected" : "Pending"
      return `${employee_first_name} ${employee_last_name} (${statusText})`
    })
    .join("\n")
}

export function getCurrentDayAndDate() {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  const now = new Date()
  const day = days[now.getDay()]
  const date = now.getDate()
  const month = months[now.getMonth()]
  const year = now.getFullYear()

  return `${day} ${date}, ${month} ${year}`
}

export const downloadSampleCsv = () => {
  const filePath = "/Client_ import-data-hrms.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "Client_ import-data-hrms.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const extractTime = datetimeString => {
  if (
    datetimeString == "00:00:00" ||
    datetimeString == null ||
    datetimeString == "0"
  ) {
    return "-"
  }
  try {
    // Split the datetime string by space to separate date and time
    const [, timePart] = datetimeString?.split(" ")
    return timePart || ""
  } catch (error) {
    console.error("Invalid datetime format:", error)
    return ""
  }
}

export const attendanceStatus = status => {
  switch (status) {
    case "1":
      return "Present" // Present
    case "2":
      return "Absent" // Absent
    case "3":
      return "Anomaly"
    case "4":
      return "Requested For Approval"
    case "5":
      return "Present"
    case "6":
      return "Anomaly Absent"
    case "7":
      return "Anomaly Penalty Deduction"
    case "8":
      return "Week Off"
    case "9":
      return "Leave"
    case "10":
      return "Holiday"
    case "0":
    case null:
      return "A"
    default:
      return "A"
  }
}

export const leaveStatus = status => {
  switch (status) {
    case "0":
      return "Pending"
    case "1":
      return "Approved"
    case "2":
      return "Rejected"
    case "3":
      return "Cancelled"
    default:
      return "Pending"
  }
}
// Utility function to format the upcoming event string
export const formatUpcomingEvent = (title, dateTimeString) => {
  // Parse the date-time string
  const date = new Date(dateTimeString);

  // Format the date to DD/MM/YYYY
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Format the time to HH:MM
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Construct the formatted string
  return `Upcoming ${title} on ${day}/${month}/${year} at ${hours}:${minutes}`;
};
export function getTimeDifference(pastTime) {
  // Validate input format
  const timePattern = /^(\d{1,2}):(\d{2}):(\d{2})$/;
  const match = pastTime.match(timePattern);
  
  if (!match) {
    throw new Error("Invalid time format. Please use 'HH:MM:SS'.");
  }

  const [_, pastHours, pastMinutes, pastSeconds] = match.map(Number);
  
  // Create a date object for the past time
  const now = new Date();
  const pastDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), pastHours, pastMinutes, pastSeconds);

  // Calculate difference in milliseconds
  const diffMs = now - pastDate;

  // If the difference is negative, it means the pastTime is in the future
  if (diffMs < 0) {
    return "00:00:00"; // or handle it as needed
  }

  const diffSec = Math.floor(diffMs / 1000);
  const diffHours = Math.floor(diffSec / 3600);
  const diffMinutes = Math.floor((diffSec % 3600) / 60);
  const diffSeconds = diffSec % 60;

  return `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}:${diffSeconds.toString().padStart(2, "0")}`;
}

export const downloadVendorSampleCsv = () => {
  const filePath = "/import_vendor_sample_data.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "import_vendor_sample_data.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadEsCateorySampleCsv = () => {
  const filePath = "/es_cateory_sample_import.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "es_cateory_sample_import.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadEsElementSampleCsv = () => {
  const filePath = "/es_element_sample_import.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "es_element_sample_import.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadLeadSampleCsv = () => {
  const filePath = "/import_lead_sample_data.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "import_lead_sample_data.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadLeadSourceSampleCsv = () => {
  const filePath = "/lead_source_sample_import.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "lead_source_sample_import.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadVendorCategorySampleCsv = () => {
  const filePath = "/vendor_category_sample_import.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "vendor_category_sample_import.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadTandASampleCsv = () => {
  const filePath = "/assign_target.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "assign_target.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const downloadEstransactionSampleCsv = () => {
  const filePath = "/es_transaction.xlsx"
  const link = document.createElement("a")
  link.href = filePath
  link.setAttribute("download", "es_transaction.xlsx")
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const currentDate = () => {
  return new Date().toISOString().split("T")[0]
}

export const convertHtmlToFormattedText = (html) => {
  // Create a temporary DOM element to parse the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Recursive function to walk through the nodes and format the text
  const processNode = (node) => {
    let text = '';

    // Handle different node types
    if (node.nodeType === Node.TEXT_NODE) {
      // Handle text nodes (like inner text)
      text += node.nodeValue;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Handle element nodes (like <a>, <b>, <i>, etc.)
      switch (node.nodeName.toLowerCase()) {
        case 'a':
          // Format anchor tags as links
          text += node.textContent + ' (' + node.getAttribute('href') + ')';
          break;
        case 'b':
        case 'strong':
          // Format bold text
          text += '**' + node.textContent + '**';
          break;
        case 'i':
        case 'em':
          // Format italic text
          text += '*' + node.textContent + '*';
          break;
        case 'p':
        case 'div':
          // Format paragraphs and divs with line breaks
          text += processChildren(node) + '\n\n';
          break;
        case 'br':
          // Handle line breaks
          text += '\n';
          break;
        default:
          // Process other tags recursively
          text += processChildren(node);
          break;
      }
    }
    return text;
  };

  // Process children nodes recursively
  const processChildren = (node) => {
    let text = '';
    node.childNodes.forEach((child) => {
      text += processNode(child);
    });
    return text;
  };

  // Convert the parsed HTML to formatted text
  return processChildren(tempDiv).trim();
};

export const convertHtmlToCsvFormattedText = (html) => {
  // Create a temporary DOM element to parse the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Function to ensure URL has correct protocol
  const ensureProtocol = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  };

  // Recursive function to walk through the nodes and format the text
  const processNode = (node) => {
    let text = '';

    // Handle different node types
    if (node.nodeType === Node.TEXT_NODE) {
      // Handle text nodes (like inner text)
      text += node.nodeValue;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      // Handle element nodes (like <a>, <b>, <i>, etc.)
      switch (node.nodeName.toLowerCase()) {
        case 'a':
          // Format anchor tags as Excel hyperlink formula with quotes around URL
          let url = node.getAttribute('href');
          const displayText = node.textContent.trim();
          if (url) {
            url = ensureProtocol(url); // Ensure URL has correct protocol
            text += `=HYPERLINK("${url}", "${displayText}")`;
          } else {
            text += displayText;
          }
          break;
        case 'b':
        case 'strong':
          // Format bold text
          text += '**' + node.textContent + '**';
          break;
        case 'i':
        case 'em':
          // Format italic text
          text += '*' + node.textContent + '*';
          break;
        case 'p':
        case 'div':
          // Format paragraphs and divs with line breaks
          text += processChildren(node) + '\n';
          break;
        case 'br':
          // Handle line breaks
          text += '\n';
          break;
        default:
          // Process other tags recursively
          text += processChildren(node);
          break;
      }
    }
    return text.trim();
  };

  // Process children nodes recursively
  const processChildren = (node) => {
    let text = '';
    node.childNodes.forEach((child) => {
      text += processNode(child);
    });
    return text;
  };

  // Convert the parsed HTML to formatted text for CSV
  return processChildren(tempDiv).trim();
};
