import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateUsers,
  apiGetUsers,
  apiEmployeeType,
  apiGetDepartment,
  apiGetDesignation,
  apiGetProbationPeriod,
  apiGetReportingManager,
  apiGetSubDepartment,
  apiGetNoticePeriod,
  apiGetOfficeUserLocation,
  apiGetJobTitle,
} from "services/UsersService"

export const SLICE_NAME = "users"

const initialState = {
  loading: false,
  usersData: [],
  UserstotalData: 0,
  currentPage: 1,
  userSearchQuery: "",
  globalSearchQuery: "",
  employeeTypeData: [],
  departmentData: [],
  subDepartmentData: [],
  designationData: [],
  probationData: [],
  managerData: [],
  noticePeriodData: [],
  officeLocationData: [],
  jobTitleData: [],
  verifyUser: true,
  userSearchData: [],
  userPermissions:{},
  userProfilePicture:""
}


export const fetchUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchDeductionReward`,
  async ({ length, start, keyword, status }) => {
    try {
      const response = await apiGetUsers({ length, start, keyword, status })
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const createUsers = createAsyncThunk(
  `${SLICE_NAME}/createUsers`,
  async data => {
    try {
      const response = await apiCreateUsers(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchEmployeeTypeUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeTypeUsers`,
  async () => {
    try {
      const response = await apiEmployeeType()
      return {
        status: "success",
        data: response?.data?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchDepartmentUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchDepartmentUsers`,
  async () => {
    try {
      const response = await apiGetDepartment()
      return {
        status: "success",
        data: response?.data?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchSubDepartmentUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchSubDepartmentUsers`,
  async department_id => {
    try {
      const response = await apiGetSubDepartment(department_id)
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchDesignationUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchDesignationUsers`,
  async () => {
    try {
      const response = await apiGetDesignation()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchUsersNoticePeriod = createAsyncThunk(
  `${SLICE_NAME}/fetchUsersNoticePeriod`,
  async () => {
    try {
      const response = await apiGetNoticePeriod()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchProbationPeriodUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchProbationPeriodUsers`,
  async () => {
    try {
      const response = await apiGetProbationPeriod()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchReportingManagerUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchReportingManagerUsers`,
  async () => {
    try {
      const response = await apiGetReportingManager()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchUsersOfficeLocation = createAsyncThunk(
  `${SLICE_NAME}/fetchUsersOfficeLocation`,
  async () => {
    try {
      const response = await apiGetOfficeUserLocation()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchUsersJobTitle = createAsyncThunk(
  `${SLICE_NAME}/fetchUsersJobTitle`,
  async () => {
    try {
      const response = await apiGetJobTitle()
      return {
        status: "success",
        data: response.data.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const users = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setUsersCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setUserSearchQuery: (state, action) => {
      state.userSearchQuery = action?.payload
    },
    setGlobalSearchQuery: (state, action) => {
      state.globalSearchQuery = action?.payload
    },
    setUserPermissions: (state, action) =>{
      state.userPermissions = action?.payload
    },
    setUserProfilePicture: (state, action) => {
      state.userProfilePicture = action?.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUsers.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false
      state.verifyUser = action?.payload?.add_user
      state.usersData = action?.payload?.data
      state.UserstotalData = action?.payload?.total
    })
    builder.addCase(fetchUsers.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchEmployeeTypeUsers.fulfilled, (state, action) => {
      state.employeeTypeData = action.payload.data.map(employeeType => ({
        value: employeeType.value,
        label: employeeType.label,
      }))
    })
    builder.addCase(fetchDepartmentUsers.fulfilled, (state, action) => {
      state.departmentData = action.payload.data.map(department => ({
        value: department?.id,
        label: department?.name,
      }))
    })
    builder.addCase(fetchSubDepartmentUsers.fulfilled, (state, action) => {
      state.subDepartmentData = action.payload.data.map(subDepartment => ({
        value: subDepartment?.id,
        label: subDepartment?.name,
      }))
    })
    builder.addCase(fetchDesignationUsers.fulfilled, (state, action) => {
      state.designationData = action.payload.data.map(designation => ({
        value: designation?.id,
        label: designation?.name,
      }))
    })
    builder.addCase(fetchProbationPeriodUsers.fulfilled, (state, action) => {
      state.probationData = action.payload.data.map(probation => ({
        value: probation?.id,
        label: probation?.name,
      }))
    })
    builder.addCase(fetchReportingManagerUsers.fulfilled, (state, action) => {
      state.managerData = action.payload.data.map(manager => ({
        value: manager?.id,
        label: `${manager?.first_name} ${manager?.last_name}`,
      }))
    })
    builder.addCase(fetchUsersNoticePeriod.fulfilled, (state, action) => {
      state.noticePeriodData = action.payload.data.map(noticePeriod => ({
        value: noticePeriod?.id,
        label: noticePeriod?.name,
      }))
    })
    builder.addCase(fetchUsersOfficeLocation.fulfilled, (state, action) => {
      state.officeLocationData = action.payload.data.map(location => ({
        value: location?.id,
        label: location?.name,
      }))
    })
    builder.addCase(fetchUsersJobTitle.fulfilled, (state, action) => {
      state.jobTitleData = action.payload.data.map(job => ({
        value: job?.id,
        label: job?.name,
      }))
      state.jobTitleData.push({ value: "0", label: "Other" })
    })
  },
})

export const {
  setLoading,
  setUsersCurrentPage,
  setUserSearchQuery,
  setGlobalSearchQuery,
  setUserPermissions,
  setUserProfilePicture
} = users.actions

export default users.reducer
