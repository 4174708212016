import { getUserId } from "constants/CommonFunction"
import { ApiService } from "./ApiService"


export async function apiGetLeaveLog({
  start,
  length,
  keyword,
  status,
  employee_id,
}) {
  return ApiService.fetchData({
    url: `leaves/logs`,
    method: "get",
    params: {
      start,
      length,
      keyword,
      status,
      employee_id,
    },
  })
}

export async function apiGetLeaveLogEmployee() {
  return ApiService.fetchData({
    url: `leaves/logs`,
    method: "get",
  })
}

export async function apiGetLeaveLogTypes(id) {
  return ApiService.fetchData({
    url: `leave_rules/emplyee_active_leave_rules/${id}`,
    method: "get",
  })
}

export async function apiCreateLeaveLog(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: "leaves/apply_leave",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiCancelLeaveLog(id) {
  return ApiService.fetchData({
    url: `leaves/cancel_leave/${id}`,
    method: "put",
  })
}
