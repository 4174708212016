import React from "react"
import { AssetTypeView } from "./AssetTypeView"
import { Container } from "reactstrap"
import { AssetTypeForm } from "./AssetTypeForm"

export const AssetTypeList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <AssetTypeForm/>
        <AssetTypeView />
      </Container>
    </div>
  )
}
