import { ApiService } from "./ApiService"

export async function apiForgetPassword(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `auth/forget_password`,
    method: "Put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiResetPassword(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `auth/reset_password`,
    method: "PUT",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  });
}


export async function apiVerifyToken({ token }) {
  return ApiService.fetchData({
    url: `auth/verify_reset_link`,
    method: "get",
    params: { token },
  })
}
