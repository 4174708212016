import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link, useLocation } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { getFullName, getUserId, getUserProfile, sortName } from "constants/CommonFunction"
import { fetchAuthProfileDetail } from "store/onBoarding/onBordingSlice"

const ProfileMenu = props => {
  const userId = getUserId();

  const [menu, setMenu] = useState(false)
  const { authUserProfile  } = useSelector(
    state => state.onBoarding
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if(userId){
      dispatch(fetchAuthProfileDetail(userId))
    }
  }, [props.success])


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        onMouseEnter={() => setMenu(true)}
        onMouseLeave={() => setMenu(false)}
      >
        <DropdownToggle
          className="btn header-item d-flex gap-2"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="avatar-xs align-self-center mx-2">
            {authUserProfile.profile_photo ? (
              <img
                src={authUserProfile?.profile_photo}
                alt="Profile Preview"
                className="avatar-title rounded-circle text-primary"
                style={{
                  backgroundColor: "white",
                  height: "45px",
                  width: "45px",
                }}
              />
            ) : (
              <span
                className="avatar-title rounded-circle text-primary"
                style={{
                  backgroundColor: "white",
                  height: "45px",
                  width: "45px",
                }}
              >
                {sortName(`${authUserProfile?.first_name} ${authUserProfile?.last_name}`)}
              </span>
            )}
          </div>
          <div className="mx-3 mt-3">
            <i
              className={`fas fa-chevron-down text-white fs-4 icon-transition ${
                menu ? "icon-transition-up" : "icon-transition-down"
              }`}
            />
          </div>
        </DropdownToggle>
        <DropdownMenu className={`dropdown-menu-end ${menu ? "show" : ""}`}>
          <DropdownItem tag="div" className="d-block">
          <Link to={'/profile/view'} className="d-block">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </Link>
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <Link to="/change-password">
              <i className="mdi mdi-lock font-size-16 align-middle me-1" />
              {props.t("Change Password")}
            </Link>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
