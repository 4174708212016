import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiCreateExpenseReward,
  apiEditExpenseRewardRule,
  apiGetExpenseReward ,
  apiGetEmployeeData,
  apiApproveStatus,
  apiGetExpenseTypeData} from "services/ExpenseRewardService"
export const SLICE_NAME = "expenseReward"

const initialState = {
  loading: false,
  openRewardModal: false,
  rewardRowData: [],
  expenseRewardData: [],
  expenseRewardtotalData: 0,
  rewardTotalData: 0,
  currentPage: 1,
  rewardSearchQuery: "",
  employeeFormData: [],
  expenseTypeFormData:[],
  DocRowDataID:0,
  review:"",
  employeeViewData:[],
  EmployeeTypeId:"",
  dateRange:[null,null]
}

export const fetchExpenseReward = createAsyncThunk(
  `${SLICE_NAME}/fetchExpenseReward`,
  async ({ length, start , keyword ,status,employee_id,from_date,to_date}) => {
    try {
      const response = await apiGetExpenseReward({ length, start, keyword,status,employee_id,from_date,to_date })
      return response?.data
    } catch (error) {
      throw error
    }
  }
)

export const editExpenseReward = createAsyncThunk(
  `${SLICE_NAME}/editExpenseReward`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiEditExpenseRewardRule(id, data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const createExpenseReward = createAsyncThunk(
  `${SLICE_NAME}/createExpenseReward`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiCreateExpenseReward(data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const fetchEmployeeFormData = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeFormData`,
  async () => {
    try {
      const response = await apiGetEmployeeData()
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchExpenseTypeFormData = createAsyncThunk(
  `${SLICE_NAME}/fetchExpenseTypeFormData`,
  async () => {
    try {
      const response = await apiGetExpenseTypeData()
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const updateStatus = createAsyncThunk(
  `${SLICE_NAME}/approveStatus`,
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await apiApproveStatus(id, data);
      return response;
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      });
    }
  }
);

const expenseRewardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setRewardTableRowData: (state, action) => {
      state.rewardRowData = action.payload
    },
    setExpenseRewardModal: (state, action) => {
      state.openRewardModal = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setRewardSearch: (state, action) => {
      state.rewardSearchQuery = action.payload
    },
    setDocRowDataID:(state, action) => {
      state.DocRowDataID=action.payload
    },
    setReview:(state,action)=>{
      state.review = action.payload
    },
    setEmployeeTypeId:(state,action)=>{
      state.EmployeeTypeId=action.payload
    },
    setDateRange:(state,action)=>{
      state.dateRange=action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchExpenseReward.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchExpenseReward.fulfilled, (state, action) => {
      state.loading = false
      state.expenseRewardData = action?.payload?.data
      state.rewardTotalData = action?.payload?.total
    })
    builder.addCase(fetchExpenseReward.rejected, state => {
      state.loading = false
    })
     builder.addCase(fetchEmployeeFormData.fulfilled, (state, action) => {
      const initialOptions={value:"",label:"All"}
      const fetchOptions= action.payload.data?.data?.data?.map(
      employee => ({
        value: employee.id,
        label: `${employee?.first_name} ${employee?.last_name}`,
      }))
      state.employeeFormData =fetchOptions
      state.employeeViewData=[initialOptions,...fetchOptions]
  })
  builder.addCase(fetchExpenseTypeFormData.fulfilled, (state, action) => {
    state.expenseTypeFormData = action.payload.data?.data?.data?.filter(expense=> expense.status==1)
    .map(
      expense => ({
        value: expense.id,
        label: `${expense?.name}`,
      })
    )
  })
  },
})

export const {
  setLoading,
  setRewardTableRowData,
  setExpenseRewardModal,
  setCurrentPage,
  setRewardSearch,
  setDocRowDataID,
  setReview,
  setEmployeeTypeId,
  setDateRange
} = expenseRewardSlice.actions

export default expenseRewardSlice.reducer
