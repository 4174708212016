import React from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { useDispatch } from "react-redux"
import { setEditPersonalDetail } from "store/onBoarding/onBordingSlice"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

export const PaidLeaveForm = () => {
  const dispatch = useDispatch()
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <MetaTags>
            <title>
              Manage User-boarding | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={{
                      leaveType: "",
                      description: "",
                      weekendsCountAsLeave: false,
                      holidaysCountAsLeave: false,
                      leaveAllowed: "",
                      accrualBasis: false,
                      allowedUnderProbation: false,
                      allowedUnderNoticePeriod: false,
                      leaveEncashEnabled: false,
                      maxEncash: "",
                      carryForwardEnabled: false,
                      maxCarryForward: "",
                    }}
                    validationSchema={Yup.object({
                      leaveType: Yup.string().required(
                        "Leave Type is required"
                      ),
                      description: Yup.string().required(
                        "Description is required"
                      ),
                      leaveAllowed: Yup.number()
                        .required("Leaves Allowed in Year is required")
                        .positive("Leaves Allowed must be positive"),
                      maxEncash: Yup.number().when("leaveEncashEnabled", {
                        is: true,
                        then: Yup.number()
                          .required("Max leaves encash allowed is required")
                          .positive(
                            "Max leaves encash allowed must be positive"
                          ),
                        otherwise: Yup.number(),
                      }),
                      maxCarryForward: Yup.number().when(
                        "carryForwardEnabled",
                        {
                          is: true,
                          then: Yup.number()
                            .required(
                              "Max leaves allowed to carry forward is required"
                            )
                            .positive(
                              "Max leaves allowed to carry forward must be positive"
                            ),
                          otherwise: Yup.number(),
                        }
                      ),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      console.log(values)
                      toastr.success("User update successful.")
                      setSubmitting(false)
                      resetForm()
                      dispatch(setEditPersonalDetail(false))
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      isSubmitting,
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <Row>
                          <Col md={12}>
                            <CardTitle className="text-primary">
                              Paid Leaves (Pro-rata)
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="text-danger">
                              Leave Data
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md="4" className="mb-3">
                            <Label htmlFor="leaveType">
                              Leave Type<span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="leaveType"
                              placeholder="Leave Type"
                              type="text"
                              className={`form-control ${
                                touched.leaveType && errors.leaveType
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.leaveType && errors.leaveType && (
                              <div className="text-danger">
                                {errors.leaveType}
                              </div>
                            )}
                          </Col>
                          <Col md="6" className="mb-3">
                            <Label htmlFor="description">
                              Description Name
                            </Label>
                            <Field
                              as="textarea"
                              name="description"
                              placeholder="Description"
                              className={`form-control ${
                                touched.description && errors.description
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.description && errors.description && (
                              <div className="text-danger">
                                {errors.description}
                              </div>
                            )}
                          </Col>
                          <Col md={12}>
                            <CardTitle className="text-danger">
                              Leaves Count
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md="5" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="weekendsCountAsLeave"
                              name="weekendsCountAsLeave"
                            />
                            <Label
                              htmlFor="weekendsCountAsLeave"
                              className="mx-2"
                            >
                              Weekends between leave - count as leave
                            </Label>
                          </Col>
                          <Col md="5" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="holidaysCountAsLeave"
                              name="holidaysCountAsLeave"
                            />
                            <Label
                              htmlFor="holidaysCountAsLeave"
                              className="mx-2"
                            >
                              Holiday between leave - Count as leave
                            </Label>
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label htmlFor="leaveAllowed">
                              Leaves Allowed in Year
                            </Label>
                            <Field
                              type="number"
                              name="leaveAllowed"
                              placeholder="Leaves Allowed"
                              className={`form-control ${
                                touched.leaveAllowed && errors.leaveAllowed
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {touched.leaveAllowed && errors.leaveAllowed && (
                              <div className="text-danger">
                                {errors.leaveAllowed}
                              </div>
                            )}
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="text-danger">
                              Accrual
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md="12" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="accrualBasis"
                              name="accrualBasis"
                            />
                            <Label htmlFor="accrualBasis" className="mx-2">
                              Creditable on Accrual basis
                            </Label>
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="text-danger">
                              Applicability
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md="5" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="allowedUnderProbation"
                              name="allowedUnderProbation"
                            />
                            <Label
                              htmlFor="allowedUnderProbation"
                              className="mx-2"
                            >
                              Allowed under probation
                            </Label>
                          </Col>
                          <Col md="5" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="allowedUnderNoticePeriod"
                              name="allowedUnderNoticePeriod"
                            />
                            <Label
                              htmlFor="allowedUnderNoticePeriod"
                              className="mx-2"
                            >
                              Allowed under Notice Period
                            </Label>
                          </Col>
                          <Col md={12} className="mt-2">
                            <CardTitle className="text-danger">
                              Leave Encash
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md="12" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="leaveEncashEnabled"
                              name="leaveEncashEnabled"
                              onChange={e => {
                                setFieldValue("maxEncash", "") // Reset maxEncash if leaveEncashEnabled changes
                                setFieldValue(
                                  "leaveEncashEnabled",
                                  e.target.checked
                                )
                              }}
                            />
                            <Label
                              htmlFor="leaveEncashEnabled"
                              className="mx-2"
                            >
                              Leave Encash Enabled
                            </Label>
                          </Col>
                          {values.leaveEncashEnabled && (
                            <Col md="4" className="mb-3">
                              <Label htmlFor="maxEncash" className="mx-2">
                                Max leaves encash allowed
                              </Label>
                              <Field
                                type="number"
                                name="maxEncash"
                                placeholder="Max leaves encash allowed"
                                className={`form-control ${
                                  touched.maxEncash && errors.maxEncash
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {touched.maxEncash && errors.maxEncash && (
                                <div className="text-danger">
                                  {errors.maxEncash}
                                </div>
                              )}
                            </Col>
                          )}
                          <Col md={12} className="mt-2">
                            <CardTitle className="text-danger">
                              Carry Forward
                            </CardTitle>
                            <hr />
                          </Col>
                          <Col md="12" className="mb-3">
                            <Input
                              type="checkbox"
                              className="cursor-pointer"
                              id="carryForwardEnabled"
                              name="carryForwardEnabled"
                              onChange={e => {
                                setFieldValue("maxCarryForward", "") // Reset maxCarryForward if carryForwardEnabled changes
                                setFieldValue(
                                  "carryForwardEnabled",
                                  e.target.checked
                                )
                              }}
                            />
                            <Label
                              htmlFor="carryForwardEnabled"
                              className="mx-2"
                            >
                              Carry forward enabled
                            </Label>
                          </Col>
                          {values.carryForwardEnabled && (
                            <Col md="4" className="mb-3">
                              <Label htmlFor="maxCarryForward" className="mx-2">
                                Max leaves allowed to carry forward
                              </Label>
                              <Field
                                type="number"
                                name="maxCarryForward"
                                placeholder="Max leaves allowed to carry forward"
                                className={`form-control ${
                                  touched.maxCarryForward &&
                                  errors.maxCarryForward
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {touched.maxCarryForward &&
                                errors.maxCarryForward && (
                                  <div className="text-danger">
                                    {errors.maxCarryForward}
                                  </div>
                                )}
                            </Col>
                          )}
                        </Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                          <Link to="/user-leave-rules">
                            <Button
                              color="info"
                              className="d-flex align-items-center"
                              type="button"
                            >
                              <i className="dripicons-cross mx-1 d-flex" />{" "}
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}
