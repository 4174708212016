import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import {
  bloodGroups,
  employmentType,
  maritalStatuses,
} from "constants/Constants"
import { useDispatch, useSelector } from "react-redux"
import {
  editPersonalInfo,
  fetchAuthProfileDetail,
  fetchProfileDetail,
  setEditPersonalDetail,
} from "store/onBoarding/onBordingSlice"
import { GET_OFFICE_LOCATION } from "helpers/api_url_helper"
import AsyncSelect from 'react-select/async';
import { get } from "helpers/api_helper"
import { values } from "lodash"
import { getUserId } from "constants/CommonFunction"

const OnbordingPersonalFrom = () => {
  const { profileDetails, userId } = useSelector(state => state.onBoarding)
  const dispatch = useDispatch();
  const authUserId = getUserId();

  const [office_location, setOffice_location] = useState(null);
  // Fetch office location options from API based on the search input
  const loadCurrentOptions = async (inputValue) => {
    const params = { keyword: inputValue, length: 150 };
    return get(GET_OFFICE_LOCATION, { params: params })
      .then((res) => {
        if (res.total>0) {
          return res.data.map((location) => ({
            label: location.name,
            value: location.id,
          }));
        }
      })
      .catch((err) => {
        toastr.error(err);
        return [];
      });
  };

  useEffect(() => {
    setOffice_location({value:profileDetails?.office_location_id, label:profileDetails?.office_location_name})
  }, [])
  
  // Handle office location selection
  const handleChange = (selectedOption) => {
    setOffice_location(selectedOption);
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    first_name: profileDetails?.first_name
                      ? profileDetails?.first_name
                      : "",
                    last_name: profileDetails?.last_name
                      ? profileDetails?.last_name
                      : "",
                    gender: profileDetails?.gender
                      ? profileDetails?.gender
                      : "",
                    blood_group: profileDetails?.blood_group
                      ? profileDetails?.blood_group
                      : "",
                    marital_status: profileDetails?.marital_status
                      ? profileDetails?.marital_status
                      : "",
                    dob: profileDetails?.dob ? profileDetails?.dob : "",
                    doj: profileDetails?.date_of_joining
                      ? profileDetails?.date_of_joining
                      : "",
                    office_location_id: profileDetails?.office_location_id? profileDetails?.office_location_id:""
                  }}
                  validationSchema={Yup.object({
                    first_name: Yup.string()
                      .min(3, "Must be at least 3 characters")
                      .max(30, "Must be at most 30 characters")
                      .required("Required"),
                    last_name: Yup.string()
                      .min(3, "Must be at least 3 characters")
                      .max(25, "Must be at most 25 characters")
                      .required("Required"),
                    gender: Yup.string().required("Required"),
                    blood_group: Yup.string(),
                    marital_status: Yup.string(),
                    office_location_id: Yup.string().required("Required"),
                    dob: Yup.date()
                      .min(
                        new Date("01-01-1950"),
                        "Must Not be smaller than 01-01-1950"
                      )
                      .max(new Date(), "Must be smaller than current date")
                      .required("Required"),
                    doj: Yup.date()
                      // .max(new Date(), "Must be smaller than current date")
                      .required("Required"),
                  })}
                  onSubmit={async values => {
                    const payload = {
                      ...values,
                      id: userId,
                    }
                    const response = await dispatch(editPersonalInfo(payload))
                    if (response.payload?.status === "success") {
                      toastr.success(response?.payload?.data?.data?.message)
                    } else {
                      toastr.error("Something went wrong.")
                    }
                    await dispatch(fetchProfileDetail(userId));
                    if(userId == authUserId){
                      await dispatch(fetchAuthProfileDetail(authUserId))
                    } 
                    await dispatch(setEditPersonalDetail(false))
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    dirty,
                    touched,
                  }) => (
                    <Form>
                      <Row>
                        <Col md={12}>
                          <CardTitle className="header-text">
                            Personal Info
                          </CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="first_name">
                            First Name<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="first_name"
                            placeholder="First Name"
                            type="text"
                            className={`form-control ${
                              touched.first_name && errors.first_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="last_name">
                            Last Name<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="last_name"
                            placeholder="Last Name"
                            type="text"
                            className={`form-control ${
                              touched.last_name && errors.last_name
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="dob">
                            Date of Birth<span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="dob"
                            placeholder="First Name"
                            type="date"
                            className={`form-control ${
                              touched.dob && errors.dob ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="dob"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="dob">
                            Date of Joining
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="doj"
                            placeholder="First Name"
                            type="date"
                            className={`form-control ${
                              touched.doj && errors.doj ? "is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="doj"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="gender">
                            Gender<span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="gender"
                            value={employmentType?.find(
                              type => type.value === values?.gender
                            )}
                            onChange={option =>
                              setFieldValue("gender", option.value)
                            }
                            options={employmentType}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="blood_group">
                            Blood Group
                          </Label>
                          <Select
                            name="blood_group"
                            value={bloodGroups?.find(
                              group => group.value === values?.blood_group
                            )}
                            onChange={option =>
                              setFieldValue("blood_group", option.value)
                            }
                            options={bloodGroups}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="marital_status">
                            Marital Status
                          </Label>
                          <Select
                            name="marital_status"
                            value={maritalStatuses?.find(
                              status => status.value === values?.marital_status
                            )}
                            onChange={option =>
                              setFieldValue("marital_status", option.value)
                            }
                            options={maritalStatuses}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label className="labels" htmlFor="office_location">
                            Office Location
                          </Label>
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            name="office_location"
                            loadOptions={loadCurrentOptions}
                            onChange={option =>
                            {setFieldValue("office_location_id", option.value); setOffice_location(option)}
                            }
                            value={office_location}
                            placeholder="Select Office Location"
                          />
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={() => {
                            dispatch(setEditPersonalDetail(false))
                          }}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <button
                          className="btn btn-md btn-primary"
                          type="submit"
                          disabled={isSubmitting || !dirty}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnbordingPersonalFrom
