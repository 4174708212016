// useExportXlsx.js
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { exportXlsxFile } from "store/master/masterSlice"
import toastr from "toastr"

const useExportXlsx = () => {
  const dispatch = useDispatch()
  const { xlsxLoader } = useSelector(state => state.master)
  const exportXlsx = useCallback(
    async (payload, fileName, filter) => {
      try {
        const response = await dispatch(
          exportXlsxFile({ master_name: payload, filter })
        )
        console.log(response, "Verify Response")
        if (response.payload.status === "success") {
          const url = window.URL.createObjectURL(
            new Blob([response.payload.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          )
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", `${fileName}.xlsx`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          toastr.success("File downloaded successfully!")
        } else {
          toastr.error("Failed to export file")
          console.error("Failed to export file:", response)
        }
      } catch (error) {
        toastr.error("Error during file export: " + error.message)
        console.error("Error during file export:", error)
      }
    },
    [dispatch]
  )

  return { exportXlsx, xlsxLoader }
}

export default useExportXlsx
