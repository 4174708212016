import React from "react";
import { Row, Col, Input, Label, Modal, Button } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import SimpleReactValidator from "simple-react-validator";
import { get, put } from "../../helpers/api_helper";
import { EDIT_ES_TRANSACTION_DATA, GET_ALL_MASTERS, GET_ES_ELEMENT,GET_ES_TRANSACTION_DATA} from "../../helpers/api_url_helper";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios"
import toastr from "toastr";
class EditTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      es_category_master: [],
      es_element_master: [],
      employees_master: [],
      selectedEsCategory: null,  // Selected category (single select)
      selectedEsElement: null,   // Selected element (single select)
      selectedTeamMembers: [], 
      es_category_id: "",
      es_element_id: "",
      from_date: "",
      to_date: "",
      no_days: "",
      location: "",
      size: "",
      total_size: "",
      quantity: "",
      internal_rate: "",
      internal_total: "",
      margin_percent: "",
      external_rate: "",
      external_total: "",
      expected_GOP: "",
      actual_GOP: "",
      advance: "",
      balance: "",
      remark: "",
      team_members: [],
      submit: false,
      isShowing: false,
      handleResponse: null,
      formSubmitSuccess: false
    }
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        percentage: {
          message: "The :attribute must be a valid percentage 0r Value (e.g., 20%, 200)",
          rule: (val) => {
            // Validate number with an optional percentage sign
            return /^\d+(\.\d{1,2})?%?$/.test(val);
          },
        },
      },
    });
  }

  componentDidMount() {
    this.loadMaster();
    this.getEsTransaction();
  }

  loadMaster = () => {
    get(GET_ALL_MASTERS, { params: { masters: 'employees,es_category_master' } }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ employees_master: data.employees, es_category_master: data.es_category_master })
      }
    }).catch(err => {
      toastr.error(err)
    })
  } 
  
    // Helper function to find option by ID   
    findOptionById = (id, options) => {
      return options.find(option => option.value === id);
    };    
  
    // Helper function to find multiple options by IDs
    findOptionsByIds = (ids, options) => {
      if (ids && Array.isArray(ids)) {
        return ids.reduce((result, id) => {
          const matchingOptions = options.filter(option => id.member_id.includes(option.value));
          return result.concat(matchingOptions); // Accumulate the matching options
        }, []);
        console.log(ids);
      }
      return [];
    };
    
  getEsTransaction = () => {
    get(GET_ES_TRANSACTION_DATA, { params: { leads_id: this.props.leads_id, id: this.props.es_transaction_id} }).then(res => {
      if (res.status) {
        let data = res.data;
        this.setState({ 
          from_date: data.from_date,
          to_date: data.to_date,
          no_days: data.no_days,
          location: data.location,
          size: data.size,
          total_size: data.total_size,
          quantity: data.quantity,
          internal_rate: data.internal_rate,
          internal_total: data.internal_total,
          margin_percent: data.margin_percent,
          external_rate: data.external_rate,
          external_total: data.external_total,
          expected_GOP: data.expected_GOP,
          actual_GOP: data.actual_GOP,
          advance: data.advance,
          balance: data.balance,
          remark: data.remark,
          selectedEsCategory: this.findOptionById(data.es_category_id, this.state.es_category_master),
          // selectedTeamMembers: this.findOptionsByIds(data.team_member, this.state.employees_master),
          selectedTeamMembers: data.team_member.map(member => ({
                label: member.label,
                value: member.value
            })),
         }, () => {     
            this.handleEsCategoryChange(this.state.selectedEsCategory, data.es_element_id);     
          });
      }
    }).catch(err => {
      toastr.error(err)
    })
  } 
  
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleResume = () => {
    let resume = document.getElementById('resume').files[0]
    this.setState({ resume: resume});
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = {
        "leads_id":this.props.leads_id,
        "id": this.props.es_transaction_id,
        "es_category_id": this.state.selectedEsCategory.value,
        "es_element_id": this.state.selectedEsElement.value,
        "from_date": this.state.from_date,
        "to_date": this.state.to_date,
        "no_days": this.state.no_days,
        "location": this.state.location,
        "size": this.state.size,
        "total_size": this.state.total_size,
        "quantity": this.state.quantity,
        "internal_rate": this.state.internal_rate,
        "internal_total": this.state.internal_total,
        "margin_percent": this.state.margin_percent,
        "external_rate": this.state.external_rate,
        "external_total": this.state.external_total,
        "expected_GOP": this.state.expected_GOP,
        "actual_GOP": this.state.actual_GOP,
        "advance": this.state.advance,
        "balance": this.state.balance,
        "remark": this.state.remark,
        "team_members": JSON.stringify(this.state.selectedTeamMembers)
      }

      this.setState({ submit: true });
      put(EDIT_ES_TRANSACTION_DATA, formData).then(res => {
        if (res.status) {
          this.setState({ submit: false })
          this.setState({
            isShowing: false
          });
          toastr.success('Es Transaction updated successful.')
          // this.setState({ formSubmitSuccess: true })
          this.props.hide(false)
          this.setState({
            es_category_id: "",
            es_element_id: "",
            from_date: "",
            to_date: "",
            no_days: "",
            location: "",
            size: "",
            total_size: "",
            quantity: "",
            internal_rate: "",
            internal_total: "",
            margin_percent: "",
            external_rate: "",
            external_total: "",
            expected_GOP: "",
            actual_GOP: "",
            advance: "",
            balance: "",
            remark: "",
            team_members: [],
          })
          this.props.reloadLead(true)
          this.props.reloadElementSheet(true)
        }
      }).catch(err => {
        if (err.response && err.response.status === 400) {
          const errorMessage = err.response.data.message || 'An error occurred';
          toastr.error(errorMessage);
        }
        this.setState({ submit: false });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  
  handleEsCategoryChange = (selectedEsCategory , selectedElementId = null) => {
    this.setState({ selectedEsCategory, selectedEsElement: null }, () => {
      // Revalidate the category field
      this.validator.showMessageFor("category");
    });
  
    if (selectedEsCategory) {
      get(GET_ES_ELEMENT, { params: { id: selectedEsCategory.value } })
        .then((response) => {
          //console.log(response);
          if (response.status) {
            this.setState({ es_element_master: response.data });
            if (selectedElementId) {
              this.setState({ selectedEsElement: this.findOptionById(selectedElementId, this.state.es_element_master)})
            }
          } else {
            this.setState({ es_element_master: [] });
            toastr.error(response.message || "No es element found.");
          }
        })
        .catch((error) => {
          //console.error("There was an error fetching the es element!", error);
          toastr.error("Error fetching es element.");
        });
    } else {
      this.setState({ es_element_master: [] });
    }
  };
  
  handleEsElementChange = (selectedEsElement) => {
    this.setState({ selectedEsElement }, () => {
      // Revalidate the business category field
      this.validator.showMessageFor("element");
    });
  };
  
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === "from_date") {
      this.setState({ to_date: "", minDate: value });
    }
  }; 
  
  // Handle team member change (multi-select)
  handleTeamMemberChange = (selectedTeamMembers) => {
    this.setState({ selectedTeamMembers });
  };
  render() {
    const { selectedEsCategory, selectedEsElement, selectedTeamMembers, es_category_master, es_element_master, employees_master ,formSubmitSuccess} = this.state;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          isOpen={this.props.isShowing}
          toggle={this.props.hide}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">EDIT TRANSACTION</h5>
            <button
              id="close_model"
              type="button"
              onClick={this.props.hide}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {formSubmitSuccess === false &&
              <form onSubmit={this.handleFormSubmit}>
                <Row>
                  <Col md="4" className="mb-3">
                    <label className="control-label">
                      Category<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={this.state.es_category_master}
                      classNamePrefix="select2-selection"
                      onChange={this.handleEsCategoryChange}
                      name="es_category_id"
                      value={selectedEsCategory}
                    />
                    {this.validator.message("category", this.state.selectedEsCategory, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <label className="control-label">
                      Element<span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti={false}
                      options={this.state.es_element_master}
                      classNamePrefix="select2-selection"
                      onChange={this.handleEsElementChange}
                      name="es_element_id"
                      value={selectedEsElement}
                    />
                    {this.validator.message("element", this.state.selectedEsElement, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="from_date">From Date<span className="text-danger">*</span></Label>
                    <Input
                      name="from_date"
                      type="date"
                      className="form-control"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.from_date}
                    />
                    {this.validator.message("from_date", this.state.from_date, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="to_date">To Date<span className="text-danger">*</span></Label>
                    <Input
                      name="to_date"
                      type="date"
                      className="form-control"
                      min={this.state.from_date}
                      onChange={this.handleInputChange}
                      defaultValue={this.state.to_date}
                    />
                    {this.validator.message("to_date", this.state.to_date, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="no_days">No.Days<span className="text-danger">*</span></Label>
                    <Input name="no_days" placeholder="Enter No.Days" min="1" max="999" maxLength="3" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.no_days} />
                      {this.validator.message("no days", this.state.no_days, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="location">Location<span className="text-danger">*</span></Label>
                    <Input name="location" placeholder="Type Location" type="text" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.location}  />
                    {this.validator.message("location", this.state.location, "required|string")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="size">Size<span className="text-danger">*</span></Label>
                    <Input name="size" placeholder="Enter Size" type="text" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.size} />
                      {this.validator.message("size", this.state.size, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="total_size">Total Size<span className="text-danger">*</span></Label>
                    <Input name="total_size" placeholder="Enter Total Size" type="text" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.total_size} />
                      {this.validator.message("total_size", this.state.total_size , "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="quantity">Quantity<span className="text-danger">*</span></Label>
                    <Input name="quantity" placeholder="Enter Quantity"  min="1" max="999999" maxLength="6" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.quantity} />
                      {this.validator.message("quantity", this.state.quantity, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="internal_rate">Internal Rate</Label>
                    <Input name="internal_rate" placeholder="Enter Internal Rate" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.internal_rate} />
                      {this.validator.message("internal_rate", this.state.internal_rate, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="internal_total">Internal Total</Label>
                    <Input name="internal_total" placeholder="Enter Internal Total" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.internal_total} />
                      {this.validator.message("internal_total", this.state.internal_total, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="margin_percent">Margin</Label>
                    <Input name="margin_percent" placeholder="Type Margin" type="text" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.margin_percent} />
                    {this.validator.message("margin", this.state.margin_percent, "percentage")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="external_rate">External Rate</Label>
                    <Input name="external_rate" placeholder="Enter External Rate" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.external_rate} />
                      {this.validator.message("external_rate", this.state.external_rate, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="external_total">External Total</Label>
                    <Input name="external_total" placeholder="Enter External Total" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue="40000" />
                      {this.validator.message("external_total", this.state.external_total, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="expected_GOP">Expected GOP</Label>
                    <Input name="expected_GOP" placeholder="Enter Expected GOP" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.expected_GOP} />
                      {this.validator.message("expected gop", this.state.expected_GOP, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="actual_GOP">Actual GOP</Label>
                    <Input name="actual_GOP" placeholder="Enter Actual GOP" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.actual_GOP} />
                      {this.validator.message("actual gop", this.state.actual_GOP, "")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <label className="control-label">
                      Team Member<span className="text-danger">*</span>
                    </label>
                    <Select
                      classNamePrefix="select2-selection"
                      name="team_members"
                      value={selectedTeamMembers}
                      onChange={this.handleTeamMemberChange}
                      options={employees_master}
                      isMulti
                    />
                    {this.validator.message("team member", this.state.selectedTeamMembers, "required|min:1|max:3")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="advance">Advance 1<span className="text-danger">*</span></Label>
                    <Input name="advance" placeholder="Enter Advance 1" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.advance} />
                      {this.validator.message("advance", this.state.advance, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="balance">Balance<span className="text-danger">*</span></Label>
                    <Input name="balance" placeholder="Enter Balance" type="number" className={"form-control"}
                      onChange={this.handleInput}
                      defaultValue={this.state.balance} />
                      {this.validator.message("balance", this.state.balance, "required")}
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="remark">Remark/Notes</Label>
                    <textarea name="remark" placeholder="Type Remark/Notes" rows={3} className={"form-control"}
                      onChange={this.handleInput} 
                      defaultValue={this.state.remark}  />
                    {this.validator.message("remark", this.state.remark, "required")}
                  </Col>
                </Row>

                <div className="d-flex flex-wrap gap-2 justify-content-end">
                  <Button color="primary" type="submit" disabled={this.state.submit}>
                    {this.state.submit === true ? "Please wait..." : "Submit Data"}
                  </Button>
                </div>
              </form>
            }
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

EditTransaction.propTypes = {
  attribs: PropTypes.any,
  isShowing: PropTypes.any,
  reloadLead: PropTypes.func,
  reloadElementSheet: PropTypes.func
};

export default EditTransaction