import React from "react"
import EmployeeAccessoryView  from "./EmployeeAccessoryView"
import { Container } from "reactstrap"
import { EmployeeAccessoryForm } from "./EmployeeAccessoryForm"

export const EmployeeAccessoryList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <EmployeeAccessoryForm/>
        <EmployeeAccessoryView />
      </Container>
    </div>
  )
}
