import React from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyOViewActiveTabs } from "store/companyOverview/companyOverviewSlice";
import { CompanyOverviewTabItems } from "constants/Constants";
import { useHistory } from "react-router-dom";

export const CompanyOverviewTabs = () => {
  const { CompanyOViewActiveTabs } = useSelector(state => state.companyOverview);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleTabClick = (tabId) => {
    dispatch(setCompanyOViewActiveTabs(tabId)); // Dispatch the action to update the active tab

    // Remove query parameters from the URL
    const currentPath = window.location.pathname; // Get the current pathname
    history.replace(currentPath); // Use replace to remove query parameters
  };

  return (
    <div className="d-flex justify-content-center">
      <Row className="justify-content-center profile-container">
        <Col md="12" className="mb-4 text-center">
          <Nav className="icon-tab nav-justified">
            {CompanyOverviewTabItems.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  style={{
                    cursor: "pointer",
                    height: "55px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={classnames({
                    active: CompanyOViewActiveTabs === item.id,
                    "nav-link": true,
                  })}
                  onClick={() => handleTabClick(item.id)} // Call handleTabClick on click
                >
                  <span>
                    {item.label}{console.log(CompanyOViewActiveTabs)}
                  </span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
    </div>
  );
};
