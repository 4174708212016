import { getUserId } from "constants/CommonFunction"
import { ApiService } from "./ApiService"

export async function apiGetWorkDetail(id) {
  return ApiService.fetchData({
    url: `/employee_work_history/employee_work_list/${id}`,
    method: "get",
  })
}

export async function apiCreateWorkDetail(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: "/employee_work_history",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditWorkDetail(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `employee_work_history/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEmployeeType() {
  return ApiService.fetchData({
    url: `employment_type`,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}

export async function apiGetDepartment() {
  return ApiService.fetchData({
    url: `department_master?length=10&start=0`,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}

export async function apiGetDesignation() {
  return ApiService.fetchData({
    url: `designations?length=10&start=0`,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}

export async function apiGetProbationPeriod() {
  return ApiService.fetchData({
    url: `probation_period?length=10&start=0`,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}

export async function apiGetReportingManager() {
  return ApiService.fetchData({
    url: `/users/get`,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}

export async function apiGetSubDepartment(department_id) {
  const url = department_id
    ? `sub_department_master/?department_master_id=${department_id}`
    : "sub_department_master"

  return ApiService.fetchData({
    url: url,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}

export async function apiGetWorkJobTitle() {
  return ApiService.fetchData({
    url: `requirement_role_master/get?status=1`,
    method: "get",
    params:{keyword:"", length:150, start:1, status:1}
  })
}
