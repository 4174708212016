import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, CardFooter, Col, Input, Row } from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import { attendeTabledata, logApprovalStatusData } from "constants/Constants"
import { Search } from "components/Datatable"
import Pagination from "components/Common/Pagination"
import NavTabs from "components/NavTabs/NavTabs"
import Select from "react-select"
import { customStyles, logStatusData } from "constants/Constants"
import { useDispatch, useSelector } from "react-redux"
import {
  createAttendanceApproval,
  fetchAttendanceApproval,
  setApprovalStatus,
  setAttendanceApprovalCurrentPage,
  setAttendanceApprovalQuery,
  toggleAnomalyRejactionForm,
} from "store/attendanceApproval/attendanceApprovalSlice"
import toastr from "toastr"
import { attendanceStatus, formatDateFormat } from "constants/CommonFunction"
import { useLocation } from "react-router-dom"
import { toggleLeaveRejactionForm } from "store/leaveApproval/leaveApprovalSlice"
import RejectAnomaliesForm from "components/Profile/RejectAnomaliesForm/RejectAnomaliesForm"
import { apiCreateAttendanceBulkApproval } from "services/AttendanceApprovalService"

export const AttendanceApproval = () => {
  const dispatch = useDispatch()
  const [selectedEmployee, setSelectedEmployee] = useState([])
  const location = useLocation()
  const [selectAll, SetselectAll] = useState(false)
  const activeTab = location.pathname
  const {
    loading,
    attendanceApprovalData,
    totalData,
    attendanceApprovalcurrentPage,
    attendanceApprovalQuery,
    approvalStatus,
  } = useSelector(state => state.attendanceApproval)

  const attendanceApproval = async (id, type, values) => {
    if (type == "approve") {
      const payload = {
        status: type == "approve" ? 1 : 2,
      }
      try {
        const response = await dispatch(
          createAttendanceApproval({ id: id, data: payload })
        )
        console.log("approve", response)
        if (response?.payload?.data.data.status) {
          toastr.success('Anomaly Approved')
        } else {
          toastr.error(response?.payload?.data?.data?.message || 'Something Went Wrong')
        }
      } catch (error) {
        toastr.error("Something Went Wrong")
      } finally {
        dispatch(
          fetchAttendanceApproval({
            start: attendanceApprovalcurrentPage,
            length: 10,
            status: approvalStatus,
            keyword: attendanceApprovalQuery,
          })
        )
      }
    } else {
      dispatch(toggleAnomalyRejactionForm({toggle:true, data:values}))
    }
  }

  const handleBulkApprove = async () => {

    // Prepare data in the required format
    let data = {
      status: 1,
      employee_attendance_request_ids: selectedEmployee.join(',') // Converts array to "1,2,3"
    };

    try {
      // Call the API to approve attendance requests in bulk
      const res = await apiCreateAttendanceBulkApproval(data);

      // Handle success or error response
      if (res?.data.success) {
        console.log('Bulk approval successful', res);
        toastr.success('Anomalies Approved')
      } else {
        console.error('Bulk approval failed', res);
      }
      dispatch(
        fetchAttendanceApproval({
          start: attendanceApprovalcurrentPage,
          length: 10,
          status: approvalStatus,
          keyword: attendanceApprovalQuery,
        })
      )
      setSelectedEmployee([])
    } catch (error) {
      console.error('Error in bulk approval', error);
    }
  };

  const toggleRowSelection = rowId => {
    const updatedSelection = selectedEmployee.includes(rowId)
      ? selectedEmployee.filter(id => id !== rowId)
      : [...selectedEmployee, rowId]
    setSelectedEmployee(updatedSelection)
  }

  const toggleAllRows = (e) => {
    if (!selectAll) {
      SetselectAll(true)
      // Select all rows
      const pendingRowIds = attendanceApprovalData?.map(row => {
        if(row.status=='0'){
          return row.employee_attendance_request_id
        }
      }) || [];
      setSelectedEmployee(pendingRowIds);
    } else {
      SetselectAll(false)
      // Deselect all rows
      setSelectedEmployee([]);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: () => (
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            onClick={e => toggleAllRows(e)}
            checked={selectAll}
          />
        ),
        accessor: "checkbox",
        Cell: ({ row }) => {
          return (
            <input
              style={{ cursor: "pointer" }}
              type="checkbox"
              defaultChecked={selectedEmployee.includes(
                row.original.employee_attendance_request_id
              )}
              onChange={() => toggleRowSelection(row.original.employee_attendance_request_id)}
              checked={selectedEmployee.includes(row.original.employee_attendance_request_id)}
            />
          )
        },
        disableSortBy: true,
      },
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>
            {(attendanceApprovalcurrentPage - 1) * 10 + (row.index + 1)}
          </div>
        ),
      },
      {
        id: "date",
        Header: () => <div style={{ width: "100px" }}>Date</div>,
        accessor: "date",
        width: 150,
        Cell: ({ value }) => {
          return (
            <div className="mb-0">{value ? formatDateFormat(value) : "-"}</div>
          )
        },
      },
      {
        id: "first_name",
        Header: () => <div style={{ width: "110px" }}>Employee Name</div>,
        accessor: "first_name",
        width: 150,
        Cell: ({ row }) => {
          const { first_name, last_name } = row.original
          return (
            <div className="mb-0">
              {first_name && last_name ? `${first_name} ${last_name}` : "-"}
            </div>
          )
        },
      },
      {
        id: "status",
        Header: () => <div style={{ width: "80px" }}>Status</div>,
        accessor: "status",
        width: 150,
        Cell: ({ value }) => {
          return (
            <div className="mb-0">{value ? attendanceStatus(value) : "-"}</div>
          )
        },
      },
      {
        id: "total_log_time",
        Header: () => <div style={{ width: "140px" }}>Total Work Duration</div>,
        accessor: "total_log_time",
        width: 150,
        Cell: ({ value }) => {
          return <div className="mb-0">{value ? value : "-"}</div>
        },
      },
      {
        id: "total_break_time",
        Header: () => (
          <div style={{ width: "140px" }}>Total Break Duration</div>
        ),
        accessor: "total_break_time",
        width: 150,
        Cell: ({ value }) => {
          return <div className="mb-0">{value ? value : "-"}</div>
        },
      },
      {
        id: "requested_in_time",
        Header: () => <div style={{ width: "80px" }}>In Time</div>,
        accessor: "requested_in_time",
        width: 150,
        Cell: ({ value }) => {
          return <div className="mb-0">{value ? value : "-"}</div>
        },
      },
      {
        id: "requested_out_time",
        Header: () => <div style={{ width: "80px" }}>Out Time</div>,
        accessor: "requested_out_time",
        width: 150,
        Cell: ({ value }) => {
          return <div className="mb-0">{value ? value : "-"}</div>
        },
      },
      {
        Header: () => <div style={{ width: "120px" }}>Clock In Location</div>,
        accessor: "clock_in_location",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "130px" }}>Clock Out Location</div>,
        accessor: "clock_out_location",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          const { id, status } = row.original
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {status == 0 ? (
                <>
                  <div
                    className="d-flex justify-content-center"
                    style={{ gap: "5px", border: "1px solid #28a745" }}
                  >
                    <i
                      disabled={loading}
                      onClick={async () => attendanceApproval(id, "approve")}
                      className="bx bx-check cursor-pointer"
                      style={{ fontSize: "20px", color: "#28a745" }}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ gap: "5px", border: "1px solid #dc3545" }}
                  >
                    <i
                      disabled={loading}
                      onClick={async () => attendanceApproval(id, "reject", row.original)}
                      className="bx bx-x cursor-pointer"
                      style={{ fontSize: "20px", color: "#dc3545" }}
                    />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    color: status == 1 ? "#28a745" : "#dc3545",
                    fontWeight: "bold",
                  }}
                >
                  {status == 1 ? "Approved" : "Rejected"}
                </div>
              )}
            </div>
          )
        },
      },
    ]
  }, [dispatch, attendanceApprovalcurrentPage, selectedEmployee, attendanceApprovalData])

  useEffect(() => {
    dispatch(
      fetchAttendanceApproval({
        start: attendanceApprovalcurrentPage,
        length: 10,
        status: approvalStatus,
        keyword: attendanceApprovalQuery,
        ...(location?.state?.row?.id && { employee_id: location.state.row.id }), // Only include employee_id if it's available
      })
    )
  }, [attendanceApprovalcurrentPage, approvalStatus, attendanceApprovalQuery, location])

  return (
    <div className={activeTab.includes("/profile/view") ? "" : "page-content"}>
      <div
        className={activeTab.includes("/profile/view") ? "" : "container-fluid"}
      >
        <NavTabs />
        <h5 className="mt-3">Attendance</h5>
        <Card className="cardBorder">
          <CardBody style={{ paddingBottom: "0px" }}>
            <Row className=" align-items-center">
              <Col md={2} sm={12} style={{ marginTop: "15px" }}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setAttendanceApprovalQuery(value))
                    dispatch(setAttendanceApprovalCurrentPage(1))
                  }}
                />
              </Col>
              <Col md={2} className="mb-3">
                <span>Status</span>
                <Select
                  id="logStatus"
                  name="logStatus"
                  size="sm"
                  options={logApprovalStatusData}
                  value={logStatusData?.find(
                    option => option.key == approvalStatus
                  )}
                  onChange={async option => {
                    await dispatch(setAttendanceApprovalCurrentPage(1))
                    await dispatch(setApprovalStatus(option?.key))
                  }}
                  styles={customStyles}
                />
              </Col>
              <Col md={8} className="d-flex mb-2" style={{marginTop:'14px'}}>
                <button className="btn btn-primary" onClick={handleBulkApprove}>Bulk Approval</button>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "55vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  key={selectedEmployee}
                  columns={columns}
                  data={attendanceApprovalData ? attendanceApprovalData : []}
                  loading={loading}
                  onSelectionChange={selectedIds =>
                    setSelectedEmployee(selectedIds)
                  }
                  toggleRowSelection={toggleRowSelection}
                  selectedRowIds={selectedEmployee}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={attendanceApprovalcurrentPage}
                totalCount={totalData ? totalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setAttendanceApprovalCurrentPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
      <RejectAnomaliesForm />
    </div>
  )
}
