import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateOtherDocument,
  apiEditOtherDocument,
  apiGetOtherDocument,
} from "services/OtherDocumentService"

export const SLICE_NAME = "otherDocument"

const initialState = {
  loading: false,
  openDocumentModal: false,
  tableRowData: [],
  documentData: [],
  totalData : 0,
  currentPage : 1
}

export const fetchOtherDocument = createAsyncThunk(
  `${SLICE_NAME}/fetchOtherDocument`,
  async ({ id, length, start }) => {
    try {
      const response = await apiGetOtherDocument({ id, length, start });
      return {
        status: "success",
        data: response?.data,
      };
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      };
    }
  }
);

export const createOtherDocument = createAsyncThunk(
  `${SLICE_NAME}/createOtherDocument`,
  async (data) => {
    try {
      const response = await apiCreateOtherDocument(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editOtherDocument = createAsyncThunk(
  `${SLICE_NAME}/editOtherDocument`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiEditOtherDocument(id, data);
      return {
        status: "success",
        data: response,
      };
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      };
    }
  }
);

const otherDocument = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTableRowData: (state, action) => {
      state.tableRowData = action.payload
    },
    setDocumentModal: (state, action) => {
      state.openDocumentModal = action.payload
    },
    setCurrentPage : (state, action) => {
        state.currentPage = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOtherDocument.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchOtherDocument.fulfilled, (state, action) => {
      state.loading = false
      state.documentData = action?.payload?.data?.data
      state.totalData = action?.payload?.data?.total
    })
    builder.addCase(fetchOtherDocument.rejected, state => {
      state.loading = false
    })
  },
})

export const { setLoading, setTableRowData, setDocumentModal, setCurrentPage } =
  otherDocument.actions

export default otherDocument.reducer
