import React, { useEffect } from "react"
import { CompanyOverviewTabs } from "./CompanyOverviewTabs"
import { useDispatch, useSelector } from "react-redux"
import { fetchProfileDetail, setUserId } from "store/onBoarding/onBordingSlice"
import { useLocation } from "react-router-dom"
import { getUserId } from "constants/CommonFunction"
import useCompanyTabsComponents from "components/Hooks/useCompanyTabComponents"

export const CompanyOverview = () => {
  const { CompanyOViewActiveTabs } = useSelector(state => state.companyOverview)
  const tabsComponents = useCompanyTabsComponents()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const profileDetail = async () => {
      const newUserId = location?.state?.row?.id
        ? location.state.row.id
        : getUserId()
      // await dispatch(setUserId(newUserId))
      // await dispatch(fetchProfileDetail(newUserId))
    }
    profileDetail()
  }, [dispatch, location])

  return (
    <div className="page-content">
      <div>
        <CompanyOverviewTabs />
        <div className="container-fluid">
          {tabsComponents.map(tab =>
             tab.id === CompanyOViewActiveTabs ? (
              <div key={tab.id}>{tab.component}</div>
            ) : null
          )}
        </div>
      </div>
    </div>
  )
}
