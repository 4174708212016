import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Button } from "reactstrap"
import MetaTags from "react-meta-tags"
import Pagination from "components/Common/Pagination"
import { TableHeader, Search } from "components/Datatable/index"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useFullPageLoader from "../../../components/Common/useFullPageLoader"
import { Link } from "react-router-dom"
import { get } from "../../../helpers/api_helper"
import {
  EXPORT_MASTER,
  GET_BRAND_DATA,
  GET_BRAND_MASTER,
  GET_VENDOR_CTEGORY_MASTER,
} from "../../../helpers/api_url_helper"
import toastr from "toastr"
import axios from "axios"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import useExportXlsx from "components/Hooks/useExportXlsx"
import { CSVLink } from "react-csv"
import { useSelector } from "react-redux"
const DatatableTables = () => {
  const [tableData, settableData] = useState([])
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState("")
  const { exportXlsx, xlsxLoader } = useExportXlsx()
  const [reloadData, setreloadData] = useState(false)
  const [csv_load, setCsvLoad] = useState(false)
  const [headers, setHeaders] = useState([])
  const [newData, setNewData] = useState([])
  const csvInstance = useRef()
  const TableColum = [
    { name: "#", field: "id", sortable: false },
    { name: "Brand", field: "name", sortable: false },
    { name: "Ordering", field: "ordering", sortable: false },
  ]
  const { brands } = useSelector(state => state.users.userPermissions);

  let PageSize = 10
  useEffect(() => {
    showLoader()
    const params = { keyword: search, length: PageSize, start: currentPage }
    get(GET_BRAND_MASTER, { params: params })
      .then(res => {
        hideLoader()
        if (res) {
          settableData(res.data)
          setTotalItems(res.total)
        }
      })
      .catch(err => {
        hideLoader()
        toastr.error(err?.response?.data?.message)
      })
  }, [currentPage, search, reloadData]) // pass `value` as a dependency

  const downloadData = () => {
    setCsvLoad(true)
    const params = { keyword: search, length: 100000, start: 1 }
    get(GET_BRAND_MASTER, { params: params })
      .then(response => {
        if (response.data.length > 0) {
          setHeaders([
            { label: "Name", key: "name" },
            { label: "Order", key: "ordering" }
          ])
          setNewData(response.data)
          if (newData && csvInstance.current && csvInstance.current.link) {
            setTimeout(() => {
              csvInstance.current.link.click()
              setNewData([])
            })
          }
        } else {
          toastr.error(response.message)
        }
        setCsvLoad(false)
      })
      .catch(err => {
        setCsvLoad(false)
        toastr.error(err?.response?.data?.message)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Brand | PARTY CRUISERS LTD</title>
        </MetaTags>
        <div className="container-fluid">
          <h5 className="mb-3">Brand</h5>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-4">
                      <Search
                        onSearch={value => {
                          setSearch(value)
                          setCurrentPage(1)
                        }}
                      />
                    </div>
                    <div className="col-md-8 d-flex justify-content-end" >
                    {brands?.export && <div className={"me-3"}>
                        <button
                            title="Export"
                            type="button"
                            onClick={downloadData}
                            style={{ minWidth: "110px", display: "flex" }}
                            className="btn btn-primary me-2 mb-2"
                          >
                            <i className="mdi mdi-file-export"></i>{" "}
                            {csv_load === false && <>Export CSV</>}
                            {csv_load === true && <>Please wait...</>}
                          </button>
                          {headers.length > 0 && (
                            <CSVLink
                              data={newData}
                              headers={headers}
                              filename={`brand.csv`}
                              target="_blank"
                              ref={csvInstance}
                            ></CSVLink>
                          )}
                      </div>
                    }
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <TableHeader
                        headers={TableColum}
                        // onSorting={(field, order) =>
                        //     setSorting({field, order})
                        // }
                      />
                      <tbody className="">
                        {loader && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">Loading...</h4>
                            </th>
                          </tr>
                        )}
                        {!loader && tableData.length === 0 && (
                          <tr>
                            <th colSpan={TableColum.length}>
                              <h4 className="text-center">No data found</h4>
                            </th>
                          </tr>
                        )}
                        {!loader &&
                          tableData.map((value, index) => (
                            <tr key={++index}>
                              <th scope="row">
                                {PageSize * (currentPage - 1) + (index + 1)}
                              </th>
                              <td>{value.name}</td>
                              <td>{value.ordering}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Row className="justify-content-center">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DatatableTables
