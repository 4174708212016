import React, { useEffect } from "react"
import { WorkDetailForm } from "./WorkDetailForm"
import { WorkDetails } from "./WorkDetails"
import { useSelector } from "react-redux"

export const WorkDetailList = () => {
  const { openWorkDetailModal } = useSelector(state => state.workDetail)
  console.log(openWorkDetailModal, "Verify Why Not Visble")
  return <div>{openWorkDetailModal ? <WorkDetailForm /> : <WorkDetails />}</div>
}
