import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateExpenseRule,
  apiEditExpenseRule,
  apiGetExpenseRewardMapping,
  apiGetExpenseRule,
  apiCreateMappingData,
  apiEditMappingData,
  apiGetValueData,
  apiGetEmployeeData
} from "services/expenseRuleService"

export const SLICE_NAME = "expenseRule"

const initialState = {
  loading: false,
  expenseTotal:0,
  expenseTableData: [],
  openExpenseRuleModal: false,
  rulecurrentPage: 1,
  ruleSearchQuery : '',
  expenseRuleRowData: [],
  mappingcurrentPage:1,
  openExpenseMappingModal:false,
  expensemappingRowData: [],
  rewardMappingData:[],
  mappingTotalData:0,
  employeeFormData: [],
  valueFormData: [],
}

export const fetchExpenseRule = createAsyncThunk(
  `${SLICE_NAME}/fetchExpenseRule`,
  async ({ length, start }) => {
    try {
      const response = await apiGetExpenseRule({ length, start })
      return {
        status: "success",
        data: response?.data,
      };
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      };
    }
  }
)

export const fetchExpenseRewardMapping= createAsyncThunk(
  `${SLICE_NAME}/fetchExpenseRewardMapping`,
  async({length,start,deduction_reward_rule_master_id}) => {
    try{
      const response=await apiGetExpenseRewardMapping({
        length,
        start,
        deduction_reward_rule_master_id,
      })
      return{
        status: "success",
        data: response?.data,
      }
    } catch(error){
      return{
        status:"failed",
        message:error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const createExpenseRule = createAsyncThunk(
  `${SLICE_NAME}/createExpenseRule`,
  async (data,{rejectWithValue}) => {
    try {
      const response = await apiCreateExpenseRule(data)
      return response?.data
    } catch (error) {
    return rejectWithValue({
      status: "failed",
      message: error?.response?.data?.message || error.toString(),
    });
    }
  }
)

export const editExpenseRule = createAsyncThunk(
  `${SLICE_NAME}/editExpenseRule`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiEditExpenseRule(id, data)
      return response?.data
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const createExpenseMapping=createAsyncThunk(
  `${SLICE_NAME}/createExpenseMapping`,
  async(data,{rejectWithValue}) => {
    try{
      const response=await apiCreateMappingData(data)
      return response
    }
    catch(error){
      return rejectWithValue({
              status: "failed",
              message: error?.response?.data?.message || error.toString(),
            })
    }
  }
)

export const editExpenseMapping= createAsyncThunk(
  `${SLICE_NAME}/editExpenseMapping`,
  async({id,data},{rejectWithValue})=> {
    try{
      const response=await apiEditMappingData(id,data)
      return response
    }
    catch(error){
      return rejectWithValue({
                    status: "failed",
                    message: error?.response?.data?.message || error.toString(),
                  })
    }
  }
)
export const fetchValueExpenseData = createAsyncThunk(
  `${SLICE_NAME}/fetchValueExpenseData`,
  async () => {
    try {
      const response = await apiGetValueData()

      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)
export const fetchEmployeeFormData = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeFormData`,
  async () => {
    try {
      const response = await apiGetEmployeeData()
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const expenseRuleSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTableRowData: (state, action) => {
      state.expenseRuleRowData=action.payload
    },
    setRuleSearch: (state, action) => {
      state.ruleSearchQuery = action.payload
    },
    setOpenExpenseRuleModal: (state, action) => {
      state.openExpenseRuleModal = action.payload
    },
    setRuleCurrentPage:(state, action) => {
      state.rulecurrentPage = action.payload
  },
  setMappingCurrentPage:(state,action)=>{
    state.mappingcurrentPage=action.payload
  },
  setExpenseMappingModal:(state,action)=>{
    state.openExpenseMappingModal=action.payload
  },
  setMappingTableRowData:(state,action)=>{
    state.expensemappingRowData = action.payload
  },
},
  extraReducers: builder => {
    builder
    .addCase(fetchExpenseRule.pending, state => {
        state.loading = true
      })
      .addCase(fetchExpenseRule.fulfilled, (state, action) => {
        state.loading = false
        state.expenseTableData=action?.payload?.data?.data
        state.expenseTotal=action?.payload?.data?.total

      })
      .addCase(fetchExpenseRule.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message;
      })
      .addCase(fetchExpenseRewardMapping.pending,(state, action) =>{
        state.loading = true
      })
      .addCase(fetchExpenseRewardMapping.fulfilled,(state,action)=>{
        state.loading=false
        state.rewardMappingData=action?.payload?.data?.data
        state.mappingTotalData=action?.payload?.data?.total
      })
      .addCase(fetchExpenseRewardMapping.rejected,(state, action)=>{
              state.loading=false
     })
     .addCase(fetchEmployeeFormData.fulfilled, (state, action) => {
      state.employeeFormData = action.payload.data?.data?.data?.map(
        employee => ({
          value: employee.id,
          label: `${employee?.first_name} ${employee?.last_name}`,
        })
      )
    })
    .addCase(fetchValueExpenseData.fulfilled, (state, action) => {

      state.valueFormData = action.payload.data?.data?.data?.map(value => ({
        value: value.id,
        label: value.name,
      }))
    })
  },
})

export const { setLoading,
  setTableRowData,
  setOpenExpenseRuleModal,
  setRuleCurrentPage,
  setRuleSearch ,
  setExpenseMappingModal,
  setMappingCurrentPage,
  setMappingTableRowData} =
  expenseRuleSlice.actions

export default expenseRuleSlice.reducer
