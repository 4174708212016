import { ApiService } from "./ApiService"


export async function apiGetExpenseRewardMapping({
  length,
  start,
  deduction_reward_rule_master_id,
}) {
  return ApiService.fetchData({
    url: `expense_rule_master_mapping`,
    method: "get",
    params: { length, start, deduction_reward_rule_master_id },
  })
}
export async function apiGetExpenseRule({ length, start }={}) {
    const params={length, start}
  return ApiService.fetchData({
    url: `/expense_rule_master`,
    method: "get",
    params,
  });
}

export async function apiCreateExpenseRule(data) {
  const urlEncodedPayload= new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: "/expense_rule_master",
    method: "Post",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}

export async function apiEditExpenseRule(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString();
  return ApiService.fetchData({
    url: `/expense_rule_master/${id}`,
    method: "put",
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: urlEncodedPayload,
  });
}

export async function apiGetExpenseMapping({
  length,
  start,
  deduction_reward_rule_master_id,
}){
  return ApiService.fetchData({
      url: `expense_rule_master_mapping`,
      method: "get",
      params: { length, start ,deduction_reward_rule_master_id},
    })
}

export async function apiCreateMappingData(data){
  const urlEncodedPayload = new URLSearchParams(data).toString();
return ApiService.fetchData({
  url: `/expense_rule_master_mapping`,
  method: "Post",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  data: urlEncodedPayload,
});
}

export async function apiEditMappingData(id,data){
  const urlEncodedPayload=new URLSearchParams(data).toString();
    return ApiService.fetchData({
      url: `/expense_rule_master_mapping/${id}`,
      method: "put",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: urlEncodedPayload,
    })
}

export async function apiGetEmployeeData() {
  return ApiService.fetchData({
    url: "/users",
    method: "get",
  })
}
export async function apiGetValueData() {
  return ApiService.fetchData({
    url: "/designations",
    method: "get",
  })
}