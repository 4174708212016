import React from "react"
import { Input, Label } from "reactstrap"
import { ErrorMessage } from "formik"

export const HandleTimeChange = ({ name, label, value, onChange }) => {
  const handleChange = e => {
    let input = e.target.value
    input = input.replace(/[^0-9:]/g, "")
    if (input.length > 5) {
      input = input.slice(0, 5)
    }
    if (input.length >= 2 && !input.includes(":")) {
      input = input.slice(0, 2) + ":" + input.slice(2)
    }
    const [hours, minutes] = input.split(":")
    if (hours && parseInt(hours, 10) > 23) {
      input = "23:" + (minutes || "00")
    }
    if (minutes && parseInt(minutes, 10) > 59) {
      input = (hours || "00") + ":59"
    }
    onChange(name, input)
  }

  return (
    <div>
      <Label className="labels" htmlFor={name}>
        {label}
        <span className="text-danger">*</span>
      </Label>
      <Input
        type="text"
        className="cursor-pointer grace-period"
        name={name}
        value={value}
        onChange={handleChange}
        placeholder="00:00"
      />
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  )
}
