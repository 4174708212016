import React, { useEffect } from 'react'
import { Row, Col, Card, CardBody, Button, CardFooter,Input } from "reactstrap"
import { Search } from "components/Datatable/index"
import { months,customStyles } from 'constants/Constants'
import Select from "react-select"
import useExportXlsx from "components/Hooks/useExportXlsx"
import GlobalTable from 'components/Datatable/GlobalTable'
import { useSelector,useDispatch } from 'react-redux'
import {setSelectedMonth,
        setYear,
        setSalarySearchQuery,
        setCurrentPage,
        setOpenSyncModal,
        fetchEmployeeSalary,

} from 'store/employeeSalary/employeeSalarySlice'
import * as Yup from "yup"
import toastr from "toastr"
import {
  getMonthName,
} from "constants/CommonFunction"
import Pagination from "components/Common/Pagination"


const SalaryView = () => {
  const dispatch = useDispatch()

    const {currentPage,
      loading,
      openSyncModal,
      salaryRowData,
      salarySearchQuery,
      year,
      salaryTotalData,
      selectedMonth
    }=useSelector(state=> state.employeeSalary)
    const { exportXlsx, xlsxLoader } = useExportXlsx()

    const columns= [
      {
        Header:"Sr. No.",
        accessor:(row,index)=> index +1,
        width:120,
        Cell:({value}) => <div>{value}</div>,
      },
      {
        Header:"Employee ID",
        accessor:"emp_id",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header:"Resource Name",
        accessor:"name",
        Cell: ({ row }) => {
          const firstName = row.original.first_name
          const lastName = row.original.last_name
          return (
            <div>
              {firstName && lastName ? `${firstName} ${lastName}` : "-"}
            </div>
          )
        },
      },
      {
        Header:"Month",
        accessor:"month",
        Cell: ({ value }) => <div>{getMonthName(value)}</div>,
      },
      {
        Header:"Year",
        accessor:"year",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },

      {
        Header:"CTC",
        accessor:"ctc",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
      {
        Header:"Monthly Salary",
        accessor:"gross_month_salary",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
      {
        Header:"PT",
        accessor:"pt",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
      {
        Header:"Rewards",
        accessor:"rewards",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
      {
        Header:"Deductions",
        accessor:"deductions",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
      {
        Header:"Loss of Pay",
        accessor:"lop",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
      {
        Header:"Net Payable",
        accessor:"net_month_salary",
        Cell: ({ value }) => <div>{value? value : "-"}</div>,
      },
    ]

    useEffect(() => {
      dispatch(
        fetchEmployeeSalary({
          length:10,
          start:currentPage,
          keyword:salarySearchQuery.length >0 ?salarySearchQuery:undefined,
          month:selectedMonth?.value,
          year: year,
        })
      )
    },[currentPage,salarySearchQuery,selectedMonth,year])

    const validationSchema = Yup.object({
      year: Yup.number()
        .integer('Year must be a number')
        .min(1920, 'Year should be between 1920 and the current year')
        .max(new Date().getFullYear(), 'Year should be between 1920 and the current year')
        .required('Year is required'),
        selectedMonth: Yup.object()
    .shape({
      value: Yup.string().required('Month value is required'),
      label: Yup.string().required('Month label is required'),
    })
    .nullable()
    .required('Month is required')
});

    const handleSync = async () => {
      // await dispatch(setRewardTableRowData([]))
      await dispatch(setOpenSyncModal(true))
    }

    const handleFilter = () => {
      const yearNumber = Number(year);
      try {
          validationSchema.validateSync({ year: yearNumber, selectedMonth });
          dispatch(setSalarySearchQuery({ month: selectedMonth?.value, year: yearNumber }));
          dispatch(setCurrentPage(1));
      } catch (error) {
        toastr.error(error.message || "Something went wrong")
      }
  };


  return (
    <div className="row">
         <div className="col-md-12">
         <h5 className="mb-3">EMPLOYEE SALARIES</h5>
         <Card className="mt-3">
         <CardBody>
         <Row className=" align-items-center">
        <Col md={2} sm={12} style={{ marginTop: "10px" }}>
        {" "}
        <Search
        onSearch={value => {
        dispatch(setSalarySearchQuery(value))
        dispatch(setCurrentPage(1))
        }}
        />
        </Col>
        <Col md={2} className="mb-3">
               <span>Month</span>
        <Select
          id="month"
          name="month"
          size="sm"
          options={months()}
          value={selectedMonth}
          onChange={option => dispatch(setSelectedMonth(option))}
          styles={customStyles}
          menuPortalTarget={document.body}
        />
        </Col>
             <Col md={2} className="mb-3">
                <span>Year </span>
               <Input
                className="form-control"
                name="year"
                placeholder="Year"
                type="text"
                onChange={e => dispatch(setYear(e.target.value))}
                />
                </Col>
                <Col md={3}>
                <Button
              className="bg-primary  mb-2 mb-md-0  d-flex align-items-center"
              style={{ borderRadius: "5px", height: "38px" }}
              onClick={handleFilter}
              >
                Filter
              </Button>
              </Col>
              <Col
                md={3}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
                style={{ marginTop: "10px" }}
              >
                {/* {expenses?.export && */}
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  style={{ borderRadius: "5px", height: "38px" }}
                  onClick={() => exportXlsx("salary-logs", "Employee Salaries", {
                    keyword:salarySearchQuery.length >0 ?salarySearchQuery:undefined,
                    month:selectedMonth?.value,
                    year: year,
                  })}
                  disabled={xlsxLoader}
                >
                  <i className="bx bx-export font-size-16 me-2" />
                  {xlsxLoader ? "Exporting..." : "Export"}
                </Button>
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleSync}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Sync
                </Button>
                {/* } */}
              </Col>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "60vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={salaryRowData ? salaryRowData : []}
                  loading={loading}
                />
              </Col>
         </Row>
         </CardBody>
         <CardFooter
           className="d-flex justify-content-end"
           style={{
             background: "#ECECEC",
             borderBottomLeftRadius: "10px",
             borderBottomRightRadius: "10px",
           }}
         >
           <div
             style={{
               height: "30px",
               marginLeft: "0px",
             }}
           >
             <Pagination
               className="mx-2"
               currentPage={currentPage}
               totalCount={salaryTotalData ? salaryTotalData : 0}
               pageSize={10}
               onPageChange={async page => {
                 dispatch(setCurrentPage(page))
               }}
             />
           </div>
         </CardFooter>
         </Card>
         </div>
    </div>
  )
}

export default SalaryView
