import React from "react"
import { LeaveLog } from "./LeaveLog"
import { ApplyLeaveForm } from "./ApplyLeaveFrom"

export const LeaveLogList = () => {
  return (
    <div>
      <ApplyLeaveForm />
      <LeaveLog />
    </div>
  )
}
