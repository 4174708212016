import { ApiService } from "./ApiService"

export async function apiGetLeaveApproval({ start, length, keyword, status, employee_id }) {
  return ApiService.fetchData({
    url: `leaves/logs`,
    method: "get",
    params: {
      start,
      length,
      keyword,
      status,
      ...(employee_id && {employee_id:employee_id})
    },
  })
}
export async function apiUpdateLeaveStatus({ id, status }) {
  const formData = new FormData();
  formData.append("status", status);
  return ApiService.fetchData({
    url: `leaves/change_leave_status/${id}`,
    method: "post",
    data: formData, // Pass FormData as the body of the request
  })
}

