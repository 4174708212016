import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  createResignationInfo,
  editResignationInfo,
  fetchResignationInfo,
  fetchResignationReasonMaster,
  setApprovalConfirmation,
  setResignationInfoModal,
  updateStatus,
} from "store/resignationInfo/ResignationInfoSlice"

export const ResignationInfoFrom = () => {
  const dispatch = useDispatch()
  const { openResignationInfoModal, tableRowData, employee_id, reasonMaster, approvalConfirmation } = useSelector(
    (state) => state.resignationInfo
  )

  // Set initial form data based on status === 0
  const [resignationData, setResignationData] = useState(
    tableRowData.find((row) => row.status === 0) || null
  )

  const toggle = () => {
    dispatch(setResignationInfoModal(false))
  }

  const [resignationReasonOptions, setResignationReasonOptions] = useState([]);

  const validationSchema = Yup.object({
    resignationDate: Yup.date().required("Date is required"),
    resignationReason: Yup.string().required("Reason is required"),
    resignationRemark: Yup.string().required("Remark is required"),
  })

  useEffect(() => {
    // Update resignationData if tableRowData changes
    setResignationData(tableRowData.find((row) => row.status == 0) || null);
    dispatch(fetchResignationReasonMaster());

  }, [tableRowData])

  useEffect(() => {
    let options = reasonMaster?.map((reason)=> {
      if(reason.status==1){
        return {
          value:reason?.id,
          label:reason?.name
        }
      }
    })
    console.log(options)
    setResignationReasonOptions(options)
  }, [reasonMaster])
  

  return (
    <Modal
      scrollable
      style={{ maxWidth: "400px", marginInline: "auto", width: "calc( 100vw - 20px)" }}
      isOpen={openResignationInfoModal}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>
        {resignationData?.id ? "Edit" : "Add"} Resignation Info
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Row>
            <Col xl="12">
              <Formik
                enableReinitialize
                initialValues={{
                  resignationDate: resignationData?.expected_last_day || '',
                  resignationReason: resignationData?.resignation_reason_master_id || '',
                  resignationRemark: resignationData?.remark || '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  console.log(values)
                  try {
                    const response = resignationData
                      ? await dispatch(
                          editResignationInfo({
                              employee_id: resignationData?.id,
                              actual_last_day: values.resignationDate,
                              resignation_reason_master_id: values.resignationReason,
                              remark: values.resignationRemark,
                          })
                        ).unwrap()
                      : await dispatch(
                          createResignationInfo({
                            employee_id: employee_id,
                            resignation_date: values.resignationDate,
                            resignation_reason_master_id: values.resignationReason,
                            remark: values.resignationRemark,
                          })
                        ).unwrap()

                    if (response?.status) {
                      toastr.success(response?.message || "Updated successful")
                    } else {
                      toastr.error(response?.message || "Something went wrong")
                    }
                    if(approvalConfirmation){
                      try {
                        const response = await dispatch(
                          updateStatus({
                            employeeId: resignationData?.id,
                            status: 1,
                          })
                        )
                        if (response?.payload?.data?.status) {
                          toastr.success(response?.payload?.data?.message)
                        } else {
                          toastr.error(response?.payload?.message)
                        }
                      } catch (error) {
                        toastr.error("Something Went Wrong")
                      }
                      dispatch(setApprovalConfirmation(false))
                      dispatch(fetchResignationInfo())
                    }
                  } catch (error) {
                    toastr.error(error.message || "An error occurred, please try again")
                  } finally {
                    console.log('finally')
                    // setSubmitting(false)
                    dispatch(fetchResignationInfo({
                      length: 10,
                      start: 1,
                      employee_id: employee_id,
                    }))
                    dispatch(setResignationInfoModal(false))
                  }
                }}
              >
                {({ values, setFieldValue, touched, errors, isSubmitting }) => (
                  <Form>
                    <Row>
                      {/* Date Field */}
                      <Col md="12" className="mb-3">
                        <Label htmlFor="resignationDate">Resignation Date</Label>
                        <Input
                          type="date"
                          name="resignationDate"
                          value={values.resignationDate}
                          onChange={(e) =>
                            setFieldValue("resignationDate", e.target.value)
                          }
                          className={`form-control ${
                            touched.resignationDate && errors.resignationDate
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {touched.resignationDate && errors.resignationDate && (
                          <div className="text-danger">
                            {errors.resignationDate}
                          </div>
                        )}
                      </Col>

                      {/* Reason Field */}
                      <Col md="12" className="mb-3">
                        <Label htmlFor="resignationReason">Resignation Reason</Label>
                        <Select
                          name="resignationReason"
                          className="menu-sm-h"
                          value={resignationReasonOptions.find(
                            (option) => option.value === values.resignationReason
                          )}
                          onChange={(option) =>
                            setFieldValue("resignationReason", option.value)
                          }
                          options={resignationReasonOptions}
                          classNamePrefix="select2-selection"
                        />
                        {touched.resignationReason && errors.resignationReason && (
                          <div className="text-danger">
                            {errors.resignationReason}
                          </div>
                        )}
                      </Col>

                      {/* Remark Field */}
                      <Col md="12" className="mb-3">
                        <Label htmlFor="resignationRemark">Remark</Label>
                        <Field
                          as="textarea"
                          placeholder="Enter remarks"
                          name="resignationRemark"
                          rows="3"
                          className={`form-control ${
                            touched.resignationRemark && errors.resignationRemark
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {touched.resignationRemark && errors.resignationRemark && (
                          <div className="text-danger">
                            {errors.resignationRemark}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                      <Button color="primary" type="submit" disabled={isSubmitting}>
                        {resignationData ? (
                          <>{isSubmitting ? "Updating..." : "Update"}</>
                        ) : (
                          <>{isSubmitting ? "Submitting..." : "Submit"}</>
                        )}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  )
}
