import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SimpleReactValidator from 'simple-react-validator'
import {get, post} from "../../../helpers/api_helper"
import {ADD_HOLIDAY, GET_OFFICE_LOCATION} from "../../../helpers/api_url_helper"
import toastr from "toastr"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/async';


class HolidayEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      holiday: "",
      date: "",
      status: true,
      office_location: null, // State to hold selected office location
      office_location_options: [],
      submit: false,
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }


  // Handle input change for form fields
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Fetch office location options from API based on the search input
  loadCurrentOptions = async (inputValue) => {
    const params = { keyword: inputValue };
    return get(GET_OFFICE_LOCATION, { params: params })
      .then((res) => {
        if (res.total>0) {
          return res.data.map((location) => ({
            label: location.name,
            value: location.id,
          }));
        }
      })
      .catch((err) => {
        toastr.error(err);
        return [];
      });
  };

  // Handle office location selection
  handleChange = (selectedOption) => {
    this.setState({ office_location: selectedOption });
  };

  // Handle form submission
  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = {
        holiday: this.state.holiday,
        date: this.state.date,
        ordering: this.state.ordering,
        status: this.state.status ? 1 : 0,
        office_location_id: this.state.office_location?.value || null,
      };
      this.setState({ submit: true });
      post(ADD_HOLIDAY, formData)
        .then((response) => {
          if (response.status) {
            this.setState({ submit: false });
            toastr.success("Holiday added successful.");
            const { history } = this.props;
            history.push("/holiday");
          }
        })
        .catch((err) => {
          toastr.error(err?.response?.data?.message);
          this.setState({ submit: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Manage Holiday | {process.env.REACT_APP_PROJECTNAME}</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Holiday" path="/holiday" breadcrumbItem="Add" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="holiday">Holiday</Label>
                          <input
                            name="holiday"
                            placeholder="Type Holiday"
                            value={this.state.holiday}
                            type="text"
                            className="form-control"
                            onChange={this.handleInput}
                          />
                          {this.validator.message(
                            "holiday",
                            this.state.holiday,
                            "required"
                          )}
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="office_location">Office Location</Label>
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={this.loadCurrentOptions}
                            onChange={this.handleChange}
                            value={this.state.office_location}
                            placeholder="Select Office Location"
                          />
                          {this.validator.message(
                            "office_location",
                            this.state.office_location,
                            "required"
                          )}
                        </Col>
                        <Col md="auto" className="mb-3">
                          <Label htmlFor="date">Date</Label>
                          <input
                            name="date"
                            placeholder="Select Date"
                            type="date"
                            value={this.state.date}
                            className="form-control"
                            onChange={this.handleInput}
                          />
                        </Col>
                        <Col md="2" className="mb-3">
                          <Label htmlFor="ordering">Ordering</Label>
                          <input
                            name="ordering"
                            placeholder="Type Ordering"
                            value={this.state.ordering}
                            type="text"
                            className="form-control"
                            onChange={this.handleInput}
                          />
                          {this.validator.message(
                            "ordering",
                            this.state.ordering,
                            "required"
                          )}
                        </Col>
                        <Col md="auto" className="mb-3 align-self-end">
                          <div className="form-check form-switch form-switch-lg">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="current_status"
                              checked={this.state.status}
                              onClick={() =>
                                this.setState({ status: !this.state.status })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="current_status"
                            >
                              Status
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={this.state.submit}
                        >
                          {this.state.submit === true
                            ? "Please wait..."
                            : "Submit Data"}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

HolidayEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
};

export default HolidayEdit;
