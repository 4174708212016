import React from "react"
import {
  OnboardingContactDetailView,
  OnboardingEmergencyDetailView,
  OnboardingKycDetailView,
  OnboardingPersonalDetailView,
  OnboardingSalaryDetailView,
  OnbordingAddressDetailView,
} from "pages/OnbordingUsers"
import { OtherDocumentList } from "pages/OtherDocument"
import { WorkDetailList } from "pages/WorkDetail"
import { ResignationInfoList } from "pages/ResignationInfo"
import { LeaveLogs } from "pages/ApplyLeave"
import { AttendanceLogs } from "pages/ManageAttendence/AttendanceLogs"
import { AttendanceApproval } from "pages/ManageAttendence"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { AttendanceRuleList } from "pages/ManageAttendence/AttendanceRule"
import { LeaveRuleList } from "pages/ApplyLeave/LeaveRules"
import { LeaveLogList } from "pages/ApplyLeave/LeaveLog/LeaveLogList"
import LeaveApprovalList from "pages/ApplyLeave/LeaveApproval/LeaveApprovalList"
import LeaveBalance from "pages/ApplyLeave/LeaveBalance"
import { useSelector } from "react-redux"

const useTabsComponents = () => {
  const location = useLocation()
  const { profileDetails } = useSelector(
    state => state.onBoarding
  )
  // Extract the current tab from the query parameters
  const params = new URLSearchParams(location.search)
  const currentTab = params.get("tab") || "logs" // Default to logs if no tab is set
  const tabsComponents = [
    {
      id: "1",
      component: (
        <>
          <OnboardingPersonalDetailView />
          <OnboardingContactDetailView />
          {profileDetails?.can_edit && <OnbordingAddressDetailView />}
          <OnboardingEmergencyDetailView />
        </>
      ),
    },
    // { id: "2", component:  },
    {
      id: "2",
      component: (
        <>
          <OnboardingSalaryDetailView />
          <WorkDetailList />
        </>
      ),
    },
    {
      id: "3",
      component: (
        <>
          <OnboardingKycDetailView />
          <OtherDocumentList />
        </>
      ),
    },
    {
      id: "4",
      component:
        currentTab == "rule" ? (
          <AttendanceRuleList />
        ) : currentTab == "approval" ? (
          <AttendanceApproval />
        ) : (
          <AttendanceLogs />
        ),
    },
    {
      id: "5",
      component:
        currentTab == "rules" ? (
          <LeaveRuleList />
        ) : currentTab == "approval" ? (
          <LeaveApprovalList />
        ) : currentTab == "balance" ? (
          <LeaveBalance />
        ) : (
          <LeaveLogList />
        ),
    },
    // { id: "6", component: <OnboardingAdditionalDetailView /> },
    { id: "6", component: <ResignationInfoList /> },
  ]

  return tabsComponents
}

export default useTabsComponents
