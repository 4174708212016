import React from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux"
import {
  createOtherDocument,
  editOtherDocument,
  fetchOtherDocument,
  setDocumentModal,
} from "store/otherDocument/otherDocumentSlice"
import { getUserId } from "constants/CommonFunction"

export const OtherDocumentForm = () => {
  const dispatch = useDispatch()
  const { openDocumentModal, tableRowData, currentPage } = useSelector(
    state => state.otherDocument
  )

  const { userId } = useSelector(state => state.onBoarding)

  const validationSchema = Yup.object({
    document_file: Yup.mixed().when("tableRowData", {
      is: tableRowData => !tableRowData || !tableRowData?.document_path,
      then: Yup.mixed().required("Required"),
      otherwise: Yup.mixed(),
    }),
    document_name: Yup.string().required("Required"),
  })

  const toggle = async () => {
    await dispatch(setDocumentModal(false))
  }

  return (
    <Modal isOpen={openDocumentModal} toggle={toggle} size="lg" centered>
      <ModalHeader className="header-text" toggle={toggle}>{`${
        tableRowData?.id ? "Edit" : "Add"
      } Other Document`}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                document_file: null,
                document_name:
                  Object.keys(tableRowData).length > 0
                    ? tableRowData?.document_name
                    : "",
                tableRowData: tableRowData || {},
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const formData = new FormData()
                if (values.document_file) {
                  formData.append("document_file", values.document_file)
                }
                formData.append("document_name", values.document_name)
                formData.append("employee_id", userId)
                try {
                  const response =
                    Object.keys(tableRowData).length > 0
                      ? await dispatch(
                          editOtherDocument({
                            id: tableRowData?.id,
                            data: formData,
                          })
                        )
                      : await dispatch(createOtherDocument(formData))
                  if (response.payload?.status === "success") {
                    toastr.success(response?.payload?.data?.data?.message)
                  } else {
                    toastr.error(response?.payload?.message)
                  }
                } catch (error) {
                  toastr.error("Something went wrong.")
                }
                await dispatch(
                  fetchOtherDocument({
                    id: userId,
                    lenght: 10,
                    start: currentPage,
                  })
                )
                await dispatch(setDocumentModal(false))
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Row>
                    {tableRowData?.document_path && (
                      <Col md="12" className="mb-3">
                        <Label className="labels" htmlFor="temporaryFile">
                          Previous File
                        </Label>
                        <div>
                          <a
                            href={tableRowData?.document_path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <b>File</b>
                          </a>
                        </div>
                      </Col>
                    )}
                    <Col md="4" className="mb-3">
                      <Label className="labels" htmlFor="document_file">
                        File
                        {!tableRowData?.document_path && (
                          <span className="text-danger">*</span>
                        )}
                      </Label>
                      <Input
                        name="document_file"
                        type="file"
                        accept="image/*,application/pdf"
                        onChange={event => {
                          setFieldValue(
                            "document_file",
                            event.currentTarget.files[0]
                          )
                        }}
                        className={`form-control ${
                          touched.document_file && errors.document_file
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="document_file"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label className="labels" htmlFor="document_name">
                        Document Name <span className="text-danger">*</span>
                      </Label>
                      <Field
                        name="document_name"
                        placeholder="Document Name"
                        type="text"
                        className={`form-control ${
                          touched.document_name && errors.document_name
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="document_name"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      {tableRowData?.id ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
