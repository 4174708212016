import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiCreateMappingData,
  apiCreateRuleData,
  apiEditMappingData,
  apiEditRuleData,
  apiGetDeductionRewardMapping,
  apiGetDeductionRule,
  apiGetDeductionRuleSearch,
  apiGetEmployeeData,
  apiGetValueData,
} from "services/DeductionRuleService"

export const SLICE_NAME = "deductionRule"

const initialState = {
  loading: false,
  openDeductionRuleModal: false,
  openDeductionMappingModal: false,
  deductionmappingRowData: [],
  rewardMappingData: [],
  mappingTotalData: 0,
  mappingcurrentPage: 1,
  deductionRuleRowData: [],
  ruleDeductionData: [],
  ruleTotalData: 0,
  rulecurrentPage: 1,
  valueFormData: [],
  employeeFormData: [],
  ruleSearchQuery: "",
  mappingSearchQuery: "",
  
}

export const fetchDeductionRewardMapping = createAsyncThunk(
  `${SLICE_NAME}/fetchDeductionRewardMapping`,
  async ({ length, start, deduction_reward_rule_master_id, keyword }) => {
    try {
      const response = await apiGetDeductionRewardMapping({
        length,
        start,
        deduction_reward_rule_master_id,
        keyword,
      })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchDeductionRule = createAsyncThunk(
  `${SLICE_NAME}/fetchDeductionRule`,
  async ({ length, start, keyword }) => {
    try {
      const response = await apiGetDeductionRule({
        length,
        start,
        keyword,
      })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchValueFormData = createAsyncThunk(
  `${SLICE_NAME}/fetchValueFormData`,
  async () => {
    try {
      const response = await apiGetValueData()
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchEmployeeFormData = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployeeFormData`,
  async () => {
    try {
      const response = await apiGetEmployeeData()
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const createExpenseMapping = createAsyncThunk(
  `${SLICE_NAME}/createExpenseMapping`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiCreateMappingData(data)
      return response // Return the entire response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const createExpenseRule = createAsyncThunk(
  `${SLICE_NAME}/createExpenseRule`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiCreateRuleData(data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const editExpenseMapping = createAsyncThunk(
  `${SLICE_NAME}/editExpenseMapping`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiEditMappingData(id, data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

export const editDeductionRule = createAsyncThunk(
  `${SLICE_NAME}/editDeductionRule`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiEditRuleData(id, data)
      return response
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)

const deductionRule = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setMappingTableRowData: (state, action) => {
      state.deductionmappingRowData = action.payload
    },
    setRuleTableRowData: (state, action) => {
      state.deductionRuleRowData = action.payload
    },
    setDeductionRuleModal: (state, action) => {
      state.openDeductionRuleModal = action.payload
    },
    setDeductionMappingModal: (state, action) => {
      state.openDeductionMappingModal = action.payload
    },
    setMappingCurrentPage: (state, action) => {
      state.mappingcurrentPage = action.payload
    },
    setRuleCurrentPage: (state, action) => {
      state.rulecurrentPage = action.payload
    },
    setRuleSearch: (state, action) => {
      state.ruleSearchQuery = action.payload
    },
    setMappingSearch: (state, action) => {
      state.mappingSearchQuery = action.payload
    },
   
  },
  extraReducers: builder => {
    builder.addCase(fetchDeductionRewardMapping.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchDeductionRewardMapping.fulfilled, (state, action) => {
      state.loading = false
      state.rewardMappingData = action?.payload?.data?.data
      state.mappingTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchDeductionRewardMapping.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchDeductionRule.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchDeductionRule.fulfilled, (state, action) => {
      state.loading = false
      state.ruleDeductionData = action?.payload?.data?.data
      state.ruleTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchDeductionRule.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchValueFormData.fulfilled, (state, action) => {
      state.valueFormData = action.payload.data?.data?.data?.map(value => ({
        value: value.id,
        label: value.name,
      }))
    })
    builder.addCase(fetchEmployeeFormData.fulfilled, (state, action) => {
      state.employeeFormData = action.payload.data?.data?.data?.map(
        employee => ({
          value: employee.id,
          label: `${employee?.first_name} ${employee?.last_name}`,
        })
      )
    })
  },
})

export const {
  setLoading,
  setMappingTableRowData,
  setDeductionRuleModal,
  setMappingCurrentPage,
  setDeductionMappingModal,
  setRuleCurrentPage,
  setRuleTableRowData,
  setRuleSearch,
  setMappingSearch,
  setReview,
  setEmployeeId,
} = deductionRule.actions

export default deductionRule.reducer
