import React from "react"
import {Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import {
  createExpenseRule,
  editExpenseRule,
  fetchExpenseRule,
  setOpenExpenseRuleModal,
} from "store/expenseRule/expenseRuleSlice"



export const ExpenseRuleFrom = () => {

    const dispatch = useDispatch()
    const {openExpenseRuleModal, rulecurrentPage, expenseRuleRowData }=
    useSelector(state => state.expenseRule)

    const validationSchema = Yup.object({
      name: Yup.string().required("Required"),
      ordering: Yup.number()
        .required("Required")
        .typeError("Must be a number")
        .positive("Must be a positive number"),
    })

    const toggle = async () => {
      await dispatch(setOpenExpenseRuleModal(false))
    }

  return (
    <Modal isOpen={openExpenseRuleModal} toggle={toggle} size="lg" centered>
    <ModalHeader toggle={toggle}>{`${expenseRuleRowData.id ? "Edit "  : "Add "} Expense Rule`}</ModalHeader>
    <ModalBody>
      <Row>
        <Col xl="12">
          <Formik
            initialValues={{
              name: expenseRuleRowData?.name
                ? expenseRuleRowData?.name
                : "",
              ordering: expenseRuleRowData?.ordering
                ? expenseRuleRowData?.ordering
                : "",
              status: expenseRuleRowData?.status
                ? expenseRuleRowData?.status
                : 0,
            }}
            validationSchema={validationSchema}
            onSubmit={async values => {
              const response =
                Object.keys(expenseRuleRowData).length > 0
                  ? await dispatch(
                      editExpenseRule({
                        id: expenseRuleRowData?.id,
                        data: values,
                      })
                    ).unwrap()
                  : await dispatch(createExpenseRule(values)).unwrap()
              try {
                if (response.data?.status == true) {
                  toastr.success(response?.data?.message)
                } else {
                  // toastr.error("Error",response?.payload?.message)
                }
              } catch (error) {
                toastr.error("Something Went Wrong Please Try Later")
              }
              await dispatch(
                fetchExpenseRule({ length: 10, start: rulecurrentPage })
              )
              await dispatch(setOpenExpenseRuleModal(false))
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              isSubmitting,
              dirty,
            }) => (
              <Form>
                <Row>
                  <Col md={12}>
                    <div className="form-check form-switch form-switch-md mb-2">
                      <Input
                        name="status"
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked={values?.status == 0 ? false : true}
                        onChange={e => {
                          setFieldValue("status", e.target.checked ? 1 : 0)
                        }}
                      />
                      <Label className="mx-2 fs-6" htmlFor="status">
                        Status
                      </Label>
                    </div>
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Field
                      name="name"
                      placeholder="Enter Employee ID"
                      type="text"
                      className={`form-control ${
                        touched.name && errors.name ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </Col>
                  <Col md="4" className="mb-3">
                    <Label htmlFor="ordering">
                      Ordering <span className="text-danger">*</span>
                    </Label>
                    <Field
                      name="ordering"
                      placeholder="Enter Employee ID"
                      type="number"
                      className={`form-control ${
                        touched.ordering && errors.ordering
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="ordering"
                      component="div"
                      className="text-danger"
                    />
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2 justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !dirty}
                  >
                    {Object.keys(expenseRuleRowData).length > 0 ? (
                      <>{isSubmitting ? "Updating..." : "Update"}</>
                    ) : (
                      <>{isSubmitting ? "Submitting..." : "Submit"}</>
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
)
}