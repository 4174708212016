import React from "react"
import { Button, Card, CardBody, CardTitle, Col, Row, Label } from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import PropTypes from "prop-types"
import {
  editSalaryDetail,
  fetchProfileDetail,
  setEditSalaryDetail,
} from "store/onBoarding/onBordingSlice"
import { useDispatch, useSelector } from "react-redux"

const OnboardingSalaryForm = () => {
  const dispatch = useDispatch()
  const { profileDetails, userId } = useSelector(state => state.onBoarding)
  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    lock_in_period: profileDetails?.lock_in_period || "",
                    salary: profileDetails?.salary || "",
                  }}
                  validationSchema={Yup.object({
                    // lock_in_period: Yup.number()
                    //   .required("Required")
                    //   .min(1, "Must be 1 or greater")
                    //   .max(12, "Must be 12 or less"),
                    salary: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                      const response = await dispatch(
                        editSalaryDetail({ id: userId, data: values })
                      )

                      if (response.payload?.status === "success") {
                        toastr.success(response?.payload?.data?.data?.message)
                      } else {
                        toastr.error("Something went wrong.")
                      }
                    } catch (error) {
                      toastr.error("Something went wrong.")
                    }
                    await dispatch(fetchProfileDetail(userId))
                    await dispatch(setEditSalaryDetail(false))
                  }}
                >
                  {({ touched, errors, isSubmitting }) => (
                    <Form>
                      {console.log(errors, "Verify Errors")}
                      <Row>
                        <Col md={12}>
                          <CardTitle className="header-text">Salary</CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md="12" lg="3" className="mb-3">
                          <Label className="labels" htmlFor="salary">
                            Salary
                          </Label>
                          <Field
                            name="salary"
                            placeholder="Enter Monthly Salary"
                            type="number"
                            className={`form-control ${
                              touched.salary && errors.salary
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {touched.salary && errors.salary && (
                            <div className="text-danger">{errors.salary}</div>
                          )}
                        </Col>
                        <Col md="12" lg="3" className="mb-3">
                          <Label className="labels" htmlFor="lock_in_period">
                            Lock in Period
                          </Label>
                          <Field
                            name="lock_in_period"
                            placeholder="Enter Lock in Period"
                            type="number"
                            className={`form-control`}
                          />
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={() => {
                            dispatch(setEditSalaryDetail(false))
                          }}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnboardingSalaryForm
