import React, { useEffect, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import MetaTags from "react-meta-tags"
import Pagination from "components/Common/Pagination"
import { TableHeader, Search } from "components/Datatable/index"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import { customStyles, logStatusData } from "constants/Constants"
import {
  fetchEmployeeAttendanceLog,
  setEmployeeLogDate,
  setEmployeeLogModal,
  setEmployeeLogPage,
  setEmployeeLogSearch,
  setEmployeeLogStatus,
  setEmployeeRowData,
} from "store/attendanceLogs/attendanceLogSlice"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import {
  attendanceStatus,
  extractTime,
  formatDate,
  sortName,
} from "constants/CommonFunction"

export const AttendanceEmployeeLogs = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    loading,
    employeeLogsData,
    employeeLogStatus,
    employeeLogsDateRange,
    employeeLogsCurrentPage,
    employeeLogTotalData,
    employeeLogSearchQuery,
  } = useSelector(state => state.attendanceLog.attendanceEmployeeLog)

  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr. No.</div>,
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(employeeLogsCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        id: "date",
        Header: () => <div style={{ width: "120px" }}>Date</div>,
        accessor: "date",
        width: 150,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        id: "status",
        Header: () => <div style={{ width: "100px" }}>Status</div>,
        accessor: "attendance_status",
        width: 120,
        Cell: ({ value }) => <div>{attendanceStatus(value)}</div>,
      },
      {
        id: "employee_name",
        Header: () => <div style={{ width: "200px" }}>Employee Name</div>,
        accessor: "employee_first_name",
        width: 250,
        Cell: ({ row }) => (
          <div
            className="d-flex align-items-center"
            style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
          >
            {row.original?.employee_profile_photo ? (
              <img
                src={row.original?.employee_profile_photo}
                alt="Employee"
                className="rounded-circle avatar-xs me-2"
              />
            ) : (
              <div className="avatar-xs align-self-center">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                  {sortName(
                    `${row.original?.employee_first_name} ${
                      row.original?.employee_last_name || ""
                    }`
                  )}
                </span>
              </div>
            )}
            <div>
              <p className="mb-0">{`${row.original.employee_first_name} ${
                row.original.employee_last_name || ""
              }`}</p>
            </div>
          </div>
        ),
      },
      {
        id: "inTime",
        Header: () => <div style={{ width: "120px" }}>In Time</div>,
        accessor: "log_in_time",
        width: 150,
        Cell: ({ value }) => (
          <div>
            {value !== "0000-00-00 00:00:00" ? extractTime(value) : "-"}
          </div>
        ),
      },
      {
        id: "outTime",
        Header: () => <div style={{ width: "120px" }}>Out Time</div>,
        accessor: "log_out_time",
        width: 150,
        Cell: ({ value }) => (
          <div>
            {value !== "0000-00-00 00:00:00" ? extractTime(value) : "-"}
          </div>
        ),
      },
      {
        Header: () => <div style={{ width: "120px" }}>Clock In Location</div>,
        accessor: "clock_in_location",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: () => <div style={{ width: "130px" }}>Clock Out Location</div>,
        accessor: "clock_out_location",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        id: "totalBreakTime",
        Header: () => <div style={{ width: "160px" }}>Total Break Time</div>,
        accessor: "total_break_time",
        width: 160,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        id: "totalLogTime",
        Header: () => <div style={{ width: "160px" }}>Total Log Time</div>,
        accessor: "total_log_time",
        width: 160,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        id: "breaks",
        Header: () => <div style={{ width: "100px" }}>Breaks</div>,
        accessor: "number_of_breaks",
        width: 100,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        id: "action",
        Header: () => <div style={{ width: "160px" }}>Action</div>,
        accessor: "status",
        width: 160,
        Cell: ({ row }) => {
          const { attendance_status, can_send_for_approval } = row?.original
          return can_send_for_approval ? (
            attendance_status == "2" || attendance_status == "3" ? (
              <button
                onClick={() => handleRequestForApproval(row?.original)}
                className="btn btn-primary btn-sm"
              >
                Request For Approval
              </button>
            ) : attendance_status == "4" ? (
              <div style={{ color: "#0094ff" }}>Requested For Approval</div>
            ) : (
              "-"
            )
          ) : (
            "-"
          )
        },
      },
    ]
  }, [dispatch, employeeLogsCurrentPage])

  const fetchEmployeeLogs = async () => {
    const { employee_id } = location?.state?.row
    if (employee_id) {
      dispatch(
        fetchEmployeeAttendanceLog({
          id: employee_id,
          length: 10,
          start: employeeLogsCurrentPage,
          from_date: employeeLogsDateRange[0]
            ? formatDate(employeeLogsDateRange[0], 1)
            : "",
          to_date: employeeLogsDateRange[1]
            ? formatDate(employeeLogsDateRange[1], 1)
            : "",
          employee_status: employeeLogStatus,
          keyword: employeeLogSearchQuery,
        })
      )
    }
  }
  useEffect(() => {
    fetchEmployeeLogs()
  }, [
    dispatch,
    location?.state?.row?.employee_id,
    employeeLogsCurrentPage,
    employeeLogsDateRange,
    employeeLogStatus,
    employeeLogSearchQuery,
  ]);

  const handleRequestForApproval = async (rowData) => {
    await dispatch(setEmployeeRowData(rowData));
    await dispatch(setEmployeeLogModal(true));
  };

  return (
    <div className="col-md-12">
      <Card className="cardBorder">
        <CardBody style={{ paddingBottom: "0px" }}>
          <Row className=" align-items-center">
            <Col md={2} sm={12} style={{ marginTop: "15px" }}>
              {" "}
              <Search
                onSearch={value => {
                  dispatch(setEmployeeLogSearch(value))
                  dispatch(setEmployeeLogPage(1))
                }}
              />
            </Col>
            <Col md={2} className="mb-3">
              <span>Status</span>
              <Select
                id="employeeLogStatus"
                name="employeeLogStatus"
                size="sm"
                options={logStatusData}
                value={logStatusData?.find(
                  option => option.value === employeeLogStatus
                )}
                onChange={async option => {
                  await dispatch(setEmployeeLogPage(1))
                  await dispatch(setEmployeeLogStatus(option?.value))
                }}
                styles={customStyles}
              />
            </Col>
            <Col md={3} className="mb-3">
              <span>Select Date</span>
              <div className="input-group d-flex" data-clear>
                <Flatpickr
                  className="form-control"
                  placeholder="Start Date to End Date"
                  options={{
                    mode: "range",
                    dateFormat: "d/m/Y",
                  }}
                  value={employeeLogsDateRange}
                  onChange={date => {
                    dispatch(setEmployeeLogDate(date))
                  }}
                />
                <button
                  type="button"
                  className="d-flex align-items-center btn btn-md btn-outline-secondary"
                  data-clear
                  onClick={() => {
                    dispatch(setEmployeeLogDate([null, null]))
                  }}
                >
                  <i className="dripicons-cross mx-1 d-flex" />
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              md={12}
              className="overflow-auto"
              style={{
                height: "56vh",
                scrollbarWidth: "thin",
                padding: "0px",
              }}
            >
              <GlobalTable
                columns={columns}
                data={employeeLogsData ? employeeLogsData : []}
                loading={loading}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          className="d-flex justify-content-end"
          style={{
            background: "#ECECEC",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <div
            style={{
              height: "30px",
              marginLeft: "0px",
            }}
          >
            <Pagination
              className="mx-2"
              currentPage={employeeLogsCurrentPage}
              totalCount={employeeLogTotalData ? employeeLogTotalData : 0}
              pageSize={10}
              onPageChange={async page => {
                dispatch(setEmployeeLogPage(page))
              }}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  )
}
