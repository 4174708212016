import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { get, put } from "../../../../helpers/api_helper";
import { GET_DEPARTMENT, EDIT_DEPARTMENT } from "../../../../helpers/api_url_helper";
import toastr from "toastr";
import PropTypes from "prop-types";
import Select from "react-select";

const ExpenseRuleMappingEdit = ({ match, history }) => {
  const [id, setId] = useState("");
  const [expenseRulesName, setExpenseRulesName] = useState("");
  const [ordering, setOrdering] = useState("");
  const [status, setStatus] = useState(true);
  const [submit, setSubmit] = useState(false);

  const validator = new SimpleReactValidator({ autoForceUpdate: this });

  useEffect(() => {
    // getDepartment();
  }, []);

  const getDepartment = () => {
    get(GET_DEPARTMENT, { params: { id: match.params.id } })
      .then((res) => {
        if (res.data) {
          setId(res.data.id);
          setExpenseRulesName(res.data.name);
          setOrdering(res.data.ordering);
          setStatus(res.data.status === "1");
        } else {
          toastr.error("Data load issue, please try again!");
        }
      })
      .catch((err) => {
        toastr.error(err?.response?.data?.message);
        history.push("/department");
      });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "department_name") {
      setExpenseRulesName(value);
    } else if (name === "ordering") {
      setOrdering(value);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      const formData = {
        id,
        deductionRule,
        ordering,
        status: status ? 1 : 0,
      };
      setSubmit(true);
      put(EDIT_DEPARTMENT, formData)
        .then((response) => {
          if (response.status) {
            setSubmit(false);
            toastr.success("Expense Rules update successful.");
            history.push("/Expense-Rule-mapping");
          }
        })
        .catch((err) => {
          toastr.error(err?.response?.data?.message);
          setSubmit(false);
        });
    } else {
      validator.showMessages();
      forceUpdate();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Expense Rules Name Map | {process.env.REACT_APP_PROJECTNAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Expense Rules Name" path="/Expense-Rule" breadcrumbItem="Edit" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <form onSubmit={handleFormSubmit}>
                  <Row>
                    <Col lg="3" className="mb-3">
                    <Label htmlFor="type">Type</Label>
                    <Select
                    isMulti={false}
                    // options={this.state.country_master}
                    name="type"
                    classNamePrefix="select2-selection"
                    />
                    </Col>
                    <Col lg="3" className="mb-3">
                    <Label htmlFor="type">Value</Label>
                    <Select
                    isMulti={false}
                    name="value"
                    classNamePrefix="select2-selection"
                    />
                    </Col>
                    <Col md="3" className="mb-3">
                    <Label htmlFor="name">Level</Label>
                    <input
                    name="level"
                    placeholder="Type Level"
                    type="number"
                    className={"form-control"}
                    />
                    </Col>
                    <Col lg="3" className="mb-3">
                    <Label htmlFor="type">Employee</Label>
                    <Select
                    isMulti={false}
                    // options={this.state.country_master}
                    name="employee"
                    classNamePrefix="select2-selection"
                    />
                    </Col>
                  </Row>
                  <Row>
                      <Col md="3" className="mb-3">
                      <div className="form-check form-switch form-switch-lg">
                      <input
                      type="checkbox"
                      className="form-check-input"
                      id="ultimate_approval"/>
                      <label
                      className="form-check-label"
                      htmlFor="ultimate_approval"
                      >
                      Ultimate Approval
                      </label>
                      </div>
                      </Col>
                      <Col md="auto" className="mb-3 align-self-end">
                        <div className="form-check form-switch form-switch-lg">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="current_status"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="current_status"
                          >
                            Status
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2 justify-content-end">
                      <Button color="primary" type="submit" disabled={submit}>
                        {submit === true ? "Please wait..." : "Submit Data"}
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ExpenseRuleMappingEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ExpenseRuleMappingEdit;
