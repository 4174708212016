import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import Pagination from "components/Common/Pagination"
import {
  fetchOtherDocument,
  setCurrentPage,
  setDocumentModal,
  setTableRowData,
} from "store/otherDocument/otherDocumentSlice"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getUserId } from "constants/CommonFunction"
import { setUserId } from "store/onBoarding/onBordingSlice"

export const OtherDocument = () => {
  const { documentData, loading, currentPage, totalData } = useSelector(
    state => state.otherDocument
  )
  const { userId } = useSelector(state => state.onBoarding)
  const dispatch = useDispatch()

  const columns = useMemo(() => {
    return [
      {
        Header: "Sr. No.",
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: "Document File",
        accessor: "document_path",
        Cell: ({ value }) => (
          <div>
            {value ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                File
              </a>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: "Document Name",
        accessor: "document_name",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "5px" }}>
            <i
              onClick={async () => {
                await dispatch(setTableRowData(row.original))
                await dispatch(setDocumentModal(true))
              }}
              className="bx bxs-edit cursor-pointer "
              style={{ fontSize: "17px", color: "#039f03" }}
            />
          </div>
        ),
      },
    ]
  }, [dispatch, currentPage])

  const handleAdd = async () => {
    await dispatch(setTableRowData([]))
    await dispatch(setDocumentModal(true))
  }

  const location = useLocation()

  useEffect(() => {
    const fetchData = async () => {
      const newUserId = location?.state?.row?.id
        ? location?.state?.row?.id
        : getUserId()
      await dispatch(setUserId(newUserId))
      await dispatch(
        fetchOtherDocument({ id: newUserId, length: 10, start: currentPage })
      )
    }
    fetchData()
  }, [dispatch, location, currentPage])

  return (
    <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
        <h5 className="mb-3">Other Document</h5>
        <Card>
          <CardBody>
            <Row className=" align-items-center">
              <Col
                md={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
              >
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{ height: "52vh", scrollbarWidth: "thin" }}
              >
                <GlobalTable
                  columns={columns}
                  data={documentData ? documentData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={currentPage}
                totalCount={totalData ? totalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  await dispatch(setCurrentPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
        </Col>
      </Row>
    </div>
  )
}
