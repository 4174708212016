import React from "react"
import { Container } from "reactstrap"
import { DeductionMappingView } from "./DeductionMappingView"
import { DeductionMappingForm } from "./DeductionMappingFrom"

export const DeductionMappingList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <DeductionMappingForm />
        <DeductionMappingView />
      </Container>
    </div>
  )
}
