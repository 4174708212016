import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import {
  setAssignAttendanceQuery,
  setAssignAttendancePage,
  fetchAssignAttendance,
  setSelectedEmployee,
} from "store/attendanceRule/attendanceRuleSlice"
import { sortFirstLetter, sortName } from "constants/CommonFunction"
import AssignAttendanceForm from "./AssignAttendanceForm"

export const AssignAttendance = () => {
  const dispatch = useDispatch()
  const {
    attendanceAssignSearchQuery,
    attendanceAssignCurrentPage,
    attendanceAssignData,
    attendanceAssignTotalData,
    selectedEmployee,
  } = useSelector(state => state.attendanceRule.assignAttendance)
  const toggleRowSelection = rowId => {
    const updatedSelection = selectedEmployee.includes(rowId)
      ? selectedEmployee.filter(id => id !== rowId)
      : [...selectedEmployee, rowId]
    dispatch(setSelectedEmployee(updatedSelection))
  }

  const toggleAllRows = checked => {
    const allRowIds = attendanceAssignData?.map(row => row.id) || []
    const updatedSelection = checked ? allRowIds : []
    dispatch(setSelectedEmployee(updatedSelection))
  }

  const columns = useMemo(() => {
    return [
      {
        Header: ({ row }) => (
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            onChange={e => toggleAllRows(e.target.checked)}
            defaultChecked={attendanceAssignData?.every(row =>
              selectedEmployee.includes(row.id)
            )}
          />
        ),
        accessor: "checkbox",
        Cell: ({ row }) => (
          <input
            style={{ cursor: "pointer" }}
            type="checkbox"
            defaultChecked={selectedEmployee.includes(row.original.id)}
            onChange={() => toggleRowSelection(row.original.id)}
          />
        ),
        disableSortBy: true,
      },
      {
        accessor: "id",
        Header: <div style={{ width: "60px" }}>Sr. No.</div>,
        Cell: ({ row }) => (
          <div>{(attendanceAssignCurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        accessor: "first_name",
        Header: () => <div style={{ width: "200px" }}>Employee Name</div>,
        Cell: ({ row }) => (
          <div
            className="d-flex align-items-center"
            style={{ gap: "10px", textDecoration: "none", color: "inherit" }}
          >
            {row.original?.profile_photo ? (
              <img
                src={row.original?.profile_photo}
                alt="Employee"
                className="rounded-circle avatar-xs me-2"
              />
            ) : (
              <div className="avatar-xs align-self-center">
                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                  {sortName(
                    `${row.original?.first_name} ${
                      row.original?.last_name || ""
                    }`
                  )}
                </span>
              </div>
            )}
            <div>
              <p className="mb-0">{`${row.original.first_name} ${
                row.original.last_name ? row.original.last_name : ""
              }`}</p>
            </div>
          </div>
        ),
      },
      {
        accessor: "department_master_name",
        Header: () => <div style={{ width: "220px" }}>Department</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "designation_master_name",
        Header: () => <div style={{ width: "120px" }}>Designation</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "reporting_manager_first_name",
        Header: () => <div style={{ width: "200px" }}>Employee Manager</div>,
        Cell: ({ row }) => {
          const { reporting_manager_first_name, reporting_manager_last_name } =
            row.original
          return (
            <div>
              <p className="mb-0">
                {reporting_manager_first_name}
                {reporting_manager_last_name &&
                  ` ${reporting_manager_last_name}`}
              </p>
            </div>
          )
        },
      },
      {
        accessor: "mobile",
        Header: () => <div style={{ width: "100px" }}>Phone</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        accessor: "signed_in",
        Header: () => <div style={{ width: "100px" }}>Signed In</div>,
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: <div style={{ width: "300px" }}>Action</div>,
        accessor: "attendance_rules",
        Cell: ({ row }) => {
          const { attendance_rules } = row.original
          return (
            <div className="d-flex flex-wrap">
              {attendance_rules?.length > 0 ? (
                attendance_rules.map((rule, index) => (
                  <div
                    key={index}
                    className="mb-3 d-flex align-items-center mx-2"
                    style={{
                      border: "1px solid #c2cbdc",
                      padding: "2px",
                      borderRadius: "50px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                  >
                    <span
                      className="avatar-title rounded-circle text-white "
                      style={{
                        background: "#c2cbdc",
                        height: "30px",
                        width: "30px",
                        background: "#0095fe",
                      }}
                    >
                      {sortFirstLetter(`${rule?.name}`)}
                    </span>
                    <div className="d-flex align-items-center mx-1 px-2 py-1">
                      {rule.name}
                    </div>
                  </div>
                ))
              ) : (
                <span>-</span>
              )}
            </div>
          )
        },
      },
    ]
  }, [
    dispatch,
    selectedEmployee,
    attendanceAssignCurrentPage,
    attendanceAssignData,
  ])

  useEffect(() => {
    dispatch(
      fetchAssignAttendance({
        length: 10,
        start: attendanceAssignCurrentPage,
        keyword: attendanceAssignSearchQuery,
      })
    )
  }, [attendanceAssignCurrentPage, attendanceAssignSearchQuery, dispatch])

  return (
    <Card>
      <CardBody style={{ paddingBottom: "0px" }}>
        <Row className=" align-items-center">
          <Col md={2} sm={12} className="d-flex mb-2">
            <Search
              onSearch={value => {
                dispatch(setAssignAttendanceQuery(value))
                dispatch(setAssignAttendancePage(1))
              }}
            />
          </Col>
          <AssignAttendanceForm />
        </Row>
        <Row>
          <Col
            md={12}
            className="overflow-auto"
            style={{
              height: "56vh",
              scrollbarWidth: "thin",
              padding: "0px",
            }}
          >
            <GlobalTable
              key={selectedEmployee}
              columns={columns}
              data={attendanceAssignData ? attendanceAssignData : []}
              loading={false}
              onSelectionChange={async selectedIds => {
                await dispatch(setSelectedEmployee(selectedIds))
              }}
              toggleRowSelection={toggleRowSelection}
              selectedRowIds={selectedEmployee}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter
        className="d-flex justify-content-end"
        style={{
          background: "#ECECEC",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <div
          style={{
            height: "30px",
            marginLeft: "0px",
          }}
        >
          <Pagination
            className="mx-2"
            currentPage={attendanceAssignCurrentPage}
            totalCount={
              attendanceAssignTotalData ? attendanceAssignTotalData : 0
            }
            pageSize={10}
            onPageChange={async page => {
              dispatch(setAssignAttendancePage(page))
            }}
          />
        </div>
      </CardFooter>
    </Card>
  )
}
