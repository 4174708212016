import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Input,
  Col,
} from "reactstrap"
import { Formik, Form } from "formik"
import {
  assignLeave,
  fetchAssignLeave,
  setSelectedEmployee,
} from "store/leaveRule/leaveRuleSlice"
import toastr from "toastr"
import { currentDate } from "constants/CommonFunction"

const AssignLeaveForm = () => {
  const [menu, setMenu] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const dispatch = useDispatch()
  const { leaveRuleData } = useSelector(state => state?.leaveRule?.leaveRule)
  const { selectedEmployee, leaveAssignCurrentPage, leaveAssignSearchQuery } =
    useSelector(state => state.leaveRule.assignLeave)

  return (
    <Col md={3} key={selectedId}>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block mx-2"
      >
        <DropdownToggle
          style={{ background: "none", border: "none", fontSize: "14px" }}
        >
          <div>
            <p className="d-flex align-items-center mb-0 text-black">
              <i
                style={{
                  borderRadius: "50%",
                  padding: "3px",
                  color: "white",
                  background: "blue",
                }}
                className="bx bx-check-square mx-1 fs-5"
              />
              Assign Rules
            </p>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="p-2 dropdown-menu-responsive"
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            minWidth: "250px",
            position: "relative",
            scrollbarWidth: "thin",
          }}
        >
          <Formik
            initialValues={{
              selectedRuleId: "",
              effective_date: currentDate(),
            }}
            onSubmit={async values => {
              const payload = {
                employees: selectedEmployee,
                effective_date: values?.effective_date,
              }
              try {
                const response = await dispatch(
                  assignLeave({
                    id: selectedId,
                    data: payload,
                  })
                )
                if (response?.payload.data?.data?.status) {
                  toastr.success(response?.payload.data?.data?.message)
                } else {
                  toastr.error(response?.payload?.message)
                }
              } catch (error) {
                toastr.error("Something Went Wrong")
              } finally {
                dispatch(
                  fetchAssignLeave({
                    length: 10,
                    start: leaveAssignCurrentPage,
                    keyword: leaveAssignSearchQuery,
                  })
                )
                dispatch(setSelectedEmployee([]))
                setSelectedId("")
                setMenu(false)
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                {console.log(values, "Verify Values")}
                <div
                  style={{
                    maxHeight: "240px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {leaveRuleData?.map(item => (
                    <DropdownItem
                      type="button"
                      className="mt-2"
                      key={item?.id}
                      toggle={false}
                    >
                      <div className="form-check">
                        <Input
                          type="checkbox"
                          name="selectedRuleId"
                          id={`rule-${item.id}`}
                          value={item?.id}
                          className="form-check-input"
                          defaultChecked={selectedId === item?.id}
                          onChange={() => {
                            setSelectedId(item?.id)
                            setFieldValue("selectedRuleId", item?.id)
                          }}
                        />
                        <label
                          className="form-check-label mx-2"
                          htmlFor={`rule-${item.id}`}
                        >
                          {item?.name}
                        </label>
                      </div>
                    </DropdownItem>
                  ))}
                </div>
                <div
                  className="mt-3"
                  style={{
                    position: "sticky",
                    bottom: 0,
                    background: "white",
                    padding: "8px 0",
                    zIndex: 1,
                  }}
                >
                  <Input
                    type="date"
                    className="mb-2"
                    value={values?.effective_date}
                    onChange={e =>
                      setFieldValue("effective_date", e.target.value)
                    }
                  />
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <button
                      type="button"
                      onClick={() => setMenu(false)}
                      className="d-flex align-items-center btn btn-sm btn-outline-secondary"
                    >
                      <i className="dripicons-cross mx-1 d-flex" />
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="d-flex align-items-center btn btn-sm btn-primary mx-2"
                      disabled={
                        !(
                          selectedEmployee?.length > 0 &&
                          selectedId.length > 0 &&
                          values.effective_date
                        )
                      }
                    >
                      <i className="bx bx-check mx-1 fs-5" />
                      {isSubmitting ? "Please Wait" : "Save"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DropdownMenu>
      </Dropdown>
    </Col>
  )
}

export default AssignLeaveForm
