import React from "react"
import { DeductionRewardRuleView } from "./DeductionRewardRuleView"
import { Container } from "reactstrap"
import { DeductionRewardRuleFrom } from "./DeductionRewardRuleFrom"

export const DeductionRewardRuleList = () => {
  return (
    <div className="page-content">
      <Container fluid={true}>
        <DeductionRewardRuleFrom/>
        <DeductionRewardRuleView />
      </Container>
    </div>
  )
}
