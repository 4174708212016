import { Field, Form, Formik } from "formik"
import React from "react"
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import toastr from "toastr"
import { toggleAnomalyRejactionForm } from "store/attendanceApproval/attendanceApprovalSlice"
import { useDispatch, useSelector } from "react-redux"
import { toggleLeaveRejactionForm, updateStatus } from "store/leaveApproval/leaveApprovalSlice"
import { formatDateFormat } from "constants/CommonFunction"

export const RejectLeaveApprovalForm = () => {
  const dispatch = useDispatch()
  const { rejectLeaveForm , leaveToAction } = useSelector(state => state.leaveApproval)
  const toggle = () => {
    dispatch(toggleLeaveRejactionForm({toggle:false, data:[]}))
  };
  const validationSchema = Yup.object().shape({
    rejectionReason: Yup.string().required('Rejection Reason is required'),
  });
  console.clear()
  console.log(leaveToAction)
  return (
    <Modal
      scrollable
      style={{ maxWidth: "400px" }}
      isOpen={rejectLeaveForm}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>Reject Leave</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                employeeName: `${leaveToAction.first_name || ""} ${leaveToAction.last_name ||""}`,
                employeeId: leaveToAction.emp_id,
                department: leaveToAction?.department_master_name,
                leaveType: leaveToAction?.leave_rule_name,
                designation: leaveToAction?.designation_master_name,
                balance: leaveToAction?.available,
                profile: leaveToAction.profile_photo,
                dateApplied: leaveToAction.created_on,
                startDate: leaveToAction.start_date,
                endDate: leaveToAction.end_date,
                total_days: leaveToAction.total_days,
                reason: leaveToAction.reason,
                rejectionReason: "",
              }}
                validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                dispatch(updateStatus({id:leaveToAction.id,status:2, reason:values.rejectionReason}))
                setSubmitting(false)
                console.log(values)
                resetForm()
                toggle()
              }}
            >
              {({ values, setFieldValue, isSubmitting, errors, touched }) => (
                <Form>
                  <Row>
                    <Col md="12">
                      <div className="d-flex mb-3 gap-3 justify-content-between">
                        <div className="d-flex gap-3">
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                            src={leaveToAction.profile_photo}
                            alt="Emplyees Image"
                          />
                          <div className="">
                            <Label>{values.employeeName}</Label>
                            <p className="mb-0">ID: {values.employeeId}</p>
                          </div>
                        </div>
                        <span style={{ fontSize: "10px" }}>
                          {formatDateFormat(values.dateApplied)}
                        </span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="">
                        <Label>Department</Label>
                        <p>{values.department}</p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="">
                        <Label>Designation</Label>
                        <p>{values.designation}</p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="">
                        <Label>Leave type</Label>
                        <p>{values.leaveType}</p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="">
                        <Label>Balance</Label>
                        <p>{values.balance}</p>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="">
                        <Label>Start Date</Label>
                        <p>{formatDateFormat(values.startDate)}</p>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="">
                        <Label>End Date</Label>
                        <p>{formatDateFormat(values.endDate)}</p>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="">
                        <Label>Total Days</Label>
                        <p>{values.total_days}</p>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="">
                        <Label>Reason</Label>
                        <p>{values.reason}</p>
                      </div>
                    </Col>
                    <Col md="12" className="mb-3">
                      <Label htmlFor="rejectionReason">
                        Reason For Rejection
                        <span className="text-danger">*</span>
                      </Label>
                      <Field
                        as="textarea"
                        name="rejectionReason"
                        placeholder="Write your reason"
                        rows="3"
                        className={`form-control ${
                          touched.rejectionReason && errors.rejectionReason
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {touched.rejectionReason && errors.rejectionReason && (
                        <div className="text-danger">
                          {errors.rejectionReason}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || values.rejectionReason === ""}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
