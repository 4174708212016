import React from "react"
import { LeaveApproval } from "./LeaveApproval"
import { RejectLeaveApprovalForm } from "./RejectLeaveApprovalForm"

const LeaveApprovalList = () => {
  return (
    <div>
      <RejectLeaveApprovalForm />
      <LeaveApproval />
    </div>
  )
}

export default LeaveApprovalList
