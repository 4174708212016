import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiAssignAttendance,
  apiCreateattendanceRule,
  apiEditattendanceRule,
  apiGetAssignAttendance,
  apiGetattendanceRule,
} from "services/AttendanceRuleService"

export const SLICE_NAME = "attendanceRule"

const initialState = {
  attendanceActiveTabs: "attendance",
  attendanceRule: {
    loading: false,
    attendanceRuleData: [],
    attendanceRuleSearchQuery: "",
    attendanceRuleTotalData: 0,
    attendanceRuleCurrentPage: 1,
  },
  assignAttendance: {
    loading: false,
    attendanceAssignData: [],
    attendanceAssignSearchQuery: "",
    attendanceAssignTotalData: 0,
    attendanceAssignCurrentPage: 1,
    selectedEmployee: [],
  },
}

export const fetchattendanceRule = createAsyncThunk(
  `${SLICE_NAME}/fetchattendanceRule`,
  async ({ keyword, length, employee_id, start, }) => {
    try {
      const response = await apiGetattendanceRule({ keyword, length, employee_id, start })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchAssignAttendance = createAsyncThunk(
  `${SLICE_NAME}/fetchAssignAttendance`,
  async ({ keyword, length, start }) => {
    try {
      const response = await apiGetAssignAttendance({ keyword, length, start })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const createAttendanceRule = createAsyncThunk(
  `${SLICE_NAME}/createAttendanceRule`,
  async data => {
    try {
      const response = await apiCreateattendanceRule(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const assignAttendance = createAsyncThunk(
  `${SLICE_NAME}/assignAttendanceRule`,
  async ({ id, data }) => {
    try {
      const response = await apiAssignAttendance(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editAttendanceRule = createAsyncThunk(
  `${SLICE_NAME}/editAttendanceRule`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiEditattendanceRule(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const attendanceRule = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAttendanceActiveTab: (state, action) => {
      state.attendanceActiveTabs = action.payload
    },
    setAttendanceRulePage: (state, action) => {
      state.attendanceRule.attendanceRuleCurrentPage = action.payload
    },
    setAttendanceRuleQuery: (state, action) => {
      state.attendanceRule.attendanceRuleSearchQuery = action.payload
    },

    // Assign Attendance
    setAssignAttendancePage: (state, action) => {
      state.assignAttendance.attendanceAssignCurrentPage = action.payload
    },
    setAssignAttendanceQuery: (state, action) => {
      state.assignAttendance.attendanceAssignSearchQuery = action.payload
    },
    setSelectedEmployee: (state, action) => {
      state.assignAttendance.selectedEmployee = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchattendanceRule.pending, state => {
      state.attendanceRule.loading = true
    })
    builder.addCase(fetchattendanceRule.fulfilled, (state, action) => {
      state.attendanceRule.loading = false
      state.attendanceRule.attendanceRuleData = action?.payload?.data?.data
      state.attendanceRule.attendanceRuleTotalData =
        action?.payload?.data?.total
    })
    builder.addCase(fetchattendanceRule.rejected, state => {
      state.loading = false
    })

    // Assign Attendance
    builder.addCase(fetchAssignAttendance.pending, state => {
      state.assignAttendance.loading = true
    })
    builder.addCase(fetchAssignAttendance.fulfilled, (state, action) => {
      state.assignAttendance.loading = false
      state.assignAttendance.attendanceAssignData = action?.payload?.data?.data
      state.assignAttendance.attendanceAssignTotalData =
        action?.payload?.data?.total
    })
    builder.addCase(fetchAssignAttendance.rejected, state => {
      state.loading = false
    })
  },
})

export const {
  setAttendanceRulePage,
  setAttendanceRuleQuery,
  setAttendanceActiveTab,
  setAssignAttendancePage,
  setAssignAttendanceQuery,
  setSelectedEmployee,
} = attendanceRule.actions

export default attendanceRule.reducer
