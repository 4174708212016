import React from "react"
import { Card, CardBody } from "reactstrap"

const CardSkeleton = () => {
  return (
    <Card className="mb-3" style={{ height: "300px", borderRadius: "8px" }}>
      <CardBody>
        <div
          className="skeleton skeleton-text mb-2"
          style={{ height: "26px", width: "10%" }}
        />
        <div
          className="skeleton skeleton-title mb-2"
          style={{ height: "200px", width: "100%" }}
        />
        <div
          className="skeleton skeleton-text mb-2"
          style={{ height: "26px", width: "10%" }}
        />
      </CardBody>
    </Card>
  )
}

export default CardSkeleton
