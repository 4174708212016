import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
    apiGetExpenseDocument,
    apiCreateExpenseDocument,
    apiDeleteExpenseDocument
} from "services/ExpenseDocumentService";

export const SLICE_NAME = "expenseDocumentSlice"

const initialState = {
  loading: false,
  openDocumentModal: false,
  expenseDocumentData: [],
  DocumentTotalData: 0,
  currentPage: 1,
  documentId: null,
  documentContent: null,
  isModalOpen: false,
  isModalViewOpen:false
}

export const fetchExpenseDocument = createAsyncThunk(
  `${SLICE_NAME}/fetchExpenseDocument`,
  async ({ id, length, start }) => {
    try {
      // console.log("Calling API with:", { id, length, start });
      const response = await apiGetExpenseDocument({ id, length, start });
      // console.log("API Response:", response);
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  }
);

export const deleteExpenseDocument = createAsyncThunk(
  `${SLICE_NAME}/deleteExpenseDocument`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await apiDeleteExpenseDocument(id, data)
      return response?.data
    } catch (error) {
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      })
    }
  }
)


export const createExpenseDocument = createAsyncThunk(
  `${SLICE_NAME}/createExpenseDocument`,
  async ({ id, data }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await apiCreateExpenseDocument(id, data)
      return response

    } catch (error) {
      // console.error("API call error:", error);
      return rejectWithValue({
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      });
    }
  }
);



const expenseDocumentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setDocumentModal: (state, action) => {
      state.openDocumentModal = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },

    setDocumentId: (state, action) => {
      state.documentId = action.payload;
    },
    setDocumentContent(state, action) {
      state.documentContent = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setIsModalViewOpen: (state, action) => {
      state.isModalViewOpen = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchExpenseDocument.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchExpenseDocument.fulfilled, (state, action) => {
      state.loading = false
      state.expenseDocumentData = action?.payload?.data
      state.DocumentTotalData = action?.payload?.total
    })
    builder.addCase(fetchExpenseDocument.rejected, state => {
      state.loading = false
    })
  },
})

export const {
  setLoading,
  setDocumentModal,
  setCurrentPage,
  setDocumentId,
  setDocumentContent,setIsModalOpen,setIsModalViewOpen
} = expenseDocumentSlice.actions

export default expenseDocumentSlice.reducer
