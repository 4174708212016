import React from 'react'
import { useSelector } from 'react-redux'
import OnboardingSalaryForm from '../OnbordingForms/OnboardingSalaryForm'
import OnboardingSalaryDetail from '../OnboardingDetails/OnboardingSalarayDetail'

export const OnboardingSalaryDetailView = () => {
    const {editSalaryDetail} = useSelector((state) => state.onBoarding)
  return (
    <div>
        {editSalaryDetail ? (
            <OnboardingSalaryForm />
        ) : (
            <OnboardingSalaryDetail />
        )}
    </div>
  )
}
