import React, { useEffect, useMemo,useState } from "react"
import { Row, Col, Card, CardBody, Button, CardFooter,Input } from "reactstrap"
import Pagination from "components/Common/Pagination"
import { Search } from "components/Datatable/index"
import GlobalTable from "components/Datatable/GlobalTable"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchEmployeeAccessory,
  setCurrentPage,
  setOpenAccessoryModal,
  setAccessorySearch,
  setEmployeeAccessoryRowData,
} from "store/employeeAccessory/employeeAccessorySlice"
import toastr from "toastr"
import { formatDateFormat } from "constants/CommonFunction"
import "../../../MultiPageLink.css"
import useExportXlsx from "components/Hooks/useExportXlsx"
import useImportXlxs from "components/Hooks/useImportXlxs"

 const EmployeeAccessoryView = () => {
  const {
    currentPage,
    accessorySearchQuery,
    employeeAccessoryData,
    employeeAccessoryTotalData,
    loading,
  } = useSelector(state => state.employeeAccessory);
  const [FormMsg, setFormMsg] = useState("")

  const dispatch = useDispatch()
  const { exportXlsx, xlsxLoader } = useExportXlsx()
  const [file, setFile] = useState(null)


  const columns = useMemo(() => {
    return [
      {
        id: "srNo",
        Header: () => <div style={{ width: "60px" }}>Sr.No</div>,
        accessor: (_row, index) => index + 1,
        Cell: ({ row }) => (
          <div>{(currentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: <div style={{width:'100px'}}>Asset Type</div>,
        accessor: "asset_type_master_name",
        Cell: ({ value }) => <div>{value? value :"-"}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Brand</div>,
        accessor: "brand",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Model No</div>,
        accessor: "model",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Name of person</div>,
        accessor: "employee_id",
        Cell: ({ row }) => {
          const firstName = row.original.first_name
          const lastName = row.original.last_name
          return (
            <div>
              {firstName && lastName ? `${firstName} ${lastName}` : "-"}
            </div>
          )
        },
      },
      {
        Header: <div style={{width:'100px'}}>Location</div>,
        accessor: "office_location_name",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Date</div>,
        accessor: "date",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Date of Return</div>,
        accessor: "date_of_return",
        Cell: ({ value }) => <div>{formatDateFormat(value)}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Status</div>,
        accessor: "status",
        Cell: ({ value }) => <div>{value==1 ? "Assigned" : "Unassigned" || "-"}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Remarks</div>,
        accessor: "remark",
        Cell: ({ value }) => <div>{value? value :"-"}</div>,
      },
      {
        Header: <div style={{width:'100px'}}>Last Actioned By</div>,
        accessor: "update_by_emp_id",
        Cell: ({ row }) => {
          const firstName = row.original.update_by_first_name
          const lastName = row.original.updated_by_last_name
          return (
            <div>
              {firstName && lastName ? `${firstName} ${lastName}` : "-"}
            </div>
          )
        },
      },
      {
        Header: <div style={{width:'100px'}}>Last Actioned Date & Time</div>,
        accessor: "updated_on",
        Cell: ({ row }) => {
          const date= row.original.updated_on
          const time= row.original.updated_on
          return (
            <div>
              {date && time? `${formatDateFormat(date)} ${time.slice(11, 19)}` : "-"}
            </div>
          )
        }
      },
      {
        Header: <div style={{width:'100px'}}>Action</div>,
        accessor: "actions",
        Cell: ({ row }) => {
          // const { is_action, approvals, status } = row.original
          return (
            <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <i
                    onClick={async () => {
                      await dispatch(setEmployeeAccessoryRowData(row?.original))
                      await dispatch(setOpenAccessoryModal(true))
                    }}
                    className="bx bxs-edit cursor-pointer "
                    style={{ fontSize: "17px", color: "#039f03" }}
                  />
                </div>
            </div>
          )
        },
      },
    ]
  }, [dispatch])

  useEffect(() => {
    dispatch(
      fetchEmployeeAccessory({
        length: 10,
        start: currentPage,
        keyword: accessorySearchQuery.length > 0 ? accessorySearchQuery : undefined,
      })
    )
  }, [currentPage, accessorySearchQuery])

  const handleAdd = async () => {
    await dispatch(setEmployeeAccessoryRowData([]))
    await dispatch(setOpenAccessoryModal(true))
  }

  const { importXlxs } = useImportXlxs();
  const ImportCSV = async e => {
    e.preventDefault();
    if (file) {
      await importXlxs("employee-assets", file);
      document.getElementById("file").value = "";
      dispatch(
        fetchEmployeeAccessory({
          length: 10,
          start: currentPage,
          keyword: accessorySearchQuery.length > 0 ? accessorySearchQuery : undefined,
        })
      )
      setFile(null); // Ensure the state gets cleared
    } else {
      toastr.error("No file selected"); // Handle case where file is not present
    }
  };
  const handleFileChange = e => {
    setFile(e.target.files[0])
  }
  return (

    <div className="row">
         <div className="col-md-12">
         <h5 className="mb-3">EMPLOYEE ACCESSORY</h5>
         <Card className="mt-3">
          <CardBody>
          <Row className=" align-items-center">
             <Col md={6} sm={12} style={{ marginTop: "10px" }}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setAccessorySearch(value))
                    dispatch(setCurrentPage(1))
                  }}
                />
              </Col>

              <Col
                md={6}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
                style={{ marginTop: "10px" }}
              >
               <div className="d-flex align-items-center">
                <form onSubmit={ImportCSV}>
                  <div className="input-group">
                    <Input
                      type="file"
                      className="form-control"
                      name={"file"}
                      accept={".xlsx"}
                      id="file"
                      aria-describedby="file"
                      aria-label="Upload"
                      onChange={handleFileChange}
                    />
                    <Button
                      color="primary"
                      type="submit"
                      id="inputGroupFileAddon04"
                      disabled={!file}
                    >
                      Import
                    </Button>
                  </div>
                </form>

                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  style={{ borderRadius: "5px", height: "38px" }}
                  onClick={() => exportXlsx("employee-assets", "Employee Accessory")}
                  disabled={xlsxLoader}
                >
                  <i className="bx bx-export font-size-16 me-2" />
                  {xlsxLoader ? "Exporting..." : "Export"}
                </Button>
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
                {/* } */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={employeeAccessoryData ? employeeAccessoryData : []}
                  loading={loading}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={currentPage}
                totalCount={employeeAccessoryTotalData ? employeeAccessoryTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  dispatch(setCurrentPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
export default EmployeeAccessoryView