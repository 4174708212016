import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Label,
  Container,
  Input,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom"

export const AttendanceApprovalEdit = () => {
  const [showTimeInputs, setShowTimeInputs] = useState(false)
  const validationSchema = Yup.object({
    employeeName: Yup.string().required("Employee name is required"),
    department: Yup.string().required("Department is required"),
    manager: Yup.string().required("Manager is required"),
    originalTimeIn: Yup.date().when("showTimeInputs", {
      is: true,
      then: Yup.date().required("Original Time In is required"),
    }),
    originalTimeOut: Yup.date().when("showTimeInputs", {
      is: true,
      then: Yup.date().required("Original Time Out is required"),
    }),
    requestedTimeIn: Yup.date().when("showTimeInputs", {
      is: true,
      then: Yup.date().required("Requested Time In is required"),
    }),
    requestedTimeOut: Yup.date().when("showTimeInputs", {
      is: true,
      then: Yup.date().required("Requested Time Out is required"),
    }),
  })

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    employeeName: "",
                    department: "",
                    manager: "",
                    present: false,
                    exactTime: false,
                    resignationReason: "", 
                    originalTimeIn: "",
                    originalTimeOut: "",
                    requestedTimeIn: "",
                    requestedTimeOut: "",
                    showTimeInputs: false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log(values)
                    toastr.success("User update successful.")
                    setSubmitting(false)
                    resetForm()
                  }}
                >
                  {({
                    touched,
                    errors,
                    isSubmitting,
                    setFieldValue,
                    values,
                  }) => (
                    <Form>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex align-items-center mt-4">
                            <CardTitle className="text-danger">
                              Anomaly Details
                            </CardTitle>
                          </div>
                          <hr />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="employeeName">Employee Name</Label>
                          <Field
                            name="employeeName"
                            placeholder="Employee Name"
                            type="text"
                            className={`form-control ${
                              touched.employeeName && errors.employeeName
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="employeeName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="department">Department</Label>
                          <Field
                            name="department"
                            placeholder="Department"
                            type="text"
                            className={`form-control ${
                              touched.department && errors.department
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="department"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="manager">Manager</Label>
                          <Field
                            name="manager"
                            placeholder="Manager"
                            type="text"
                            className={`form-control ${
                              touched.manager && errors.manager
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="manager"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="mb-3">
                          <Label>Mark Anomaly</Label>
                          <div className="d-flex">
                            <div className="form-check me-4">
                              <Input
                                type="checkbox"
                                name="present"
                                className="form-check-input"
                                id="present"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="present"
                              >
                                Mark As present
                              </Label>
                            </div>
                            <div className="form-check">
                              <Input
                                type="checkbox"
                                name="exactTime"
                                className="form-check-input"
                                id="exactTime"
                                onChange={() => {
                                  console.log(!showTimeInputs)
                                  setShowTimeInputs(!showTimeInputs)
                                  setFieldValue(
                                    "showTimeInputs",
                                    !values.showTimeInputs
                                  )
                                }}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="exactTime"
                              >
                                Mark Exact Time
                              </Label>
                            </div>
                          </div>
                        </Col>
                        {showTimeInputs && (
                          <>
                            <Col md={12}>
                              <label>Original Time</label>
                            </Col>
                            <Col md={3}>
                              <label>Time In</label>
                              <Field
                                name="originalTimeIn"
                                type="date"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="originalTimeIn"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col md={3}>
                              <label>Time Out</label>
                              <Field
                                name="originalTimeOut"
                                type="date"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="originalTimeOut"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col md={12} className="mt-2">
                              <label>Requested Time</label>
                            </Col>
                            <Col md={3}>
                              <label>Time In</label>
                              <Field
                                name="requestedTimeIn"
                                type="date"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="requestedTimeIn"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col md={3}>
                              <label>Time Out</label>
                              <Field
                                name="requestedTimeOut"
                                type="date"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="requestedTimeOut"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </>
                        )}
                        <Col md="12" className="mb-3 mt-3">
                          <Label htmlFor="resignationReason">
                            Reason For Rejection
                          </Label>
                          <Field
                            as="textarea"
                            placeholder="Reason For Rejection"
                            name="resignationReason"
                            rows="3"
                            className={`form-control ${
                              touched.resignationReason &&
                              errors.resignationReason
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="resignationReason"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Link to="/user-attendance-approval">
                          <Button
                            color="info"
                            className="d-flex align-items-center"
                            type="reset"
                            onClick={() => {}}
                          >
                            <i className="dripicons-cross mx-1 d-flex" />
                            Cancel
                          </Button>
                        </Link>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
