import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  apiAssignLeave,
  apiCreateleaveRule,
  apiDeleteAssignLeave,
  apiEditleaveRule,
  apiGetAssignLeave,
  apiGetleaveRule,
} from "services/LeaveRuleService"

export const SLICE_NAME = "leaveRule"

const initialState = {
  leaveActiveTabs: "leave",
  leaveRule: {
    loading: false,
    leaveRuleData: [],
    leaveRuleSearchQuery: "",
    leaveRuleTotalData: 0,
    leaveRuleCurrentPage: 1,
  },
  assignLeave: {
    loading: false,
    leaveAssignData: [],
    leaveAssignSearchQuery: "",
    leaveAssignTotalData: 0,
    leaveAssignCurrentPage: 1,
    selectedEmployee: [],
    openAssignDeleteModal: false,
    deleteAssignEmployee: [],
  },
}

export const fetchleaveRule = createAsyncThunk(
  `${SLICE_NAME}/fetchleaveRule`,
  async ({ keyword, length, start, employee_id }) => {
    try {
      const response = await apiGetleaveRule({ keyword, length, start, employee_id })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const fetchAssignLeave = createAsyncThunk(
  `${SLICE_NAME}/fetchAssignLeave`,
  async ({ keyword, length, start }) => {
    try {
      const response = await apiGetAssignLeave({ keyword, length, start })
      return {
        status: "success",
        data: response?.data,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const createLeaveRule = createAsyncThunk(
  `${SLICE_NAME}/createLeaveRule`,
  async data => {
    try {
      const response = await apiCreateleaveRule(data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const assignLeave = createAsyncThunk(
  `${SLICE_NAME}/assignLeaveRule`,
  async ({ id, data }) => {
    try {
      const response = await apiAssignLeave(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const editLeaveRule = createAsyncThunk(
  `${SLICE_NAME}/editLeaveRule`,
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await apiEditleaveRule(id, data)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

export const deleteAssignLeave = createAsyncThunk(
  `${SLICE_NAME}/deleteAssignLeave`,
  async id => {
    try {
      const response = await apiDeleteAssignLeave(id)
      return {
        status: "success",
        data: response,
      }
    } catch (error) {
      return {
        status: "failed",
        message: error?.response?.data?.message || error.toString(),
      }
    }
  }
)

const leaveRule = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLeaveActiveTab: (state, action) => {
      state.leaveActiveTabs = action.payload
    },
    setLeaveRulePage: (state, action) => {
      state.leaveRule.leaveRuleCurrentPage = action.payload
    },
    setLeaveRuleQuery: (state, action) => {
      state.leaveRule.leaveRuleSearchQuery = action.payload
    },

    // Assign Leave
    setAssignLeavePage: (state, action) => {
      state.assignLeave.leaveAssignCurrentPage = action.payload
    },
    setAssignLeaveQuery: (state, action) => {
      state.assignLeave.leaveAssignSearchQuery = action.payload
    },
    setSelectedEmployee: (state, action) => {
      state.assignLeave.selectedEmployee = action.payload
    },
    setDeleteAssignEmployee: (state, action) => {
      state.assignLeave.deleteAssignEmployee = action.payload
    },
    setOpenAssignDeleteModal: (state, action) => {
      state.assignLeave.openAssignDeleteModal = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchleaveRule.pending, state => {
      state.leaveRule.loading = true
    })
    builder.addCase(fetchleaveRule.fulfilled, (state, action) => {
      state.leaveRule.loading = false
      state.leaveRule.leaveRuleData = action?.payload?.data?.data
      state.leaveRule.leaveRuleTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchleaveRule.rejected, state => {
      state.loading = false
    })

    // Assign Leave
    builder.addCase(fetchAssignLeave.pending, state => {
      state.assignLeave.loading = true
    })
    builder.addCase(fetchAssignLeave.fulfilled, (state, action) => {
      state.assignLeave.loading = false
      state.assignLeave.leaveAssignData = action?.payload?.data?.data
      state.assignLeave.leaveAssignTotalData = action?.payload?.data?.total
    })
    builder.addCase(fetchAssignLeave.rejected, state => {
      state.loading = false
    })
  },
})

export const {
  setLeaveRulePage,
  setLeaveRuleQuery,
  setLeaveActiveTab,
  setAssignLeavePage,
  setAssignLeaveQuery,
  setSelectedEmployee,
  setOpenAssignDeleteModal,
  setDeleteAssignEmployee,
} = leaveRule.actions

export default leaveRule.reducer
