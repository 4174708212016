import React, { useState, useMemo } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  editAddressDetail,
  fetchCity,
  fetchCountry,
  fetchPermanentCity,
  fetchPermanentState,
  fetchProfileDetail,
  fetchState,
  setEditAddressDetail,
} from "store/onBoarding/onBordingSlice"

const OnbordingAddressForm = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [stateValue, setStateValue] = useState("")
  const [cityValue, setCityValue] = useState("")
  const [permanentCityValue, setPermanentCityValue] = useState("")
  const [permanentStateValue, setPermanentStateValue] = useState("")

  const {
    countryData,
    stateData,
    cityData,
    permanentStateData,
    permanentCityData,
    profileDetails,
    userId,
  } = useSelector(state => state.onBoarding)

  useMemo(() => {
    setStateValue(profileDetails?.address?.c_location_state_id)
    setCityValue(profileDetails?.address?.c_location_master_id)
    setPermanentStateValue(profileDetails?.address?.p_location_state_id)
    setPermanentCityValue(profileDetails?.address?.p_location_master_id)
  }, [profileDetails])

  const handleCountry = async () => {
    await setLoading(true)
    try {
      await dispatch(fetchCountry())
    } catch (error) {
      await setLoading(false)
    } finally {
      await setLoading(false)
    }
  }

  const handleState = async (country_id, type) => {
    await setLoading(true)
    try {
      if (type === "c_location_state") {
        await dispatch(fetchState(country_id))
      } else {
        await dispatch(fetchPermanentState(country_id))
      }
    } catch (error) {
      await setLoading(false)
    } finally {
      await setLoading(false)
    }
  }

  const handleCity = async (state_id, type) => {
    await setLoading(true)
    try {
      if (type === "c_location_city") {
        await dispatch(fetchCity(state_id))
      } else {
        await dispatch(fetchPermanentCity(state_id))
      }
    } catch (error) {
      await setLoading(false)
    } finally {
      await setLoading(false)
    }
  }

  useMemo(() => {
    dispatch(fetchCountry())
    dispatch(fetchState(profileDetails?.address?.c_location_country_id))
    dispatch(fetchCity(profileDetails?.address?.c_location_state_id))
    dispatch(
      fetchPermanentState(profileDetails?.address?.p_location_country_id)
    )
    dispatch(fetchPermanentCity(profileDetails?.address?.p_location_state_id))
  }, [profileDetails])

  const validationSchema = Yup.object().shape({
    c_address_line_1: Yup.string().required("Required"),
    c_address_line_2: Yup.string(),
    c_landmark: Yup.string(),
    c_location_country_id: Yup.string().required("Required"),
    c_location_state_id: Yup.string().required("Required"),
    c_location_master_id: Yup.string().required("Required"),
    c_pincode: Yup.string()
      .matches(/^\d+$/, "That doesn't look like a Picode")
      .min(6, "At Least 6 Digit")
      .max(6, "At Most 6 Digit")
      .required("Required"),
    p_address_line_2: Yup.string(),
    p_landmark: Yup.string(),
    p_address_line_1: Yup.string().when("is_parmanet_address", {
      is: false,
      then: Yup.string().required("Required"),
    }),
    p_location_country_id: Yup.string().when("is_parmanet_address", {
      is: false,
      then: Yup.string().required("Required"),
    }),
    p_location_state_id: Yup.string().when("is_parmanet_address", {
      is: false,
      then: Yup.string().required("Required"),
    }),
    p_location_master_id: Yup.string().when("is_parmanet_address", {
      is: false,
      then: Yup.string().required("Required"),
    }),
    p_pincode: Yup.string().when("is_parmanet_address", {
      is: false,
      then: Yup.string()
        .matches(/^\d+$/, "That doesn't look like a Picode")
        .min(6, "At Least 6 Digit")
        .max(6, "At Most 6 Digit")
        .required("Required"),
    }),
  })

  return (
    <>
      <div className="d-flex justify-content-center">
        <Row className="profile-container">
          <Col xl="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={{
                    c_address_line_1:
                      profileDetails?.address?.c_address_line_1 || "",
                    c_address_line_2: profileDetails?.address?.c_address_line_2
                      ? profileDetails?.address?.c_address_line_2
                      : "",
                    c_landmark: profileDetails?.address?.c_landmark
                      ? profileDetails?.address?.c_landmark
                      : "",
                    c_location_country_id:
                      profileDetails?.address?.c_location_country_id || "",
                    c_location_state_id:
                      profileDetails?.address?.c_location_state_id || "",
                    c_location_master_id:
                      profileDetails?.address?.c_location_master_id || "",
                    c_pincode: profileDetails?.address?.c_pincode || "",
                    p_address_line_1:
                      profileDetails?.address?.p_address_line_1 || "",
                    p_address_line_2: profileDetails?.address?.p_address_line_2
                      ? profileDetails?.address?.p_address_line_2
                      : "",
                    p_landmark: profileDetails?.address?.p_landmark || "",
                    p_location_country_id:
                      profileDetails?.address?.p_location_country_id || "",
                    p_location_state_id:
                      profileDetails?.address?.p_location_state_id || "",
                    p_location_master_id:
                      profileDetails?.address?.p_location_master_id || "",
                    p_pincode: profileDetails?.address?.p_pincode || "",
                    is_parmanet_address:
                      profileDetails?.address?.is_parmanet_address == 1
                        ? true
                        : false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (
                    values,
                    { setSubmitting, resetForm, setFieldValue }
                  ) => {
                    if (values?.is_parmanet_address) {
                      values.p_address_line_1 = values.c_address_line_1
                      values.p_address_line_2 = values.c_address_line_2
                      values.p_landmark = values.c_landmark
                      values.p_location_country_id =
                        values.c_location_country_id
                      values.p_location_state_id = values.c_location_state_id
                      values.p_location_master_id = values.c_location_master_id
                      values.p_pincode = values.c_pincode
                    }
                    values.is_parmanet_address = values.is_parmanet_address
                      ? 1
                      : 2
                    const payload = {
                      ...values,
                      id: userId,
                    }
                    const response = await dispatch(editAddressDetail(payload))
                    try {
                      if (response.payload?.status === "success") {
                        toastr.success(response?.payload?.data?.data?.message)
                      } else {
                        toastr.error(response?.payload?.message)
                      }
                    } catch (error) {
                      toastr.error("Something Went Wrong Please Try Latter !")
                    }
                    await dispatch(fetchProfileDetail(userId))
                    await dispatch(setEditAddressDetail(false))
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    errors,
                    touched,
                    dirty,
                  }) => (
                    <Form>
                      <Row>
                        <Col md={12}>
                          <CardTitle className="header-text">
                            Addresses
                          </CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md={12}>
                          <CardTitle className="header-text">
                            Current Address
                          </CardTitle>
                          <hr className="underline" />
                        </Col>
                        <Col md="5" className="mb-3">
                          <Label className="labels" htmlFor="c_address_line_1">
                            Address line 1{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="c_address_line_1"
                            placeholder="Address line 1"
                            type="text"
                            className={`form-control ${
                              touched.c_address_line_1 &&
                              errors.c_address_line_1
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="c_address_line_1"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="5" className="mb-3">
                          <Label className="labels" htmlFor="c_address_line_2">
                            Address line 2
                          </Label>
                          <Field
                            name="c_address_line_2"
                            placeholder="Address line 2"
                            type="text"
                            className={`form-control ${
                              touched.c_address_line_2 &&
                              errors.c_address_line_2
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="c_address_line_2"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="2" className="mb-3">
                          <Label className="labels" htmlFor="c_landmark">
                            Landmark
                          </Label>
                          <Field
                            name="c_landmark"
                            placeholder="Landmark"
                            type="text"
                            className={`form-control ${
                              touched.c_landmark && errors.c_landmark
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="c_landmark"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3 pointer">
                          <Label
                            className="labels"
                            htmlFor="c_location_country_id"
                          >
                            Country <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="c_location_country_id"
                            value={countryData?.find(
                              option =>
                                option.value === values?.c_location_country_id
                            )}
                            onChange={option => {
                              setFieldValue(
                                "c_location_country_id",
                                option.value
                              )
                              setStateValue("")
                              setCityValue("")
                              setFieldValue("c_location_state_id", "")
                              setFieldValue("c_location_master_id", "")
                            }}
                            options={countryData}
                            onFocus={handleCountry}
                            onMenuOpen={handleCountry}
                            isLoading={loading}
                            classNamePrefix="select2-selection"
                            style={{ maxHeight: "100px" }}
                          />
                          <ErrorMessage
                            name="c_location_country_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label
                            className="labels"
                            htmlFor="c_location_state_id"
                          >
                            State <span className="text-danger">*</span>
                          </Label>
                          <Select
                            isDisabled={!values.c_location_country_id}
                            name="c_location_state_id"
                            onFocus={() => {
                              handleState(
                                values?.c_location_country_id,
                                "c_location_state"
                              )
                            }}
                            onMenuOpen={() => {
                              handleState(
                                values.c_location_country_id,
                                "c_location_state"
                              )
                            }}
                            value={
                              (stateValue?.length > 0 &&
                                stateData?.find(
                                  option => option.value == stateValue
                                )) ||
                              stateValue
                            }
                            onChange={option => {
                              setFieldValue(
                                "c_location_state_id",
                                option?.value
                              )
                              setStateValue(option?.value)
                            }}
                            isLoading={loading}
                            options={stateData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="c_location_state_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label
                            className="labels"
                            htmlFor="c_location_master_id"
                          >
                            City <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="c_location_master_id"
                            isDisabled={!values.c_location_state_id}
                            isLoading={loading}
                            onFocus={() => {
                              handleCity(
                                values.c_location_state_id,
                                "c_location_city"
                              )
                            }}
                            onMenuOpen={() => {
                              handleCity(
                                values.c_location_state_id,
                                "c_location_city"
                              )
                            }}
                            value={
                              (cityValue?.length > 0 &&
                                cityData?.find(
                                  option => option.value == cityValue
                                )) ||
                              cityValue
                            }
                            onChange={option => {
                              setFieldValue(
                                "c_location_master_id",
                                option.value
                              )
                              setCityValue(option.value)
                            }}
                            options={cityData}
                            classNamePrefix="select2-selection"
                          />
                          <ErrorMessage
                            name="c_location_master_id"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label className="labels" htmlFor="c_pincode">
                            Pincode <span className="text-danger">*</span>
                          </Label>
                          <Field
                            name="c_pincode"
                            placeholder="Pincode"
                            type="number"
                            className={`form-control ${
                              touched.c_pincode && errors.c_pincode
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="c_pincode"
                            component="div"
                            className="text-danger"
                          />
                        </Col>
                        <Col>
                          <div>
                            <Input
                              type="checkbox"
                              name="is_parmanet_address"
                              className="form-check-input"
                              defaultChecked={values.is_parmanet_address}
                              onChange={e => {
                                setFieldValue(
                                  "is_parmanet_address",
                                  !values.is_parmanet_address
                                )
                              }}
                            />
                            <Label
                              className="mx-2 labels"
                              htmlFor="is_parmanet_address"
                            >
                              Is Permanent Address Same as Current Address
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      {!values.is_parmanet_address && (
                        <Row className="">
                          <Col md={12}>
                            <CardTitle className="header-text">
                              Permanent Address
                            </CardTitle>
                            <hr className="underline" />
                          </Col>
                          <Col md="5" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="p_address_line_1"
                            >
                              Address line 1{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="p_address_line_1"
                              placeholder="Address line 1"
                              type="text"
                              className={`form-control ${
                                touched.p_address_line_1 &&
                                errors.p_address_line_1
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="p_address_line_1"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="5" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="p_address_line_2"
                            >
                              Address line 2
                            </Label>
                            <Field
                              name="p_address_line_2"
                              placeholder="Address line 2"
                              type="text"
                              className={`form-control ${
                                touched.p_address_line_2 &&
                                errors.p_address_line_2
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="p_address_line_2"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="2" className="mb-3">
                            <Label className="labels" htmlFor="p_landmark">
                              Landmark
                            </Label>
                            <Field
                              name="p_landmark"
                              placeholder="Landmark"
                              type="text"
                              className={`form-control ${
                                touched.p_landmark && errors.p_landmark
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="p_landmark"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="p_location_country_id"
                            >
                              Country <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="p_location_country_id"
                              value={countryData.find(
                                option =>
                                  option.value === values.p_location_country_id
                              )}
                              onChange={option => {
                                setFieldValue(
                                  "p_location_country_id",
                                  option.value
                                )
                                setPermanentStateValue("")
                                setPermanentCityValue("")
                                setFieldValue("p_location_state_id", "")
                                setFieldValue("p_location_master_id", "")
                              }}
                              options={countryData}
                              classNamePrefix="select2-selection"
                              onFocus={handleCountry}
                              onMenuOpen={handleCountry}
                              isLoading={loading}
                            />
                            <ErrorMessage
                              name="p_location_country_id"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="p_location_state_id"
                            >
                              State <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isDisabled={!values?.p_location_country_id}
                              name="p_location_state_id"
                              onFocus={() => {
                                handleState(
                                  values.p_location_country_id,
                                  "p_location_state"
                                )
                              }}
                              onMenuOpen={() => {
                                handleState(
                                  values.p_location_country_id,
                                  "p_location_state"
                                )
                              }}
                              value={
                                (permanentStateValue?.length > 0 &&
                                  permanentStateData?.find(
                                    option =>
                                      option.value == permanentStateValue
                                  )) ||
                                permanentStateValue
                              }
                              onChange={option => {
                                setFieldValue(
                                  "p_location_state_id",
                                  option.value
                                )
                                setPermanentStateValue(option.value)
                              }}
                              options={permanentStateData}
                              classNamePrefix="select2-selection"
                            />
                            <ErrorMessage
                              name="p_location_state_id"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label
                              className="labels"
                              htmlFor="p_location_master_id"
                            >
                              City <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isDisabled={!values?.p_location_state_id}
                              name="p_location_master_id"
                              onFocus={() => {
                                handleCity(
                                  values.p_location_state_id,
                                  "p_location_city"
                                )
                              }}
                              onMenuOpen={() => {
                                handleCity(
                                  values.p_location_state_id,
                                  "p_location_city"
                                )
                              }}
                              value={
                                (permanentCityValue?.length > 0 &&
                                  permanentCityData?.find(
                                    option =>
                                      option?.value == permanentCityValue
                                  )) ||
                                permanentCityValue
                              }
                              onChange={option => {
                                setFieldValue(
                                  "p_location_master_id",
                                  option.value
                                )
                                setPermanentCityValue(option?.value)
                              }}
                              options={permanentCityData}
                              classNamePrefix="select2-selection"
                            />
                            <ErrorMessage
                              name="p_location_master_id"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                          <Col md="3" className="mb-3">
                            <Label className="labels" htmlFor="p_pincode">
                              Pincode <span className="text-danger">*</span>
                            </Label>
                            <Field
                              name="p_pincode"
                              placeholder="Pincode"
                              type="number"
                              className={`form-control ${
                                touched.p_pincode && errors.p_pincode
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="p_pincode"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </Row>
                      )}
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <button
                          className="d-flex align-items-center btn btn-md btn-outline-secondary"
                          type="reset"
                          onClick={() => {
                            dispatch(setEditAddressDetail(false))
                          }}
                        >
                          <i className="dripicons-cross mx-1 d-flex" />
                          Cancel
                        </button>
                        <button
                          className="btn btn-md btn-primary"
                          type="submit"
                          disabled={isSubmitting || !dirty}
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default OnbordingAddressForm
