import React, { useState } from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  attendanceRequest,
  fetchEmployeeAttendanceLog,
  setEmployeeLogModal,
  setEmployeeRowData,
} from "store/attendanceLogs/attendanceLogSlice"
import { formatDate } from "constants/CommonFunction"

const AttendanceEmployeeForm = () => {
  const {
    employeeLogsModal,
    employeeRowData,
    employeeLogsCurrentPage,
    employeeLogsDateRange,
    employeeLogStatus,
    employeeLogSearchQuery,
  } = useSelector(state => state.attendanceLog.attendanceEmployeeLog)
  const location = useLocation()
  const dispatch = useDispatch()
  const validationSchema = Yup.object({
    request_for: Yup.number().required("Required"),
    remark: Yup.string(),
    in_time: Yup.string().when("request_for", {
      is: 2,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    out_time: Yup.string().when("request_for", {
      is: 2,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
  })

  const toggle = () => {
    dispatch(setEmployeeLogModal(false))
    dispatch(setEmployeeRowData([]))
  }

  return (
    <Modal isOpen={employeeLogsModal} toggle={toggle} size="lg" centered>
      <ModalHeader className="header-text" toggle={toggle}>
        Anomaly Details
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
              initialValues={{
                request_for: 1,
                remark: "",
                in_time: "",
                out_time: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                try {
                  const response = await dispatch(
                    attendanceRequest({
                      id: employeeRowData?.employee_attendance_id,
                      data: values,
                    })
                  )
                  console.log(response, "Employee response")
                  if (response?.payload?.status == "success") {
                    toastr.success(response?.payload?.data?.data?.message)
                    // After the modal action is completed, trigger a data refresh
                    const { employee_id } = location?.state?.row;
                    if (employee_id) {
                      dispatch(
                        fetchEmployeeAttendanceLog({
                          id: employee_id,
                          length: 10,
                          start: employeeLogsCurrentPage,
                          from_date: employeeLogsDateRange[0] ? formatDate(employeeLogsDateRange[0], 1) : "",
                          to_date: employeeLogsDateRange[1] ? formatDate(employeeLogsDateRange[1], 1) : "",
                          employee_status: employeeLogStatus,
                          keyword: employeeLogSearchQuery,
                        })
                      );
                    }
                  } else {
                    toastr.error(response?.payload?.message)
                    dispatch(setEmployeeLogModal(false))
                  }
                } catch (error) {
                  console.log(error);
                  toastr.error(error || "Something Went Wrong")
                  dispatch(setEmployeeLogModal(false))
                }
                
                dispatch(setEmployeeLogModal(false))
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                setFieldValue,
                values,
                dirty,
              }) => (
                <Form>
                  <Row>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="employeeName">
                        {`${employeeRowData?.employee_first_name} 
                        ${employeeRowData?.employee_last_name}`}
                      </Label>
                      <div>{employeeRowData?.employee_emp_id}</div>
                    </Col>
                    {/* <Col md="4" className="mb-3">
                      <Label htmlFor="department">Department</Label>
                      <div>Technology</div>
                    </Col>
                    <Col md="4" className="mb-3">
                      <Label htmlFor="manager">Manager</Label>
                      <div>Brijesh Yadadv</div>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col md="12" className="mb-3">
                      <Label>Mark Anomaly</Label>
                      <div className="d-flex">
                        <div className="form-check me-4">
                          <Input
                            type="radio"
                            name="request_for"
                            value="1"
                            defaultChecked={values?.request_for === 1}
                            onChange={() => {
                              setFieldValue("request_for", 1)
                            }}
                            className="form-check-input"
                            id="markAsPresent"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="markAsPresent"
                          >
                            Mark As Present
                          </Label>
                        </div>
                        <div className="form-check">
                          <Input
                            type="radio"
                            name="request_for"
                            value="2"
                            defaultChecked={values?.request_for === 2}
                            onChange={() => {
                              setFieldValue("request_for", 2)
                            }}
                            className="form-check-input"
                            id="markExactTime"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="markExactTime"
                          >
                            Mark Exact Time
                          </Label>
                        </div>
                      </div>
                      <ErrorMessage
                        name="request_for"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                    {values?.request_for === 2 && (
                      <>
                        <Col md={3}>
                          <label>
                            Time In<span className="text-danger">*</span>
                          </label>
                          <Field
                            name="in_time"
                            type="time"
                            className={`form-control ${
                              touched.in_time && errors.in_time
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="in_time"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md={3}>
                          <label>
                            Time Out<span className="text-danger">*</span>
                          </label>
                          <Field
                            name="out_time"
                            type="time"
                            className={`form-control ${
                              touched.out_time && errors.out_time
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="out_time"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </>
                    )}
                    <Col md="12" className="mb-3 mt-3">
                      <Label htmlFor="remark">Remark</Label>
                      <Field
                        as="textarea"
                        placeholder="Remark"
                        name="remark"
                        rows="3"
                        className={`form-control ${
                          touched.remark && errors.remark ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="remark"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <button
                      className="d-flex align-items-center btn btn-md btn-outline-secondary"
                      type="reset"
                      onClick={toggle}
                    >
                      <i className="dripicons-cross mx-1 d-flex" />
                      Cancel
                    </button>
                    <button
                      className="btn btn-md btn-primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default AttendanceEmployeeForm
