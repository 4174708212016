import React, { useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap"
import GlobalTable from "components/Datatable/GlobalTable"
import Pagination from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import { Search } from "components/Datatable"
import { Link } from "react-router-dom"
import { deduction } from "constants/Constants"
import {
  fetchDeductionRule,
  setDeductionRuleModal,
  setRuleCurrentPage,
  setRuleSearch,
  setRuleTableRowData,
} from "store/deductionRule/deductionRuleSlice"

export const DeductionRewardRuleView = () => {
  const dispatch = useDispatch()
  const { rulecurrentPage, ruleDeductionData, ruleTotalData, ruleSearchQuery } =
    useSelector(state => state.deductionRule)
  const columns = useMemo(() => {
    return [
      {
        Header: "Sr. No.",
        accessor: (_row, index) => index + 1,
        width: 120,
        Cell: ({ row }) => (
          <div>{(rulecurrentPage - 1) * 10 + (row.index + 1)}</div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Ordering",
        accessor: "ordering",
        Cell: ({ value }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div
            style={{ width: "60px" }}
            className={`text-white d-flex justify-content-center text-center rounded ${
              value == 1 ? "bg-success" : "bg-danger"
            }`}
          >
            <span style={{ padding: "2px" }}>
              {value == 1 ? "Active" : "Inactive"}
            </span>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "actions",
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              <i
                onClick={async () => {
                  await dispatch(setRuleTableRowData(row?.original))
                  await dispatch(setDeductionRuleModal(true))
                }}
                className="bx bxs-edit cursor-pointer "
                style={{ fontSize: "17px", color: "#039f03" }}
              />
            </div>
            <div>
              <Link
                to={{
                  pathname: "/deduction-rewards-mapping",
                  state: row.original,
                }}
              >
                <i
                  onClick={async () => {}}
                  className="fas fa-eye cursor-pointer "
                  style={{ fontSize: "14px", color: "grey" }}
                />
              </Link>
            </div>
          </div>
        ),
      },
    ]
  }, [dispatch, rulecurrentPage])

  const handleAdd = async () => {
    await dispatch(setRuleTableRowData([]))
    await dispatch(setDeductionRuleModal(true))
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        fetchDeductionRule({
          length: 10,
          start: rulecurrentPage,
          keyword: ruleSearchQuery.length > 0 ? ruleSearchQuery : undefined,
        })
      )
    }
    fetchData()
  }, [rulecurrentPage, ruleSearchQuery, dispatch])

  return (
    <div className="row">
      <div className="col-md-12">
        <h5 className="mb-3">DEDUCTION & REWARD RULES</h5>
        <Card>
          <CardBody>
            <Row className=" align-items-center">
              <Col md={6} sm={12}>
                {" "}
                <Search
                  onSearch={value => {
                    dispatch(setRuleSearch(value))
                    dispatch(setRuleCurrentPage(1))
                  }}
                />
              </Col>
              <Col
                md={6}
                sm={12}
                className="mb-2 d-flex justify-content-end flex-wrap"
              >
                <Button
                  className="bg-primary mx-2 mb-2 mb-md-0 px-2 d-flex align-items-center"
                  onClick={handleAdd}
                >
                  <i
                    className="dripicons-plus mx-2 d-flex"
                    style={{ border: "1px solid white", borderRadius: "50%" }}
                  />
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                className="overflow-auto"
                style={{
                  height: "56vh",
                  scrollbarWidth: "thin",
                  padding: "0px",
                }}
              >
                <GlobalTable
                  columns={columns}
                  data={ruleDeductionData ? ruleDeductionData : []}
                  loading={false}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter
            className="d-flex justify-content-end"
            style={{
              background: "#ECECEC",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <div
              style={{
                height: "30px",
                marginLeft: "0px",
              }}
            >
              <Pagination
                className="mx-2"
                currentPage={rulecurrentPage}
                totalCount={ruleTotalData ? ruleTotalData : 0}
                pageSize={10}
                onPageChange={async page => {
                  await dispatch(setRuleCurrentPage(page))
                }}
              />
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}
