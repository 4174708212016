import { getUserId } from "constants/CommonFunction"
import { ApiService } from "./ApiService"

export async function apiProfileDetail(id) {
  return ApiService.fetchData({
    url: `users/profileDetail/${id}`,
    method: "get",
  })
}

export async function apiGetCountry() {
  return ApiService.fetchData({
    url: `location_country_master`,
    method: "get",
  })
}

export async function apiGetState(country_id) {
  return ApiService.fetchData({
    url: `location_state_master`,
    method: "get",
    params: { country_id },
  })
}

export async function apiGetCity(state_id) {
  return ApiService.fetchData({
    url: `location_master`,
    method: "get",
    params: { state_id },
  })
}

export async function apieditPersonalInfo(data) {
  return ApiService.fetchData({
    url: `users/updatePersonalDetails`,
    method: "put",
    data: data,
  })
}

export async function apiEditContactDetail(data) {
  return ApiService.fetchData({
    url: `users/updateContactDetails`,
    method: "put",
    data: data,
  })
}

export async function apiEditEmergencyContact(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `users/updateEmergencyDetails`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditAddress(data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `users/updateAddress`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditSalaryDetail(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `users/updateSalaryDetails/${id}`,
    method: "put",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}

export async function apiEditKycDocument(id, data) {
  return ApiService.fetchData({
    url: `users/upload_kyc_documents/${id}`,
    method: "post",
    data: data,
  })
}

export async function apiUploadImage(id, data) {
  return ApiService.fetchData({
    url: `users/uploadProfilePhoto/${id}`,
    method: "post",
    data: data,
  });
}


export async function apiApproveProfile(id, data) {
  const urlEncodedPayload = new URLSearchParams(data).toString()
  return ApiService.fetchData({
    url: `users/approveProfile/${id}`,
    method: "Post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: urlEncodedPayload,
  })
}