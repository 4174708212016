import React, { useEffect, useMemo, useState } from "react"
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Formik, ErrorMessage, Form } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { mappingTypeOptions } from "constants/Constants"
import {
  createExpenseMapping,
  editExpenseMapping,
  setExpenseMappingModal,
  fetchExpenseRewardMapping,
  fetchEmployeeFormData,
  fetchValueExpenseData
} from "store/expenseRule/expenseRuleSlice"
import { getUserId } from "constants/CommonFunction"
import toastr from "toastr"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

export const ExpenseMappingForm = () => {
  const dispatch = useDispatch()
  const {
    openExpenseMappingModal,
    mappingcurrentPage,
    expensemappingRowData,
    employeeFormData,
    valueFormData
  } = useSelector(state => state.expenseRule)
  const location = useLocation()
  const [valueData, setValueData] = useState("")
  const [employeeData, setEmployeeData] = useState("")

  const validationSchema = Yup.object({
    type: Yup.string().required("Required"),
    value: Yup.number().required("Required"),
    level: Yup.number()
      .required("Required")
      .typeError("Must be a number")
      .positive("Must be a positive number"),
      employee_id: Yup.string().required("Required"),
  })

  const toggle = async () => {
    await dispatch(setExpenseMappingModal(false))
  }

  useMemo(() => {
    setEmployeeData(expensemappingRowData?.employee_id || "")
    setValueData(expensemappingRowData?.value || "")
  }, [
    expensemappingRowData?.employee_id,
    openExpenseMappingModal,
  ])
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchValueExpenseData())
      await dispatch(fetchEmployeeFormData())
    }
    fetchData()
  }, [dispatch])

  return (
    <Modal
      isOpen={openExpenseMappingModal}
      toggle={toggle}
      size="lg"
      centered
    >
      <ModalHeader toggle={toggle}>{`${expensemappingRowData.id ? "Edit " : "Add "} Expense Rule Mapping`}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xl="12">
            <Formik
            enableReinitialize={true}
              initialValues={{
                type: expensemappingRowData?.type
                  ? Number(expensemappingRowData?.type)
                  : 0,
                value: expensemappingRowData?.value ?
                Number(expensemappingRowData?.value)
                : "",
                level: expensemappingRowData?.level
                  ? expensemappingRowData?.level
                  : null,
                employee_id: expensemappingRowData?.employee_id
                ? Number(expensemappingRowData?.employee_id)
                : "",
                status: expensemappingRowData?.status
                  ? expensemappingRowData?.status
                  : 0,
                ultimate_approval: expensemappingRowData?.ultimate_approval
                  ? expensemappingRowData?.ultimate_approval
                  : 0,
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                const payload = {
                  ...values,
                  expense_rule_master_id: location.state.id,
                }
                const response =
                  Object.keys(expensemappingRowData).length > 0
                    ? await dispatch(
                        editExpenseMapping({
                          id: expensemappingRowData?.id,
                          data: payload,
                        })
                      ).unwrap()
                    : await dispatch(createExpenseMapping(payload)).unwrap()
                try {
                  if (response.data?.status == true) {
                    toastr.success(response?.data?.message)
                  } else {
                    toastr.error(response?.payload?.message)
                  }
                } catch (error) {
                  toastr.error("Something Went Wrong Please Try Latter")
                }
                await dispatch(
                  fetchExpenseRewardMapping({
                    length: 10,
                    start: mappingcurrentPage,
                    expense_rule_master_id: location?.state?.id,
                  })
                )
                await dispatch(setExpenseMappingModal(false))
              }}
            >
              {({
                values,
                setFieldValue,
                touched,
                errors,
                isSubmitting,
                dirty,
              }) => (
                <Form>
                  <Row>
                    <Col md={3}>
                      <div className="form-check form-switch form-switch-md mb-2">
                        <Input
                          name="status"
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked={values?.status == 0 ? false : true}
                          onChange={e => {
                            setFieldValue("status", e.target.checked ? 1 : 0)
                          }}
                        />
                        <Label className="mx-2 fs-6" htmlFor="status">
                          Status
                        </Label>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="form-check form-switch form-switch-md mb-2">
                        <Input
                          name="ultimate_approval"
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked={
                            values.ultimate_approval == 0 ? false : true
                          }
                          onChange={e => {
                            setFieldValue(
                              "ultimate_approval",
                              e.target.checked ? 1 : 0
                            )
                          }}
                        />
                        <Label
                          className="mx-2 fs-6"
                          htmlFor="ultimate_approval"
                        >
                          Ultimate Approval
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="type">
                        Type
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="type"
                        value={mappingTypeOptions?.find(
                          option => option.value == values.type
                        )}
                        onChange={option => {
                          setFieldValue("type", option?.value);
                          if (option.value == 0) {
                            setFieldValue("employee_id", 0);
                            setFieldValue("value", 0);
                            setValueData("");
                            setEmployeeData("");
                          } else {
                            setFieldValue("employee_id", "");
                            setFieldValue("value", "");
                            setValueData("");
                            setEmployeeData("");
                          }
                        }}

                        options={mappingTypeOptions}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="value">
                        Value
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="value"
                        isDisabled={!values?.type}
                        value={
                          (valueData?.length > 0 &&
                            valueFormData?.find(
                              option => option.value == valueData
                            )) ||
                          valueData
                        }
                        // value={valueData}
                        onChange={option => {
                          setValueData(
                            valueFormData?.find(
                              option => option.value == values.value
                            )
                          )
                          setFieldValue("value", Number(option.value))
                        }}
                        options={valueFormData}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="value"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="level">
                        Level
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="level"
                        type="number"
                        placeholder="Level"
                        value={values.level}
                        onChange={e =>
                          setFieldValue("level", Number(e.target.value))
                        }
                      />
                      <ErrorMessage
                        name="level"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label htmlFor="employee">
                        Employee
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="employee_id"
                        isDisabled={!values?.type}
                        value={
                          (employeeData?.length > 0 &&
                            employeeFormData?.find(
                              option => option.value == employeeData
                            )) ||
                          employeeData
                        }
                        onChange={option => {
                          setEmployeeData(
                            employeeFormData?.find(
                              option => option.value == values.employee
                            )
                          )
                          setFieldValue(
                            "employee_id",
                            Number(option.value)
                          )
                        }}
                        options={employeeFormData}
                        classNamePrefix="select2-selection"
                      />
                      <ErrorMessage
                        name="employee_id"
                        component="div"
                        className="text-danger"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !dirty}
                    >
                      {Object.keys(expensemappingRowData).length > 0 ? (
                        <>{isSubmitting ? "Updating..." : "Update"}</>
                      ) : (
                        <>{isSubmitting ? "Submitting..." : "Submit"}</>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
