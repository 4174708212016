import React from "react"
import MetaTags from "react-meta-tags"
import {Button, Card, CardBody, Col, Container, Label, Row,} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SimpleReactValidator from 'simple-react-validator'
import {get, put} from "../../../helpers/api_helper"
import { EDIT_BRAND_MASTER, EDIT_BUSINESS_CATEGORY_MASTER, GET_BRAND_MASTER, GET_BUSINESS_CATEGORY_MASTER} from "../../../helpers/api_url_helper"
import toastr from "toastr"
import PropTypes from "prop-types"
import Select from "react-select";


class BusinessCategoryEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      brand: "",
      brand_master_id:"",
      status: true,
      handleResponse: null,
      submit: false
    }
    this.validator = new SimpleReactValidator({autoForceUpdate: this})
  }

  componentDidMount() {
    this.getBrand();
    this.getBusinessCategory();
  }

  getBrand = () => {
    get(GET_BRAND_MASTER).then(res => {
      if (res.data) {
        let values = res.data.map((brand)=>{
          return {value:brand.id, label:brand.name}
        })
        this.setState({
          brand_master: values,
        })
      }else {
        toastr.error("Data load issue try again!")
      }
    }).catch(err => {
      toastr.error(err?.response?.data?.message)
      // eslint-disable-next-line react/prop-types
      const { history } = this.props;
      // eslint-disable-next-line react/prop-types
      history.push("/business-category");
    })
  }

  
  getBusinessCategory = () => {
    get(GET_BUSINESS_CATEGORY_MASTER, {params: {id: this.props.match.params.id}}).then(res => {
      if (res.data) {
        this.setState({
          id: res.data.id,
          business_category: res.data.name,
          ordering: res.data.ordering,
          status: res.data.status=="1"?1:0,
          brand_master_id: res.data.brand_master_id,
          brand_master_Selected: {value:res.data.brand_master_id, label: res.data.brand_master_name},
        })
      }else {
        toastr.error("Data load issue try again!")
      }
    }).catch(err => {
      toastr.error(err?.response?.data?.message)
      // eslint-disable-next-line react/prop-types
      const { history } = this.props;
      // eslint-disable-next-line react/prop-types
      // history.push("/business-category");
    })
  }

  handleInput = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      const formData = {
        "business_category":this.state.business_category,
        "ordering" : this.state.ordering,
        "status" : this.state.status ? 1 : 0,
        "id":this.props.match.params.id,
        "brand_master_id" : this.state.brand_master_id,
      }
      this.setState({submit: true})
      put(EDIT_BUSINESS_CATEGORY_MASTER,formData).then(response => {
        if (response.status) {
          this.setState({submit: false})
          toastr.success('business category update successful.')
          // eslint-disable-next-line react/prop-types
          const {history} = this.props
          // eslint-disable-next-line react/prop-types
          history.push('/business-category')
        }
      }).catch(err => {
        toastr.error(err?.response?.data?.message)
        this.setState({submit: false})
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  handleChangeBrand=(e)=>{
    this.setState({
      brand_master_Selected: e,
      brand_master_id:e.value
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
            Manage Business Category | {process.env.REACT_APP_PROJECTNAME}
            </title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Business Category" path="/business-category" breadcrumbItem="Edit"/>
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <form onSubmit={this.handleFormSubmit}>
                      <Row>
                        <Col md="4" className="mb-3">
                          <Label htmlFor="brand">Brand <span className="text-danger">*</span></Label>
                          <Select value={this.state.brand_master_Selected} isMulti={false} name="brand" options={this.state.brand_master} onChange={e =>  this.handleChangeBrand(e)} classNamePrefix="select2-selection" isDisabled={true} />
                          {this.validator.message("brand_master_id", this.state.brand_master_id, "required")}
                        </Col>
                        <Col md="3" className="mb-3">
                          <Label htmlFor="business_category">Business Category</Label>
                          <input name="business_category" placeholder="Type Businessn Category" defaultValue={this.state.business_category} type="text" className={"form-control"} onChange={this.handleInput}/>
                          {this.validator.message('business_category', this.state.business_category, 'required')}
                        </Col>
                        <Col md="2" className="mb-3">
                          <Label htmlFor="ordering">Ordering</Label>
                          <input name="ordering" placeholder="Type Ordering" defaultValue={this.state.ordering} type="text" className={"form-control"} onChange={this.handleInput}/>
                          {this.validator.message('ordering', this.state.ordering, 'required')}
                        </Col>
                      </Row>
                      <div className="d-flex flex-wrap gap-2 justify-content-end">
                        <Button color="primary" type="submit" disabled={this.state.submit}>
                          {this.state.submit === true ? 'Please wait...' : 'Submit Data'}
                        </Button>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

BusinessCategoryEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}

export default BusinessCategoryEdit
