export const employmentType = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
]

export const bloodGroups = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
]

export const maritalStatuses = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
]

export const relation = [
  { value: "Mother", label: "Mother" },
  { value: "Father", label: "Father" },
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
  { value: "Wife", label: "Wife" },
  { value: "Husband", label: "Husband" },
  { value: "Son", label: "Son" },
  { value: "Daughter", label: "Daughter" },
]

export const deduction = [
  { document_name: "Rule A", ordering: 1, status: "Active" },
  { document_name: "Rule B", ordering: 2, status: "Inactive" },
  { document_name: "Rule C", ordering: 3, status: "Active" },
  { document_name: "Rule D", ordering: 4, status: "Inactive" },
  { document_name: "Rule E", ordering: 5, status: "Active" },
  { document_name: "Rule F", ordering: 6, status: "Inactive" },
  { document_name: "Rule G", ordering: 7, status: "Active" },
  { document_name: "Rule H", ordering: 8, status: "Inactive" },
  { document_name: "Rule I", ordering: 9, status: "Active" },
  { document_name: "Rule J", ordering: 10, status: "Inactive" },
]

export const documentData = [
  { value: 1, label: "Aadhar Card" },
  { value: 2, label: "Pan Card" },
  { value: 3, label: "Liecense" },
  { value: 4, label: "Passport" },
  { value: 5, label: "Voter Id" },
]

export const mappingTypeOptions = [
  { value: 0, label: "Reporting Manager" },
  { value: 1, label: "Designation" },
]

export const rewardType = [
  { label: "Deduction", value: 0 },
  { label: "Reward", value: 1 },
]

export const penaltyOptions = [
  { value: "1", label: "Half Day" },
  { value: "2", label: "Full Day" },
]

export const workingDaysOptions = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
]

export const leaveTypes = [
  { value: "annual", label: "Annual Leave" },
  { value: "sick", label: "Sick Leave" },
  { value: "casual", label: "Casual Leave" },
  { value: "maternity", label: "Maternity Leave" },
  { value: "paternity", label: "Paternity Leave" },
  { value: "bereavement", label: "Bereavement Leave" },
  { value: "unpaid", label: "Unpaid Leave" },
  { value: "study", label: "Study Leave" },
  { value: "compensatory", label: "Compensatory Leave" },
  { value: "halfday", label: "Half Day Leave" },
]

export const selectHalf = [
  { value: 1, label: "First Half" },
  { value: 2, label: "Second Half" },
]

export const leaveTabledata = [
  {
    EmployeeName: "John Doe",
    Department: "HR",
    EmployeeManager: "Jane Smith",
    StartDate: "1st June 2024",
    EndDate: "5th June 2024",
    DaysApplied: 5,
    Status: "Approved",
  },
  {
    EmployeeName: "Alice Johnson",
    Department: "Engineering",
    EmployeeManager: "Robert Brown",
    StartDate: "10th June 2024",
    EndDate: "12th June 2024",
    DaysApplied: 3,
    Status: "Pending",
  },
  {
    EmployeeName: "Michael Lee",
    Department: "Marketing",
    EmployeeManager: "Susan Wilson",
    StartDate: "20th June 2024",
    EndDate: "22nd June 2024",
    DaysApplied: 3,
    Status: "Rejected",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "1st July 2024",
    EndDate: "3rd July 2024",
    DaysApplied: 3,
    Status: "Approved",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "10th July 2024",
    EndDate: "12th July 2024",
    DaysApplied: 3,
    Status: "Pending",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "15th July 2024",
    EndDate: "17th July 2024",
    DaysApplied: 3,
    Status: "Approved",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "20th July 2024",
    EndDate: "22nd July 2024",
    DaysApplied: 3,
    Status: "Rejected",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "25th July 2024",
    EndDate: "27th July 2024",
    DaysApplied: 3,
    Status: "Approved",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "1st August 2024",
    EndDate: "3rd August 2024",
    DaysApplied: 3,
    Status: "Pending",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "10th August 2024",
    EndDate: "12th August 2024",
    DaysApplied: 3,
    Status: "Approved",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "15th August 2024",
    EndDate: "17th August 2024",
    DaysApplied: 3,
    Status: "Pending",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "20th August 2024",
    EndDate: "22nd August 2024",
    DaysApplied: 3,
    Status: "Approved",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "25th August 2024",
    EndDate: "27th August 2024",
    DaysApplied: 3,
    Status: "Rejected",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "1st September 2024",
    EndDate: "3rd September 2024",
    DaysApplied: 3,
    Status: "Approved",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    StartDate: "10th September 2024",
    EndDate: "12th September 2024",
    DaysApplied: 3,
    Status: "Pending",
  },
]

export const attendeTabledata = [
  {
    EmployeeName: "John Doe",
    Department: "HR",
    EmployeeManager: "Jane Smith",
    InTime: "9:00 AM",
    OutTime: "5:00 PM",
  },
  {
    EmployeeName: "Alice Johnson",
    Department: "Engineering",
    EmployeeManager: "Robert Brown",
    InTime: "8:30 AM",
    OutTime: "4:30 PM",
  },
  {
    EmployeeName: "Michael Lee",
    Department: "Marketing",
    EmployeeManager: "Susan Wilson",
    InTime: "10:00 AM",
    OutTime: "6:00 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
  {
    EmployeeName: "Jessica White",
    Department: "Finance",
    EmployeeManager: "Daniel Kim",
    InTime: "9:15 AM",
    OutTime: "5:15 PM",
  },
]

export function convertTime(timeString) {
  const [hoursStr, minutesStr, secondsStr] = timeString?.split(":")
  let hours = parseInt(hoursStr, 10)
  const minutes = parseInt(minutesStr, 10)
  const formattedHours = hours.toString()?.padStart(2, "0")
  const formattedMinutes = minutes.toString()?.padStart(2, "0")
  return `${formattedHours}:${formattedMinutes}`
}

export const getInitialWorkingDays = (selectedValues, workingDaysOptions) => {
  const newValues = selectedValues?.split(",")
  return workingDaysOptions?.filter(option =>
    newValues?.includes(option?.value)
  )
}

export function months() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return months.map((month, index) => ({
    label: month,
    value: (index + 1).toString().padStart(2, "0"),
  }))
}

export const documnetFields = [
  "address_proof_permanent",
  "address_proof_temporary",
  "id_proof",
  "address_proof_permanent_doc_type",
  "address_proof_permanent_number",
  "address_proof_temporary_doc_type",
  "address_proof_temporary_number",
  "id_proof_doc_type",
  "id_proof_number",
]

export const statusType = [
  // { label: "All", value: 0 },
  { label: "Pending", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Rejected", value: 2 },
]

export const tabItems = [
  { id: "1", icon: "fas fa-user", label: "Personal", key: "Personal" },
  { id: "2", icon: "far fa-sticky-note", label: "Work", key: "work" },
  { id: "3", icon: "fas fa-file-alt", label: "Document", key: "document" },
  { id: "4", icon: "fas fa-calendar-check", label: "Attendance", key: "attendance" },
  { id: "5", icon: "fas fa-calendar-minus", label: "Leave", key: "leave" },
  // { id: "6", icon: "fas fa-info-circle", label: "Additional Details", key: "details" },
  { id: "6", icon: "fab fa-facebook-messenger", label: "E - Exit", key: "exit" },
]

export const CompanyOverviewTabItems=[
  {id:"1", icon: "fas fa-user", label:"Overview"},
  {id:"2", icon: "far fa-sticky-note",label:"Address"},
  {id:"3",icon: "fas fa-file-alt",label:"Policies"}
]

export const reviews = [
  { value: "", label: "All" },
  { value: 1, label: "Approved" },
  { value: 2, label: "Rejected" },
  { value: 0, label: "Pending" },
]

export const logStatusData = [
  { value: "", label: "All" },
  { value: 1, label: "Present" },
  { value: 2, label: "Absent" },
  { value: 3, label: "Anomaly" },
  { value: 4, label: "Requested" },
]

export const logApprovalStatusData = [
  { value: 1,  key: "", label: "All" },
  { value: 2, key: 0, label: "Pending" },
  { value: 3, key: 1, label: "Approved" },
  { value: 4, key: 2, label: "Rejected" },
]
export const logStatusData1 = [
  { value: "", label: "All" },
  { value: 0, label: "Present" },
  { value: 1, label: "Approved" },
  { value: 2, label: "Rejected" },
]
export const leaveLogsStatus = [
  { label: "All", value: "" },
  { label: "Pending", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Rejected", value: 2 },
  { label: "Cancelled", value: 3 },
]

export const customStyles = {
  control: base => ({
    ...base,
    height: 35,
    minHeight: 35,
    cursor: "pointer",
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
  }),
  }),
}

export const multiSelectStyles = {
  control: base => ({
    ...base,
    cursor: "pointer",
  }),
}
// Helper function to check if a user is valid
export const isValidUser = user =>
  user?.id &&
  user?.employee_first_name &&
  user?.employee_last_name &&
  (user?.profile_photo || user?.profile_photo === "")

export const accrual_freq = [
  { value: 1, label: "Monthly" },
  { value: 2, label: "Quaterly" },
  { value: 3, label: "Half Yearly" },
]

export const accrual_credit = [
  { value: 1, label: "Start" },
  { value: 2, label: "End" },
]

export const status = [
  { value: 0, label: "Unassigned" },
  { value: 1, label: "Assigned" },
]