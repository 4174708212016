import React from "react"
import { LeaveRule } from "./LeaveRule"
import { AssignLeave } from "./AssignLeave"
import { useDispatch, useSelector } from "react-redux"
import { setLeaveActiveTab } from "store/leaveRule/leaveRuleSlice"
import "../../../MultiPageLink.css"
import LeaveNavTabs from "components/LeaveNavTabs/LeaveNavTabs"
import { useLocation } from "react-router-dom"

export const LeaveRuleList = () => {
  const dispatch = useDispatch()
  const { leaveActiveTabs } = useSelector(state => state.leaveRule)
  const { leaves } = useSelector(state => state.users.userPermissions);
  const handleTabClick = tabName => {
    dispatch(setLeaveActiveTab(tabName))
  }

  const location = useLocation()
  const activeTab = location.pathname
  return (
    <div className={activeTab.includes("/profile/view") ? "" : "page-content"}>
      <div className={activeTab.includes("/profile/view") ? "" : "container-fluid"}>
        <LeaveNavTabs />
        <div className="tab-container">
          <h5
            onClick={() => handleTabClick("leave")}
            className={`tab-item fs-5 ${
              leaveActiveTabs === "leave" ? "active-tab" : ""
            }`}
          >
            Leave
          </h5>
          {(leaves?.rules?.assign_rule && !location?.state?.row?.id) &&<h5
            onClick={() => handleTabClick("assignLeave")}
            className={`tab-item fs-5 ${
              leaveActiveTabs === "assignLeave" ? "active-tab" : ""
            }`}
          >
            Assign Leave
          </h5>}
        </div>
        {leaveActiveTabs == "leave" ? <LeaveRule /> : <AssignLeave />}
      </div>
    </div>
  )
}
