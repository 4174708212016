import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import toastr from "toastr";

// Pass new generated access token here
const token = accessToken;

// Apply base URL for axios
const API_URL = process.env.REACT_APP_APIURL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}
  
export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}

// Handle 401 Unauthorized responses
axiosApi.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    // Get current location
    const currentPath = window.location.pathname;

    // Redirect to login page with next query parameter
    window.location.href = `/login?next=${currentPath}`;
  } else {
    return Promise.reject(error);
  }
});